import sortBy from 'lodash/sortBy';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useContainerDimensions from '../../../../custom-hooks/use-container-dimensions';
import {
  AbsoluteLoadingIndicatorContainer,
  ClearInputIcon,
  ColumnCenteredDiv,
  EndAlignedColumnDiv,
  EndAlignedLargeTinyText,
  ExtraSmallCompleteButton,
  LightDarkExtraTinyText,
  LightDarkSMText,
  MediumDarkTinyText,
  ModularWidthInput,
  RedCancelIcon,
  SelectIcon,
} from '../../../../styles/shared-styled-components';
import {
  addOnKey,
  alternativeSuggestionMappingKey,
  clarifyOptionsMappingKey,
  completeStatusKey,
  deferMappingKey,
  directMappingKey,
  discontinuedSessionTypeStatus,
  dropInKey,
  incompleteStatusKey,
  notApplicableMappingKey,
  optionsMap,
  packageKey,
  sessionTypeBookableStatusAbbreviatedLabelMap,
  sessionTypeTypesMap,
  stagingFilterConversionDelay,
  unbookableSessionTypeStatus,
  unclassifiedKey,
} from '../../../../utils/constants';
import { objectIsEmpty } from '../../../../utils/data';
import { getTimestamp } from '../../../../utils/date';
import { formatKeyToLabel } from '../../../../utils/string';
import { BaseContext } from '../../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../LoadingIndicator';
import EditTrainingResolutionExplanationModal from '../../../Modals/EditTrainingResolutionExplanationModal';
import {
  ActionCard,
  ActionConnectorTextContainer,
  ActionContainer,
  ActionDescriptionText,
  ActionIconContainer,
  ActionsContainer,
  ActionSubtitleText,
  ActionTextContainer,
  ActionTitleText,
  CancelIconContainer,
  ConfirmMenuContainer,
  EmptyDataContainer,
  EmptySelectOptionsContainer,
  EmptySelectOptionsText,
  ExplanationIcon,
  MappingActionsContainer,
  MappingContainer,
  MappingResolutionContainer,
  MustAddOfferingsText,
  OfferingAttachmentButtonContainer,
  OfferingAttachmentContainer,
  OfferingAttachmentText,
  OfferingCancelIcon,
  OfferingCancelIconContainer,
  OfferingContainer,
  OfferingsListContainer,
  OfferingTitleText,
  OptionCheckbox,
  OptionItemContainer,
  OptionRadioButton,
  OptionsListContainer,
  OptionsSearchBoxContainer,
  OptionSubitemSubtitleText,
  OptionSubitemText,
  ResolutionActionCard,
  ResolutionActionsContainer,
  ResolutionDescriptionText,
  ResolutionExplanationContainer,
  ResolutionExplanationHeaderTextContainer,
  ResolutionExplanationText,
  ResolutionExplanationTextContainer,
  ResolutionTextContainer,
  ResolutionTitleText,
  SelectCheckbox,
  SelectOptionsContainer,
  SelectOptionsTitleTextContainer,
  ServicesAutoSaveContainer,
  ServicesAutoSaveText,
} from '../../shared-training-components';

const optionKeys = Object.keys(optionsMap);

const getBaseResolutionObject = (type, existingResolutions) => {
  let executionOrder;
  const numResolutions = existingResolutions.length;
  if (!numResolutions) {
    executionOrder = 0;
  } else {
    executionOrder = existingResolutions[numResolutions - 1].executionOrder + 1;
  }

  return {
    id: uuidv4(),
    type,
    offerings: [],
    explanation: null,
    executionOrder,
  };
};

const checkIfCanBeCompleted = (resolutions) => {
  const numResolutions = resolutions.length;
  let canBeCompleted = !!numResolutions;

  for (let i = 0; i < numResolutions; i++) {
    const resolution = resolutions[i];
    const { type, offerings } = resolution;
    if (type !== notApplicableMappingKey && type !== deferMappingKey) {
      const numOfferings = offerings.length;
      canBeCompleted =
        type === clarifyOptionsMappingKey ? numOfferings > 1 : numOfferings > 0;
    }
    if (!canBeCompleted) {
      break;
    }
  }

  return canBeCompleted;
};

const getUpdatedStatus = (currentStatus, canBeCompleted) => {
  return canBeCompleted && currentStatus === completeStatusKey
    ? completeStatusKey
    : incompleteStatusKey;
};

const filterServices = ({
  services,
  searchFilter,
  offeringIdAttachingAddOnsFor,
}) => {
  const filteredServices =
    !searchFilter && !offeringIdAttachingAddOnsFor
      ? services
      : services.filter((s) => {
          let shouldReturn = true;
          if (offeringIdAttachingAddOnsFor) {
            shouldReturn = s.type === addOnKey;
          }
          if (searchFilter && shouldReturn) {
            shouldReturn = s.displayName?.toLowerCase().includes(searchFilter);
          }
          return shouldReturn;
        });
  return filteredServices;
};

const ServiceMapping = ({
  serviceCorpus,
  sessionTypeCatalog,
  loading,
  agentName,
  selectedPrompt,
  onChangePromptResolutions,
  onChangePromptStatus,
  remainingHeight,
}) => {
  const { drawerOpen, drawerExpanded } = useContext(BaseContext);

  const containsValidPrompt = !objectIsEmpty(selectedPrompt);

  const selectedPromptCategoryId = selectedPrompt?.categoryId;
  const category = serviceCorpus[selectedPromptCategoryId];
  const categoryName = formatKeyToLabel(category?.name);

  const categoryPrompts = category?.prompts || {};
  const promptObject = categoryPrompts[selectedPrompt?.id];
  const prompt = promptObject?.prompt;

  const currentStatus = prompt?.status;
  const promptId = prompt?.id;
  const lastSavedTimestamp = getTimestamp(
    prompt?.updatedAt,
    false,
    false,
    true,
  );
  const utterance = prompt?.utterance;

  const resolutions = sortBy(
    promptObject?.resolutions || [],
    (p) => p.executionOrder,
  );

  const [viewingResolutionKey, setViewingResolutionKey] = useState();
  const [availableSessionTypes, setAvailableSessionTypes] = useState([]);
  const [stagingSearchFilter, setStagingSearchFilter] = useState('');
  const [offeringTypeFilter, setOfferingTypeFilter] = useState(dropInKey);
  const [offeringIdAttachingAddOnsFor, setOfferingIdAttachingAddOnsFor] =
    useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [explanationModalOpen, setExplanationModalOpen] = useState(false);
  const [explanationEditing, setExplanationEditing] = useState();

  const mustSelectOffering =
    viewingResolutionKey &&
    viewingResolutionKey !== notApplicableMappingKey &&
    viewingResolutionKey !== deferMappingKey;

  const mappingContainerRef = useRef();
  const mappingContainerDimensions = useContainerDimensions(
    mappingContainerRef,
    [drawerOpen, drawerExpanded],
  );
  const mappingContainerWidth = mappingContainerDimensions?.width;

  const reset = () => {
    setViewingResolutionKey();
    setExplanationEditing();
    setOfferingIdAttachingAddOnsFor();
  };

  useEffect(() => {
    reset();
  }, [promptId]);

  useEffect(() => {
    if (sessionTypeCatalog) {
      const allCategories = Object.values(sessionTypeCatalog?.categories);
      let allSessionTypes = [];
      allCategories.map((c) => {
        const allCategoryServices = [
          ...(c?.dropIns || []),
          ...(c?.consultations || []),
          ...(c?.addOns || []),
        ];
        allSessionTypes.push({
          categoryId: c.id,
          categoryName: c.name,
          services: allCategoryServices,
        });
      });
      allSessionTypes.push({
        categoryId: packageKey,
        categoryName: sessionTypeTypesMap[packageKey],
        services: Object.values(sessionTypeCatalog?.packages) || [],
      });
      setAvailableSessionTypes(allSessionTypes);
    }
  }, [sessionTypeCatalog]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingSearchFilter !== searchFilter) {
        setSearchFilter(stagingSearchFilter);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingSearchFilter]);

  const resolutionKeys = resolutions.map((a) => a?.type);
  const isUnbookable = resolutionKeys.includes(notApplicableMappingKey);
  const isBookable = resolutions.length && !isUnbookable;

  const viewingResolution = resolutions.find(
    (r) => r.type === viewingResolutionKey,
  );
  const resolutionOfferings = viewingResolution?.offerings || [];

  const onUpdatePromptResolutions = (updatedPromptResolutions) => {
    const canBeCompleted = checkIfCanBeCompleted(updatedPromptResolutions);
    const updatedStatus = getUpdatedStatus(currentStatus, canBeCompleted);

    onChangePromptResolutions(updatedStatus, updatedPromptResolutions);
  };

  const onUpdateResolutionOfferings = ({
    shouldSelect,
    resolutionKeyToUpdate,
    offering,
    multiSelectAllowed,
    offeringIdForAddOn = null,
  }) => {
    const updatedResolutions = resolutions.map((r) => {
      if (r.type === resolutionKeyToUpdate) {
        const newOfferingId = offering.id;
        const currentOfferings = r['offerings'] || [];

        if (shouldSelect) {
          if (!offeringIdForAddOn) {
            return {
              ...r,
              offerings: multiSelectAllowed
                ? [...currentOfferings, offering]
                : [offering],
            };
          } else {
            // Attach add on to associated offering
            const updatedOfferings = currentOfferings.map((o) => {
              const currentAddOns = [...(o.addOns || [])];

              if (o.id === offeringIdForAddOn) {
                const updatedAddOns = [...currentAddOns, offering];
                return {
                  ...o,
                  addOns: updatedAddOns,
                };
              }
              return o;
            });
            return {
              ...r,
              offerings: updatedOfferings,
            };
          }
        } else {
          if (!offeringIdForAddOn) {
            return {
              ...r,
              offerings: currentOfferings.filter((o) => o.id !== newOfferingId),
            };
          } else {
            const updatedOfferings = currentOfferings.map((o) => {
              const currentAddOns = [...(o.addOns || [])];

              if (o.id === offeringIdForAddOn) {
                const updatedAddOns = currentAddOns.filter(
                  (addOn) => addOn.id !== newOfferingId,
                );
                return {
                  ...o,
                  addOns: updatedAddOns,
                };
              }
              return o;
            });
            return {
              ...r,
              offerings: updatedOfferings,
            };
          }
        }
      }
      return r;
    });

    const canBeCompleted = checkIfCanBeCompleted(updatedResolutions);
    const updatedStatus = getUpdatedStatus(currentStatus, canBeCompleted);

    onChangePromptResolutions(updatedStatus, updatedResolutions);
  };

  const onUpdateResolutionExplanation = (
    resolutionKeyToUpdate,
    explanation,
  ) => {
    const updatedResolutions = resolutions.map((r) => {
      if (r.type === resolutionKeyToUpdate) {
        return {
          ...r,
          explanation,
        };
      }
      return r;
    });

    const canBeCompleted = checkIfCanBeCompleted(updatedResolutions);
    const updatedStatus = getUpdatedStatus(currentStatus, canBeCompleted);

    onChangePromptResolutions(updatedStatus, updatedResolutions);
  };

  const savingText = `Last auto-saved ${lastSavedTimestamp}`;

  if (loading) {
    return (
      <AbsoluteLoadingIndicatorContainer>
        <LoadingIndicator />
      </AbsoluteLoadingIndicatorContainer>
    );
  }

  const sortedResolutions = sortBy(resolutions, (r) => r.executionOrder);
  const canBeCompleted = checkIfCanBeCompleted(sortedResolutions);

  const updatedStatus = getUpdatedStatus(currentStatus, canBeCompleted);

  return (
    <>
      <MappingContainer>
        {containsValidPrompt ? (
          <>
            <MappingResolutionContainer
              remainingHeight={remainingHeight}
              ref={mappingContainerRef}
            >
              <ResolutionTextContainer>
                <ResolutionTitleText>Prompt</ResolutionTitleText>
                <ResolutionDescriptionText>
                  When someone is talking to {agentName} about {categoryName},
                  and says:
                  <br></br>
                  <br></br>
                  <i>"{utterance}"</i>
                </ResolutionDescriptionText>
              </ResolutionTextContainer>
              <ResolutionTextContainer>
                <ResolutionTitleText start>Response</ResolutionTitleText>
                <ResolutionDescriptionText>
                  {agentName} should respond by doing the following:
                </ResolutionDescriptionText>
              </ResolutionTextContainer>
              <ResolutionActionsContainer>
                {sortedResolutions.map((resolution, idx) => {
                  const resolutionKey = resolution.type;
                  const explanation = resolution?.explanation;
                  const offerings = resolution?.offerings || [];
                  const numOfferings = offerings.length;
                  const doesntRequireOffering =
                    resolutionKey === notApplicableMappingKey ||
                    resolutionKey === deferMappingKey;
                  const containsMinimumRequiredOfferings = doesntRequireOffering
                    ? true
                    : resolutionKey === clarifyOptionsMappingKey
                    ? numOfferings >= 2
                    : !!numOfferings;
                  const viewing = resolutionKey === viewingResolutionKey;
                  return (
                    <>
                      <ResolutionActionCard
                        disableBoxShadow={!viewing}
                        onClick={() => {
                          if (viewing) {
                            setViewingResolutionKey();
                            setOfferingIdAttachingAddOnsFor();
                          } else {
                            setViewingResolutionKey(resolutionKey);
                          }
                        }}
                      >
                        <ColumnCenteredDiv>
                          <ActionDescriptionText>
                            {optionsMap[resolutionKey]?.description}
                            {!doesntRequireOffering && `:`}
                          </ActionDescriptionText>
                          {!!numOfferings && (
                            <OfferingsListContainer>
                              {offerings.map((o) => {
                                const offeringId = o.id;
                                const offeringType = o.type;
                                const addOns = o.addOns || [];
                                return (
                                  <>
                                    <OfferingContainer>
                                      <OfferingTitleText>
                                        {o.name}
                                      </OfferingTitleText>
                                      <OfferingCancelIconContainer>
                                        <OfferingCancelIcon
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            onUpdateResolutionOfferings({
                                              shouldSelect: false,
                                              resolutionKeyToUpdate:
                                                resolutionKey,
                                              offering: { id: offeringId },
                                              multiSelectAllowed: true,
                                            });
                                            setOfferingIdAttachingAddOnsFor();
                                          }}
                                        />
                                      </OfferingCancelIconContainer>
                                    </OfferingContainer>
                                    {offeringType === dropInKey && (
                                      <>
                                        {addOns.map((a) => (
                                          <OfferingAttachmentContainer>
                                            <LightDarkExtraTinyText>
                                              With Add-On:
                                              <br></br>'{a.name}'
                                            </LightDarkExtraTinyText>
                                            <OfferingCancelIconContainer>
                                              <OfferingCancelIcon
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  onUpdateResolutionOfferings({
                                                    shouldSelect: false,
                                                    resolutionKeyToUpdate:
                                                      resolutionKey,
                                                    offering: {
                                                      id: a.id,
                                                    },
                                                    multiSelectAllowed: true,
                                                    offeringIdForAddOn:
                                                      offeringId,
                                                  });
                                                }}
                                              />
                                            </OfferingCancelIconContainer>
                                          </OfferingAttachmentContainer>
                                        ))}
                                        <OfferingAttachmentButtonContainer
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            setStagingSearchFilter('');
                                            setSearchFilter('');
                                            setOfferingIdAttachingAddOnsFor(
                                              offeringId,
                                            );
                                            setViewingResolutionKey(
                                              resolutionKey,
                                            );
                                          }}
                                          selected={
                                            offeringIdAttachingAddOnsFor
                                          }
                                        >
                                          <OfferingAttachmentText>
                                            Attach add-ons
                                          </OfferingAttachmentText>
                                        </OfferingAttachmentButtonContainer>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </OfferingsListContainer>
                          )}
                          {!containsMinimumRequiredOfferings && (
                            <ResolutionExplanationContainer>
                              <MustAddOfferingsText>
                                {resolutionKey === clarifyOptionsMappingKey
                                  ? `Must add at least two options`
                                  : `None added`}
                              </MustAddOfferingsText>
                            </ResolutionExplanationContainer>
                          )}
                          {containsMinimumRequiredOfferings ? (
                            <ResolutionExplanationContainer>
                              <ResolutionExplanationTextContainer>
                                <ResolutionExplanationHeaderTextContainer
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setExplanationEditing({
                                      resolutionKey,
                                      value: explanation,
                                    });
                                    setExplanationModalOpen(true);
                                  }}
                                >
                                  <ResolutionExplanationText>
                                    {explanation ? (
                                      <>Including explanation</>
                                    ) : (
                                      `Attach explanation (optional)`
                                    )}
                                  </ResolutionExplanationText>
                                  <ExplanationIcon />
                                </ResolutionExplanationHeaderTextContainer>
                                {explanation && (
                                  <>
                                    <ResolutionExplanationText>
                                      "{explanation}"
                                    </ResolutionExplanationText>
                                  </>
                                )}
                              </ResolutionExplanationTextContainer>
                            </ResolutionExplanationContainer>
                          ) : (
                            <></>
                          )}
                        </ColumnCenteredDiv>
                        <CancelIconContainer>
                          <RedCancelIcon
                            onClick={(event) => {
                              event.stopPropagation();
                              onUpdatePromptResolutions(
                                resolutions.filter(
                                  (r) => r.type !== resolutionKey,
                                ),
                              );
                              if (viewing) {
                                setViewingResolutionKey();
                                setOfferingIdAttachingAddOnsFor();
                              }
                            }}
                          />
                        </CancelIconContainer>
                      </ResolutionActionCard>
                      {idx !== resolutions.length - 1 && (
                        <ActionConnectorTextContainer>
                          <MediumDarkTinyText>and then</MediumDarkTinyText>
                        </ActionConnectorTextContainer>
                      )}
                    </>
                  );
                })}
              </ResolutionActionsContainer>
              {mappingContainerWidth ? (
                <ConfirmMenuContainer
                  width={mappingContainerWidth}
                  drawerOpen={drawerOpen}
                  drawerExpanded={drawerExpanded}
                  submoduleDrawerExpanded
                  smallPadding
                >
                  <ExtraSmallCompleteButton
                    onClick={() => onChangePromptStatus(completeStatusKey)}
                    disabled={!canBeCompleted}
                  >
                    {updatedStatus === completeStatusKey
                      ? `Completed ✓`
                      : `Mark complete`}
                  </ExtraSmallCompleteButton>
                  <ServicesAutoSaveContainer>
                    <ServicesAutoSaveText>{savingText}</ServicesAutoSaveText>
                  </ServicesAutoSaveContainer>
                </ConfirmMenuContainer>
              ) : (
                <></>
              )}
            </MappingResolutionContainer>
            <MappingActionsContainer
              hideBorder={!mustSelectOffering}
              remainingHeight={remainingHeight}
            >
              <ColumnCenteredDiv>
                <ResolutionTitleText>
                  Select actions for {agentName} to execute
                </ResolutionTitleText>
                <ActionsContainer>
                  {optionKeys.map((key) => {
                    const option = optionsMap[key];
                    const { label, subtitle } = option;
                    const viewing = viewingResolutionKey === key;
                    const selected = resolutionKeys.includes(key);

                    let canBeSelected = true;
                    if (key === notApplicableMappingKey) {
                      canBeSelected = !isBookable;
                    } else if (key !== alternativeSuggestionMappingKey) {
                      canBeSelected = !isUnbookable;
                    }
                    const disabled = !canBeSelected;

                    return (
                      <ActionContainer>
                        <ActionCard
                          selected={selected}
                          disabled={disabled}
                          disableBoxShadow={!viewing}
                          onClick={() => {
                            if (viewing) {
                              setViewingResolutionKey();
                              setOfferingIdAttachingAddOnsFor();
                            } else if (canBeSelected && !viewing) {
                              setViewingResolutionKey(key);

                              if (!selected) {
                                onUpdatePromptResolutions([
                                  ...resolutions,
                                  getBaseResolutionObject(
                                    key,
                                    sortedResolutions,
                                  ),
                                ]);
                              }
                            }
                          }}
                        >
                          <ActionIconContainer>
                            <SelectCheckbox
                              onClick={(event) => {
                                event.stopPropagation();

                                if (selected) {
                                  onUpdatePromptResolutions(
                                    resolutions.filter((r) => r.type !== key),
                                  );
                                  setViewingResolutionKey();
                                  setOfferingIdAttachingAddOnsFor();
                                } else if (canBeSelected) {
                                  onUpdatePromptResolutions([
                                    ...resolutions,
                                    getBaseResolutionObject(
                                      key,
                                      sortedResolutions,
                                    ),
                                  ]);
                                  setViewingResolutionKey(key);
                                }
                              }}
                              disabled={disabled}
                              checked={selected}
                            />
                          </ActionIconContainer>
                          <ActionTextContainer>
                            <ActionTitleText disabled={disabled}>
                              {label}
                            </ActionTitleText>
                            <ActionSubtitleText disabled={disabled}>
                              {subtitle}
                            </ActionSubtitleText>
                          </ActionTextContainer>
                        </ActionCard>
                      </ActionContainer>
                    );
                  })}
                </ActionsContainer>
              </ColumnCenteredDiv>
            </MappingActionsContainer>
            <SelectOptionsContainer>
              {mustSelectOffering ? (
                <>
                  <SelectOptionsTitleTextContainer>
                    <ResolutionTitleText end>
                      Select{' '}
                      {offeringIdAttachingAddOnsFor ? `Add-Ons` : `offerings`}
                    </ResolutionTitleText>
                  </SelectOptionsTitleTextContainer>
                  <OptionsSearchBoxContainer moveUp>
                    <ModularWidthInput
                      value={stagingSearchFilter}
                      onChange={(e) => setStagingSearchFilter(e.target.value)}
                      placeholder='Search'
                      InputProps={{
                        endAdornment: (
                          <ClearInputIcon
                            onClick={() => setStagingSearchFilter('')}
                          />
                        ),
                      }}
                      extraSmallHeight
                    />
                  </OptionsSearchBoxContainer>
                  <OptionsListContainer remainingHeight={remainingHeight}>
                    {sortBy(availableSessionTypes, (s) =>
                      s.categoryName === sessionTypeTypesMap[packageKey]
                        ? 'zzz'
                        : s.categoryName === unclassifiedKey
                        ? 'zz'
                        : s.categoryName,
                    )?.map((sessionTypeCategory) => {
                      const { categoryName, services } = sessionTypeCategory;
                      const categoryLabel = `${
                        categoryName || 'Unknown Category'
                      }`;

                      const filteredServices = filterServices({
                        services,
                        searchFilter,
                        offeringIdAttachingAddOnsFor,
                      });

                      const numServices = filteredServices.length;
                      return numServices ? (
                        <>
                          <OptionItemContainer top>
                            <div />
                            <EndAlignedLargeTinyText>
                              {categoryLabel}
                            </EndAlignedLargeTinyText>
                          </OptionItemContainer>
                          {sortBy(filteredServices, (s) => s.displayName).map(
                            (s, idx) => {
                              const {
                                id: offeringId,
                                displayName: name,
                                type,
                                bookableStatus,
                              } = s;

                              let formattedOffering = {
                                id: offeringId,
                                name,
                                type:
                                  categoryName ===
                                  sessionTypeTypesMap[packageKey]
                                    ? packageKey
                                    : type,
                              };
                              if (type === dropInKey) {
                                formattedOffering['addOns'] = [];
                              }

                              let offeringSelected;

                              if (!offeringIdAttachingAddOnsFor) {
                                offeringSelected = resolutionOfferings
                                  .map((o) => o.id)
                                  .includes(offeringId);
                              } else {
                                offeringSelected = resolutionOfferings
                                  .find(
                                    (o) =>
                                      o.id === offeringIdAttachingAddOnsFor,
                                  )
                                  ?.addOns?.map((a) => a.id)
                                  ?.includes(offeringId);
                              }

                              const disabled = [
                                unbookableSessionTypeStatus,
                                discontinuedSessionTypeStatus,
                              ].includes(bookableStatus);

                              return (
                                <OptionItemContainer
                                  bottom={idx === numServices - 1}
                                  disabled={disabled}
                                >
                                  <div>
                                    {viewingResolutionKey ===
                                      directMappingKey &&
                                    !offeringIdAttachingAddOnsFor ? (
                                      <OptionRadioButton
                                        checked={offeringSelected}
                                        disabled={disabled}
                                        onChange={() => {
                                          if (!disabled) {
                                            onUpdateResolutionOfferings({
                                              shouldSelect: !offeringSelected,
                                              resolutionKeyToUpdate:
                                                viewingResolutionKey,
                                              offering: formattedOffering,
                                              multiSelectAllowed: false,
                                            });
                                          }
                                        }}
                                      />
                                    ) : (
                                      <OptionCheckbox
                                        checked={offeringSelected}
                                        disabled={disabled}
                                        onClick={() => {
                                          if (!disabled) {
                                            onUpdateResolutionOfferings({
                                              shouldSelect: !offeringSelected,
                                              resolutionKeyToUpdate:
                                                viewingResolutionKey,
                                              offering: formattedOffering,
                                              multiSelectAllowed: true,
                                              offeringIdForAddOn:
                                                offeringIdAttachingAddOnsFor,
                                            });
                                          }
                                        }}
                                      />
                                    )}
                                  </div>
                                  <EndAlignedColumnDiv>
                                    <OptionSubitemText>
                                      {s.displayName}
                                    </OptionSubitemText>
                                    <OptionSubitemSubtitleText
                                      status={bookableStatus}
                                    >
                                      {
                                        sessionTypeBookableStatusAbbreviatedLabelMap[
                                          bookableStatus
                                        ]
                                      }
                                    </OptionSubitemSubtitleText>
                                  </EndAlignedColumnDiv>
                                </OptionItemContainer>
                              );
                            },
                          )}
                        </>
                      ) : (
                        <></>
                      );
                    })}
                  </OptionsListContainer>
                </>
              ) : (
                <EmptySelectOptionsContainer>
                  <EmptySelectOptionsText>
                    Select an action to edit offerings for
                  </EmptySelectOptionsText>
                  <SelectIcon />
                </EmptySelectOptionsContainer>
              )}
            </SelectOptionsContainer>
          </>
        ) : (
          <EmptyDataContainer ref={mappingContainerRef}>
            <LightDarkSMText>Select a flow to edit</LightDarkSMText>
            <SelectIcon />
          </EmptyDataContainer>
        )}
      </MappingContainer>
      <EditTrainingResolutionExplanationModal
        isOpen={explanationModalOpen}
        onClose={() => {
          setExplanationModalOpen();
          setExplanationEditing();
        }}
        explanation={explanationEditing}
        onSave={onUpdateResolutionExplanation}
      />
    </>
  );
};

export default ServiceMapping;
