import styled from 'styled-components';
import { CalendarFixedMaxWidth } from '../../../components/Schedule/Calendar/styled';
import {
  AutoHorizontalMargins,
  BaseBox,
  CenterFlexStyle,
  ChatBox,
  ColumnCenteredDiv,
  DarkEssText,
  ExtraSmallGapStyle,
  MediumDarkSmallText,
  MediumGapStyle,
  SMGapStyle,
  SmallGapStyle,
  SmallIconSize,
  SyncIcon,
  WhiteExtraSmallText,
  XIcon,
} from '../../../styles/shared-styled-components';

export const ClientBox = styled(BaseBox)`
  padding: 30px 10px;
  justify-content: flex-start;
  align-items: center;
`;

export const ClientSection = styled(ColumnCenteredDiv)`
  margin-top: 20px;
  ${MediumGapStyle}
  width: 80vw;
  max-width: 1250px;
  ${AutoHorizontalMargins}
`;

export const ChatDemoText = styled(MediumDarkSmallText)`
  line-height: 1.15;
`;

export const CreateChatButtonContainer = styled(ColumnCenteredDiv)`
  flex: 1;
  ${MediumGapStyle}
`;

export const ResetChatButtonContainer = styled.div`
  position: absolute;
  top: -8px;
  right: -7px;
  border-color: ${({ theme }) => theme.color.lightError};
  color: white;
  background-color: ${({ theme }) => theme.color.lightError};
  border-radius: 10px;
  padding: 3px 5px;
  ${CenterFlexStyle}
`;

export const ResetIcon = styled(XIcon)`
  color: unset;
  ${SmallIconSize}
`;

export const CalendarSection = styled(ClientSection)``;

export const CalendarHeaderContainer = styled.div`
  ${CenterFlexStyle}
  position: relative;
  width: ${CalendarFixedMaxWidth}px;
`;

export const DateText = styled(DarkEssText)`
  ${CenterFlexStyle}
  ${SmallGapStyle}
`;

export const DateActionsContainer = styled.div`
  ${CenterFlexStyle}
  margin-left: 10px;
  ${ExtraSmallGapStyle}
  min-width: 85px;
`;

export const RefreshContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: -1px;
`;

export const RefreshCalendarIcon = styled(SyncIcon)`
  color: ${({ theme }) => theme.color.lightPrimary};
  cursor: pointer;
  margin-top: -1px;
  margin-left: -6px;
`;

export const PromptSection = styled(ClientSection)`
  width: 100%;
  ${AutoHorizontalMargins}
`;

export const PromptBox = styled(ChatBox)`
  ${CenterFlexStyle}
`;

export const FunctionsContainer = styled(ColumnCenteredDiv)`
  width: 100%;
  align-items: flex-start;
  ${SMGapStyle}
`;

export const FunctionsHeaderContainer = styled.div`
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
`;

export const FunctionsNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${SmallGapStyle}
`;

export const FunctionsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ParamsContainer = styled.div`
  background-color: ${({ theme }) => theme.color.lightPrimary};
  border-radius: 20px;
  padding: 3px 10px;
  cursor: pointer;
`;

export const ParamsText = styled(WhiteExtraSmallText)`
  font-size: 15px;
`;
