import styled from 'styled-components';
import { DemoButton } from './style-Homepage';
import {
  TestimonialContainer,
  TestimonialMedia,
  TestimonialMediaContainer,
  TestimonialTextContainer,
} from './style-Testimonials';

const darkCardColors = [
  'rgb(50,50,50)',
  'rgb(55,50,50)',
  'rgb(50,55,50)',
  'rgb(50,50,55)',
];

const lightCardColors = ['#B8C4C1', '#C9CACB', '#D9D9DB', '#E9E5ED', '#F2F1F1'];

export const CampaignTypesContainer = styled(TestimonialContainer)`
  position: sticky;
  box-shadow: none;
  opacity: 1;

  &:nth-child(1) {
    top: 150px;
    background: ${(props) =>
      props.dark ? darkCardColors[0] : lightCardColors[0]};
  }
  &:nth-child(2) {
    top: 170px;
    background: ${(props) =>
      props.dark ? darkCardColors[1] : lightCardColors[1]};
  }
  &:nth-child(3) {
    top: 190px;
    background: ${(props) =>
      props.dark ? darkCardColors[2] : lightCardColors[2]};
  }
  &:nth-child(4) {
    top: 210px;
    background: ${(props) =>
      props.dark ? darkCardColors[3] : lightCardColors[3]};
  }
  &:nth-child(5) {
    top: 230px;
    background: ${(props) =>
      props.dark ? darkCardColors[4] : lightCardColors[4]};
  }
  z-index: 200;

  @media (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const CampaignTypesMediaContainer = styled(TestimonialMediaContainer)``;

export const CampaignTypesExampleButton = styled(DemoButton)`
  background: ${(props) =>
    props.dark ? 'none' : ({ theme }) => theme.color.lightGray};
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.lightCharcoal};
  transition: all 0.3s;
  :hover {
    background: ${(props) =>
      props.dark ? 'none' : ({ theme }) => theme.color.superLightGray};
    opacity: 0.7;
  }
`;
export const CampaignTypesWrapper = styled.div`
  position: relative;
`;

export const CampaignTypesMedia = styled(TestimonialMedia)`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20%;
    height: auto;
  }
`;

export const CampaignTypesLeftContainer = styled(TestimonialTextContainer)``;
