import React from 'react';
import {
  LogoCarouselContainer,
  LogoSlide,
  LogoSlideTrack,
  Slider,
} from '../styles/style-Homepage';

import {
  HomepageFacebookAdsLogo,
  HomepageGoHighLevelLogo,
  HomepageGoogleAdsLogo,
  HomepageMeevoLogo,
  HomepageMindBodyLogo,
  HomepageSquareLogo,
  HomepageZenotiLogo,
  PartnersContainer,
} from '../styles/style-Integrations';

const Integrations = () => {
  return (
    <>
      <p>
        &nbsp;
        <PartnersContainer>
          <LogoCarouselContainer>
            <Slider>
              <LogoSlideTrack>
                <LogoSlide>
                  <HomepageZenotiLogo />
                  Zenoti
                </LogoSlide>
                <LogoSlide>
                  <HomepageMindBodyLogo />
                  MindBody
                </LogoSlide>
                <LogoSlide>
                  <HomepageSquareLogo />
                  Square
                </LogoSlide>
                <LogoSlide>
                  <HomepageGoogleAdsLogo />
                  Google Ads
                </LogoSlide>
                <LogoSlide>
                  <HomepageGoHighLevelLogo />
                  GoHighLevel
                </LogoSlide>
                <LogoSlide>
                  <HomepageFacebookAdsLogo />
                  Facebook Ads
                </LogoSlide>
                <LogoSlide>
                  <HomepageMeevoLogo />
                  Meevo
                </LogoSlide>

                {/* repeat */}
                <LogoSlide>
                  <HomepageZenotiLogo />
                  Zenoti
                </LogoSlide>
                <LogoSlide>
                  <HomepageMindBodyLogo />
                  MindBody
                </LogoSlide>
                <LogoSlide>
                  <HomepageSquareLogo />
                  Square
                </LogoSlide>
                <LogoSlide>
                  <HomepageGoogleAdsLogo />
                  Google Ads
                </LogoSlide>
                <LogoSlide>
                  <HomepageGoHighLevelLogo />
                  GoHighLevel
                </LogoSlide>
                <LogoSlide>
                  <HomepageFacebookAdsLogo />
                  Facebook Ads
                </LogoSlide>
                <LogoSlide>
                  <HomepageMeevoLogo />
                  Meevo
                </LogoSlide>
              </LogoSlideTrack>
            </Slider>
          </LogoCarouselContainer>
        </PartnersContainer>
      </p>
    </>
  );
};

export default Integrations;
