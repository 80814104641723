import styled, { css } from 'styled-components';
import ClientSearchBox from '../../../../components/Search/ClientSearchBox/ClientSearchBox';
import { SmallPromptInput } from '../../../../components/Training/shared-training-components';
import {
  AddIcon,
  AutoHorizontalMargins,
  CardStyle,
  CenterFlexStyle,
  ChatIcon,
  ColumnCenteredDiv,
  ColumnFlexStyle,
  DarkSmallText,
  EditIcon,
  EssText,
  ExtraLargeGapStyle,
  FullWidthCenteredDiv,
  LightDarkExtraSmallText,
  LightDarkMediumText,
  MLIconSize,
  MediumDarkLargeText,
  MediumGapStyle,
  RemoveIcon,
  SMGapStyle,
  SMMIconSize,
  ScrollStyle,
  SecondaryButton,
  SmallGapStyle,
  ThinScrollBarStyle,
  TrackProgressIcon,
} from '../../../../styles/shared-styled-components';

export const DropDownMenu = styled(SmallPromptInput)`
  margin: 0 !important;
`;

export const PipelineContainer = styled.div`
  display: flex;
  align-items: top;
  justify-content: flex-start;
  gap: 20px;
  flex-direction: row;
  font-family: ${({ theme }) => theme.typography.baseFont};
  height: 80%;
  max-height: 80%;
  min-width: 100%;

  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const PipelineFiltersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
`;
export const PipelineFilterSubtext = styled.div`
  margin: 5px 0;
  font-size: 0.8em;
  color: ${({ theme }) => theme.color.darkGray};
  font-family: ${({ theme }) => theme.typography.baseFont};
`;
export const PipelineDropDownContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PipelineFilterResults = styled.div`
  font-family: ${({ theme }) => theme.typography.baseFont};
  padding: 10px 30px;
  font-size: 0.85em;
  margin: 10px;
  width: fit-content;
  border: 1px solid ${({ theme }) => theme.color.lightPrimary};
  border-radius: ${({ theme }) => theme.spacing.mlBorderRadius}px;
  color: ${({ theme }) => theme.color.primary};
`;

export const PipelineColumn = styled.div`
  width: 20%;
  height: 90%;
  max-height: 90%;
  padding: 15px 0;
  // overflow: auto;
  :nth-child(1) {
    box-shadow: 0px -2px 0px 0px ${({ theme }) => theme.color.defaultGray};
  }
  :nth-child(2) {
    box-shadow: 0px -2px 0px 0px ${({ theme }) => theme.color.primary};
  }
  :nth-child(3) {
    box-shadow: 0px -2px 0px 0px ${({ theme }) => theme.color.darkGold};
  }
  :nth-child(4) {
    box-shadow: 0px -2px 0px 0px ${({ theme }) => theme.color.deepGreen};
  }
  :nth-child(5) {
    box-shadow: 0px -2px 0px 0px ${({ theme }) => theme.color.error};
  }

  transition: all 0.3s;
`;

export const PiplineCampaignFilter = styled.div`
  ${CenterFlexStyle}
`;
export const PipelineSearchBox = styled(ClientSearchBox)`
  margin: 0;
`;
export const PipelineColumnHeader = styled.div`
  width: 100%;
  max-height: 15%;
  padding: 10px;
  padding-bottom: 0px;
`;
export const PipelineColumnHeaderTitle = styled.div`
  font-size: 1.2em;
`;
export const PipelineColumnHeaderSubtext = styled.div`
  font-size: 0.8em;
  margin: 10px 0;
  color: ${({ theme }) => theme.color.darkGray};
`;

export const PipelineClientContainer = styled.div`
  overflow-y: scroll;
  ${ThinScrollBarStyle}
  overflow-x: hidden;
  height: 60vh;
`;

export const PipelineClient = styled.div`
  max-height: 20%;
  width: 90%;
  background: ${({ theme }) => theme.color.lightGray};
  padding: 15px;
  border-radius: 10px;
  margin: 15px 0;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);

  transition: all 0.3s;
  :hover {
    filter: brightness(1.05);
  }
`;

export const PipelineClientName = styled.div``;

export const PipelineClientContacted = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.color.darkGray};
  font-size: 0.8em;
`;

export const PipelineClientTooltips = styled.div`
  margin-top: 10px;
`;

export const CreatedGoalsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${ColumnFlexStyle}
  ${AutoHorizontalMargins}
  margin-bottom: 40px;
`;

export const CreateGoalButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: -20px;
`;

export const CreateGoalButton = styled(SecondaryButton)`
  min-width: 100px;
  padding: 3px 20px;
  font-size: 16px;
  ${CenterFlexStyle}
  ${SmallGapStyle}
  margin: 0px 0px 30px 0px;
`;

export const CreateGoalAddIcon = styled(AddIcon)`
  ${SMMIconSize}
`;

export const SwimLaneTitlesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 0px 10px;
  height: 100px;
`;

export const SwimLaneTitleContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  ${(props) =>
    props.start
      ? css`
          padding-left: 10px;
          justify-content: flex-start;
        `
      : props.end
      ? css`
          padding-right: 10px;
          justify-content: flex-end;
        `
      : css`
          justify-content: center;
        `}
`;

export const SwimLaneTitleText = styled(EssText)`
  text-decoration: underline;
  text-underline-offset: 7px;
  text-decoration-color: ${({ theme }) => theme.color.lightPrimary};
  text-decoration-style: dotted;
`;

export const SwimLanesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SwimLane = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  height: 500px;
  ${ScrollStyle}
  background-color: ${({ theme }) => theme.color.offwhite};
  border-top: 1px dotted ${({ theme }) => theme.color.muiInput};
  border-bottom: 1px dotted ${({ theme }) => theme.color.muiInput};
  ${SMGapStyle}
  ${(props) =>
    props.start
      ? css`
          border-left: 1px dotted ${({ theme }) => theme.color.muiInput};
          padding-left: 10px;
          border-top-left-radius: 20px 20px;
          border-bottom-left-radius: 20px 20px;
          flex: 6;
        `
      : props.end
      ? css`
          border-right: 1px dotted ${({ theme }) => theme.color.muiInput};
          padding-right: 10px;
          border-top-right-radius: 20px 20px;
          border-bottom-right-radius: 20px 20px;
          flex: 6;
        `
      : css`
          border-left: 1px dotted ${({ theme }) => theme.color.muiInput};
          border-right: 1px dotted ${({ theme }) => theme.color.muiInput};
          flex: 6;
        `}
`;

export const SwimLaneCardsContainer = styled(FullWidthCenteredDiv)`
  flex-direction: column;
  ${MediumGapStyle}
`;

export const GoalCard = styled.div`
  ${CardStyle}
  border-radius: ${({ theme }) => theme.spacing.largeBorderRadius};
  border-width: 4px;
  width: 70%;
  ${AutoHorizontalMargins}
  ${ColumnFlexStyle}
  justify-content: space-between;
  ${SMGapStyle}
  background-color: ${({ theme }) => theme.color.superLightPrimary};
  min-height: 250px;
  ${(props) =>
    props.start
      ? css`
          border-left: 5px solid ${({ theme }) => theme.color.incomplete};
          border-top: 5px solid ${({ theme }) => theme.color.incomplete};
        `
      : props.end
      ? css`
          border-left: 5px solid ${({ theme }) => theme.color.brightGreen};
          border-top: 5px solid ${({ theme }) => theme.color.brightGreen};
        `
      : css`
          border-left: 5px solid ${({ theme }) => theme.color.underReview};
          border-top: 5px solid ${({ theme }) => theme.color.underReview};
        `}
`;

export const GoalCardTitleText = styled(DarkSmallText)`
  text-decoration: underline;
  text-underline-offset: 7px;
  text-decoration-color: ${({ theme }) => theme.color.muiInputDarkGray};
  cursor: pointer;
`;

export const GoalCardFieldContainer = styled(ColumnCenteredDiv)`
  gap: 0px;
`;

export const GoalCardFieldTitleText = styled(LightDarkExtraSmallText)``;

export const GoalCardActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 5px;
`;

export const GoalCardActionIconContainer = styled.div`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: ${({ theme }) => theme.color.primary};
  color: white;
  ${CenterFlexStyle}
  cursor: pointer;
`;

export const DeleteGoalCardActionIconContainer = styled(
  GoalCardActionIconContainer,
)`
  background-color: ${({ theme }) => theme.color.lightError};
`;

const GoalCardActionIconStyle = css`
  ${SMMIconSize}
  color: unset;
`;

export const TrackGoalProgressIcon = styled(TrackProgressIcon)`
  ${GoalCardActionIconStyle}
`;

export const EditGoalIcon = styled(EditIcon)`
  ${GoalCardActionIconStyle}
`;

export const DeleteGoalIcon = styled(RemoveIcon)`
  ${GoalCardActionIconStyle}
  height: 20px;
  width: 20px;
`;

export const GoalCardActionButton = styled(EssText)`
  min-width: unset;
  margin: unset;
  font-size: ${({ theme }) => theme.typography.essSize};
  cursor: pointer;
  padding: 5px 2px;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-thickness: 3px;
    text-decoration-color: ${({ theme }) => theme.color.lightPrimary};
  }
`;

export const EmptyGoalsContainer = styled(ColumnCenteredDiv)`
  width: 100%;
  padding: 20px;
  ${ExtraLargeGapStyle}
  height: fit-content;
`;

export const GoalsIconContainer = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.primary};
  border: 1px solid ${({ theme }) => theme.color.muiInput};
  padding: 15px;
`;

export const LargeGoalsIcon = styled(ChatIcon)`
  ${MLIconSize}
  color: white;
`;

export const GoalsTitleText = styled(MediumDarkLargeText)`
  font-weight: bold;
`;

export const GoalsSubtitleText = styled(LightDarkMediumText)``;

export const SingleButtonContainer = styled(ColumnCenteredDiv)`
  margin-top: 15px;
  ${MediumGapStyle}
  margin-top: 5px;
`;

export const TopSectionContainer = styled.div`
  ${CardStyle}
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => !props.useDynamicHeight && '100px'};
  flex-wrap: wrap;
  padding: ${(props) =>
    props.useLargePadding
      ? '40px'
      : `${props.theme.spacing.smallContainerPadding}px ${props.theme.spacing.smContainerPadding}px`};
  margin-bottom: 60px;
  margin-top: 10px;
  border: ${(props) => props.hideBorder && 'none'};
`;
