import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import { SmallLogoTransparent } from '../../../styles/shared-styled-components';
import { homepageNavbarCollapseWidth } from '../../../utils/constants';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import {
  AppBar,
  HamburgerMenu,
  LogoContainer,
  LogoText,
  MobileMenu,
  MobileMenuItem,
  NavbarDemoButton,
  NavbarGoToAppButton,
  ToolBarLeftContainer,
  ToolBarLeftSection,
  ToolBarMiddleSection,
  ToolBarRightContainer,
  ToolBarRightSection,
} from './styled';

const WebsiteHeader = ({ darkMode, onNavigate = () => {} }) => {
  const { pathname } = useLocation();
  const { width } = useContext(BaseContext);
  const { user: auth0User } = useAuth0();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);

  const navigateToPath = (path) => {
    onNavigate();
    navigate(path);
  };

  const collapseLeftDrawer = width < 800;
  const collapseLeftDrawerMore = width < 600;
  const collapseRightDrawer = width < homepageNavbarCollapseWidth;
  const onHomeScreen = pathname === Paths.homepage;

  return (
    <>
      <AppBar>
        <ToolBarLeftContainer dark={darkMode}>
          <ToolBarLeftSection>
            <LogoContainer
              onClick={() => !onHomeScreen && navigateToPath(Paths.homepage)}
            >
              <SmallLogoTransparent />
              {!collapseLeftDrawer && (
                <LogoText dark={darkMode}>LiveIQ</LogoText>
              )}
            </LogoContainer>
          </ToolBarLeftSection>
          <ToolBarMiddleSection dark={darkMode}>
            <li>
              <a href='#five-minute-setup'>Setup</a>
            </li>
            <li>
              <a href='#features'>Features</a>
            </li>
            <li>
              <a href='#testimonials'>Testimonials</a>
            </li>
            <li>
              <a href='#faq'>FAQ</a>
            </li>
            <li>
              <a href='#pricing'>Pricing</a>
            </li>
          </ToolBarMiddleSection>
        </ToolBarLeftContainer>
        <ToolBarRightContainer dark={darkMode}>
          {/* Right fixed section */}
          {collapseRightDrawer ? (
            <>
              <NavbarDemoButton href={Paths.calendlyLink}>
                Book a demo
              </NavbarDemoButton>
              <HamburgerMenu
                dark={darkMode}
                onClick={() => setMenuOpen(!menuOpen)}
              >
                &#9776; {/* Hamburger Icon */}
              </HamburgerMenu>
            </>
          ) : (
            <ToolBarRightSection>
              <NavbarDemoButton href={Paths.calendlyLink}>
                Book a demo
              </NavbarDemoButton>
              <NavbarGoToAppButton
                onClick={() =>
                  navigateToPath(!auth0User ? Paths.getStarted : Paths.callback)
                }
                dark={darkMode}
              >
                {!auth0User ? 'Get started' : 'Go to app'}
              </NavbarGoToAppButton>
            </ToolBarRightSection>
          )}
        </ToolBarRightContainer>
        {collapseLeftDrawer && menuOpen && (
          <MobileMenu
            dark={darkMode}
            isOpen={menuOpen}
          >
            <MobileMenuItem
              dark={darkMode}
              href='#five-minute-setup'
            >
              Setup
            </MobileMenuItem>
            <MobileMenuItem
              dark={darkMode}
              href='#features'
            >
              Features
            </MobileMenuItem>
            <MobileMenuItem
              dark={darkMode}
              href='#testimonials'
            >
              Testimonials
            </MobileMenuItem>
            <MobileMenuItem
              dark={darkMode}
              href='#faq'
            >
              FAQ
            </MobileMenuItem>
            <MobileMenuItem
              dark={darkMode}
              href='#pricing'
            >
              Pricing
            </MobileMenuItem>
            <NavbarGoToAppButton
              onClick={() =>
                navigateToPath(!auth0User ? Paths.getStarted : Paths.callback)
              }
            >
              {!auth0User ? 'Get started' : 'Go to app'}
            </NavbarGoToAppButton>
          </MobileMenu>
        )}
      </AppBar>
    </>
  );
};

export default WebsiteHeader;
