import React from 'react';
import {
  DarkGoldText,
  LargeText,
  LightDarkEssText,
} from '../../../styles/shared-styled-components';
import { CopyContainer } from '../styles/style-Copy';

const Copy = ({ darkMode }) => {
  return (
    <CopyContainer>
      <LargeText>
        Turn missed calls into <DarkGoldText>pure profit</DarkGoldText> 💸
      </LargeText>
      <LightDarkEssText>
        MedSpas have some of the highest missed call rates among medical
        clinics. As the industry continues to expand, being able to book
        appointments 24/7, across all languages and channels, is a huge
        competitive advantage
      </LightDarkEssText>
    </CopyContainer>
  );
};

export default Copy;
