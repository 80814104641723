import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import {
  GET_MULTI_LOCATION_USERS,
  GET_MY_LOCATION_USERS,
  GET_MY_USER,
} from '../queries/users';

export const useMyUser = ({ email, shouldSkip }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_USER, {
    fetchPolicy: 'cache-first',
    skip: !email || shouldSkip,
    variables: {
      email,
    },
  });

  return {
    error,
    loading,
    refetch,
    user: get(data, 'getMyUser', null),
  };
};

export const useMyLocationUsers = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_LOCATION_USERS, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  return {
    error,
    loading,
    refetch,
    users: get(data, 'getMyLocationUsers', null),
  };
};

export const useMultiLocationUsers = ({ locationId }) => {
  const { data, error, loading, refetch } = useQuery(GET_MULTI_LOCATION_USERS, {
    fetchPolicy: 'network-only',
    variables: {
      locationId,
    },
  });

  return {
    error,
    loading,
    refetch,
    users: get(data, 'getMultiLocationUsers', null),
  };
};
