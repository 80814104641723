import ConstructionIcon from '@mui/icons-material/Construction';
import styled, { css } from 'styled-components';
import {
  CenterFlexStyle,
  CenteredDiv,
  ChatBoxBackgroundStyle,
  ClientIcon,
  ColumnFlexStyle,
  ExtraSmallGapStyle,
  ExtraSmallIconSize,
  StartAlignedMediumDarkTinyText,
} from '../../../styles/shared-styled-components';

export const ChatCardContainer = styled.div.attrs({
  vertical: true,
})`
  padding: 10px;
  ${(props) =>
    props.position === 'start'
      ? css`
          padding-top: 15px;
        `
      : props.position === 'end'
      ? css`
          padding-bottom: 20px;
        `
      : null}
  // Making room for when the scrollbar takes up width
  padding-right: 13px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.muiInput};
  border-top: 1px solid transparent;
  ${(props) =>
    props.selected
      ? css`
          border-right: 1px solid transparent;
          ${ChatBoxBackgroundStyle}
        `
      : css`
          border-right: 1px solid ${({ theme }) => theme.color.muiInput};
        `}
`;

export const LeftSideContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`;

export const ClientNameText = styled(StartAlignedMediumDarkTinyText)`
  ${(props) =>
    props.selected &&
    css`
      color: black;
    `}
  line-height: 1.25;
`;

export const RightSideContainer = styled(CenteredDiv)`
  ${ExtraSmallGapStyle}
`;

export const DemoIconContainer = styled.div`
  ${CenterFlexStyle}
  background-color: ${({ theme }) => theme.color.muiInput};
  padding: 3px;
  border-radius: 50%;
`;

export const DemoIcon = styled(ConstructionIcon)`
  height: 15px;
  width: 15px;
  color: ${({ theme }) => theme.color.muiInputDarkGray};
`;

export const RealIconContainer = styled(DemoIconContainer)`
  ${CenterFlexStyle}
  background-color: ${({ theme }) => theme.color.turquoise};
`;

export const RealIcon = styled(ClientIcon)`
  ${ExtraSmallIconSize}
  color: white;
`;
