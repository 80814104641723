import React, { useContext } from 'react';
import {
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import {
  bookedKey,
  demoStatusKey,
  inactiveKey,
  statusLabelMap,
} from '../../../utils/constants';
import {
  dateToTextFormat,
  getFormattedTimeStringFromDateTime,
} from '../../../utils/date';
import { getFormattedFirstName } from '../../../utils/name';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import {
  BlankSquare,
  CalendarContainer,
  DateTitleText,
  ScheduleContainer,
  ScrollContainer,
  StaffColumn,
  StaffNameCell,
  StaffNameText,
  TimeSlotContainer,
  TimeSlotText,
  TimeSlotTextContainer,
  TimeSlotTitleColumn,
} from './styled';

const timeSlotTitles = [
  { '06:00': { label: '6:00 AM' } },
  { '06:15': { label: '6:15 AM' } },
  { '06:30': { label: '6:30 AM' } },
  { '06:45': { label: '6:45 AM' } },
  { '07:00': { label: '7:00 AM' } },
  { '07:15': { label: '7:15 AM' } },
  { '07:30': { label: '7:30 AM' } },
  { '07:45': { label: '7:45 AM' } },
  { '08:00': { label: '8:00 AM' } },
  { '08:15': { label: '8:15 AM' } },
  { '08:30': { label: '8:30 AM' } },
  { '08:45': { label: '8:45 AM' } },
  { '09:00': { label: '9:00 AM' } },
  { '09:15': { label: '9:15 AM' } },
  { '09:30': { label: '9:30 AM' } },
  { '09:45': { label: '9:45 AM' } },
  { '10:00': { label: '10:00 AM' } },
  { '10:15': { label: '10:15 AM' } },
  { '10:30': { label: '10:30 AM' } },
  { '10:45': { label: '10:45 AM' } },
  { '11:00': { label: '11:00 AM' } },
  { '11:15': { label: '11:15 AM' } },
  { '11:30': { label: '11:30 AM' } },
  { '11:45': { label: '11:45 AM' } },
  { '12:00': { label: '12:00 PM' } },
  { '12:15': { label: '12:15 PM' } },
  { '12:30': { label: '12:30 PM' } },
  { '12:45': { label: '12:45 PM' } },
  { '13:00': { label: '1:00 PM' } },
  { '13:15': { label: '1:15 PM' } },
  { '13:30': { label: '1:30 PM' } },
  { '13:45': { label: '1:45 PM' } },
  { '14:00': { label: '2:00 PM' } },
  { '14:15': { label: '2:15 PM' } },
  { '14:30': { label: '2:30 PM' } },
  { '14:45': { label: '2:45 PM' } },
  { '15:00': { label: '3:00 PM' } },
  { '15:15': { label: '3:15 PM' } },
  { '15:30': { label: '3:30 PM' } },
  { '15:45': { label: '3:45 PM' } },
  { '16:00': { label: '4:00 PM' } },
  { '16:15': { label: '4:15 PM' } },
  { '16:30': { label: '4:30 PM' } },
  { '16:45': { label: '4:45 PM' } },
  { '17:00': { label: '5:00 PM' } },
  { '17:15': { label: '5:15 PM' } },
  { '17:30': { label: '5:30 PM' } },
  { '17:45': { label: '5:45 PM' } },
  { '18:00': { label: '6:00 PM' } },
  { '18:15': { label: '6:15 PM' } },
  { '18:30': { label: '6:30 PM' } },
  { '18:45': { label: '6:45 PM' } },
  { '19:00': { label: '7:00 PM' } },
  { '19:15': { label: '7:15 PM' } },
  { '19:30': { label: '7:30 PM' } },
  { '19:45': { label: '7:45 PM' } },
  { '20:00': { label: '8:00 PM' } },
  { '20:15': { label: '8:15 PM' } },
  { '20:30': { label: '8:30 PM' } },
  { '20:45': { label: '8:45 PM' } },
  { '21:00': { label: '9:00 PM' } },
  { '21:15': { label: '9:15 PM' } },
  { '21:30': { label: '9:30 PM' } },
  { '21:45': { label: '9:45 PM' } },
  { '22:00': { label: '10:00 PM' } },
];
const timeslotLabelsMap = {};
timeSlotTitles.map((obj) => {
  const time = Object.keys(obj)[0];
  const label = obj[time].label;
  timeslotLabelsMap[time] = label;
});

const StaffSchedule = ({
  date,
  staffSchedule,
  includeLabel = true,
  removeTopPadding = false,
  fixedHeight = null,
}) => {
  const { inDemoMode } = useContext(BaseContext);

  return (
    <ScheduleContainer
      removeTopPadding={removeTopPadding}
      fixedHeight={fixedHeight}
    >
      {includeLabel && (
        <DateTitleText>Schedule for {dateToTextFormat(date)}</DateTitleText>
      )}
      <CalendarContainer>
        <ScrollContainer>
          <TimeSlotTitleColumn>
            <BlankSquare />
            {timeSlotTitles.map((ts, idx) => (
              <TimeSlotTextContainer
                top={idx === 0}
                bottom={idx === timeSlotTitles.length - 1}
              >
                <TimeSlotText>{Object.values(ts)[0].label}</TimeSlotText>
              </TimeSlotTextContainer>
            ))}{' '}
            <TimeSlotTextContainer placeholder />
          </TimeSlotTitleColumn>
          {staffSchedule?.map((staff, staffIdx) => (
            <StaffColumn isLast={staffIdx === staffSchedule.length - 1}>
              <StaffNameCell isLast={staffIdx === staffSchedule.length - 1}>
                <StaffNameText>
                  <Tooltip
                    title={
                      <TooltipTitleText>
                        {inDemoMode ? `S${staffIdx + 1}` : staff.firstName}
                      </TooltipTitleText>
                    }
                    arrow
                  >
                    {inDemoMode
                      ? `S${staffIdx + 1}`
                      : getFormattedFirstName(staff.firstName, 6)}
                  </Tooltip>
                </StaffNameText>
              </StaffNameCell>
              {timeSlotTitles.map((ts, idx) => {
                const timeKey = Object.keys(ts)[0];
                const block = staff.timeSlotMap[timeKey];
                const status = !block ? inactiveKey : block.status;
                const isStart = block?.isStart;
                const isEnd = block?.isEnd;
                const label = `${
                  inDemoMode ? 'Staff Member' : staff.firstName
                } ${
                  status === bookedKey
                    ? 'has an'
                    : status === demoStatusKey
                    ? 'has a'
                    : 'is'
                } ${statusLabelMap[status]}`;
                const startTime = getFormattedTimeStringFromDateTime(
                  block?.start,
                );
                const endTime = getFormattedTimeStringFromDateTime(block?.end);
                return (
                  <>
                    {status === inactiveKey ? (
                      <TimeSlotContainer
                        top={idx === 0}
                        bottom={idx === timeSlotTitles.length - 1}
                        status={status}
                        isStart={isStart}
                        isEnd={isEnd}
                      />
                    ) : (
                      <Tooltip
                        title={
                          <>
                            <TooltipTitleText>{label}</TooltipTitleText>
                            <br></br>
                            <TooltipTitleText>
                              Start: {startTime}
                            </TooltipTitleText>
                            <TooltipTitleText>End: {endTime}</TooltipTitleText>
                          </>
                        }
                        arrow
                        placement='top'
                      >
                        <TimeSlotContainer
                          top={idx === 0}
                          bottom={idx === timeSlotTitles.length - 1}
                          status={status}
                          isStart={isStart}
                          isEnd={isEnd}
                        />
                      </Tooltip>
                    )}
                  </>
                );
              })}
            </StaffColumn>
          ))}
        </ScrollContainer>
      </CalendarContainer>
    </ScheduleContainer>
  );
};

export default StaffSchedule;
