import React from 'react';
import Paths from '../../Paths';
import { LinkTextSpan } from '../../styles/shared-styled-components';
import {
  FooterContainer,
  FooterContentContainer,
  FooterText,
  LeftFooterTextContainer,
  RightFooterTextContainer,
} from './styled';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContentContainer>
        <LeftFooterTextContainer>
          <FooterText>
            <LinkTextSpan
              href={Paths.privacyPolicy}
              target='_blank'
              useLargerOffset={false}
            >
              Privacy Policy
            </LinkTextSpan>
          </FooterText>
          <FooterText>
            {' '}
            <LinkTextSpan
              href={Paths.eula}
              target='_blank'
              useLargerOffset={false}
            >
              End-User License Agreement
            </LinkTextSpan>
          </FooterText>
        </LeftFooterTextContainer>
        <RightFooterTextContainer>
          <FooterText position='end'>
            © 2024 MedAcquire Inc.
            <br></br>
            All rights reserved.
          </FooterText>
        </RightFooterTextContainer>
      </FooterContentContainer>
    </FooterContainer>
  );
};

export default Footer;
