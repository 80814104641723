import { MessageOutlined, PieChartTwoTone } from '@mui/icons-material';
import React from 'react';
import { TbCloudDataConnection } from 'react-icons/tb';
import {
  FiveMinuteSetupContainer,
  FiveMinuteSetupHeaderText,
  FiveMinuteSetupMediaContainer,
  FiveMinuteSetupMediaHeaderText,
  FiveMinuteSetupMediaIcon,
  FiveMinuteSetupMediaItem,
  FiveMinuteSetupMediaSubText,
  FiveMinuteSetupSubText,
} from '../styles/style-FiveMinuteSetup';
import Integrations from './Integrations';

const FiveMinuteSetup = ({ darkMode }) => {
  const stepsData = [
    {
      icon: <TbCloudDataConnection />,
      header: 'Connect to your EMR / CRM',
      subText:
        '1-click connection to your EMR & CRM, as easy as "Sign-in with Google"',
    },
    {
      icon: <MessageOutlined />,
      header: 'Train Ava on your business',
      subText:
        'Instruct Ava on all your booking flows, so she can understand your buisness as well as you do',
    },
    {
      icon: <PieChartTwoTone />,
      header: 'Launch your Campaigns',
      subText:
        'Customize which contacts to target, and watch the bookings flow in',
    },
  ];

  return (
    <div id='five-minute-setup'>
      <FiveMinuteSetupContainer dark={darkMode}>
        <FiveMinuteSetupHeaderText dark={darkMode}>
          5-Minute Setup with LiveIQ
        </FiveMinuteSetupHeaderText>
        <FiveMinuteSetupSubText dark={darkMode}>
          Onboard your new superstar employee in 3 easy steps
        </FiveMinuteSetupSubText>
        <FiveMinuteSetupMediaContainer>
          {stepsData.map((step, index) => (
            <FiveMinuteSetupMediaItem key={index}>
              <FiveMinuteSetupMediaIcon>{step.icon}</FiveMinuteSetupMediaIcon>
              <FiveMinuteSetupMediaHeaderText dark={darkMode}>
                {step.header}
              </FiveMinuteSetupMediaHeaderText>
              <FiveMinuteSetupMediaSubText dark={darkMode}>
                {step.subText}
              </FiveMinuteSetupMediaSubText>
            </FiveMinuteSetupMediaItem>
          ))}
        </FiveMinuteSetupMediaContainer>
        <Integrations />
      </FiveMinuteSetupContainer>
    </div>
  );
};

export default FiveMinuteSetup;
