import { useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { useMyFiles } from '../../../../api/hooks/content';
import { DELETE_FILE, UPLOAD_FILES } from '../../../../api/mutations/content';
import {
  ColumnCenteredDiv,
  ExtraSmallAddIcon,
  ExtraSmallSecondaryButton,
  FileInput,
  ScrollWrapper,
} from '../../../../styles/shared-styled-components';
import { getFileTypeFromUrl } from '../../../../utils/string';
import LoadingIndicator from '../../../LoadingIndicator';
import Snackbar from '../../../Snackbar';
import {
  ListInputTitleContainer,
  PromptContainer,
  PromptsSection,
  PromptTitleText,
} from '../../shared-training-components';
import {
  FileContainer,
  FilePlaceholderIcon,
  FilesContainer,
  FileTitleText,
  IconDiv,
  ImageFilePreview,
  RemoveFileIcon,
} from './styled';

const Assets = ({ agentName }) => {
  const { files, refetch, loading } = useMyFiles();
  const [uploadFilesMutation, { loading: uploadFilesLoading }] =
    useMutation(UPLOAD_FILES);
  const [deleteFileMutation, { loading: deleteFilesLoading }] =
    useMutation(DELETE_FILE);

  const uploadInputRef = useRef();

  const [snackbarMessage, setSnackbarMessage] = useState('');

  const numFiles = files.length;

  const onClickUpload = () => {
    uploadInputRef.current.click();
  };

  const onUpload = async (event) => {
    const file = event.target.files[0];

    uploadFilesMutation({
      variables: {
        files: [file],
      },
      onCompleted: async (data) => {
        const success = data.uploadFiles;

        if (success) {
          setSnackbarMessage('Uploaded successfully');
          await refetch();
        }
      },
    });
  };

  const onDeleteFile = async (id) => {
    deleteFileMutation({
      variables: {
        id,
      },
      onCompleted: async (data) => {
        const success = data.deleteFile;

        if (success) {
          setSnackbarMessage('Deleted file');
          await refetch();
        }
      },
    });
  };

  if (loading || uploadFilesLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <PromptsSection hideOverflow>
        <PromptContainer
          overflow
          addLargeGap
        >
          <ListInputTitleContainer>
            <ColumnCenteredDiv>
              <PromptTitleText>
                Upload assets for {agentName} to send
              </PromptTitleText>
              <ExtraSmallSecondaryButton onClick={() => onClickUpload()}>
                <ExtraSmallAddIcon />
              </ExtraSmallSecondaryButton>
              <FileInput
                ref={uploadInputRef}
                onChange={(e) => onUpload(e)}
              />
            </ColumnCenteredDiv>
          </ListInputTitleContainer>
          {numFiles ? (
            <ScrollWrapper addTopMargin>
              <FilesContainer>
                {files.map((f) => {
                  const fileType = getFileTypeFromUrl(f.url);
                  return (
                    <FileContainer>
                      <IconDiv>
                        {['jpg', 'gif', 'png', 'svg', 'jpeg'].includes(
                          fileType,
                        ) ? (
                          <>
                            <ImageFilePreview src={f.url} />
                            <RemoveFileIcon
                              isImage
                              onClick={() => onDeleteFile(f.id)}
                            />
                          </>
                        ) : (
                          <>
                            <FilePlaceholderIcon />
                            <RemoveFileIcon
                              onClick={() => onDeleteFile(f.id)}
                            />
                          </>
                        )}
                      </IconDiv>
                      <FileTitleText>{f?.name}</FileTitleText>
                    </FileContainer>
                  );
                })}
              </FilesContainer>
            </ScrollWrapper>
          ) : (
            <></>
          )}
        </PromptContainer>
      </PromptsSection>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        quick
      />
    </>
  );
};

export default Assets;
