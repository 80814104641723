import gql from 'graphql-tag';

export const GET_MY_LOCATIONS = gql`
  query getMyLocations {
    getMyLocations {
      id
      businessId
      name
      nickname
      address
    }
  }
`;

export const GET_MY_LOCATION_OVERVIEW = gql`
  query getMyLocationOverview {
    getMyLocationOverview {
      hours
      links
    }
  }
`;

export const GET_MY_EMBEDDINGS_SYNC_STATUS = gql`
  query getMyEmbeddingsSyncStatus {
    getMyEmbeddingsSyncStatus {
      status
      timestamp
    }
  }
`;

export const GET_MY_CALENDARS = gql`
  query getMyCalendars {
    getMyCalendars {
      id
      name
    }
  }
`;

export const GET_MY_SESSION_TYPES = gql`
  query getMySessionTypes($onlyBookable: Boolean!) {
    getMySessionTypes(onlyBookable: $onlyBookable) {
      categories
      uncategorized
      allSessionTypes {
        id
        locationId
        locationCalendarId
        categoryId
        displayName
        description
        bookableStatus
        price
        priceSetting
        durationMinutes
        displayDurationMinutes
        type
        numberOfPeopleToBook
        addOnApplications
        units
        requiredConsultationSessionTypeId
        followUpDays
        clientDepositAmount
        leadDepositAmount
      }
      packages
      memberships
    }
  }
`;

export const GET_MY_CATEGORIES = gql`
  query getMyCategories {
    getMyCategories
  }
`;

export const GET_MY_BOOKABLE_SESSION_TYPES = gql`
  query getMyBookableSessionTypes {
    getMyBookableSessionTypes
  }
`;

export const GET_MY_STAFF = gql`
  query getMyStaff {
    getMyStaff {
      id
      firstName
      lastName
      gender
      avatar
      bio
      isBookable
      eligibleSessionTypeIds
    }
  }
`;

export const GET_MY_PROMOS = gql`
  query getMyPromos {
    getMyPromos {
      id
      name
      description
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;

export const GET_PROMO_BY_ID = gql`
  query getPromoById($id: String!, $editing: Boolean!) {
    getPromoById(id: $id, editing: $editing) {
      promo {
        id
        name
        description
        discountType
        discountAmount
        startDate
        endDate
        createdAt
        updatedAt
        staff
        sessionTypes
      }
      availableSessionTypes
      availableStaff {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_ALL_ACCESSIBLE_ENTERPRISES = gql`
  query getAllAccessibleEnterprises {
    getAllAccessibleEnterprises {
      business {
        id
        name
        type
      }
      locations {
        id
        name
        nickname
        isValid
        isPaused
      }
    }
  }
`;

export const GET_SERVICE_SETTINGS = gql`
  query getServiceSettings {
    getServiceSettings {
      followUp
      deposit
      cancellation
      payment
    }
  }
`;

export const GET_AVAILABLE_SESSION_TYPES = gql`
  query getAvailableSessionTypes($asCatalog: Boolean!) {
    getAvailableSessionTypes(asCatalog: $asCatalog)
  }
`;

export const GET_CATALOG_DIAGNOSTICS = gql`
  query getCatalogDiagnostics {
    getCatalogDiagnostics
  }
`;
