import React from 'react';
import {
  AutoScrollWrapper,
  StartAlignedMediumDarkEssText,
} from '../../../../styles/shared-styled-components';
import {
  dropdownInputType,
  phoneDeploymentSettingOptions,
  phoneTaskOptions,
} from '../../../../utils/constants';
import LoadingIndicator from '../../../LoadingIndicator';
import SwitchToggle from '../../../Micro/SwitchToggle/SwitchToggle';
import {
  ListInputContainer,
  ListInputSection,
  ListInputTitleContainer,
  PromptContainer,
  PromptInput,
  PromptsSection,
  PromptTitleText,
} from '../../shared-training-components';

const PhoneSettings = ({ agentName, agentSettings, onChange, loading }) => {
  const settings = agentSettings?.settings;
  const phoneSettings = settings?.phone || {};
  console.log('settings', settings);

  const deploymentMode = phoneSettings.deploymentMode;
  const enabledTasks = phoneSettings.enabledTasks || {};
  console.log('deploymentMode', deploymentMode);

  const onChangeTaskEnabled = (key, enabled) => {
    const updatedEnabledTasks = { ...enabledTasks };
    if (enabled) {
      updatedEnabledTasks[key] = true;
    } else {
      delete updatedEnabledTasks[key];
    }

    onChange({
      ...agentSettings,
      phone: {
        ...phoneSettings,
        enabledTasks: updatedEnabledTasks,
      },
    });
  };

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer>
        <PromptInput
          value={deploymentMode}
          onChange={(e) => {
            onChange({
              ...agentSettings,
              phone: {
                ...phoneSettings,
                deploymentMode: e.target.value,
              },
            });
          }}
          type={dropdownInputType}
          options={phoneDeploymentSettingOptions}
          label='Deployment mode'
        />
      </PromptContainer>
      <PromptContainer overflow>
        <ListInputTitleContainer>
          <PromptTitleText>
            Select which of these tasks {agentName} is allowed to handle over
            the phone
          </PromptTitleText>
        </ListInputTitleContainer>
        <ListInputSection widthPercentage={60}>
          <AutoScrollWrapper>
            {phoneTaskOptions.map((type) => {
              const { key, label } = type;
              const included = !!enabledTasks[key];
              return (
                <ListInputContainer>
                  <StartAlignedMediumDarkEssText>
                    {label}
                  </StartAlignedMediumDarkEssText>
                  <SwitchToggle
                    value={included}
                    onChange={() => onChangeTaskEnabled(key, !included)}
                  />
                </ListInputContainer>
              );
            })}
          </AutoScrollWrapper>
        </ListInputSection>
      </PromptContainer>
    </PromptsSection>
  );
};

export default PhoneSettings;
