import { sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  Chip,
  ChipCancelIcon,
  Dropdown,
  DropdownLabel,
  FlexibleMediumPrimaryButton,
  FlexibleMediumSecondaryButton,
  Form,
  FormControlLabel,
  FormGroup,
  MLText,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  Stack,
} from '../../../styles/shared-styled-components';
import {
  appointmentStatusesField,
  chatAppointmentStatusMap,
  chatAppointmentStatuses,
  chatCampaignField,
  chatContactTypeField,
  chatDisengagementReasonLabelMap,
  chatDisengagementReasons,
  chatInterceptionReasonLabelMap,
  chatInterceptionReasons,
  chatMedia,
  chatMediumField,
  chatMediumLabelMap,
  chatStatusField,
  chatStatusLabelMap,
  chatStatuses,
  contactTypeLabelMap,
  contactTypesArrForFilter,
  disengagementReasonField,
  emptyArrayValue,
  emptyStringValue,
  equalsKey,
  greaterThanKey,
  interceptionReasonField,
  lessThanKey,
  numUserMessagesField,
  productionChatMedia,
  sequenceIdsField,
  superAdminRole,
} from '../../../utils/constants';
import { valueIsEmpty } from '../../../utils/data';
import { getArrayFromInputString } from '../../../utils/filters';
import { getSortedAgentVersionNames } from '../../../utils/string';
import { scrollElementIntoView } from '../../../utils/view';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import RangeFilter from '../../Filters/RangeFilter/RangeFilter';
import Input from '../../Form/Input';
import {
  AdvancedButton,
  AdvancedButtonContainer,
  AdvancedDownArrow,
  AdvancedUpArrow,
  FieldsContainer,
} from './styled';

const parseFilters = (
  filters,
  campaignIds,
  sequenceIds,
  chatMediaToDisplay,
) => {
  const chatCampaignFilterArr = filters[chatCampaignField];
  let chatCampaignFilter;
  if (!chatCampaignFilterArr) {
    chatCampaignFilter = campaignIds;
  } else {
    const { value } = chatCampaignFilterArr[0];
    chatCampaignFilter = getArrayFromInputString(value);
  }

  const chatContactTypeFilterArr = filters[chatContactTypeField];
  let chatContactTypeFilter;
  if (!chatContactTypeFilterArr) {
    chatContactTypeFilter = contactTypesArrForFilter;
  } else {
    const { value } = chatContactTypeFilterArr[0];
    chatContactTypeFilter = getArrayFromInputString(value);
  }

  const chatStatusFilterArr = filters[chatStatusField];
  let chatStatusFilter;
  if (!chatStatusFilterArr) {
    chatStatusFilter = chatStatuses;
  } else {
    const { value } = chatStatusFilterArr[0];
    chatStatusFilter = getArrayFromInputString(value);
  }

  const chatMediaFilterArr = filters[chatMediumField];
  let chatMediaFilter;
  if (!chatMediaFilterArr) {
    chatMediaFilter = chatMediaToDisplay;
  } else {
    const { value } = chatMediaFilterArr[0];
    chatMediaFilter = getArrayFromInputString(value);
  }

  const bookedAppointmentFilterArr = filters[appointmentStatusesField];
  let bookedAppointmentFilter;
  if (!bookedAppointmentFilterArr) {
    bookedAppointmentFilter = chatAppointmentStatuses;
  } else {
    const { value } = bookedAppointmentFilterArr[0];
    bookedAppointmentFilter = getArrayFromInputString(value);
  }

  const numUserMessagesFilterArr = filters[numUserMessagesField];
  let numUserMessagesLowerBoundFilter;
  let numUserMessagesUpperBoundFilter;
  if (numUserMessagesFilterArr) {
    numUserMessagesFilterArr.map((f) => {
      const { operator, value } = f;
      if (operator === greaterThanKey) {
        numUserMessagesLowerBoundFilter = value;
      } else if (operator === lessThanKey) {
        numUserMessagesUpperBoundFilter = value;
      }
    });
  }

  const sequenceIdsFilterArr = filters[sequenceIdsField];
  let sequenceIdsFilter;
  if (!sequenceIdsFilterArr) {
    sequenceIdsFilter = [emptyArrayValue, ...sequenceIds];
  } else {
    const { value } = sequenceIdsFilterArr[0];
    sequenceIdsFilter = getArrayFromInputString(value);
  }

  const disengagementReasonFilterArr = filters[disengagementReasonField];
  let disengegamentReasonFilter;
  if (!disengagementReasonFilterArr) {
    disengegamentReasonFilter = chatDisengagementReasons;
  } else {
    const { value } = disengagementReasonFilterArr[0];
    disengegamentReasonFilter = getArrayFromInputString(value);
  }

  const interceptionReasonFilterArr = filters[interceptionReasonField];
  let interceptionReasonFilter;
  if (!interceptionReasonFilterArr) {
    interceptionReasonFilter = chatInterceptionReasons;
  } else {
    const { value } = interceptionReasonFilterArr[0];
    interceptionReasonFilter = getArrayFromInputString(value);
  }

  return {
    chatCampaignFilter,
    chatContactTypeFilter,
    chatStatusFilter,
    chatMediaFilter,
    bookedAppointmentFilter,
    numUserMessagesLowerBoundFilter,
    numUserMessagesUpperBoundFilter,
    sequenceIdsFilter,
    disengegamentReasonFilter,
    interceptionReasonFilter,
  };
};

let allowAgentVersionFiltering = false;
const fieldsContainerId = 'fields-container';

const preSequenceAgentVersionName = 'v0: First launch';

const FilterChatsModal = ({
  isOpen,
  onClose,
  filters,
  allCampaigns,
  sequences,
  onApplyFilters,
  onResetFilters,
}) => {
  const { user } = useContext(BaseContext);
  const role = user?.role;

  const campaignNameLabelMap = {};
  const allCampaignIds = allCampaigns?.map((c) => {
    campaignNameLabelMap[c.id] = c.name;
    return c.id;
  });

  let allSequenceIds = [];
  const sequenceIdMap = {};
  const allAgentVersionNames = Object.keys(sequences);
  allAgentVersionNames.map((agentVersionName) => {
    const versionSequences = sequences[agentVersionName];
    allSequenceIds = [
      ...allSequenceIds,
      ...versionSequences.map((sequence) => {
        sequenceIdMap[sequence.id] = agentVersionName;
        return sequence.id;
      }),
    ];
  });

  const chatMediaToDisplay =
    role === superAdminRole ? chatMedia : productionChatMedia;

  const {
    chatCampaignFilter,
    chatContactTypeFilter,
    chatStatusFilter,
    chatMediaFilter,
    bookedAppointmentFilter,
    numUserMessagesLowerBoundFilter,
    numUserMessagesUpperBoundFilter,
    sequenceIdsFilter,
    disengegamentReasonFilter,
    interceptionReasonFilter,
  } = parseFilters(filters, allCampaignIds, allSequenceIds, chatMediaToDisplay);

  const filterLength = Object.keys(filters).length;

  const [campaignIds, setCampaignIds] = useState(chatCampaignFilter);
  const [contactTypes, setContactTypes] = useState(chatContactTypeFilter);
  const [statuses, setStatuses] = useState(chatStatusFilter);
  const [media, setMedia] = useState(chatMediaFilter);
  const [appointmentStatuses, setAppointmentStatuses] = useState(
    bookedAppointmentFilter,
  );
  const [numUserMessagesLowerBound, setNumUserMessagesLowerBound] = useState(
    numUserMessagesLowerBoundFilter,
  );
  const [numUserMessagesUpperBound, setNumUserMessagesUpperBound] = useState(
    numUserMessagesUpperBoundFilter,
  );

  const agentVersionNamesIncluded = sequenceIdsFilter?.includes(emptyArrayValue)
    ? [emptyArrayValue]
    : [];
  sequenceIdsFilter.map((id) => {
    const agentVersionNameForSequence = sequenceIdMap[id];
    if (
      agentVersionNameForSequence &&
      !agentVersionNamesIncluded.includes(agentVersionNameForSequence)
    ) {
      agentVersionNamesIncluded.push(agentVersionNameForSequence);
    }
  });
  const [agentVersionNames, setAgentVersionNames] = useState(
    agentVersionNamesIncluded?.length
      ? agentVersionNamesIncluded
      : [emptyArrayValue, ...allAgentVersionNames],
  );

  const [advancedFiltersShown, setAdvancedFiltersShown] = useState(false);
  const [disengagementReasons, setDisengagementReasons] = useState(
    disengegamentReasonFilter,
  );
  const [interceptionReasons, setInterceptionReasons] = useState(
    interceptionReasonFilter,
  );

  useEffect(() => {
    setCampaignIds(
      campaignIds?.length ? campaignIds : allCampaigns?.map((c) => c.id) || [],
    );
  }, [allCampaigns]);

  useEffect(() => {
    setAgentVersionNames(
      agentVersionNamesIncluded?.length
        ? agentVersionNamesIncluded
        : [emptyArrayValue, ...allAgentVersionNames],
    );
  }, [sequences]);

  useEffect(() => {
    if (filterLength === 0) {
      setCampaignIds(allCampaignIds);
      setContactTypes(contactTypesArrForFilter);
      setStatuses(chatStatuses);
      setMedia(chatMediaToDisplay);
      setAppointmentStatuses(chatAppointmentStatuses);
      setNumUserMessagesLowerBound();
      setNumUserMessagesUpperBound();
      setAgentVersionNames([emptyArrayValue, ...allAgentVersionNames]);
      setDisengagementReasons(chatDisengagementReasons);
      setInterceptionReasons(chatInterceptionReasons);
    }
  }, [filterLength]);

  useEffect(() => {
    if (advancedFiltersShown) {
      scrollToBottom();
    }
  }, [advancedFiltersShown]);

  const handleClose = () => {
    onClose();
    setAdvancedFiltersShown(false);
  };

  const onSaveFilters = () => {
    const updatedFilters = { ...filters };

    // Campaign
    if (campaignIds?.length !== allCampaignIds?.length) {
      updatedFilters[chatCampaignField] = [
        {
          key: chatCampaignField,
          operator: equalsKey,
          value: `[${campaignIds.join(', ')}]`,
        },
      ];
    } else {
      delete updatedFilters[chatCampaignField];
    }

    // Contact type
    if (contactTypesArrForFilter.length !== contactTypes.length) {
      updatedFilters[chatContactTypeField] = [
        {
          key: chatContactTypeField,
          operator: equalsKey,
          value: `[${contactTypes.join(', ')}]`,
        },
      ];
    } else {
      delete updatedFilters[chatContactTypeField];
    }

    // Status
    if (chatStatuses.length !== statuses.length) {
      updatedFilters[chatStatusField] = [
        {
          key: chatStatusField,
          operator: equalsKey,
          value: `[${statuses.join(', ')}]`,
        },
      ];
    } else {
      delete updatedFilters[chatStatusField];
    }

    // Medium
    if (chatMediaToDisplay.length !== media.length) {
      updatedFilters[chatMediumField] = [
        {
          key: chatMediumField,
          operator: equalsKey,
          value: `[${media.join(', ')}]`,
        },
      ];
    } else {
      delete updatedFilters[chatMediumField];
    }

    // Booked appt
    if (chatAppointmentStatuses.length !== appointmentStatuses.length) {
      updatedFilters[appointmentStatusesField] = [
        {
          key: appointmentStatusesField,
          operator: equalsKey,
          value: `[${appointmentStatuses.join(', ')}]`,
        },
      ];
    } else {
      delete updatedFilters[appointmentStatusesField];
    }

    // Num user messages
    if (
      numUserMessagesLowerBound != null ||
      numUserMessagesUpperBound != null
    ) {
      const numMessagesFilter = [];

      if (!valueIsEmpty(numUserMessagesLowerBound)) {
        const parsedLowerValue = parseInt(numUserMessagesLowerBound);
        if (!isNaN(parsedLowerValue)) {
          numMessagesFilter.push({
            key: numUserMessagesField,
            operator: greaterThanKey,
            value: parsedLowerValue,
          });
        }
      }

      if (!valueIsEmpty(numUserMessagesUpperBound)) {
        const parsedUpperValue = parseInt(numUserMessagesUpperBound);
        if (!isNaN(parsedUpperValue)) {
          numMessagesFilter.push({
            key: numUserMessagesField,
            operator: lessThanKey,
            value: parsedUpperValue,
          });
        }
      }

      updatedFilters[numUserMessagesField] = numMessagesFilter;
      console.log('updatedFilters', updatedFilters);
    } else {
      delete updatedFilters[numUserMessagesField];
    }

    // Sequence IDs
    let sequenceIdsFiltered = [];
    agentVersionNames.map((name) => {
      if (name === emptyArrayValue) {
        sequenceIdsFiltered = [...sequenceIdsFiltered, emptyArrayValue];
      } else {
        const sequencesForVersion = sequences[name];
        sequenceIdsFiltered = [
          ...sequenceIdsFiltered,
          ...sequencesForVersion.map((s) => s.id),
        ];
      }
    });
    const agentVersionNamesIncluded = sequenceIdsFilter?.includes(
      emptyArrayValue,
    )
      ? [emptyArrayValue]
      : [];
    sequenceIdsFilter.map((id) => {
      const agentVersionNameForSequence = sequenceIdMap[id];
      if (
        agentVersionNameForSequence &&
        !agentVersionNamesIncluded.includes(agentVersionNameForSequence)
      ) {
        agentVersionNamesIncluded.push(agentVersionNameForSequence);
      }
    });
    if (
      sequenceIdsFilter.length !== sequenceIdsFiltered.length &&
      allowAgentVersionFiltering
    ) {
      updatedFilters[sequenceIdsField] = [
        {
          key: sequenceIdsField,
          operator: equalsKey,
          value: `[${sequenceIdsFiltered.join(', ')}]`,
        },
      ];
    }

    // Disengagement reason
    if (chatDisengagementReasons.length !== disengagementReasons.length) {
      updatedFilters[disengagementReasonField] = [
        {
          key: disengagementReasonField,
          operator: equalsKey,
          value: `[${disengagementReasons.join(', ')}]`,
        },
      ];
    } else {
      delete updatedFilters[chatDisengagementReasons];
    }

    // Interception reason
    if (chatInterceptionReasons.length !== interceptionReasons.length) {
      updatedFilters[interceptionReasonField] = [
        {
          key: interceptionReasonField,
          operator: equalsKey,
          value: `[${interceptionReasons.join(', ')}]`,
        },
      ];
    } else {
      delete updatedFilters[interceptionReasonField];
    }

    onApplyFilters(updatedFilters);
  };

  const onRevertFiltersToDefault = () => {
    onResetFilters();
    onClose();
  };

  const scrollToBottom = () => {
    scrollElementIntoView(
      document,
      fieldsContainerId,
      'end',
      'nearest',
      'instant',
    );
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        maxWidth={830}
      >
        <ModalContainer useSmallGap>
          <MLText>Filter Chats</MLText>
          <ModalInputsContainer>
            <ModalInputsContainerScrollWrapper>
              <FieldsContainer id={fieldsContainerId}>
                {campaignIds?.length ? (
                  <Input
                    id='campaign'
                    value={campaignIds}
                    removeGap={true}
                    CustomInputComponent={
                      <Form fitContentHeight>
                        <DropdownLabel>Campaign</DropdownLabel>
                        <Dropdown
                          multiple
                          label={`Campaign`}
                          value={campaignIds || []}
                          renderValue={(selected) => {
                            return (
                              <Stack>
                                {Object.values(selected)?.map(
                                  (selectedCampaignId) => {
                                    const label =
                                      campaignNameLabelMap[selectedCampaignId];
                                    return (
                                      <Chip
                                        key={selectedCampaignId}
                                        label={label}
                                        ternary
                                        onDelete={() => {
                                          const updatedCampaignIds = [
                                            ...campaignIds.filter(
                                              (id) => id !== selectedCampaignId,
                                            ),
                                          ];
                                          setCampaignIds(updatedCampaignIds);
                                        }}
                                        deleteIcon={
                                          <ChipCancelIcon
                                            onMouseDown={(event) =>
                                              event.stopPropagation()
                                            }
                                          />
                                        }
                                      />
                                    );
                                  },
                                )}
                              </Stack>
                            );
                          }}
                        >
                          {allCampaignIds?.map((id) => {
                            return (
                              <>
                                <FormGroup key={id}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={campaignIds?.includes(id)}
                                        onClick={(e) => {
                                          const selected = e.target.checked;
                                          let updatedCampaignIds;
                                          if (selected) {
                                            updatedCampaignIds = [
                                              ...campaignIds,
                                              id,
                                            ];
                                          } else {
                                            updatedCampaignIds = [
                                              ...campaignIds.filter(
                                                (cid) => cid !== id,
                                              ),
                                            ];
                                          }
                                          setCampaignIds(updatedCampaignIds);
                                        }}
                                      />
                                    }
                                    label={campaignNameLabelMap[id]}
                                  />
                                </FormGroup>
                              </>
                            );
                          })}
                        </Dropdown>
                      </Form>
                    }
                  />
                ) : (
                  <></>
                )}
                <Input
                  id='status'
                  value={contactTypes}
                  removeGap={true}
                  CustomInputComponent={
                    <Form fitContentHeight>
                      <DropdownLabel>Contact type</DropdownLabel>
                      <Dropdown
                        multiple
                        label={`Contact type`}
                        value={contactTypes}
                        renderValue={(selected) => {
                          return (
                            <Stack>
                              {Object.values(selected)?.map((selectedType) => {
                                const label = contactTypeLabelMap[selectedType];
                                return (
                                  <Chip
                                    key={selectedType}
                                    label={label}
                                    ternary
                                    onDelete={() => {
                                      const updatedTypes = [
                                        ...contactTypes.filter(
                                          (t) => t !== selectedType,
                                        ),
                                      ];
                                      setContactTypes(updatedTypes);
                                    }}
                                    deleteIcon={
                                      <ChipCancelIcon
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                  />
                                );
                              })}
                            </Stack>
                          );
                        }}
                      >
                        {sortBy(contactTypesArrForFilter, (t) => t)?.map(
                          (type) => {
                            return (
                              <>
                                <FormGroup key={type}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={contactTypes.includes(type)}
                                        onClick={(e) => {
                                          const selected = e.target.checked;
                                          let updatedTypes;
                                          if (selected) {
                                            updatedTypes = [
                                              ...contactTypes,
                                              type,
                                            ];
                                          } else {
                                            updatedTypes = [
                                              ...contactTypes.filter(
                                                (t) => t !== type,
                                              ),
                                            ];
                                          }
                                          setContactTypes(updatedTypes);
                                        }}
                                      />
                                    }
                                    label={contactTypeLabelMap[type]}
                                  />
                                </FormGroup>
                              </>
                            );
                          },
                        )}
                      </Dropdown>
                    </Form>
                  }
                />
                <Input
                  id='status'
                  value={statuses}
                  removeGap={true}
                  CustomInputComponent={
                    <Form fitContentHeight>
                      <DropdownLabel>Chat status</DropdownLabel>
                      <Dropdown
                        multiple
                        label={`Chat status`}
                        value={statuses}
                        renderValue={(selected) => {
                          return (
                            <Stack>
                              {Object.values(selected)?.map(
                                (selectedStatus) => {
                                  const label =
                                    chatStatusLabelMap[selectedStatus];
                                  return (
                                    <Chip
                                      key={selectedStatus}
                                      label={label}
                                      ternary
                                      onDelete={() => {
                                        const updatedStatuses = [
                                          ...statuses.filter(
                                            (s) => s !== selectedStatus,
                                          ),
                                        ];
                                        setStatuses(updatedStatuses);
                                      }}
                                      deleteIcon={
                                        <ChipCancelIcon
                                          onMouseDown={(event) =>
                                            event.stopPropagation()
                                          }
                                        />
                                      }
                                    />
                                  );
                                },
                              )}
                            </Stack>
                          );
                        }}
                      >
                        {sortBy(chatStatuses, (s) => s)?.map((status) => {
                          return (
                            <>
                              <FormGroup key={status}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={statuses.includes(status)}
                                      onClick={(e) => {
                                        const selected = e.target.checked;
                                        let updatedStatuses;
                                        if (selected) {
                                          updatedStatuses = [
                                            ...statuses,
                                            status,
                                          ];
                                        } else {
                                          updatedStatuses = [
                                            ...statuses.filter(
                                              (s) => s !== status,
                                            ),
                                          ];
                                        }
                                        setStatuses(updatedStatuses);
                                      }}
                                    />
                                  }
                                  label={chatStatusLabelMap[status]}
                                />
                              </FormGroup>
                            </>
                          );
                        })}
                      </Dropdown>
                    </Form>
                  }
                />
                <Input
                  id='medium'
                  value={media}
                  removeGap={true}
                  CustomInputComponent={
                    <Form fitContentHeight>
                      <DropdownLabel>Chat medium</DropdownLabel>
                      <Dropdown
                        multiple
                        label={`Chat medium`}
                        value={media}
                        renderValue={(selected) => {
                          return (
                            <Stack>
                              {Object.values(selected)?.map(
                                (selectedMedium) => {
                                  const label =
                                    chatMediumLabelMap[selectedMedium];
                                  return (
                                    <Chip
                                      key={selectedMedium}
                                      label={label}
                                      ternary
                                      onDelete={() => {
                                        const updatedMedia = [
                                          ...media.filter(
                                            (s) => s !== selectedMedium,
                                          ),
                                        ];
                                        setMedia(updatedMedia);
                                      }}
                                      deleteIcon={
                                        <ChipCancelIcon
                                          onMouseDown={(event) =>
                                            event.stopPropagation()
                                          }
                                        />
                                      }
                                    />
                                  );
                                },
                              )}
                            </Stack>
                          );
                        }}
                      >
                        {sortBy(chatMedia, (m) => m)?.map((medium) => {
                          return (
                            <>
                              <FormGroup key={medium}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={media.includes(medium)}
                                      onClick={(e) => {
                                        const selected = e.target.checked;
                                        let updatedMedia;
                                        if (selected) {
                                          updatedMedia = [...media, medium];
                                        } else {
                                          updatedMedia = [
                                            ...media.filter(
                                              (m) => m !== medium,
                                            ),
                                          ];
                                        }
                                        setMedia(updatedMedia);
                                      }}
                                    />
                                  }
                                  label={chatMediumLabelMap[medium]}
                                />
                              </FormGroup>
                            </>
                          );
                        })}
                      </Dropdown>
                    </Form>
                  }
                />
                <Input
                  id='booking'
                  value={appointmentStatuses}
                  removeGap={true}
                  CustomInputComponent={
                    <Form fitContentHeight>
                      <DropdownLabel>Booking status</DropdownLabel>
                      <Dropdown
                        multiple
                        label={`Booking status`}
                        value={appointmentStatuses}
                        renderValue={(selected) => {
                          return (
                            <Stack>
                              {Object.values(selected)?.map(
                                (selectedStatus) => {
                                  const label =
                                    chatAppointmentStatusMap[selectedStatus];
                                  return (
                                    <Chip
                                      key={selectedStatus}
                                      label={label}
                                      ternary
                                      onDelete={() => {
                                        const updatedAppointmentStatuses = [
                                          ...appointmentStatuses.filter(
                                            (s) => s !== selectedStatus,
                                          ),
                                        ];
                                        setAppointmentStatuses(
                                          updatedAppointmentStatuses,
                                        );
                                      }}
                                      deleteIcon={
                                        <ChipCancelIcon
                                          onMouseDown={(event) =>
                                            event.stopPropagation()
                                          }
                                        />
                                      }
                                    />
                                  );
                                },
                              )}
                            </Stack>
                          );
                        }}
                      >
                        {sortBy(chatAppointmentStatuses, (s) => s)?.map(
                          (status) => {
                            return (
                              <>
                                <FormGroup key={status}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={appointmentStatuses.includes(
                                          status,
                                        )}
                                        onClick={(e) => {
                                          const selected = e.target.checked;
                                          let updatedStatuses;
                                          if (selected) {
                                            updatedStatuses = [
                                              ...appointmentStatuses,
                                              status,
                                            ];
                                          } else {
                                            updatedStatuses = [
                                              ...appointmentStatuses.filter(
                                                (s) => s !== status,
                                              ),
                                            ];
                                          }
                                          setAppointmentStatuses(
                                            updatedStatuses,
                                          );
                                        }}
                                      />
                                    }
                                    label={chatAppointmentStatusMap[status]}
                                  />
                                </FormGroup>
                              </>
                            );
                          },
                        )}
                      </Dropdown>
                    </Form>
                  }
                />
                <RangeFilter
                  label='Number of user messages'
                  lowerBound={numUserMessagesLowerBound}
                  setLowerBound={setNumUserMessagesLowerBound}
                  upperBound={numUserMessagesUpperBound}
                  setUpperBound={setNumUserMessagesUpperBound}
                />
                {allowAgentVersionFiltering && (
                  <Input
                    id='agent-version'
                    value={agentVersionNames}
                    removeGap={true}
                    CustomInputComponent={
                      <Form fitContentHeight>
                        <DropdownLabel>Agent version used</DropdownLabel>
                        <Dropdown
                          multiple
                          label={`Agent version used`}
                          value={getSortedAgentVersionNames(agentVersionNames)}
                          renderValue={(selected) => {
                            return (
                              <Stack>
                                {Object.values(selected)?.map(
                                  (agentVersionName) => {
                                    const valueToDisplay =
                                      agentVersionName === emptyArrayValue
                                        ? preSequenceAgentVersionName
                                        : agentVersionName;
                                    return (
                                      <Chip
                                        key={agentVersionName}
                                        label={valueToDisplay}
                                        ternary
                                        onDelete={() => {
                                          const updatedAgentVersionNames = [
                                            ...agentVersionNames.filter(
                                              (n) => n !== agentVersionName,
                                            ),
                                          ];
                                          setAgentVersionNames(
                                            updatedAgentVersionNames,
                                          );
                                        }}
                                        deleteIcon={
                                          <ChipCancelIcon
                                            onMouseDown={(event) =>
                                              event.stopPropagation()
                                            }
                                          />
                                        }
                                      />
                                    );
                                  },
                                )}
                              </Stack>
                            );
                          }}
                        >
                          {getSortedAgentVersionNames([
                            emptyArrayValue,
                            ...allAgentVersionNames,
                          ])?.map((name) => {
                            const displayName =
                              name === emptyArrayValue
                                ? preSequenceAgentVersionName
                                : name;
                            return (
                              <>
                                <FormGroup key={name}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={agentVersionNames.includes(
                                          name,
                                        )}
                                        onClick={(e) => {
                                          const selected = e.target.checked;
                                          let updatedAgentVersionNames;
                                          if (selected) {
                                            updatedAgentVersionNames = [
                                              ...agentVersionNames,
                                              name,
                                            ];
                                          } else {
                                            updatedAgentVersionNames = [
                                              ...agentVersionNames.filter(
                                                (n) => n !== name,
                                              ),
                                            ];
                                          }
                                          setAgentVersionNames(
                                            updatedAgentVersionNames,
                                          );
                                        }}
                                      />
                                    }
                                    label={displayName}
                                  />
                                </FormGroup>
                              </>
                            );
                          })}
                        </Dropdown>
                      </Form>
                    }
                  />
                )}
                <AdvancedButtonContainer>
                  <AdvancedButton
                    onClick={() => {
                      setAdvancedFiltersShown(!advancedFiltersShown);
                    }}
                  >
                    Advanced{' '}
                    {advancedFiltersShown ? (
                      <AdvancedUpArrow />
                    ) : (
                      <AdvancedDownArrow />
                    )}
                  </AdvancedButton>
                </AdvancedButtonContainer>
                {advancedFiltersShown && (
                  <>
                    <Input
                      id='disengagement'
                      value={disengagementReasons}
                      removeGap={true}
                      CustomInputComponent={
                        <Form fitContentHeight>
                          <DropdownLabel>Disengagement reason</DropdownLabel>
                          <Dropdown
                            multiple
                            label={`Disengagement reason`}
                            value={disengagementReasons}
                            renderValue={(selected) => {
                              return (
                                <Stack>
                                  {Object.values(selected)?.map(
                                    (selectedReason) => {
                                      const isEmptyVal =
                                        selectedReason === emptyStringValue;
                                      const label =
                                        chatDisengagementReasonLabelMap[
                                          selectedReason
                                        ];
                                      return (
                                        !isEmptyVal && (
                                          <Chip
                                            key={selectedReason}
                                            label={label}
                                            ternary
                                            onDelete={() => {
                                              const updatedReasons = [
                                                ...disengagementReasons.filter(
                                                  (r) => r !== selectedReason,
                                                ),
                                              ];
                                              setDisengagementReasons(
                                                updatedReasons,
                                              );
                                            }}
                                            deleteIcon={
                                              <ChipCancelIcon
                                                onMouseDown={(event) =>
                                                  event.stopPropagation()
                                                }
                                              />
                                            }
                                          />
                                        )
                                      );
                                    },
                                  )}
                                </Stack>
                              );
                            }}
                          >
                            {sortBy(chatDisengagementReasons, (r) => r)?.map(
                              (reason) => {
                                const isEmptyVal = reason === emptyStringValue;
                                return (
                                  !isEmptyVal && (
                                    <>
                                      <FormGroup key={reason}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={disengagementReasons.includes(
                                                reason,
                                              )}
                                              onClick={(e) => {
                                                const selected =
                                                  e.target.checked;
                                                let updatedReasons;
                                                if (selected) {
                                                  updatedReasons = [
                                                    ...disengagementReasons,
                                                    reason,
                                                  ];
                                                } else {
                                                  updatedReasons = [
                                                    ...disengagementReasons.filter(
                                                      (r) => r !== reason,
                                                    ),
                                                  ];
                                                }
                                                setDisengagementReasons(
                                                  updatedReasons,
                                                );
                                              }}
                                            />
                                          }
                                          label={
                                            chatDisengagementReasonLabelMap[
                                              reason
                                            ]
                                          }
                                        />
                                      </FormGroup>
                                    </>
                                  )
                                );
                              },
                            )}
                          </Dropdown>
                        </Form>
                      }
                    />
                    <Input
                      id='interception'
                      value={interceptionReasons}
                      removeGap={true}
                      CustomInputComponent={
                        <Form fitContentHeight>
                          <DropdownLabel>Interception reason</DropdownLabel>
                          <Dropdown
                            multiple
                            label={`Interception reason`}
                            value={interceptionReasons}
                            renderValue={(selected) => {
                              return (
                                <Stack>
                                  {Object.values(selected)?.map(
                                    (selectedReason) => {
                                      const isEmptyVal =
                                        selectedReason === emptyStringValue;
                                      const label =
                                        chatInterceptionReasonLabelMap[
                                          selectedReason
                                        ];
                                      return (
                                        !isEmptyVal && (
                                          <Chip
                                            key={selectedReason}
                                            label={label}
                                            ternary
                                            onDelete={() => {
                                              const updatedReasons = [
                                                ...interceptionReasons.filter(
                                                  (r) => r !== selectedReason,
                                                ),
                                              ];
                                              setInterceptionReasons(
                                                updatedReasons,
                                              );
                                            }}
                                            deleteIcon={
                                              <ChipCancelIcon
                                                onMouseDown={(event) =>
                                                  event.stopPropagation()
                                                }
                                              />
                                            }
                                          />
                                        )
                                      );
                                    },
                                  )}
                                </Stack>
                              );
                            }}
                          >
                            {sortBy(chatInterceptionReasons, (r) => r)?.map(
                              (reason) => {
                                const isEmptyVal = reason === emptyStringValue;
                                return (
                                  !isEmptyVal && (
                                    <>
                                      <FormGroup key={reason}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={interceptionReasons.includes(
                                                reason,
                                              )}
                                              onClick={(e) => {
                                                const selected =
                                                  e.target.checked;
                                                let updatedReasons;
                                                if (selected) {
                                                  updatedReasons = [
                                                    ...interceptionReasons,
                                                    reason,
                                                  ];
                                                } else {
                                                  updatedReasons = [
                                                    ...interceptionReasons.filter(
                                                      (r) => r !== reason,
                                                    ),
                                                  ];
                                                }
                                                setInterceptionReasons(
                                                  updatedReasons,
                                                );
                                              }}
                                            />
                                          }
                                          label={
                                            chatInterceptionReasonLabelMap[
                                              reason
                                            ]
                                          }
                                        />
                                      </FormGroup>
                                    </>
                                  )
                                );
                              },
                            )}
                          </Dropdown>
                        </Form>
                      }
                    />
                  </>
                )}
              </FieldsContainer>
            </ModalInputsContainerScrollWrapper>
          </ModalInputsContainer>
          <ModalActions>
            <FlexibleMediumPrimaryButton
              removeMargins
              largePadding
              fixedMinWidth={240}
              onClick={() => onSaveFilters()}
            >
              Apply filters
            </FlexibleMediumPrimaryButton>
            <FlexibleMediumSecondaryButton
              removeMargins
              largePadding
              fixedMinWidth={240}
              onClick={() => onRevertFiltersToDefault()}
            >
              Revert to default
            </FlexibleMediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default FilterChatsModal;
