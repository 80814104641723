import styled, { css } from 'styled-components';
import {
  ClientsTabIcon,
  DemoTabIcon,
} from '../../../../components/NavBar/Drawer/styled';
import {
  AutoHorizontalMargins,
  AppointmentBookedIcon as BaseAppointmentBookedIcon,
  BaseBorderRadiusStyle,
  BaseCardWidthStyle,
  ChatBox as BaseChatBox,
  BaseDrawerContainer,
  BoxShadowStyle,
  CalendarIcon,
  CancelIcon,
  CenterFlexStyle,
  ChatActionsContainer,
  ChatDrawerWidth,
  ChatHeaderContainerHeight,
  ClientProfileDrawerWidthWithOffset,
  CollapsedDrawerWidthValue,
  ColumnCenteredDiv,
  ColumnFlexStyle,
  ContentContainer,
  ContinueIcon,
  DarkExtraSmallText,
  EssText,
  ExpandedDrawerWidthValue,
  ExtraSmallGapStyle,
  ExtraSmallIconSize,
  LightDarkExtraSmallText,
  MediumGapStyle,
  SMGapStyle,
  SMMGapStyle,
  SMMIconSize,
  SMMMIconSize,
  SendIcon,
  SmallGapStyle,
  TestIcon,
  UnderlineStyle,
  XIcon,
} from '../../../../styles/shared-styled-components';
import {
  disengagedChatStatusKey,
  interceptedChatStatusKey,
} from '../../../../utils/constants';

export const ChatContentContainer = styled(ContentContainer)`
  --left-offset: ${(props) =>
    props.drawerExpanded
      ? `${ExpandedDrawerWidthValue + ChatDrawerWidth}px`
      : props.drawerOpen
      ? `${CollapsedDrawerWidthValue + ChatDrawerWidth}px`
      : `${ChatDrawerWidth}px`};
  --right-offset: ${(props) =>
    props.clientProfileDrawerDisplayed
      ? ClientProfileDrawerWidthWithOffset + 3
      : -16}px;
  margin: unset;
  position: fixed;
  left: ${(props) =>
    props.drawerExpanded
      ? `${ChatDrawerWidth + ExpandedDrawerWidthValue}`
      : `${ChatDrawerWidth + CollapsedDrawerWidthValue}`}px;
  width: calc(100% - var(--left-offset) - var(--right-offset));
`;

export const ChatDrawer = styled(BaseDrawerContainer)`
  left: ${(props) =>
    props.drawerExpanded
      ? `${ExpandedDrawerWidthValue + 2}px`
      : props.drawerOpen
      ? `${CollapsedDrawerWidthValue + 2}px`
      : '0px'};
  width: ${ChatDrawerWidth}px;
`;

export const ChatHeaderContainer = styled(ColumnCenteredDiv)`
  ${AutoHorizontalMargins}
  position: relative;
  height: ${ChatHeaderContainerHeight}px;
`;

export const ChatClientContainer = styled.div`
  ${CenterFlexStyle}
  ${MediumGapStyle}
  width: 100%;
  justify-content: flex-end;
`;

export const ChatClientIconContainer = styled.div`
  ${CenterFlexStyle}
  ${ExtraSmallGapStyle}
  margin-top: 3px;
`;

export const ContinueChatIconContainer = styled.div`
  ${CenterFlexStyle}
  border: 1px solid ${({ theme }) => theme.color.lightPrimary};
  border-radius: 50%;
  margin-top: 3px;
`;

export const ContinueChatIcon = styled(ContinueIcon)`
  margin-top: 1px;
  height: 17px;
  width: 17px;
  color: ${({ theme }) => theme.color.lightPrimary};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const ResendMessageIcon = styled(SendIcon)`
  margin-top: 1px;
  height: 23px;
  width: 23px;
  color: ${({ theme }) => theme.color.lightPrimary};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const DNDIcon = styled(CancelIcon)`
  margin-top: 4px;
  ${SMMIconSize}
  margin-left: -1px;
  color: ${({ theme }) => theme.color.mediumLightError} !important;
  &:hover {
    color: ${({ theme }) => theme.color.lightError} !important;
  }
`;

export const TestChatIcon = styled(TestIcon)`
  ${SMMIconSize}
  color: ${({ theme }) => theme.color.lightGreen};
  cursor: pointer;
`;

export const ResetChatIconContainer = styled.div`
  ${CenterFlexStyle}
  border: 1px solid ${({ theme }) => theme.color.lightError};
  border-radius: 50%;
  padding: 2px;
  margin-top: 3px;
`;

export const ResetChatIcon = styled(XIcon)`
  ${ExtraSmallIconSize}
  color: ${({ theme }) => theme.color.lightError};
  &:hover {
    color: ${({ theme }) => theme.color.error};
  }
`;

export const AppointmentBookedIcon = styled(BaseAppointmentBookedIcon)`
  margin-top: 4px;
  &:hover {
    color: ${({ theme }) => theme.color.deepGreen};
  }
`;

export const BookAppointmentIcon = styled(CalendarIcon)`
  margin-top: 1px;
  ${SMMMIconSize}
  color: ${({ theme }) => theme.color.lightPrimary};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const BackToChatsContainer = styled.div`
  position: absolute;
  left: 0;
`;

export const ChatSection = styled.div`
  ${ColumnFlexStyle}
  ${SMMGapStyle}
  height: ${(props) => props.remainingHeight - 70}px;
  ${BaseCardWidthStyle}
  width: calc(100% - 18px);
  margin-top: -20px;
  margin-bottom: 10px;
`;

export const ChatBox = styled(BaseChatBox)`
  min-height: 100%;
  width: 100%;
  margin-top: 0px;
  border-radius: 0px;
`;

export const ChatSectionActionsContainer = styled(ChatActionsContainer)`
  border-radius: 0px;
  position: relative;
  flex: 0;
  ${(props) =>
    props.expanded
      ? css`
          height: 300px;
          ${ColumnFlexStyle}
          ${SmallGapStyle}
          padding: 25px 30px;
          align-items: flex-end;
        `
      : css`
          height: 70px;
          padding-bottom: 25px;
        `}
`;

export const ExpandMessageBoxContainer = styled.div`
  position: absolute;
  ${(props) =>
    props.type === 'collapse'
      ? css`
          right: 3px;
          top: 3px;
          transform: rotate(5deg);
        `
      : css`
          right: 2px;
          top: 3px;
        `}
`;

export const ChatBoard = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

export const ChatCardsContainer = styled(ColumnCenteredDiv)`
  ${SMGapStyle}
  justify-content: flex-start;
  height: 100%;
  ${(props) =>
    props.empty &&
    css`
      margin-top: 10px;
    `}
  padding: 0px 10px;
  padding-top: 10px;
  border-radius: 10px;
  position: relative;
  padding-bottom: 50px;
`;

export const ChatSwimLaneContainer = styled.div`
  padding: 5px 15px;
  flex: 1;
  ${(props) =>
    props.position === 'start'
      ? css`
          border-right: 2px solid ${({ theme }) => theme.color.muiInput};
        `
      : props.position === 'end'
      ? css`
          border-left: 2px solid ${({ theme }) => theme.color.muiInput};
        `
      : css`
          flex: 1;
        `}
`;

export const LaneTitleContainer = styled.div`
  display: flex;
  padding: 0px 10px;
  align-items: center;
  ${(props) =>
    props.position === 'start'
      ? css`
          justify-content: flex-start;
          padding-left: 20px;
        `
      : props.position === 'end'
      ? css`
          justify-content: flex-end;
          padding-right: 20px;
        `
      : css`
          justify-content: center;
        `}
  margin-bottom: 20px;
`;

export const LaneTitleText = styled(EssText).attrs({ useLargerOffset: true })`
  ${UnderlineStyle}
`;

export const EmptyCardsContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  ${(props) =>
    props.position === 'start'
      ? css`
          justify-content: flex-start;
          left: 20px;
        `
      : props.position === 'end'
      ? css`
          justify-content: flex-end;
          right: 20px;
        `
      : css`
          left: 0%;
          justify-content: center;
        `}
`;

export const ChatCard = styled.div.attrs({
  addHoverShadow: true,
})`
  ${BaseBorderRadiusStyle}
  ${BoxShadowStyle}
  width: 95%;
  ${ColumnFlexStyle}
  justify-content: flex-start;
  padding: 15px;
  ${MediumGapStyle}
  position: relative;
  cursor: pointer;
  margin-bottom: ${(props) => props.isBottom && '10px'};
  margin-right: 10px;
`;

export const ChatCardTitle = styled(DarkExtraSmallText)`
  text-align: start;
  font-size: 18px;
`;

export const ChatCardSubtextContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: space-between;
  width: 100%;
`;

export const ChatCardSubtext = styled(LightDarkExtraSmallText)`
  font-size: 16px;
  text-align: start;
`;

export const ChatCardActionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${SmallGapStyle}
`;

export const ChatMediumChipContainer = styled.div`
  position: absolute;
  top: -6px;
  right: -8px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  ${CenterFlexStyle}
  background-color: ${(props) =>
    props.status === 'demo'
      ? props.theme.color.muiInput
      : props.status === disengagedChatStatusKey
      ? props.theme.color.defaultGray
      : props.status === interceptedChatStatusKey
      ? props.theme.color.underReview
      : props.theme.color.lightSecondary};
`;

export const DemoMediumIcon = styled(DemoTabIcon)`
  height: 23px;
  width: 23px;
  color: ${({ theme }) => theme.color.muiInputDarkGray};
`;

export const ProductionMediumIcon = styled(ClientsTabIcon)`
  height: 22px;
  width: 22px;
  color: ${(props) =>
    props.status === interceptedChatStatusKey
      ? props.theme.color.offwhite
      : 'white'};
`;
