import styled, { css } from 'styled-components';
import {
  ColumnFlexStyle,
  EmptyGapColumnCenteredDiv,
  LargeInputHeight,
  SmallGapStyle,
  TextInputWidthStyle,
} from '../../../../styles/shared-styled-components';

export const SelectContainer = styled(EmptyGapColumnCenteredDiv)`
  margin-top: 15px;
  ${TextInputWidthStyle}
`;

const SelectContainerWidthStyle = css`
  width: calc(100% - 10px);
`;

export const SelectTitleTextContainer = styled.div`
  display: flex;
  ${SelectContainerWidthStyle}
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
`;

export const NoApplicationTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  height: ${LargeInputHeight}px;
  ${SelectContainerWidthStyle}
`;

export const DatesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${TextInputWidthStyle}
  padding: 0px 15px;
  margin-top: 5px;
`;

export const DateContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: ${(props) => (props.end ? 'flex-end' : 'flex-start')};
`;

export const DateTextContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.end ? 'flex-end' : 'flex-start')};
  align-items: center;
  ${SmallGapStyle}
`;
