import sortBy from 'lodash/sortBy';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMySequences } from '../../../api/hooks/sequence';
import FilterDropdown from '../../../components/Analytics/FilterDropdown';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import EmptyCard from '../../../components/Micro/EmptyCard';
import CreateSequenceModal from '../../../components/Modals/CreateSequenceModal';
import EditSequenceSettingsModal from '../../../components/Modals/EditSequenceSettingsModal';
import Header from '../../../components/NavBar/Header';
import Snackbar from '../../../components/Snackbar';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  BaseCard,
  CardPageSection,
  CenteredDivWithGap,
  ColumnCenteredDiv,
  ContentContainer,
  ExtraSmallSecondaryButton,
  MediumGapColumnCenteredDiv,
  PageContainer,
  PageTitleText,
  PrimaryTextSpan,
  SettingsIcon,
  SmallAddIcon,
  SmallLightDarkTextSpan,
  SpaceBetweenFlexDiv,
  StartAlignedMediumDarkLargeTinyText,
  StartAlignedMediumDarkSmallText,
  Tab,
  Tabs,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import {
  newChatsSequenceTypeKey,
  sequencesTabCookieKey,
  sequenceStagedStatusKey,
  sequenceStartedStatusKey,
  sequenceStatusLabelMap,
  sequenceTypeLabelMap,
  superAdminRole,
  textSeparatorChar,
} from '../../../utils/constants';
import { getTimestamp } from '../../../utils/date';
import { getUserLocation } from '../../../utils/user';

const getSequenceTimestamp = (status, startDate, endDate, timezone) => {
  const isCompleted = ![
    sequenceStagedStatusKey,
    sequenceStartedStatusKey,
  ].includes(status);

  return getTimestamp(isCompleted ? endDate : startDate, false, false, true);
};

const individualKey = 'individual';

const pastKey = 'past';
const upcomingKey = 'upcoming';
const allKey = 'all';

const pastTabKey = 0;
const upcomingTabKey = 1;

const currentDate = new Date().toISOString();

const Sequences = () => {
  const { user, cookies, setCookie, drawerOpen, drawerExpanded } =
    useContext(BaseContext);
  const location = getUserLocation(user);
  const locationName = location?.name;
  const timezone = location?.timezone;
  const isSuperAdmin = user?.role === superAdminRole;
  const contentContainerRef = useRef();

  const [sequenceLocationFilter, setSequenceLocationFilter] =
    useState(individualKey);

  const {
    data: sequences,
    loading: sequencesLoading,
    refetch: refetchSequences,
  } = useMySequences({
    locations: sequenceLocationFilter,
  });

  const tabCookie = cookies[sequencesTabCookieKey];
  const [tab, setTab] = useState(
    tabCookie != null ? parseInt(tabCookie) : pastTabKey,
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const fetchedSequences = sequences?.getMySequences || [];
  const filteredSequences = [];
  fetchedSequences.map((s) => {
    const timestamp = getSequenceTimestamp(
      s.status,
      s.startDate,
      s.endDate,
      timezone,
    );
    const formattedSequence = {
      ...s,
      timestamp,
    };

    if (tab === pastTabKey && s.startDate <= currentDate) {
      filteredSequences.push(formattedSequence);
    } else if (tab === upcomingTabKey && s.startDate > currentDate) {
      filteredSequences.push(formattedSequence);
    }
  });
  const allSequences =
    sortBy(filteredSequences, (s) => s.startDate).reverse() || [];

  const sequenceLocationFilterOptions = [
    {
      key: individualKey,
      label: locationName,
    },
    {
      key: allKey,
      label: 'All Locations',
    },
  ];

  useEffect(() => {
    if (tabCookie != null) {
      setTab(parseInt(tabCookie));
    }
  }, [tabCookie]);

  const onCreate = async (success) => {
    setSnackbarMessage(
      success ? `Sequence creating...` : `Sequence failed to create`,
    );
    if (success) {
      await new Promise((resolve) => setTimeout(resolve, 5 * 1000));
      await refetchSequences();
    }
  };

  const onSaveSettings = () => {
    setSettingsModalOpen(false);
    setSnackbarMessage('Saved settings');
  };

  const onChangeTab = (tabValue) => {
    setCookie(sequencesTabCookieKey, parseInt(tabValue), {});
  };

  if (sequencesLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    sequences && (
      <>
        <MetaSetter
          title={`Sequences`}
          description={`Sequences`}
        />
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
            ref={contentContainerRef}
          >
            <ColumnCenteredDiv>
              <PageTitleText>Sequences</PageTitleText>
            </ColumnCenteredDiv>
            <ColumnCenteredDiv>
              <Tabs
                value={tab}
                onChange={(_, key) => onChangeTab(key)}
              >
                <Tab
                  value={pastTabKey}
                  label='Past'
                />
                <Tab
                  value={upcomingTabKey}
                  label='Scheduled'
                />
              </Tabs>
            </ColumnCenteredDiv>
            <CardPageSection>
              <SpaceBetweenFlexDiv>
                {isSuperAdmin ? (
                  <ExtraSmallSecondaryButton
                    onClick={() => setCreateModalOpen(true)}
                  >
                    <SmallAddIcon /> Create sequence
                  </ExtraSmallSecondaryButton>
                ) : (
                  <div />
                )}
                <CenteredDivWithGap>
                  <Tooltip
                    title={
                      <TooltipTitleText>
                        Edit sequence settings
                      </TooltipTitleText>
                    }
                    placement='bottom'
                  >
                    <SettingsIcon onClick={() => setSettingsModalOpen(true)} />
                  </Tooltip>
                  {isSuperAdmin && (
                    <FilterDropdown
                      value={sequenceLocationFilter}
                      onChange={setSequenceLocationFilter}
                      options={sequenceLocationFilterOptions}
                    />
                  )}
                </CenteredDivWithGap>
              </SpaceBetweenFlexDiv>
              {allSequences?.length === 0 ? (
                <EmptyCard text='No sequences displayed' />
              ) : (
                <MediumGapColumnCenteredDiv>
                  {allSequences.map((sequence) => {
                    const type = sequence.type;
                    const campaignName = sequence.campaignName;
                    const agentVersionName = sequence.agentVersionName;
                    const status = sequence.status;
                    const isCompleted = ![
                      sequenceStagedStatusKey,
                      sequenceStartedStatusKey,
                    ].includes(status);

                    const timestamp = sequence.timestamp;

                    const verb =
                      type === newChatsSequenceTypeKey
                        ? `created`
                        : `followed up with`;
                    return (
                      <BaseCard key={sequence.id}>
                        <StartAlignedMediumDarkSmallText>
                          {campaignName} - {sequenceTypeLabelMap[type]} Sequence{' '}
                          <SmallLightDarkTextSpan>
                            {textSeparatorChar}
                          </SmallLightDarkTextSpan>{' '}
                          {sequenceStatusLabelMap[status]}
                        </StartAlignedMediumDarkSmallText>
                        <>
                          {!isCompleted ? (
                            <>
                              <StartAlignedMediumDarkLargeTinyText>
                                <PrimaryTextSpan>
                                  Running {agentVersionName}
                                </PrimaryTextSpan>
                                <StartAlignedMediumDarkLargeTinyText>
                                  Started {timestamp}
                                </StartAlignedMediumDarkLargeTinyText>
                                {status === sequenceStartedStatusKey && (
                                  <StartAlignedMediumDarkLargeTinyText>
                                    Chats {verb} so far: {sequence.numChats}
                                  </StartAlignedMediumDarkLargeTinyText>
                                )}
                              </StartAlignedMediumDarkLargeTinyText>
                            </>
                          ) : (
                            <>
                              <StartAlignedMediumDarkLargeTinyText>
                                <PrimaryTextSpan>
                                  Ran {agentVersionName}
                                </PrimaryTextSpan>
                                <br></br>
                                Finished {timestamp}
                                <br></br> Chats {verb}: {sequence.numChats}
                              </StartAlignedMediumDarkLargeTinyText>
                            </>
                          )}
                        </>
                      </BaseCard>
                    );
                  })}
                </MediumGapColumnCenteredDiv>
              )}
            </CardPageSection>
          </ContentContainer>
        </PageContainer>
        <CreateSequenceModal
          isOpen={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          onCreate={onCreate}
        />
        <EditSequenceSettingsModal
          isOpen={settingsModalOpen}
          onClose={() => setSettingsModalOpen(false)}
          onSave={onSaveSettings}
        />
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
          quick
        />
      </>
    )
  );
};

export default Sequences;
