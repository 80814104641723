import { gql } from '@apollo/client';

export const GET_MY_ANALYTICS = gql`
  query getMyAnalytics(
    $filteredCampaignIds: [String]
    $start: String
    $end: String
  ) {
    getMyAnalytics(
      filteredCampaignIds: $filteredCampaignIds
      start: $start
      end: $end
    )
  }
`;
