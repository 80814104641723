import styled from 'styled-components';
import {
  AgentAvatar as BaseAgentAvatar,
  AgentAvatarContainer as BaseAgentAvatarContainer,
  CenteredDiv,
  SmallGapStyle,
} from '../../../../styles/shared-styled-components';

export const AgentAvatarContainer = styled(BaseAgentAvatarContainer)`
  height: 180px;
  width: 180px;
  padding: 0px;
  cursor: default;
  &:hover {
    transform: unset;
  }
`;

export const AgentAvatar = styled(BaseAgentAvatar)`
  overflow: auto;
  height: 111%;
  width: 110%;
`;

export const AgentNameContainer = styled(CenteredDiv)`
  margin-top: 10px;
  ${SmallGapStyle}
  height: 80px;
  margin-bottom: -15px;
`;
