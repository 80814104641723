import styled from 'styled-components';
import {
  CenterFlexStyle,
  DashboardIcon,
  ExtraSmallGapStyle,
  SmallGapStyle,
} from '../../../styles/shared-styled-components';
import { AppBar as BaseAppBar, Toolbar as BaseToolBar } from '../Header/styled';

export const AppBar = styled(BaseAppBar)`
  background-color: white;
  z-index: 1001;
`;

export const ToolBar = styled(BaseToolBar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.color.charcoal};
`;

export const ToolBarContainerLeft = styled.div`
  height: 100%;
  ${CenterFlexStyle}
`;

export const ToolBarContainerRight = styled(ToolBarContainerLeft)`
  ${SmallGapStyle}
`;

export const WebsiteLogo = styled.img`
  height: 42px;
  width: auto;
  cursor: pointer;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  ${ExtraSmallGapStyle}
  height: 42px;
  align-items: center;
  cursor: pointer;
`;

export const LogoIcon = styled(DashboardIcon)`
  height: 42px;
  width: 42px;
  color: ${({ theme }) => theme.color.primary};
`;

export const LogoText = styled.p`
  font-size: 35px;
  color: white;
  font-family: ${({ theme }) => theme.typography.logoFont};
  margin: 0px;
`;
