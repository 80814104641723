import FontFaceObserver from 'fontfaceobserver';
import React, { useEffect, useRef, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import WebsiteHeader from '../../components/NavBar/WebsiteHeader/WebsiteHeader';
import MetaSetter from '../../components/Utils/MetaSetter';
import CampaignTypes from './components/CampaignTypes';
import Copy from './components/Copy';
import FAQ from './components/FAQ';
import Features1 from './components/Features1';
import FiveMinuteSetup from './components/FiveMinuteSetup';
import Hero from './components/Hero';
import Partners from './components/Partners';
import Pricing from './components/Pricing';
import Testimonials from './components/Testimonials';
import {
  BackgroundContainer,
  DotAnimation,
  HomepagePageContainer,
} from './styles/style-Homepage';

const darkMode = false;

const Homepage = () => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const font = new FontFaceObserver('LogoFont');

    font.load().then(() => {
      setFontLoaded(true);
    });
  }, []);

  return (
    fontLoaded && (
      <>
        <MetaSetter
          title={'LiveIQ'}
          description={'Grow your MedSpa with AI'}
        />
        <WebsiteHeader darkMode={darkMode} />
        <BackgroundContainer ref={scrollContainerRef}>
          <DotAnimation />
          <HomepagePageContainer dark={darkMode}>
            <Hero darkMode={darkMode} />
            <Partners darkMode={darkMode} />
            {/* <Statistics darkMode={darkMode} /> */}
            <Copy darkMode={darkMode} />
            <Testimonials darkMode={darkMode} />
            <FiveMinuteSetup darkMode={darkMode} />
            <Features1 darkMode={darkMode} />
            <CampaignTypes darkMode={darkMode} />
            {/* <Features2 darkMode={darkMode} /> */}
            <FAQ darkMode={darkMode} />
            <Pricing darkMode={darkMode} />
          </HomepagePageContainer>
          <Footer />
        </BackgroundContainer>
      </>
    )
  );
};

export default Homepage;
