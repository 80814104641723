import styled from 'styled-components';
import {
  AutoHorizontalMargins,
  CenteredDiv,
  CenterFlexStyle,
  ColumnFlexStyle,
  EditIcon,
  EmptyGapColumnCenteredDiv,
  EssGapStyle,
  ExtraSmallGapStyle,
  ExtraSmallPrimaryButton,
  ExtraSmallSecondaryButton,
  FullWidthCenteredDiv,
  RemoveIcon,
  SmallIconSize,
} from '../../../styles/shared-styled-components';
import { DownArrow, UpArrow } from '../../Micro/ArrowIcon/styled';

export const AnalyticsItemContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.muiInput};
  border-radius: 10px;
  width: 80%;
  ${AutoHorizontalMargins}
  padding: 20px;
  position: relative;
`;

export const MenuContainer = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.muiInput};
  ${CenterFlexStyle}
  cursor: pointer;
  margin-top: -3px;
`;

export const MenuIcon = styled(DownArrow)`
  color: ${(props) =>
    props.selected
      ? props.theme.color.muiInputDarkGray
      : props.theme.color.muiInput};
  height: 16px;
  width: 16px;
  &:hover {
    color: ${({ theme }) => theme.color.muiInputDarkGray};
  }
`;

export const AnalyticsItemActionsContainer = styled.div`
  position: absolute;
  right: 7px;
  bottom: calc(50% - 15px);
  ${ColumnFlexStyle};
`;

export const EditAppointmentIcon = styled(EditIcon)`
  ${SmallIconSize}
  color: ${(props) =>
    props.selected
      ? props.theme.color.primary
      : props.theme.color.lightPrimary};
  cursor: pointer;
`;

export const UnassignAppointmentIcon = styled(RemoveIcon)`
  color: ${(props) =>
    props.selected ? props.theme.color.error : props.theme.color.lightError};
  ${SmallIconSize}
  &:hover {
    color: ${({ theme }) => theme.color.error};
  }
`;

export const ActionsButtonContainer = styled(CenteredDiv)`
  margin-top: 10px;
  ${ExtraSmallGapStyle}
`;

export const SaveEditsButton = styled(ExtraSmallPrimaryButton)`
  font-size: 14px;
`;

export const UnassignButton = styled(ExtraSmallPrimaryButton)`
  background-color: ${({ theme }) => theme.color.lightError} !important;
  &:hover {
    background-color: ${({ theme }) => theme.color.error} !important;
  }
  font-size: 14px;
`;

export const CancelUnassignmentButton = styled(ExtraSmallSecondaryButton)`
  font-size: 14px;
`;

export const EditStatusContainer = styled(FullWidthCenteredDiv)`
  ${ExtraSmallGapStyle}
  margin-bottom: -20px;
`;

export const ExpandLineItemsIcon = styled(DownArrow)`
  height: 18px;
  width: 18px;
  cursor: pointer;
`;

export const CollapseLineItemsIcon = styled(UpArrow)`
  height: 18px;
  width: 18px;
  cursor: pointer;
`;

export const LineItemsContainer = styled(EmptyGapColumnCenteredDiv)`
  margin-top: 10px;
`;

export const EditSalePriceContainer = styled(FullWidthCenteredDiv)`
  margin-top: 8px;
  margin-bottom: 13px;
  ${EssGapStyle}
`;
