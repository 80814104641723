import { sortBy } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import { useMyLocationUsers } from '../../../api/hooks/users';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import EmptyCard from '../../../components/Micro/EmptyCard';
import CreateUserModal from '../../../components/Modals/CreateUserModal';
import Header from '../../../components/NavBar/Header';
import Snackbar from '../../../components/Snackbar';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  BaseCard,
  CardHeaderContainer,
  CardPageSection,
  CardPageSectionActionsContainer,
  ColumnCenteredDiv,
  ContentContainer,
  DarkSmallText,
  ExtraSmallSecondaryButton,
  MediumGapColumnCenteredDiv,
  PageContainer,
  PageTitleText,
  SmallAddIcon,
  SmallLightDarkTextSpan,
  StartAlignedLightDarkEssText,
} from '../../../styles/shared-styled-components';
import {
  consultantRole,
  textSeparatorChar,
  userRoleLabelMap,
} from '../../../utils/constants';
import { getFormattedFullNameFromUser } from '../../../utils/name';
import { getUserLocation } from '../../../utils/user';

const Users = () => {
  const { user, drawerOpen, drawerExpanded } = useContext(BaseContext);

  const {
    users,
    refetch: refetchUsers,
    loading: usersLoading,
  } = useMyLocationUsers({});
  const locationUsers = sortBy(users, (u) => u.firstName)?.filter(
    (u) => u?.enterprise?.business?.id != null || u?.role === consultantRole,
  );
  const userMap = {};
  locationUsers?.map((u) => {
    userMap[u.id] = u;
  });

  const location = getUserLocation(user);

  const contentContainerRef = useRef();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  if (usersLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    locationUsers && (
      <>
        <MetaSetter
          title={`Users`}
          description={`Users`}
        />
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
            ref={contentContainerRef}
          >
            <ColumnCenteredDiv>
              <PageTitleText>Users</PageTitleText>
            </ColumnCenteredDiv>
            <CardPageSection>
              <CardPageSectionActionsContainer>
                <ExtraSmallSecondaryButton
                  onClick={() => setCreateModalOpen(true)}
                  largePadding
                >
                  <SmallAddIcon /> Add user
                </ExtraSmallSecondaryButton>
              </CardPageSectionActionsContainer>
              {locationUsers?.length === 0 ? (
                <EmptyCard text='No users added' />
              ) : (
                <MediumGapColumnCenteredDiv>
                  {locationUsers?.map((user) => {
                    return (
                      <BaseCard>
                        <CardHeaderContainer>
                          <DarkSmallText>
                            {getFormattedFullNameFromUser(user)}{' '}
                            {textSeparatorChar}{' '}
                            <SmallLightDarkTextSpan>
                              {userRoleLabelMap[user.role]}
                            </SmallLightDarkTextSpan>
                          </DarkSmallText>
                        </CardHeaderContainer>
                        <div>
                          <StartAlignedLightDarkEssText>
                            {user.email}
                          </StartAlignedLightDarkEssText>
                        </div>
                      </BaseCard>
                    );
                  })}
                </MediumGapColumnCenteredDiv>
              )}
            </CardPageSection>
          </ContentContainer>
        </PageContainer>
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
          quick
        />
        <CreateUserModal
          isOpen={createModalOpen}
          onClose={() => {
            setCreateModalOpen(false);
          }}
          onSave={() => {
            refetchUsers();
            setSnackbarMessage('User created');
          }}
        />
      </>
    )
  );
};

export default Users;
