import {
  CalendarMonthRounded,
  Person2Rounded,
  RecyclingRounded,
  ReviewsRounded,
  SellRounded,
} from '@mui/icons-material';
import React from 'react';
import {
  CampaignTypesContainer,
  CampaignTypesLeftContainer,
  CampaignTypesMedia,
  CampaignTypesMediaContainer,
  CampaignTypesWrapper,
} from '../styles/style-CampaignTypes';
import {
  TestimonialHeaderSubText,
  TestimonialHeaderText,
} from '../styles/style-Testimonials';

const CampaignTypes = ({ darkMode }) => {
  const campaignData = [
    {
      header: 'Convert leads into clients',
      subText:
        'Convert new leads from your CRM, with automatic follow-ups and sales tactics to get them booked',
      icon: <RecyclingRounded />,
    },
    {
      header: 'Fill cancellations',
      subText: 'Automatically find and fill cancelled slots on your calendar',
      icon: <CalendarMonthRounded />,
    },
    {
      header: 'Re-activate existing clients',
      subText:
        'Target past clients with special promos to get them back in the door',
      icon: <Person2Rounded />,
    },
    {
      header: 'Sell membership plans',
      subText: 'Target frequent clients to upgrade them to membership plans',
      icon: <SellRounded />,
    },
    {
      header: 'Collect reviews & feedback',
      subText:
        'Improve your reviews, and collect post-appointment feedback, written straight into your EMR',
      icon: <ReviewsRounded />,
    },
  ];

  return (
    <>
      <CampaignTypesWrapper>
        {campaignData.map((campaign, index) => (
          <CampaignTypesContainer
            dark={darkMode}
            key={index}
          >
            <CampaignTypesLeftContainer>
              <p>
                <TestimonialHeaderText>{campaign.header}</TestimonialHeaderText>
              </p>

              <TestimonialHeaderSubText>
                {campaign.subText}
              </TestimonialHeaderSubText>
            </CampaignTypesLeftContainer>
            <CampaignTypesMediaContainer>
              <CampaignTypesMedia>{campaign.icon}</CampaignTypesMedia>
            </CampaignTypesMediaContainer>
          </CampaignTypesContainer>
        ))}
      </CampaignTypesWrapper>
    </>
  );
};

export default CampaignTypes;
