import React from 'react';
import {
  DarkGoldText,
  GoldText,
} from '../../../styles/shared-styled-components';
import {
  LogoCarouselContainer,
  LogoSlide,
  LogoSlideTrack,
  Slider,
} from '../styles/style-Homepage';
import { PartnersContainer } from '../styles/style-Integrations';
import {
  AvaTrustedText,
  BeautiqueLogo,
  BellatoxLogo,
  FoxRiverLogo,
  HLCWellnessLogo,
  LiquividaLogo,
  OvumHealthLogo,
  SkinlabLogo,
} from '../styles/style-Partners';

const Partners = ({ darkMode }) => {
  return (
    <>
      <PartnersContainer>
        <AvaTrustedText dark={darkMode}>
          Ava is a proud team member of{' '}
          {darkMode ? (
            <GoldText>30+ amazing businesses</GoldText>
          ) : (
            <DarkGoldText>30+ amazing businesses</DarkGoldText>
          )}
        </AvaTrustedText>
        <p />
        <LogoCarouselContainer>
          <Slider>
            <LogoSlideTrack>
              <LogoSlide>
                <SkinlabLogo />
              </LogoSlide>
              <LogoSlide>
                <FoxRiverLogo />
              </LogoSlide>
              <LogoSlide>
                <LiquividaLogo />
              </LogoSlide>
              <LogoSlide>
                <BeautiqueLogo />
              </LogoSlide>
              <LogoSlide>
                <OvumHealthLogo />
              </LogoSlide>
              <LogoSlide>
                <HLCWellnessLogo />
              </LogoSlide>
              <LogoSlide>
                <BellatoxLogo />
              </LogoSlide>
              {/* repeat */}
              <LogoSlide>
                <SkinlabLogo />
              </LogoSlide>
              <LogoSlide>
                <FoxRiverLogo />
              </LogoSlide>
              <LogoSlide>
                <LiquividaLogo />
              </LogoSlide>
              <LogoSlide>
                <BeautiqueLogo />
              </LogoSlide>
              <LogoSlide>
                <OvumHealthLogo />
              </LogoSlide>
              <LogoSlide>
                <HLCWellnessLogo />
              </LogoSlide>
              <LogoSlide>
                <BellatoxLogo />
              </LogoSlide>
            </LogoSlideTrack>
          </Slider>
        </LogoCarouselContainer>
      </PartnersContainer>
    </>
  );
};

export default Partners;
