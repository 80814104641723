import { useMutation } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  useMySessionTypes,
  useMyStaff,
  useServiceSettings,
} from '../../../../api/hooks/enterprise';
import {
  useAgentSettings,
  useMemberships,
  useModuleStatus,
  useOverview,
  useServiceCorpus,
} from '../../../../api/hooks/training';
import { EDIT_AGENT_NAME } from '../../../../api/mutations/agent';
import {
  CREATE_BOOKING_FLOW,
  CREATE_FAQ,
  DELETE_PROMPT,
  EDIT_AGENT_SETTINGS,
  EDIT_FAQ,
  EDIT_OFFERINGS,
  EDIT_POLICY,
  EDIT_PROMPT,
  EDIT_STAFF_MEMBERS,
} from '../../../../api/mutations/training';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import AgentVideoMessageModal from '../../../../components/Modals/AgentVideoMessageModal';
import CreateBookingFlowModal from '../../../../components/Modals/CreateBookingFlowModal';
import CreateFAQModal from '../../../../components/Modals/CreateFAQModal';
import Header from '../../../../components/NavBar/Header';
import Info from '../../../../components/Training/Agent/Info';
import PhoneSettings from '../../../../components/Training/Agent/PhoneSettings/PhoneSettings';
import TextSettings from '../../../../components/Training/Agent/TextSettings';
import Assets from '../../../../components/Training/Catalog/Assets';
import Categories from '../../../../components/Training/Catalog/Categories/Categories';
import GeneralFAQ from '../../../../components/Training/Catalog/GeneralFAQ';
import Memberships from '../../../../components/Training/Catalog/Memberships/Memberships';
import ServiceFAQ from '../../../../components/Training/Catalog/ServiceFAQ';
import ServiceMapping from '../../../../components/Training/Catalog/ServiceMapping/ServiceMapping';
import Services from '../../../../components/Training/Catalog/Services';
import Staff from '../../../../components/Training/Catalog/Staff';
import Hours from '../../../../components/Training/Overview/Hours';
import Links from '../../../../components/Training/Overview/Links';
import Location from '../../../../components/Training/Overview/Location/Location';
import Cancellations from '../../../../components/Training/Policies/Cancellations';
import Deposits from '../../../../components/Training/Policies/Deposits';
import FollowUps from '../../../../components/Training/Policies/FollowUps';
import Payments from '../../../../components/Training/Policies/Payments';
import {
  CancelIconContainer,
  EmptySelectOptionsContainer,
  ListBannerContainer,
  ListBannerTextContainer,
} from '../../../../components/Training/shared-training-components';
import MetaSetter from '../../../../components/Utils/MetaSetter';
import {
  AbsoluteLoadingIndicatorContainer,
  AgentAvatar,
  AgentAvatarContainer,
  AutoScrollWrapper,
  ColumnCenteredDiv,
  DarkEssText,
  ExtraSmallAddIcon,
  LightDarkSmallText,
  LightErrorTinyText,
  LightExtraTinyText,
  PageContainer,
  PageTitleText,
  RedCancelIcon,
  SelectIcon,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  addOnKey,
  addressSubmoduleKey,
  agentInfoKey,
  agentPhoneSettingsKey,
  agentSettingsModuleKey,
  agentTextSettingsKey,
  assetsSubmoduleKey,
  autoSaveDelayMs,
  bookingFlowsSubmoduleKey,
  bookingPromptTypeKey,
  bookingSubmoduleKey,
  cancellationSubmoduleKey,
  catalogModuleKey,
  catalogSubmodules,
  categoriesSubmoduleKey,
  collapsedModulesCookieKey,
  collapsedSubmodulesCookieKey,
  completeStatusKey,
  consultationKey,
  defaultMultipersonServicePromptKey,
  defaultServicePromptKey,
  dropInKey,
  faqPromptTypeKey,
  followUpsSubmoduleKey,
  generalCategoryTypeKey,
  generalFaqsSubmoduleKey,
  hoursSubmoduleKey,
  linksSubmoduleKey,
  locationCustomPromptKey,
  membershipsSubmoduleKey,
  offeringsSubmoduleKey,
  overviewModuleKey,
  overviewSubmodules,
  packageKey,
  paymentsSubmoduleKey,
  policyModuleKey,
  policySubmodules,
  selectedPromptCookieKey,
  selectedSubmoduleCookieKey,
  serviceCategoryTypeKey,
  serviceFaqsSubmoduleKey,
  staffSubmoduleKey,
  uncategorizedKey,
  universalPromptKey,
} from '../../../../utils/constants';
import { objectIsEmpty } from '../../../../utils/data';
import { getCookieExpiryObject } from '../../../../utils/date';
import {
  formatKeyToLabel,
  parseCookieValueString,
} from '../../../../utils/string';
import {
  checkIfUserIsSuperAdmin,
  getUserLiveIQAgents,
  getUserLocationId,
} from '../../../../utils/user';
import {
  AddSubmoduleCardButton,
  AddSubmoduleCardButtonContainer,
  AgentSettingsModuleIcon,
  AnswerSection,
  CatalogModuleIcon,
  CategoryDownArrow,
  CategoryUpArrow,
  CompleteModuleIcon,
  DrawerLoadingIndicatorContainer,
  IncompleteModuleIcon,
  LocationModuleIcon,
  ModuleContainer,
  ModuleDownArrow,
  ModuleIconsContainer,
  ModuleListItem,
  ModuleNameContainer,
  ModuleNameText,
  ModulesContentContainer,
  ModulesDrawer,
  ModulesDrawerHeaderContainer,
  ModuleStatusIconContainer,
  ModuleUpArrow,
  NotApplicableModuleIcon,
  PolicyModuleIcon,
  SubmoduleCard,
  SubmoduleCardScrollContainer,
  SubmoduleCardSectionContainer,
  SubmoduleCardText,
  SubmoduleContainer,
  SubmoduleNameContainer,
  SubmoduleNameText,
  SubmodulesContainer,
  SubmodulesDrawer,
  SubmoduleSectionHeaderContainer,
  SubmodulesSection,
  SubmoduleText,
} from './styled';

const modules = {
  [agentSettingsModuleKey]: {
    label: 'Agent',
    icon: AgentSettingsModuleIcon,
    submodules: [
      {
        key: agentInfoKey,
        label: 'Info',
      },
      {
        key: agentPhoneSettingsKey,
        label: 'Phone',
      },
      {
        key: agentTextSettingsKey,
        label: 'Text',
      },
    ],
  },
  [overviewModuleKey]: {
    label: 'Location',
    icon: LocationModuleIcon,
    submodules: [
      {
        key: addressSubmoduleKey,
        label: 'Address',
      },
      {
        key: hoursSubmoduleKey,
        label: 'Hours',
      },
      {
        key: linksSubmoduleKey,
        label: 'Links',
      },
    ],
  },
  [catalogModuleKey]: {
    label: 'Catalog',
    icon: CatalogModuleIcon,
    submodules: [
      {
        key: staffSubmoduleKey,
        label: 'Staff',
      },
      {
        key: categoriesSubmoduleKey,
        label: 'Categories',
      },
      {
        key: offeringsSubmoduleKey,
        label: 'Service Menu',
      },
      {
        key: bookingFlowsSubmoduleKey,
        label: 'Booking Flows',
      },
      {
        key: serviceFaqsSubmoduleKey,
        label: 'Service FAQs',
      },
      {
        key: generalFaqsSubmoduleKey,
        label: 'General FAQs',
      },
      {
        key: membershipsSubmoduleKey,
        label: 'Memberships',
      },
      {
        key: assetsSubmoduleKey,
        label: 'Assets',
      },
    ],
  },
  [policyModuleKey]: {
    label: 'Policies',
    icon: PolicyModuleIcon,
    submodules: [
      {
        key: bookingSubmoduleKey,
        label: 'Booking',
      },
      {
        key: cancellationSubmoduleKey,
        label: 'Cancellations',
      },
      {
        key: followUpsSubmoduleKey,
        label: 'Follow-Ups',
      },
      {
        key: paymentsSubmoduleKey,
        label: 'Payments',
      },
    ],
  },
};
const moduleArr = Object.keys(modules);

const CompleteIcon = (
  <ModuleStatusIconContainer>
    <Tooltip
      title={<TooltipTitleText>Module complete</TooltipTitleText>}
      placement='right'
    >
      <CompleteModuleIcon />
    </Tooltip>
  </ModuleStatusIconContainer>
);
const IncompleteIcon = (
  <ModuleStatusIconContainer>
    <Tooltip
      title={<TooltipTitleText>Module incomplete</TooltipTitleText>}
      placement='right'
    >
      <IncompleteModuleIcon />
    </Tooltip>
  </ModuleStatusIconContainer>
);
const NotApplicableIcon = (
  <ModuleStatusIconContainer>
    <Tooltip
      title={<TooltipTitleText>Module not applicable</TooltipTitleText>}
      placement='right'
    >
      <NotApplicableModuleIcon />
    </Tooltip>
  </ModuleStatusIconContainer>
);

const cookieExpiryObject = getCookieExpiryObject();

const getSelectedModule = (selectedSubmodule) => {
  if (!selectedSubmodule) {
    return null;
  }

  if (overviewSubmodules.includes(selectedSubmodule)) {
    return overviewModuleKey;
  }
  if (catalogSubmodules.includes(selectedSubmodule)) {
    return catalogModuleKey;
  }
};

const stringifyPromptObject = (prompt, resolutions) => {
  const abbreviatedPrompt = {
    id: prompt?.id,
    status: prompt?.status,
    title: prompt?.title,
    utterance: prompt?.utterance,
  };
  const promptStr = JSON.stringify(abbreviatedPrompt);
  const resolutionStr = JSON.stringify(resolutions);
  return `${promptStr}_${resolutionStr}`;
};

const getStringifiedPrompt = (promptToStringify, serviceCorpus) => {
  if (!promptToStringify || !serviceCorpus) {
    return {};
  }

  const { id: promptId, categoryId } = promptToStringify;
  const categoryToUpdate = serviceCorpus?.[categoryId];
  const prompts = categoryToUpdate?.prompts || {};
  const selectedPromptObject = prompts[promptId];
  const basePrompt = selectedPromptObject?.prompt;
  const baseResolutions = selectedPromptObject?.resolutions;

  return {
    obj: selectedPromptObject,
    str: stringifyPromptObject(basePrompt, baseResolutions),
  };
};

const getStringifiedFaq = (faq, resolutions) => {
  return `${faq?.utterance}${resolutions?.[0]?.explanation}`;
};

const Training = () => {
  const {
    width,
    user,
    refetchUser,
    userLoading,
    drawerOpen,
    drawerExpanded,
    cookies,
    setCookie,
  } = useContext(BaseContext);

  const [saveAgentNameMutation] = useMutation(EDIT_AGENT_NAME);
  const [saveAgentSettingsMutation] = useMutation(EDIT_AGENT_SETTINGS);
  const [saveStaffMutation] = useMutation(EDIT_STAFF_MEMBERS);
  const [saveOfferingsMutation] = useMutation(EDIT_OFFERINGS);
  const [saveServiceBookingPromptMutation] = useMutation(EDIT_PROMPT);
  const [createBookingFlowMutation] = useMutation(CREATE_BOOKING_FLOW);
  const [createFaqMutation] = useMutation(CREATE_FAQ);
  const [saveFaqMutation] = useMutation(EDIT_FAQ);
  const [deletePromptMutation] = useMutation(DELETE_PROMPT);
  const [savePolicyMutation] = useMutation(EDIT_POLICY);

  const userLocationId = getUserLocationId(user);
  const baseAgent = getUserLiveIQAgents(user)?.[0] || {};
  const baseAgentName = baseAgent?.name;
  const isSuperAdmin = checkIfUserIsSuperAdmin(user);

  const contentContainerRef = useRef();

  const collapsedModulesCookieValue = cookies[collapsedModulesCookieKey];
  const collapsedSubmodulesCookieValue = cookies[collapsedSubmodulesCookieKey];
  const selectedSubmoduleCookieValue = cookies[selectedSubmoduleCookieKey];
  const selectedPromptCookieValue = cookies[selectedPromptCookieKey];
  const stringifiedSelectedPromptCookieValue = JSON.stringify(
    selectedPromptCookieValue,
  );

  const [collapsedModules, setCollapsedModules] = useState(
    parseCookieValueString(collapsedModulesCookieValue),
  );
  const [collapsedSubmodules, setCollapsedSubmodules] = useState(
    parseCookieValueString(collapsedSubmodulesCookieValue),
  );
  const [selectedSubmodule, setSelectedSubmodule] = useState(
    selectedSubmoduleCookieValue,
  );
  const [createBookingFlowModalOpen, setCreateBookingFlowModalOpen] =
    useState(false);
  const [createFaqModalOpen, setCreateFaqModalOpen] = useState(false);
  const [
    createServicePromptModalCategory,
    setCreateServicePromptModalCategory,
  ] = useState(null);
  const [agentVideoMessageModalOpen, setAgentVideoMessageModalOpen] =
    useState(false);
  const [remainingHeight, setRemainingHeight] = useState(0);
  const [hasLoaded, setHasLoaded] = useState(false);

  const selectedModule = getSelectedModule(selectedSubmodule);
  const submoduleDrawerExpanded =
    selectedSubmodule === bookingFlowsSubmoduleKey ||
    selectedSubmodule === serviceFaqsSubmoduleKey ||
    selectedSubmodule === generalFaqsSubmoduleKey;

  const moduleStatusRes = useModuleStatus();
  const refetchModuleStatus = moduleStatusRes?.refetch;
  const moduleStatusLoading = moduleStatusRes?.loading;
  const moduleStatusMap = moduleStatusRes?.data || {};

  // Agent
  const agentSettingRes = useAgentSettings({
    skipCondition: ![agentPhoneSettingsKey, agentTextSettingsKey].includes(
      selectedSubmodule,
    ),
  });
  const baseAgentSettingsData = agentSettingRes?.data;
  const baseAgentSettings = {
    agentId: baseAgentSettingsData?.agentId,
    settings: baseAgentSettingsData?.settings,
  };
  const refetchAgentSettings = agentSettingRes?.refetch;
  const agentSettingsLoading = agentSettingRes?.loading;

  // Overview
  const overviewRes = useOverview({
    skipCondition:
      selectedModule !== overviewModuleKey &&
      selectedSubmodule !== followUpsSubmoduleKey,
  });
  const overviewData = overviewRes?.data;
  const refetchOverview = overviewRes?.refetch;

  // Catalog
  const staffRes = useMyStaff({
    skipCondition: selectedSubmodule !== staffSubmoduleKey,
  });
  const baseStaffData = staffRes?.staff;
  const staffLoading = staffRes?.loading;
  const refetchStaff = staffRes?.refetch;
  const serviceCorpusRes = useServiceCorpus({
    skipCondition: selectedModule !== catalogModuleKey,
  });
  const {
    data: baseServiceCorpus,
    loading: serviceCorpusLoading,
    refetch: refetchServiceCorpus,
  } = serviceCorpusRes;
  const {
    sessionTypes: baseSessionTypeCatalog,
    loading: sessionTypesLoading,
    refetch: refetchSessionTypeCatalog,
  } = useMySessionTypes({
    onlyBookable: false,
    skipCondition: ![bookingFlowsSubmoduleKey, offeringsSubmoduleKey].includes(
      selectedSubmodule,
    ),
  });
  const membershipsRes = useMemberships({
    skipCondition: selectedSubmodule !== membershipsSubmoduleKey,
  });

  // Policies
  const policiesRes = useServiceSettings({
    skipCondition: !policySubmodules.includes(selectedSubmodule),
  });
  const basePolicyData = policiesRes?.serviceSettings;
  const policyLoading = policiesRes?.loading;
  const refetchPolicy = policiesRes?.refetch;

  const refetchAllData = () => {
    refetchAgentSettings();
    refetchOverview();
    refetchStaff();
    refetchServiceCorpus();
    refetchSessionTypeCatalog();
    refetchPolicy();
  };

  // State
  const [agent, setAgent] = useState(baseAgent);
  const [agentSettings, setAgentSettings] = useState(baseAgentSettings);
  const [staff, setStaff] = useState(baseStaffData);
  const [modifiedStaff, setModifiedStaff] = useState([]);
  const [sessionTypeCatalog, setSessionTypeCatalog] = useState(
    baseSessionTypeCatalog,
  );
  const [modifiedOfferings, setModifiedOfferings] = useState([]);
  const [excludedOfferingCategoriesShown, setExcludedOfferingCategoriesShown] =
    useState(false);
  const [serviceCorpus, setServiceCorpus] = useState(baseServiceCorpus || {});
  const [selectedPrompt, setSelectedPrompt] = useState(
    selectedPromptCookieValue,
  );
  const [policy, setPolicy] = useState(basePolicyData);

  // Parsed values
  const agentName = agent?.name;

  const { str: selectedPromptStr } = getStringifiedPrompt(
    selectedPrompt,
    serviceCorpus,
  );

  const sortedCategories = useMemo(() => {
    const serviceCategoryIds = Object.keys(serviceCorpus);
    return sortBy(
      serviceCategoryIds.map((id) => serviceCorpus[id]),
      (c) => (c.included ? -1 : 1),
      (c) => c.name?.toLowerCase(),
    );
  }, [serviceCorpus]);

  // Data fetching UEs
  useEffect(() => {
    if (baseAgent) {
      setAgent(baseAgent);
    }
  }, [baseAgent]);

  useEffect(() => {
    if (baseAgentSettingsData) {
      setAgentSettings(baseAgentSettings);
    }
  }, [baseAgentSettingsData]);

  useEffect(() => {
    if (baseStaffData) {
      setStaff(baseStaffData);
    }
  }, [baseStaffData]);

  useEffect(() => {
    if (baseServiceCorpus) {
      setServiceCorpus(baseServiceCorpus);
    }
  }, [baseServiceCorpus]);

  useEffect(() => {
    if (baseSessionTypeCatalog) {
      setSessionTypeCatalog(baseSessionTypeCatalog);
    }
  }, [baseSessionTypeCatalog]);

  useEffect(() => {
    if (basePolicyData) {
      setPolicy(basePolicyData);
    }
  }, [basePolicyData]);

  // Refetch on changes
  useEffect(() => {
    refetchAllData();
  }, [userLocationId]);

  // Refetch module status
  useEffect(() => {
    refetchModuleStatus();
  }, [
    baseAgent,
    baseAgentSettingsData,
    baseStaffData,
    overviewData,
    baseServiceCorpus,
    baseSessionTypeCatalog,
    basePolicyData,
  ]);

  useEffect(() => {
    if (collapsedModulesCookieValue != null) {
      setCollapsedModules(parseCookieValueString(collapsedModulesCookieValue));
    }
  }, [collapsedModulesCookieValue]);

  useEffect(() => {
    if (collapsedSubmodulesCookieValue != null) {
      setCollapsedSubmodules(
        parseCookieValueString(collapsedSubmodulesCookieValue),
      );
    }
  }, [collapsedSubmodulesCookieValue]);

  useEffect(() => {
    if (selectedSubmoduleCookieValue != null) {
      setSelectedSubmodule(selectedSubmoduleCookieValue);
    }
  }, [selectedSubmoduleCookieValue]);

  useEffect(() => {
    if (selectedPromptCookieValue != null) {
      setSelectedPrompt(selectedPromptCookieValue);
    }
  }, [stringifiedSelectedPromptCookieValue]);

  // Setting the remaining height - need to watch variables that trigger re-renders
  useEffect(() => {
    const handleResize = () => {
      const divHeight =
        contentContainerRef?.current?.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const heightDifference = windowHeight - divHeight;
      setRemainingHeight(heightDifference);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    setHasLoaded(true);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width, submoduleDrawerExpanded, hasLoaded]);

  // Save agent
  const saveAgentName = async (updatedName) => {
    saveAgentNameMutation({
      variables: {
        agent: baseAgent,
        name: updatedName,
      },
      onCompleted: (data) => {
        refetchUser();
      },
    });
  };

  const autoSaveAgentName = async () => {
    if (agentName !== baseAgentName) {
      await saveAgentName(agentName);
    }
  };

  const saveAgentSettings = async (updatedSettings) => {
    saveAgentSettingsMutation({
      variables: {
        agentId: updatedSettings?.agentId,
        settings: updatedSettings?.settings,
      },
      onCompleted: () => {
        refetchAgentSettings();
      },
    });
  };

  const autoSaveAgentSettings = async () => {
    if (!baseAgentSettings?.agentId) {
      return;
    }

    if (JSON.stringify(baseAgentSettings) !== JSON.stringify(agentSettings)) {
      await saveAgentSettings(agentSettings);
    }
  };

  // Save staff
  const saveStaff = async (updatedStaff, sourceModifiedStaff) => {
    saveStaffMutation({
      variables: {
        staff: updatedStaff,
      },
      onCompleted: async (data) => {
        const success = data.editStaffMembers;

        if (success) {
          const justUpdatedStaffIdMap = {};
          const lastUpdatedAtMap = {};

          sourceModifiedStaff.map((s) => {
            const { id, lastUpdatedAt } = s;

            if (lastUpdatedAt) {
              lastUpdatedAtMap[lastUpdatedAt] = true;
            }
            justUpdatedStaffIdMap[id] = true;
          });

          refetchStaff();

          // Check for fresh updates
          const recentlyModifiedStaff = modifiedStaff.filter((s) => {
            return (
              !justUpdatedStaffIdMap[s.id] || !lastUpdatedAtMap[s.lastUpdatedAt]
            );
          });
          setModifiedStaff(recentlyModifiedStaff);
        } else {
          refetchStaff();
        }
      },
    });
  };

  const autoSaveStaff = async () => {
    const sourceModifiedStaff = [...modifiedStaff];
    if (!sourceModifiedStaff.length) {
      return;
    }

    const modifiedStaffIdMap = {};
    modifiedStaff.map((s) => {
      modifiedStaffIdMap[s.id] = true;
    });

    const staffToUpdate = staff.filter((s) => {
      return !!modifiedStaffIdMap[s.id];
    });

    await saveStaff(staffToUpdate, sourceModifiedStaff);
  };

  // Save services
  const saveOfferings = async (updatedOfferings, sourceModifiedOfferings) => {
    if (!updatedOfferings?.length) {
      return;
    }

    saveOfferingsMutation({
      variables: {
        offerings: updatedOfferings,
      },
      onCompleted: async (data) => {
        const success = data.editOfferings;

        if (success) {
          const justUpdatedOfferingIdMap = {};
          const lastUpdatedAtMap = {};
          sourceModifiedOfferings.map((s) => {
            const { id, lastUpdatedAt } = s;

            if (lastUpdatedAt) {
              lastUpdatedAtMap[lastUpdatedAt] = true;
            }
            justUpdatedOfferingIdMap[id] = true;
          });

          refetchSessionTypeCatalog();

          // Check for fresh updates
          const recentlyModifiedOfferings = modifiedOfferings.filter((s) => {
            return (
              !justUpdatedOfferingIdMap[s.id] ||
              !lastUpdatedAtMap[s.lastUpdatedAt]
            );
          });
          setModifiedOfferings(recentlyModifiedOfferings);
        } else {
          refetchSessionTypeCatalog();
        }
      },
    });
  };

  const autoSaveOfferings = async () => {
    const sourceModifiedOfferings = [...modifiedOfferings];

    const categories = sessionTypeCatalog?.categories;
    const packages = sessionTypeCatalog?.packages;

    const modifiedOfferingObjects = [];
    sourceModifiedOfferings.map((s) => {
      const { id, categoryId, type } = s;

      if (type === packageKey) {
        const modifiedPackage = packages[id];
        modifiedOfferingObjects.push(modifiedPackage);
      } else {
        const category = categories[categoryId];
        const typeKeyToSearch =
          type === addOnKey
            ? 'addOns'
            : type === dropInKey
            ? 'dropIns'
            : type === consultationKey
            ? 'consultations'
            : null;

        if (typeKeyToSearch) {
          const sessionTypesArr = category[typeKeyToSearch];
          const modifiedSessionTypeObject = sessionTypesArr.find(
            (s) => s.id === id,
          );
          modifiedOfferingObjects.push(modifiedSessionTypeObject);
        }
      }
    });

    // Should always be the case
    await saveOfferings(modifiedOfferingObjects, sourceModifiedOfferings);
  };

  // Save prompts
  const saveServiceBookingPrompt = async (prompt) => {
    saveServiceBookingPromptMutation({
      variables: {
        prompt,
      },
      onCompleted: async (data) => {
        const updatedPrompt = data.editPrompt;
        const prompt = updatedPrompt?.prompt;
        const resolutions = updatedPrompt?.resolutions;

        const promptInputObject = {
          id: prompt?.id,
          categoryId: prompt?.corpusCategoryId,
          status: prompt?.status,
        };

        // Check if more state updates have come through since then, and only update state
        // if its up-to-date with what we've just modified in the DB
        // TODO - not currently working
        const { str: updatedStateStr } = getStringifiedPrompt(
          promptInputObject,
          serviceCorpus,
        );
        const updatedDbStr = stringifyPromptObject(
          promptInputObject,
          resolutions,
        );

        if (updatedDbStr === updatedStateStr) {
          updateServiceBookingPrompt(
            promptInputObject,
            prompt.status,
            updatedPrompt.resolutions,
          );
        }

        refetchServiceCorpus();
      },
    });
  };

  const autoSaveSelectedServiceBookingPrompt = async (
    promptToSave,
    inputCorpus = null,
  ) => {
    if (objectIsEmpty(promptToSave)) {
      return;
    }

    const corpusToUse = inputCorpus || serviceCorpus;

    const { str: promptToSaveStr, obj: promptToSaveObject } =
      getStringifiedPrompt(promptToSave, corpusToUse);

    if (promptToSaveStr) {
      const { id: promptId, categoryId } = promptToSave;
      const baseCategory = baseServiceCorpus[categoryId];
      const basePrompts = baseCategory?.prompts;
      const basePromptObject = basePrompts[promptId];
      const basePrompt = basePromptObject?.prompt;
      const basePromptResolutions = basePromptObject?.resolutions;
      const basePromptStr = stringifyPromptObject(
        basePrompt,
        basePromptResolutions,
      );
      const hasChanged = promptToSaveStr !== basePromptStr;

      if (hasChanged) {
        await saveServiceBookingPrompt(promptToSaveObject);
      }
    }
  };

  // Save FAQs
  const saveFaq = async (id, utterance, resolution) => {
    saveFaqMutation({
      variables: {
        id,
        utterance,
        resolution,
      },
      onCompleted: async (data) => {
        refetchServiceCorpus();
      },
    });
  };

  const autoSaveSelectedFaq = async (faqToSave, inputCorpus = null) => {
    if (objectIsEmpty(faqToSave)) {
      return;
    }

    const corpusToUse = inputCorpus || serviceCorpus;

    const inputFaq = faqToSave?.prompt ? faqToSave.prompt : faqToSave;
    const id = inputFaq?.id;

    const { obj: faqObject } = getStringifiedPrompt(
      {
        id,
        categoryId: inputFaq?.categoryId || inputFaq?.corpusCategoryId,
      },
      corpusToUse,
    );

    const faq = faqObject.prompt;
    const resolutions = faqObject.resolutions;

    const faqStr = getStringifiedFaq(faq, resolutions);

    if (faqStr) {
      const { id: promptId, corpusCategoryId: categoryId } = faq;
      const baseCategory = baseServiceCorpus[categoryId];
      const basePrompts = baseCategory?.prompts;
      const basePromptObject = basePrompts[promptId];
      const basePrompt = basePromptObject?.prompt;
      const basePromptResolutions = basePromptObject?.resolutions;
      const basePromptStr = getStringifiedFaq(
        basePrompt,
        basePromptResolutions,
      );
      const hasChanged = faqStr !== basePromptStr;

      if (hasChanged) {
        await saveFaq(
          id,
          faqObject?.prompt?.utterance,
          faqObject?.resolutions?.[0],
        );
      }
    }
  };

  // Save policy
  const savePolicy = async (updatedPolicy) => {
    console.log('updatedPolicy', updatedPolicy);

    savePolicyMutation({
      variables: {
        policy: updatedPolicy,
      },
      onCompleted: (data) => {
        refetchPolicy();
      },
    });
  };

  const autoSavePolicy = async () => {
    if (!basePolicyData) {
      return;
    }

    if (JSON.stringify(policy) !== JSON.stringify(basePolicyData)) {
      savePolicy(policy);
    }
  };

  // Auto-save Agent
  useEffect(() => {
    const intervalId = setInterval(() => {
      autoSaveAgentName();
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [agentName, baseAgentName]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      autoSaveAgentSettings();
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [agentSettings?.settings, baseAgentSettings?.settings]);

  // Auto-save Staff
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (modifiedStaff.length) {
        autoSaveStaff();
      }
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [modifiedStaff, staff, baseStaffData]);

  // Auto-save Services
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (modifiedOfferings.length) {
        autoSaveOfferings();
      }
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [modifiedOfferings, sessionTypeCatalog, baseSessionTypeCatalog]);

  // Auto-save Prompts/FAQs
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (selectedSubmodule === bookingFlowsSubmoduleKey) {
        autoSaveSelectedServiceBookingPrompt(selectedPrompt);
      } else if (
        selectedSubmodule === serviceFaqsSubmoduleKey ||
        selectedSubmodule === generalFaqsSubmoduleKey
      ) {
        autoSaveSelectedFaq(selectedPrompt);
      }
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [selectedPromptStr, serviceCorpus, baseServiceCorpus]);

  // Auto-save Policy
  useEffect(() => {
    const intervalId = setInterval(() => {
      autoSavePolicy();
    }, autoSaveDelayMs);

    return () => clearInterval(intervalId);
  }, [policy, basePolicyData]);

  // Modify agent
  const onChangeAgentName = (updatedName) => {
    setAgent({ ...agent, name: updatedName });
  };

  const onChangeAgentSettings = (updatedSettings) => {
    setAgentSettings({
      ...agentSettings,
      settings: updatedSettings,
    });
  };

  // Modify staff
  const onChangeStaff = (updatedStaff) => {
    const id = updatedStaff?.id;

    if (!id) {
      return;
    }

    const updatedStaffArr = staff.map((s) => {
      if (s.id === id) {
        return updatedStaff;
      }
      return s;
    });

    setStaff(updatedStaffArr);
    setModifiedStaff([
      ...modifiedStaff,
      { id, lastUpdatedAt: new Date().toISOString() },
    ]);
  };

  // Modify services
  const updateOffering = (originalOffering, updatedOffering) => {
    const originalCategoryId = originalOffering?.categoryId;
    const originalType = originalOffering?.type;
    const { id, categoryId, type } = updatedOffering;
    console.log('updatedOffering', updatedOffering);

    let updatedSessionTypeCatalog;

    if (type === packageKey) {
      const packages = sessionTypeCatalog?.packages;

      updatedSessionTypeCatalog = {
        ...sessionTypeCatalog,
        packages: {
          ...packages,
          [id]: updatedOffering,
        },
      };
    } else {
      const categoryHasChanged = originalCategoryId !== categoryId;
      const typeHasChanged = originalType !== type;

      const originalTypeKeyToSearch =
        originalType === addOnKey
          ? 'addOns'
          : originalType === dropInKey
          ? 'dropIns'
          : originalType === consultationKey
          ? 'consultations'
          : null;
      const typeKeyToSearch = !typeHasChanged
        ? originalTypeKeyToSearch
        : type === addOnKey
        ? 'addOns'
        : type === dropInKey
        ? 'dropIns'
        : type === consultationKey
        ? 'consultations'
        : null;

      if (!typeKeyToSearch) {
        return;
      }

      const categories = sessionTypeCatalog?.categories;
      const wasInitiallyUncategorized = !originalCategoryId;
      const existingCategoryToUpdate = wasInitiallyUncategorized
        ? sessionTypeCatalog?.[uncategorizedKey]
        : categories[originalCategoryId];

      const originalTypeOfferings =
        existingCategoryToUpdate[originalTypeKeyToSearch];
      const updatedOfferings = originalTypeOfferings
        .map((s) => {
          if (s.id === id) {
            return categoryHasChanged || typeHasChanged
              ? null
              : updatedOffering;
          }
          return s;
        })
        .filter((s) => s != null);

      let updatedCategory = {
        ...existingCategoryToUpdate,
        [originalTypeKeyToSearch]: updatedOfferings,
      };

      if (typeHasChanged && !categoryHasChanged) {
        const newTypeOfferings = existingCategoryToUpdate[typeKeyToSearch];
        const newUpdatedOfferings = [...newTypeOfferings, updatedOffering];

        updatedCategory = {
          ...updatedCategory,
          [typeKeyToSearch]: newUpdatedOfferings,
        };
      }

      if (wasInitiallyUncategorized) {
        updatedSessionTypeCatalog = {
          ...sessionTypeCatalog,
          [uncategorizedKey]: {
            ...sessionTypeCatalog[uncategorizedKey],
            ...updatedCategory,
          },
        };
      } else {
        updatedSessionTypeCatalog = {
          ...sessionTypeCatalog,
          categories: {
            ...categories,
            [originalCategoryId]: updatedCategory,
          },
        };
      }

      if (categoryHasChanged) {
        const newCategoryToUpdate = categories[categoryId];
        const newCategoryTypeOfferings = newCategoryToUpdate[typeKeyToSearch];
        const newCategoryUpdatedOfferings = [
          ...newCategoryTypeOfferings,
          updatedOffering,
        ];

        const updatedNewCategory = {
          ...newCategoryToUpdate,
          [typeKeyToSearch]: newCategoryUpdatedOfferings,
        };

        updatedSessionTypeCatalog = {
          ...updatedSessionTypeCatalog,
          categories: {
            ...updatedSessionTypeCatalog.categories,
            [categoryId]: updatedNewCategory,
          },
        };
      }
    }

    setSessionTypeCatalog(updatedSessionTypeCatalog);
    setModifiedOfferings([
      ...modifiedOfferings,
      { id, categoryId, type, lastUpdatedAt: new Date().toISOString() },
    ]);
  };

  // Modify prompts
  const updateServiceBookingPrompt = (
    promptToSave,
    status,
    resolutions = null,
    triggerAutoSave = false,
  ) => {
    const { id: promptId, categoryId } = promptToSave;
    const categoryToUpdate = serviceCorpus[categoryId];
    const prompts = categoryToUpdate?.prompts;
    const promptToUpdate = prompts[promptId];

    const updatedPrompt = {
      ...promptToUpdate,
      prompt: {
        ...promptToUpdate['prompt'],
        status,
      },
      resolutions: resolutions || promptToUpdate.resolutions,
    };

    const updatedCorpus = {
      ...serviceCorpus,
      [categoryId]: {
        ...categoryToUpdate,
        prompts: {
          ...prompts,
          [promptId]: updatedPrompt,
        },
      },
    };

    setServiceCorpus(updatedCorpus);

    if (triggerAutoSave) {
      autoSaveSelectedServiceBookingPrompt(
        {
          id: updatedPrompt?.prompt?.id,
          categoryId: updatedPrompt?.prompt?.corpusCategoryId,
        },
        updatedCorpus,
      );
    }
  };

  // Modify FAQs
  const onCreateBookingFlow = (title, utterance, universal) => {
    const categoryId = createServicePromptModalCategory.id;

    createBookingFlowMutation({
      variables: {
        categoryId,
        title,
        utterance,
        universal,
      },
      onCompleted: async (data) => {
        const createdBookingFlow = data.createBookingFlow;
        const {
          id: createdId,
          status,
          title,
          utterance,
          key,
        } = createdBookingFlow;

        if (createdId) {
          refetchServiceCorpus();
          onChangeSelectedPrompt({
            id: createdId,
            categoryId,
            status,
            title,
            utterance,
            key,
            resolutions: [],
          });
          onChangeCollapsedSubmodules(categoryId, false);
        }
      },
    });
  };

  const onCreateFaq = (title, utterance, answer, universal) => {
    const categoryId = createServicePromptModalCategory.id;

    createFaqMutation({
      variables: {
        categoryId,
        title,
        utterance,
        answer,
        universal,
      },
      onCompleted: async (data) => {
        const createdFaq = data.createFaq;
        const {
          id: createdId,
          status,
          title,
          utterance,
          resolutions,
          key,
        } = createdFaq;

        if (createdId) {
          refetchServiceCorpus();
          onChangeSelectedPrompt({
            id: createdId,
            categoryId,
            status,
            title,
            utterance,
            key,
            resolutions,
          });
          onChangeCollapsedSubmodules(categoryId, false);
        }
      },
    });
  };

  const onUpdateFaq = ({ faq, utterance, answer }) => {
    const { id: promptId, corpusCategoryId: categoryId } = faq;
    const categoryToUpdate = serviceCorpus[categoryId];
    const prompts = categoryToUpdate?.prompts;
    const promptToUpdate = prompts[promptId];

    const updatedPrompt = {
      ...promptToUpdate,
      prompt: {
        ...promptToUpdate['prompt'],
        utterance: utterance || promptToUpdate?.prompt?.utterance,
      },
      resolutions:
        answer != null
          ? [{ ...promptToUpdate.resolutions[0], explanation: answer }]
          : [...promptToUpdate.resolutions],
    };

    const updatedCorpus = {
      ...serviceCorpus,
      [categoryId]: {
        ...categoryToUpdate,
        prompts: {
          ...prompts,
          [promptId]: updatedPrompt,
        },
      },
    };

    setServiceCorpus(updatedCorpus);
  };

  const onDeletePrompt = (id) => {
    deletePromptMutation({
      variables: {
        id,
      },
      onCompleted: () => {
        refetchServiceCorpus();

        if (selectedPrompt.id === id) {
          onChangeSelectedPrompt();
        }
      },
    });
  };

  // Modify deposits
  const onChangeDepositPolicy = (updatedDepositPolicy) => {
    setPolicy((prevPolicy) => ({
      ...prevPolicy,
      deposit: {
        ...prevPolicy.deposit,
        ...updatedDepositPolicy,
      },
    }));
  };

  const onChangeCancellationsPolicy = (updatedCancellationsPolicy) => {
    setPolicy((prevPolicy) => ({
      ...prevPolicy,
      cancellation: {
        ...prevPolicy.cancellation,
        ...updatedCancellationsPolicy,
      },
    }));
  };

  const onChangeFollowUpsPolicy = (updatedFollowUpsPolicy) => {
    setPolicy((prevPolicy) => ({
      ...prevPolicy,
      followUp: {
        ...prevPolicy.followUp,
        ...updatedFollowUpsPolicy,
      },
    }));
  };

  const onChangePaymentsPolicy = (acceptedType, shouldAdd) => {
    const currentPaymentPolicy = policy?.payment;
    const currentAcceptedTypes = currentPaymentPolicy?.acceptedTypes || [];
    const updatedAcceptedTypes = shouldAdd
      ? [...currentAcceptedTypes, acceptedType]
      : currentAcceptedTypes.filter((t) => t !== acceptedType);
    setPolicy({
      ...policy,
      payment: {
        ...currentPaymentPolicy,
        acceptedTypes: Array.from(new Set(updatedAcceptedTypes)),
      },
    });
  };

  // On change selections
  const onChangeCollapsedModules = (key, shouldAdd) => {
    let updatedCollapsedModules;
    if (shouldAdd) {
      updatedCollapsedModules = [...collapsedModules, key];
    } else {
      updatedCollapsedModules = collapsedModules.filter((k) => k !== key);
    }
    console.log('updatedCollapsedModules', updatedCollapsedModules);

    const updatedCollapsedModuleString = parseCookieValueString(
      updatedCollapsedModules,
    );

    setCookie(
      collapsedModulesCookieKey,
      updatedCollapsedModuleString,
      cookieExpiryObject,
    );

    const subModules = modules[key]['submodules'];
    if (
      !shouldAdd &&
      subModules.map((s) => s.key).includes(selectedSubmodule)
    ) {
      onChangeSelectedSubmodule('');
    }
  };

  const onChangeCollapsedSubmodules = (key, shouldAdd) => {
    let updatedCollapsedSubmodules;
    if (shouldAdd) {
      updatedCollapsedSubmodules = [...collapsedSubmodules, key];
    } else {
      updatedCollapsedSubmodules = collapsedSubmodules.filter((k) => k !== key);
    }

    if (updatedCollapsedSubmodules.length === collapsedSubmodules) {
      return;
    }

    const updatedCollapsedSubmodulestring = parseCookieValueString(
      updatedCollapsedSubmodules,
    );

    setCookie(
      collapsedSubmodulesCookieKey,
      updatedCollapsedSubmodulestring,
      cookieExpiryObject,
    );
  };

  const autoSaveOnBlur = (previousSelectedSubmodule) => {
    if (previousSelectedSubmodule === agentInfoKey) {
      autoSaveAgentName();
    } else if (
      previousSelectedSubmodule === agentTextSettingsKey ||
      previousSelectedSubmodule === agentPhoneSettingsKey
    ) {
      autoSaveAgentSettings();
    } else if (previousSelectedSubmodule === staffSubmoduleKey) {
      autoSaveStaff();
    } else if (previousSelectedSubmodule === bookingFlowsSubmoduleKey) {
      autoSaveSelectedServiceBookingPrompt(selectedPrompt);
    } else if (
      previousSelectedSubmodule === serviceFaqsSubmoduleKey ||
      previousSelectedSubmodule === generalFaqsSubmoduleKey
    ) {
      autoSaveSelectedFaq(selectedPrompt);
    } else if (previousSelectedSubmodule === offeringsSubmoduleKey) {
      autoSaveOfferings();
    } else if (policySubmodules.includes(previousSelectedSubmodule)) {
      autoSavePolicy();
    }
  };

  const onChangeSelectedSubmodule = (updatedSelectedSubmodule) => {
    onChangeSelectedPrompt();

    setCookie(
      selectedSubmoduleCookieKey,
      updatedSelectedSubmodule,
      cookieExpiryObject,
    );
  };

  const onChangeSelectedPrompt = (updatedSelectedPrompt) => {
    console.log('updatedSelectedPrompt', updatedSelectedPrompt);
    autoSaveOnBlur(selectedSubmodule);

    setCookie(
      selectedPromptCookieKey,
      updatedSelectedPrompt || {},
      cookieExpiryObject,
    );
  };

  const categoryInclusionArr = [];

  return (
    <>
      <MetaSetter
        title={`Training`}
        description={`Train your LiveIQ Agent`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
        moduleDrawerDisplayed
      >
        <ModulesContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          submoduleDrawerExpanded={submoduleDrawerExpanded}
          ref={contentContainerRef}
          largeBottomPadding
        >
          <ColumnCenteredDiv>
            <PageTitleText>
              Training
              {selectedSubmodule && ` - ${formatKeyToLabel(selectedSubmodule)}`}
            </PageTitleText>
          </ColumnCenteredDiv>
          <ModulesDrawer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
          >
            {moduleStatusLoading ? (
              <DrawerLoadingIndicatorContainer>
                <LoadingIndicator size={40} />
              </DrawerLoadingIndicatorContainer>
            ) : (
              <>
                <ModulesDrawerHeaderContainer>
                  <DarkEssText>Modules</DarkEssText>
                  <Tooltip
                    title={
                      <TooltipTitleText>
                        Click to hear a message from {agentName}
                      </TooltipTitleText>
                    }
                  >
                    <AgentAvatarContainer
                      onClick={() => setAgentVideoMessageModalOpen(true)}
                    >
                      <AgentAvatar />
                    </AgentAvatarContainer>
                  </Tooltip>
                </ModulesDrawerHeaderContainer>
                <AutoScrollWrapper>
                  {moduleArr.map((key, idx) => {
                    const m = modules[key];
                    const { label, submodules } = m;
                    const labelToUse = label;
                    const isExpanded = !collapsedModules.includes(key);

                    const submoduleStatusMap = moduleStatusMap[key] || {};
                    const submoduleStatuses = Object.values(submoduleStatusMap);
                    const moduleIsComplete =
                      !!submoduleStatuses.length &&
                      !submoduleStatuses.some((s) => s === false);
                    return (
                      <ModuleContainer top={idx === 0}>
                        <ModuleListItem>
                          <ModuleNameContainer>
                            {moduleIsComplete ? CompleteIcon : IncompleteIcon}
                            <ModuleNameText selected={selectedModule === key}>
                              {labelToUse}
                            </ModuleNameText>
                          </ModuleNameContainer>
                          <ModuleIconsContainer>
                            <m.icon />
                            {isExpanded ? (
                              <ModuleUpArrow
                                onClick={() => {
                                  onChangeCollapsedModules(key, true);
                                }}
                              />
                            ) : (
                              <ModuleDownArrow
                                onClick={() => {
                                  onChangeCollapsedModules(key, false);
                                }}
                              />
                            )}
                          </ModuleIconsContainer>
                        </ModuleListItem>
                        {isExpanded && (
                          <SubmodulesContainer>
                            {(submodules || []).map((s) => {
                              const { key, label } = s;
                              const selected = key === selectedSubmodule;

                              const submoduleIsComplete =
                                !!submoduleStatusMap[key];
                              return (
                                <SubmoduleContainer
                                  onClick={() => {
                                    onChangeSelectedSubmodule(key);
                                  }}
                                >
                                  <SubmoduleText selected={selected}>
                                    {label}
                                  </SubmoduleText>
                                  {submoduleIsComplete
                                    ? CompleteIcon
                                    : IncompleteIcon}
                                </SubmoduleContainer>
                              );
                            })}
                          </SubmodulesContainer>
                        )}
                      </ModuleContainer>
                    );
                  })}
                </AutoScrollWrapper>
              </>
            )}
          </ModulesDrawer>
          {submoduleDrawerExpanded && !moduleStatusLoading && (
            <SubmodulesDrawer
              drawerOpen={drawerOpen}
              drawerExpanded={drawerExpanded}
            >
              {serviceCorpusLoading ? (
                <AbsoluteLoadingIndicatorContainer>
                  <LoadingIndicator />
                </AbsoluteLoadingIndicatorContainer>
              ) : (
                <SubmoduleCardScrollContainer>
                  {sortedCategories.map((category, idx) => {
                    const {
                      id: categoryId,
                      name,
                      prompts,
                      type,
                      included,
                    } = category;
                    const promptIds = Object.keys(prompts);
                    const formattedName = formatKeyToLabel(name);
                    const typeToFilterOn =
                      selectedSubmodule === bookingFlowsSubmoduleKey
                        ? serviceCategoryTypeKey
                        : selectedSubmodule === serviceFaqsSubmoduleKey
                        ? serviceCategoryTypeKey
                        : selectedSubmodule === generalFaqsSubmoduleKey
                        ? generalCategoryTypeKey
                        : null;

                    const displayed =
                      type === typeToFilterOn &&
                      (selectedSubmodule === bookingFlowsSubmoduleKey ||
                        ((selectedSubmodule === serviceFaqsSubmoduleKey ||
                          selectedSubmodule === generalFaqsSubmoduleKey) &&
                          included));

                    let shouldDisplayExclusionBanner;
                    if (displayed) {
                      const numExistingInclusions = categoryInclusionArr.length;
                      const previousInclusion = numExistingInclusions
                        ? categoryInclusionArr[numExistingInclusions - 1]
                        : null;
                      shouldDisplayExclusionBanner = !previousInclusion
                        ? false
                        : !included && previousInclusion === true;
                      categoryInclusionArr.push(included);
                    }

                    let categoryIsComplete = true;

                    const promptObjects = sortBy(
                      promptIds.map((id) => {
                        const promptObject = prompts[id]?.prompt;
                        if (
                          categoryIsComplete &&
                          promptObject.status !== completeStatusKey
                        ) {
                          categoryIsComplete = false;
                        }
                        return promptObject;
                      }),
                      (p) =>
                        p.key === defaultServicePromptKey
                          ? -1
                          : p.key === defaultMultipersonServicePromptKey
                          ? 0
                          : 1,
                      (p) => p.title,
                    );

                    const categoryIsExpanded =
                      !collapsedSubmodules.includes(categoryId);

                    return (
                      displayed && (
                        <>
                          {shouldDisplayExclusionBanner && (
                            <ListBannerContainer>
                              <ListBannerTextContainer>
                                <LightErrorTinyText centerElements>
                                  Excluded Categories{' '}
                                  {excludedOfferingCategoriesShown ? (
                                    <CategoryUpArrow
                                      onClick={() => {
                                        setExcludedOfferingCategoriesShown(
                                          false,
                                        );
                                      }}
                                    />
                                  ) : (
                                    <CategoryDownArrow
                                      onClick={() => {
                                        setExcludedOfferingCategoriesShown(
                                          true,
                                        );
                                      }}
                                    />
                                  )}
                                </LightErrorTinyText>
                              </ListBannerTextContainer>
                            </ListBannerContainer>
                          )}
                          {!(!excludedOfferingCategoriesShown && !included) && (
                            <SubmodulesSection>
                              <SubmoduleSectionHeaderContainer>
                                <SubmoduleNameContainer>
                                  {categoryIsComplete
                                    ? CompleteIcon
                                    : IncompleteIcon}
                                  <SubmoduleNameText>
                                    {formattedName}
                                  </SubmoduleNameText>
                                </SubmoduleNameContainer>
                                {categoryIsExpanded ? (
                                  <ModuleUpArrow
                                    onClick={() => {
                                      onChangeCollapsedSubmodules(
                                        categoryId,
                                        true,
                                      );
                                    }}
                                  />
                                ) : (
                                  <ModuleDownArrow
                                    onClick={() => {
                                      onChangeCollapsedSubmodules(
                                        categoryId,
                                        false,
                                      );
                                    }}
                                  />
                                )}
                              </SubmoduleSectionHeaderContainer>
                              {categoryIsExpanded && (
                                <SubmoduleCardSectionContainer>
                                  {promptObjects.map((prompt) => {
                                    const id = prompt.id;
                                    const promptObject = prompts[id];
                                    const resolutions =
                                      promptObject?.resolutions;
                                    const status = prompt?.status;
                                    const title = prompt?.title;
                                    const key = prompt?.key;

                                    const titleCharLimit = 25;
                                    const formattedTitle =
                                      title.length > titleCharLimit
                                        ? `${title
                                            .slice(0, titleCharLimit)
                                            .trim()}...`
                                        : title;

                                    const submoduleTypeToFilterOn =
                                      selectedSubmodule ===
                                      bookingFlowsSubmoduleKey
                                        ? bookingPromptTypeKey
                                        : selectedSubmodule ===
                                            serviceFaqsSubmoduleKey ||
                                          selectedSubmodule ===
                                            generalFaqsSubmoduleKey
                                        ? faqPromptTypeKey
                                        : null;
                                    const type = prompt?.type;

                                    if (type !== submoduleTypeToFilterOn) {
                                      return <></>;
                                    }

                                    const isCustomToLocation =
                                      prompt?.key === locationCustomPromptKey;
                                    const isUniversalCustom =
                                      prompt?.key?.includes(universalPromptKey);

                                    const corpusCategoryId =
                                      prompt?.corpusCategoryId;
                                    const selected = selectedPrompt?.id === id;
                                    const complete =
                                      prompt?.status === completeStatusKey;

                                    return (
                                      <>
                                        <SubmoduleCard
                                          onClick={() => {
                                            const promptObject = {
                                              id,
                                              categoryId: corpusCategoryId,
                                              title,
                                              status,
                                              key,
                                              utterance: prompt.utterance,
                                              resolutions,
                                            };
                                            onChangeSelectedPrompt(
                                              promptObject,
                                            );
                                          }}
                                          selected={selected}
                                          disableBoxShadow={!selected}
                                        >
                                          <SubmoduleCardText>
                                            {formattedTitle}
                                          </SubmoduleCardText>
                                          {complete
                                            ? CompleteIcon
                                            : IncompleteIcon}
                                          {(isCustomToLocation ||
                                            (isUniversalCustom &&
                                              isSuperAdmin)) && (
                                            <CancelIconContainer
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                onDeletePrompt(id);
                                              }}
                                            >
                                              <RedCancelIcon extraSmall />
                                            </CancelIconContainer>
                                          )}
                                        </SubmoduleCard>
                                      </>
                                    );
                                  })}
                                </SubmoduleCardSectionContainer>
                              )}
                              {(selectedSubmodule === serviceFaqsSubmoduleKey ||
                                selectedSubmodule ===
                                  bookingFlowsSubmoduleKey) && (
                                <AddSubmoduleCardButtonContainer>
                                  <AddSubmoduleCardButton
                                    onClick={() => {
                                      if (
                                        selectedSubmodule ===
                                        serviceFaqsSubmoduleKey
                                      ) {
                                        setCreateFaqModalOpen(true);
                                      } else {
                                        setCreateBookingFlowModalOpen(true);
                                      }
                                      setCreateServicePromptModalCategory({
                                        id: categoryId,
                                        name: formattedName,
                                      });
                                    }}
                                  >
                                    <ExtraSmallAddIcon green />
                                    <LightExtraTinyText>Add</LightExtraTinyText>
                                  </AddSubmoduleCardButton>
                                </AddSubmoduleCardButtonContainer>
                              )}
                            </SubmodulesSection>
                          )}
                        </>
                      )
                    );
                  })}
                </SubmoduleCardScrollContainer>
              )}
            </SubmodulesDrawer>
          )}
          {selectedSubmodule && !moduleStatusLoading ? (
            <AnswerSection remainingHeight={remainingHeight}>
              {selectedSubmodule === agentInfoKey ? (
                <Info
                  agent={agent}
                  loading={userLoading}
                  onChange={onChangeAgentName}
                  onBlur={() => autoSaveAgentName()}
                />
              ) : selectedSubmodule === agentPhoneSettingsKey ? (
                <PhoneSettings
                  agentName={agentName}
                  agentSettings={agentSettings}
                  loading={agentSettingsLoading}
                  onChange={onChangeAgentSettings}
                  onBlur={() => autoSaveAgentSettings()}
                />
              ) : selectedSubmodule === agentTextSettingsKey ? (
                <TextSettings
                  agentName={agentName}
                  agentSettings={agentSettings}
                  loading={agentSettingsLoading}
                  onChange={onChangeAgentSettings}
                  onBlur={() => autoSaveAgentSettings()}
                />
              ) : selectedSubmodule === addressSubmoduleKey ? (
                <Location res={overviewRes} />
              ) : selectedSubmodule === hoursSubmoduleKey ? (
                <Hours res={overviewRes} />
              ) : selectedSubmodule === linksSubmoduleKey ? (
                <Links res={overviewRes} />
              ) : selectedSubmodule === categoriesSubmoduleKey ? (
                <Categories
                  loading={serviceCorpusLoading}
                  baseCategories={sortedCategories}
                  refetch={refetchServiceCorpus}
                />
              ) : selectedSubmodule === staffSubmoduleKey ? (
                <Staff
                  staff={staff}
                  onUpdate={onChangeStaff}
                  loading={staffLoading}
                  agentName={agentName}
                  remainingHeight={remainingHeight}
                />
              ) : selectedSubmodule === offeringsSubmoduleKey ? (
                <Services
                  loading={sessionTypesLoading}
                  sessionTypeCatalog={sessionTypeCatalog}
                  onUpdate={updateOffering}
                  agentName={agentName}
                  remainingHeight={remainingHeight}
                />
              ) : selectedSubmodule === bookingFlowsSubmoduleKey ? (
                <ServiceMapping
                  serviceCorpus={serviceCorpus}
                  loading={sessionTypesLoading}
                  sessionTypeCatalog={sessionTypeCatalog}
                  agentName={agentName}
                  selectedPrompt={selectedPrompt}
                  onChangePromptResolutions={(
                    updatedStatus,
                    updatedResolutions,
                  ) =>
                    updateServiceBookingPrompt(
                      selectedPrompt,
                      updatedStatus,
                      updatedResolutions,
                    )
                  }
                  onChangePromptStatus={(status) =>
                    updateServiceBookingPrompt(
                      selectedPrompt,
                      status,
                      null,
                      true,
                    )
                  }
                  remainingHeight={remainingHeight}
                />
              ) : selectedSubmodule === serviceFaqsSubmoduleKey ? (
                <ServiceFAQ
                  selectedFaq={selectedPrompt}
                  serviceCorpus={serviceCorpus}
                  refetch={refetchServiceCorpus}
                  onUpdate={onUpdateFaq}
                  agentName={agentName}
                />
              ) : selectedSubmodule === generalFaqsSubmoduleKey ? (
                <GeneralFAQ
                  selectedFaq={selectedPrompt}
                  serviceCorpus={serviceCorpus}
                  refetch={refetchServiceCorpus}
                  onUpdate={onUpdateFaq}
                  agentName={agentName}
                />
              ) : selectedSubmodule === membershipsSubmoduleKey ? (
                <Memberships res={membershipsRes} />
              ) : selectedSubmodule === assetsSubmoduleKey ? (
                <Assets agentName={agentName} />
              ) : selectedSubmodule === bookingSubmoduleKey ? (
                <Deposits
                  policy={policy}
                  onChange={onChangeDepositPolicy}
                  loading={policyLoading}
                />
              ) : selectedSubmodule === cancellationSubmoduleKey ? (
                <Cancellations
                  policy={policy}
                  loading={policyLoading}
                  onChange={onChangeCancellationsPolicy}
                />
              ) : selectedSubmodule === followUpsSubmoduleKey ? (
                <FollowUps
                  policy={policy}
                  onChange={onChangeFollowUpsPolicy}
                  links={overviewData?.links}
                  loading={policyLoading}
                />
              ) : selectedSubmodule === paymentsSubmoduleKey ? (
                <Payments
                  policy={policy}
                  onChange={onChangePaymentsPolicy}
                  loading={policyLoading}
                />
              ) : (
                <></>
              )}
            </AnswerSection>
          ) : (
            <EmptySelectOptionsContainer>
              <LightDarkSmallText>
                Select a module to train {agentName} on
              </LightDarkSmallText>
              <SelectIcon />
            </EmptySelectOptionsContainer>
          )}
        </ModulesContentContainer>
      </PageContainer>
      <CreateBookingFlowModal
        isOpen={createBookingFlowModalOpen}
        onClose={() => {
          setCreateBookingFlowModalOpen(false);
          setCreateServicePromptModalCategory();
        }}
        category={createServicePromptModalCategory}
        onCreate={onCreateBookingFlow}
      />
      <CreateFAQModal
        isOpen={createFaqModalOpen}
        onClose={() => {
          setCreateFaqModalOpen(false);
          setCreateServicePromptModalCategory();
        }}
        category={createServicePromptModalCategory}
        onCreate={onCreateFaq}
      />
      <AgentVideoMessageModal
        isOpen={agentVideoMessageModalOpen}
        onClose={() => setAgentVideoMessageModalOpen(false)}
        agentName={agentName}
      />
    </>
  );
};

export default Training;
