import { useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import get from 'lodash/get';
import {
  GET_CLIENT_FILTER_TEMPLATE_BY_ID,
  GET_CONTACT_BY_ID,
  GET_MY_CLIENT_FILTER_TEMPLATES,
} from '../queries/client';

export const useContactById = ({ id }) => {
  const { data, error, loading, refetch } = useQuery(GET_CONTACT_BY_ID, {
    fetchPolicy: 'cache-first',
    variables: {
      id,
    },
    skip: !id,
  });

  const client = get(data, 'getContactById', null);

  return {
    error,
    loading,
    refetch,
    client,
  };
};

export const useMyClientFilterTemplates = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_MY_CLIENT_FILTER_TEMPLATES,
    {
      fetchPolicy: 'cache-first',
      skip: skipCondition,
    },
  );

  const clientFilterTemplates = get(data, 'getMyClientFilterTemplates', []);

  const sortedTemplates = sortBy(
    clientFilterTemplates,
    (t) => new Date(get(t, 'updatedAt')),
  ).reverse();

  return {
    error,
    loading,
    refetch,
    clientFilterTemplates: sortedTemplates,
  };
};

export const useClientFilterTemplateById = ({ id }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_CLIENT_FILTER_TEMPLATE_BY_ID,
    {
      fetchPolicy: 'cache-first',
      variables: {
        id,
      },
      skip: !id,
    },
  );

  const template = get(data, 'getClientFilterTemplateById', null);

  return {
    error,
    loading,
    refetch,
    template,
  };
};
