import sortBy from 'lodash/sortBy';
import React, { useContext, useState } from 'react';
import {
  CapitalizedTextSpan,
  CenteredDivWithExtraSmallGap,
  DarkMLText,
  MediumDarkLargeTinyText,
  MediumGapFullWidthColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  MenuItem,
  MenuItemCheckmark,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainerScrollWrapper,
  ModalTitleTextContainer,
  SmallMenuItemText,
  StatusTextSpan,
  TinyText,
  TinyTextInput,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import {
  ascendingKey,
  cancelledStatusKey,
  completeStatusKey,
  descendingKey,
  integerInputType,
  liveiqSourceKey,
  noShowStatusKey,
  scheduledStatusKey,
  serviceSaleType,
  sessionTypeUnitsMap,
  sessionUnitKey,
} from '../../../utils/constants';
import { getFormattedDateString } from '../../../utils/date';
import { getInitials } from '../../../utils/name';
import {
  formatNumber,
  safeParseFloat,
  safeParseInt,
} from '../../../utils/numbers';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../LoadingIndicator';
import NumberFieldFormat from '../../NumberFieldFormat';
import PopperMenu from '../../PopperMenu';
import {
  ActionsButtonContainer,
  AnalyticsItemActionsContainer,
  AnalyticsItemContainer,
  CancelUnassignmentButton,
  CollapseLineItemsIcon,
  EditAppointmentIcon,
  EditSalePriceContainer,
  EditStatusContainer,
  ExpandLineItemsIcon,
  LineItemsContainer,
  MenuContainer,
  MenuIcon,
  SaveEditsButton,
  UnassignAppointmentIcon,
  UnassignButton,
} from './styled';

const allAppointmentsKey = 'all';
const completedAppointmentsKey = 'completed';
const upcomingAppointmentsKey = 'upcoming';
const cancelledAppointmentsKey = 'cancelled';

const contactNameSortKey = 'name';
const dateSortKey = 'date';
const statusSortKey = 'status';

const customStatusOrder = {
  complete: 1,
  scheduled: 2,
  [cancelledStatusKey]: 3,
};

const getSortedAppointments = (appointments, sortField) => {
  const { key, direction } = sortField;

  let sorted;
  if (key === contactNameSortKey) {
    sorted = sortBy(appointments, (a) => a.contact?.name);
  } else if (key === dateSortKey) {
    sorted = sortBy(appointments, (a) => a.startedAt);
  } else if (key === statusSortKey) {
    sorted = sortBy(appointments, (a) => customStatusOrder[a.status]);
  }

  return direction === descendingKey ? sorted.reverse() : sorted;
};

const AnalyticsAppointmentModal = ({
  isOpen,
  onClose,
  appointments,
  title,
  onViewChatsWithAppointmentStatus,
  onEdit,
  onUnassign,
  actionLoading,
}) => {
  const { inDemoMode } = useContext(BaseContext);

  const [sortField, setSortField] = useState({
    key: statusSortKey,
    direction: ascendingKey,
  });
  const [sortParamMenuAnchorEl, setSortParamMenuAnchorEl] = useState();
  const [appointmentToEdit, setAppointmentToEdit] = useState();
  const [
    appointmentIdsWithExpandedLineItems,
    setAppointmentIdsWithExpandedLineItems,
  ] = useState([]);
  const [appointmentStatusMenuAnchorEl, setAppointmentStatusMenuAnchorEl] =
    useState();
  const [appointmentIdToUnassign, setAppointmentIdToUnassign] = useState();

  const sortParamMenuOpenBoolean = Boolean(sortParamMenuAnchorEl);
  const appointmentStatusMenuOpenBoolean = Boolean(
    appointmentStatusMenuAnchorEl,
  );

  const sortedAppointments = getSortedAppointments(appointments, sortField);

  const sortFieldString = `${sortField['key']}-${sortField['direction']}`;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      maxWidth={600}
    >
      <ModalContainer>
        <ModalTitleTextContainer>
          <DarkMLText>
            {title} ({sortedAppointments.length})
          </DarkMLText>
          <MenuContainer
            onClick={(e) => setSortParamMenuAnchorEl(e.currentTarget)}
          >
            <MenuIcon />
          </MenuContainer>
        </ModalTitleTextContainer>
        <PopperMenu
          open={sortParamMenuOpenBoolean}
          anchorElement={sortParamMenuAnchorEl}
          onClose={() => setSortParamMenuAnchorEl()}
          variant='offset'
        >
          <MenuItem
            disableRipple
            onClick={() => {
              setSortField({
                key: statusSortKey,
                direction: ascendingKey,
              });
            }}
          >
            <SmallMenuItemText>Status (asc.)</SmallMenuItemText>
            {sortFieldString === `${statusSortKey}-${ascendingKey}` && (
              <MenuItemCheckmark />
            )}
          </MenuItem>
          <MenuItem
            disableRipple
            onClick={() => {
              setSortField({
                key: statusSortKey,
                direction: descendingKey,
              });
            }}
          >
            <SmallMenuItemText>Status (desc.)</SmallMenuItemText>
            {sortFieldString === `${statusSortKey}-${descendingKey}` && (
              <MenuItemCheckmark />
            )}
          </MenuItem>
          <MenuItem
            disableRipple
            onClick={() => {
              setSortField({
                key: contactNameSortKey,
                direction: ascendingKey,
              });
            }}
          >
            <SmallMenuItemText>Client (asc.)</SmallMenuItemText>
            {sortFieldString === `${contactNameSortKey}-${ascendingKey}` && (
              <MenuItemCheckmark />
            )}
          </MenuItem>
          <MenuItem
            disableRipple
            onClick={() => {
              setSortField({
                key: contactNameSortKey,
                direction: descendingKey,
              });
            }}
          >
            <SmallMenuItemText>Client (desc.)</SmallMenuItemText>
            {sortFieldString === `${contactNameSortKey}-${descendingKey}` && (
              <MenuItemCheckmark />
            )}
          </MenuItem>
        </PopperMenu>
        <MediumGapFullWidthColumnCenteredDiv>
          <ModalInputsContainerScrollWrapper columnFlex>
            {actionLoading ? (
              <LoadingIndicator />
            ) : (
              <>
                {sortedAppointments.map((a) => {
                  const id = a.id;
                  const selectedForEditing = id === appointmentToEdit?.id;
                  const appointmentToDisplay = selectedForEditing
                    ? appointmentToEdit
                    : a;
                  const status = appointmentToDisplay.status;

                  const sales = appointmentToDisplay.sales;
                  const lineItems = [];
                  const revenue = sales
                    ? sales.reduce((acc, sale) => {
                        const { totalAmount, name } = sale;
                        lineItems.push({ totalAmount, name });
                        return acc + parseInt(totalAmount);
                      }, 0)
                    : appointmentToDisplay.appliedPrice || null;
                  const numLineItems = lineItems.length;
                  const revenueLabel = formatNumber(revenue);
                  const lineItemsExpanded =
                    appointmentIdsWithExpandedLineItems.includes(id);

                  const hasRevenue = revenue != null && revenue != -1;

                  const sessionTypeId = appointmentToDisplay.sessionTypeId;
                  const sessionTypeName = appointmentToDisplay.sessionTypeName;
                  const serviceUnits = appointmentToDisplay.sessionTypeUnits;
                  const selectedForUnassignment =
                    id === appointmentIdToUnassign;
                  const contact = appointmentToDisplay.contact;

                  const statusLabel = `${status[0].toUpperCase()}${status.slice(
                    1,
                  )}`.replaceAll('_', ' ');

                  return (
                    <AnalyticsItemContainer>
                      <MediumDarkLargeTinyText capitalize>
                        {selectedForEditing && (
                          <>
                            Editing:
                            <br></br>
                          </>
                        )}
                        {!selectedForEditing ? (
                          <>
                            <CapitalizedTextSpan>
                              {inDemoMode
                                ? getInitials(
                                    contact?.name?.split(' ')?.[0],
                                    contact?.name?.split(' ')?.[1],
                                  )
                                : contact?.name}
                            </CapitalizedTextSpan>
                            {' - '}
                            <StatusTextSpan status={status}>
                              {statusLabel}
                            </StatusTextSpan>
                          </>
                        ) : (
                          <EditStatusContainer>
                            <CapitalizedTextSpan>
                              {inDemoMode
                                ? getInitials(
                                    contact?.name?.split(' ')?.[0],
                                    contact?.name?.split(' ')?.[1],
                                  )
                                : contact?.name}
                            </CapitalizedTextSpan>
                            {' - '}
                            <StatusTextSpan status={status}>
                              {statusLabel}
                            </StatusTextSpan>
                            <MenuContainer
                              onClick={(e) =>
                                setAppointmentStatusMenuAnchorEl(
                                  e.currentTarget,
                                )
                              }
                            >
                              <MenuIcon selected />
                            </MenuContainer>
                            <PopperMenu
                              open={appointmentStatusMenuOpenBoolean}
                              anchorElement={appointmentStatusMenuAnchorEl}
                              onClose={() => setAppointmentStatusMenuAnchorEl()}
                              variant='offset'
                            >
                              <MenuItem
                                disableRipple
                                onClick={() => {
                                  setAppointmentToEdit({
                                    ...appointmentToEdit,
                                    status: scheduledStatusKey,
                                  });
                                }}
                              >
                                <SmallMenuItemText>Scheduled</SmallMenuItemText>
                                {status === scheduledStatusKey && (
                                  <MenuItemCheckmark />
                                )}
                              </MenuItem>
                              <MenuItem
                                disableRipple
                                onClick={() => {
                                  setAppointmentToEdit({
                                    ...appointmentToEdit,
                                    status: completeStatusKey,
                                  });
                                }}
                              >
                                <SmallMenuItemText>Complete</SmallMenuItemText>
                                {status === completeStatusKey && (
                                  <MenuItemCheckmark />
                                )}
                              </MenuItem>
                              <MenuItem
                                disableRipple
                                onClick={() => {
                                  setAppointmentToEdit({
                                    ...appointmentToEdit,
                                    status: cancelledStatusKey,
                                  });
                                }}
                              >
                                <SmallMenuItemText>Cancelled</SmallMenuItemText>
                                {status === cancelledStatusKey && (
                                  <MenuItemCheckmark />
                                )}
                              </MenuItem>
                              <MenuItem
                                disableRipple
                                onClick={() => {
                                  setAppointmentToEdit({
                                    ...appointmentToEdit,
                                    status: noShowStatusKey,
                                  });
                                }}
                              >
                                <SmallMenuItemText>No-show</SmallMenuItemText>
                                {status === noShowStatusKey && (
                                  <MenuItemCheckmark />
                                )}
                              </MenuItem>
                            </PopperMenu>
                          </EditStatusContainer>
                        )}
                        <br></br>
                        <span style={{ textTransform: 'none' }}>
                          {sessionTypeName || 'Unknown service'} on{' '}
                          {appointmentToDisplay.startedAt
                            ? getFormattedDateString(
                                appointmentToDisplay.startedAt,
                                false,
                              )
                            : 'unknown date'}
                        </span>
                        {status !== cancelledStatusKey &&
                          status !== noShowStatusKey && (
                            <>
                              <br></br>
                              {!selectedForEditing ? (
                                <>
                                  <CenteredDivWithExtraSmallGap
                                    style={{
                                      textTransform: 'none',
                                    }}
                                  >
                                    {hasRevenue
                                      ? `${
                                          status === completeStatusKey
                                            ? `Collected`
                                            : `Booked for`
                                        } ${
                                          safeParseInt(revenue) === -1
                                            ? `${
                                                status === completeStatusKey
                                                  ? 'unknown'
                                                  : 'variable'
                                              } price`
                                            : `$${revenueLabel}${
                                                !!serviceUnits &&
                                                serviceUnits !==
                                                  sessionUnitKey &&
                                                status !== completeStatusKey
                                                  ? `/${
                                                      sessionTypeUnitsMap[
                                                        serviceUnits
                                                      ]?.toLowerCase() ||
                                                      sessionUnitKey
                                                    } `
                                                  : ''
                                              }${
                                                numLineItems
                                                  ? ` (${numLineItems} line item${
                                                      numLineItems > 1
                                                        ? 's'
                                                        : ''
                                                    })`
                                                  : ''
                                              }`
                                        }`
                                      : `${
                                          status === completeStatusKey
                                            ? `Collected revenue unknown`
                                            : `Booked price unknown`
                                        }`}
                                    {hasRevenue && numLineItems ? (
                                      <>
                                        {!lineItemsExpanded ? (
                                          <ExpandLineItemsIcon
                                            onClick={() => {
                                              setAppointmentIdsWithExpandedLineItems(
                                                [
                                                  ...appointmentIdsWithExpandedLineItems,
                                                  id,
                                                ],
                                              );
                                            }}
                                          />
                                        ) : (
                                          <CollapseLineItemsIcon
                                            onClick={() => {
                                              setAppointmentIdsWithExpandedLineItems(
                                                appointmentIdsWithExpandedLineItems.filter(
                                                  (expandedId) =>
                                                    expandedId !== id,
                                                ),
                                              );
                                            }}
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </CenteredDivWithExtraSmallGap>
                                  {lineItemsExpanded && (
                                    <LineItemsContainer>
                                      {lineItems.map((l) => (
                                        <TinyText>
                                          {l.name} ($
                                          {formatNumber(
                                            parseFloat(l.totalAmount),
                                          )}
                                          )
                                        </TinyText>
                                      ))}
                                    </LineItemsContainer>
                                  )}
                                </>
                              ) : (
                                <EditSalePriceContainer>
                                  {status === completeStatusKey
                                    ? `Collected`
                                    : `Booked price`}
                                  :{' '}
                                  <TinyTextInput
                                    value={revenue}
                                    onChange={(e) => {
                                      const updatedSaleValue = safeParseFloat(
                                        e.target.value,
                                      );
                                      const existingSales =
                                        appointmentToEdit['sales'] || [];
                                      const numExistingSales =
                                        existingSales.length;

                                      let updatedSales;
                                      const newSaleObject = {
                                        itemType: serviceSaleType,
                                        itemTypeId: sessionTypeId,
                                        name: `'${sessionTypeName}' Service`,
                                        source: liveiqSourceKey,
                                        clientId: appointmentToDisplay.clientId,
                                      };

                                      if (!numExistingSales) {
                                        // Add new sale
                                        updatedSales = [
                                          {
                                            totalAmount: updatedSaleValue,
                                            ...newSaleObject,
                                          },
                                        ];
                                      } else if (numExistingSales === 1) {
                                        // Edit existing sale
                                        updatedSales = [
                                          {
                                            ...existingSales[0],
                                            totalAmount: updatedSaleValue,
                                            ...newSaleObject,
                                          },
                                        ];
                                      } else {
                                        // If sale for service found, update that and set all other sales to $0,
                                        // if not found, arbitrarily decide to update the first one,
                                        // and then set all other sales to $0
                                        const matchingSaleToUpdate =
                                          existingSales.find(
                                            (s) =>
                                              (s.sessionTypeId =
                                                a.sessionTypeId),
                                          );
                                        if (matchingSaleToUpdate) {
                                          updatedSales = existingSales.map(
                                            (s) => {
                                              return {
                                                ...s,
                                                totalAmount:
                                                  s.id ===
                                                  matchingSaleToUpdate.id
                                                    ? updatedSaleValue
                                                    : 0,
                                              };
                                            },
                                          );
                                        } else {
                                          updatedSales = existingSales.map(
                                            (s, idx) => {
                                              return {
                                                ...s,
                                                totalAmount:
                                                  idx === 0
                                                    ? updatedSaleValue
                                                    : 0,
                                                ...newSaleObject,
                                              };
                                            },
                                          );
                                        }
                                      }

                                      const updatedAppointment = {
                                        ...appointmentToEdit,
                                        sales: updatedSales,
                                      };
                                      setAppointmentToEdit(updatedAppointment);
                                    }}
                                    fixedWidth={100}
                                    type={integerInputType}
                                    InputProps={{
                                      inputComponent: NumberFieldFormat,
                                    }}
                                  />
                                </EditSalePriceContainer>
                              )}
                            </>
                          )}
                      </MediumDarkLargeTinyText>
                      <AnalyticsItemActionsContainer>
                        <Tooltip
                          title={
                            <TooltipTitleText>
                              Edit appointment
                            </TooltipTitleText>
                          }
                          placement='right'
                        >
                          <EditAppointmentIcon
                            onClick={() => {
                              if (selectedForEditing) {
                                setAppointmentToEdit();
                              } else {
                                setAppointmentToEdit(a);
                                setAppointmentIdToUnassign();
                              }
                            }}
                            selected={selectedForEditing}
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            <TooltipTitleText>
                              Unassign appointment
                            </TooltipTitleText>
                          }
                          placement='right'
                        >
                          <UnassignAppointmentIcon
                            onClick={() => {
                              if (selectedForUnassignment) {
                                setAppointmentIdToUnassign();
                              } else {
                                setAppointmentIdToUnassign(id);
                                setAppointmentToEdit();
                              }
                            }}
                            selected={selectedForUnassignment}
                          />
                        </Tooltip>
                      </AnalyticsItemActionsContainer>
                      {selectedForEditing && (
                        <ActionsButtonContainer>
                          <SaveEditsButton
                            onClick={() => {
                              onEdit(appointmentToEdit);
                              setAppointmentToEdit();
                            }}
                          >
                            Save changes
                          </SaveEditsButton>
                          <CancelUnassignmentButton
                            onClick={() => setAppointmentToEdit()}
                          >
                            Cancel
                          </CancelUnassignmentButton>
                        </ActionsButtonContainer>
                      )}
                      {selectedForUnassignment && (
                        <ActionsButtonContainer>
                          <UnassignButton
                            onClick={() => {
                              onUnassign(id);
                              setAppointmentIdToUnassign();
                            }}
                            disabled={actionLoading}
                          >
                            Confirm unassignment
                          </UnassignButton>
                          <CancelUnassignmentButton
                            onClick={() => setAppointmentIdToUnassign()}
                          >
                            Cancel
                          </CancelUnassignmentButton>
                        </ActionsButtonContainer>
                      )}
                    </AnalyticsItemContainer>
                  );
                })}
              </>
            )}
          </ModalInputsContainerScrollWrapper>
        </MediumGapFullWidthColumnCenteredDiv>
        <ModalActions>
          <MediumPrimaryButton onClick={onViewChatsWithAppointmentStatus}>
            View chats
          </MediumPrimaryButton>
          <MediumSecondaryButton onClick={onClose}>Close</MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default AnalyticsAppointmentModal;
