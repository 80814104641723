import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownLabel,
  Form,
  MLText,
  MediumDarkEssText,
  MediumPrimaryButton,
  MenuItem,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
} from '../../../styles/shared-styled-components';
import {
  addOnKey,
  dollarInputType,
  dropdownInputType,
  fixedPriceSettingKey,
  integerInputType,
  priceSettingsLabelMap,
  requiresConsultationBookableSessionTypeStatus,
  sessionTypeBookableStatusLabelMap,
  sessionTypeTypesLabelMap,
  sessionTypeUnitsLabelMap,
} from '../../../utils/constants';
import { valueIsEmpty } from '../../../utils/data';
import { safeParseFloat, safeParseInt } from '../../../utils/numbers';
import Input from '../../Form/Input';
import SwitchToggle from '../../Micro/SwitchToggle/SwitchToggle';
import {
  ExpandedBookableStatusContainer,
  InputToggleContainer,
  InputToggleSection,
} from './styled';

const EditServiceModal = ({
  isOpen,
  onClose,
  onSave,
  sessionType,
  categories,
  consultations,
}) => {
  const categoryKeys = Object.keys(categories);
  let categoryOptionsLabelMap = [];
  categoryKeys?.map((key) => {
    const category = categories[key];
    const { id, name } = category;

    if (id) {
      categoryOptionsLabelMap.push({
        key: id,
        label: name,
      });
    }
  });
  categoryOptionsLabelMap = sortBy(categoryOptionsLabelMap, (c) => c.label);

  const consultationOptions = consultations.map((c) => {
    return { key: c.id, label: c.displayName };
  });

  const [name, setName] = useState(sessionType?.displayName || '');
  const [description, setDescription] = useState(
    sessionType?.description || '',
  );
  const [categoryId, setCategoryId] = useState(sessionType?.categoryId || '');
  const [price, setPrice] = useState(sessionType?.price || '');
  const [priceSetting, setPriceSetting] = useState(
    sessionType?.priceSetting || fixedPriceSettingKey,
  );
  const [units, setUnits] = useState(sessionType?.units || '');
  const [durationMinutes, setDurationMinutes] = useState(
    sessionType?.durationMinutes || '',
  );
  const [displayDurationMinutes, setDisplayDurationMinutes] = useState(
    sessionType?.displayDurationMinutes || '',
  );
  const [bookingStatus, setBookingStatus] = useState(
    sessionType?.bookableStatus || '',
  );
  const [
    requiredConsultationSessionTypeId,
    setRequiredConsultationSessionTypeId,
  ] = useState(sessionType?.requiredConsultationSessionTypeId || '');
  const [type, setType] = useState(sessionType?.type || '');
  const [numberOfPeopleToBook, setNumberOfPeopleToBook] = useState(
    safeParseInt(sessionType?.numberOfPeopleToBook) || '',
  );
  const [followUpDays, setFollowUpDays] = useState(
    safeParseInt(sessionType?.followUpDays) || '',
  );
  const [clientDepositAmount, setClientDepositAmount] = useState(
    safeParseFloat(sessionType?.clientDepositAmount),
  );
  const [leadDepositAmount, setLeadDepositAmount] = useState(
    safeParseFloat(sessionType?.leadDepositAmount),
  );

  useEffect(() => {
    if (sessionType && isOpen) {
      setName(sessionType.displayName);
      setDescription(sessionType.description);
      setCategoryId(sessionType.categoryId);
      setPrice(sessionType.price);
      setPriceSetting(sessionType.priceSetting || fixedPriceSettingKey);
      setUnits(sessionType.units);
      setDurationMinutes(sessionType.durationMinutes);
      setDisplayDurationMinutes(sessionType.displayDurationMinutes);
      setBookingStatus(sessionType.bookableStatus);
      setRequiredConsultationSessionTypeId(
        sessionType.requiredConsultationSessionTypeId,
      );
      setType(sessionType.type);
      setNumberOfPeopleToBook(safeParseInt(sessionType?.numberOfPeopleToBook));
      setFollowUpDays(safeParseInt(sessionType?.followUpDays));
      setClientDepositAmount(safeParseFloat(sessionType?.clientDepositAmount));
      setLeadDepositAmount(safeParseFloat(sessionType?.leadDepositAmount));
    }
  }, [sessionType, isOpen]);

  const handleClose = () => {
    setName('');
    setDescription('');
    setCategoryId('');
    setPrice('');
    setPriceSetting('');
    setUnits('');
    setDurationMinutes('');
    setDisplayDurationMinutes('');
    setBookingStatus('');
    setRequiredConsultationSessionTypeId('');
    setType('');
    setNumberOfPeopleToBook('');
    setFollowUpDays('');
    setClientDepositAmount('');
    setLeadDepositAmount('');
    onClose();
  };

  const onClickSave = () => {
    const updatedObject = {
      ...sessionType,
      id: sessionType.id,
      locationId: sessionType?.locationId,
      displayName: name,
      description,
      categoryId,
      price:
        safeParseInt(price) != null
          ? safeParseInt(price)
          : safeParseInt(sessionType?.price),
      priceSetting,
      units,
      durationMinutes: parseInt(durationMinutes),
      displayDurationMinutes: parseInt(displayDurationMinutes),
      type,
      bookableStatus: bookingStatus,
      requiredConsultationSessionTypeId,
      numberOfPeopleToBook:
        safeParseInt(numberOfPeopleToBook) != null
          ? safeParseInt(numberOfPeopleToBook)
          : safeParseInt(sessionType?.numberOfPeopleToBook),
      followUpDays: followUpDays != null ? safeParseInt(followUpDays) : null,
      clientDepositAmount,
      leadDepositAmount,
    };
    onSave(updatedObject);
  };

  const usingDefaultDepositSettings =
    clientDepositAmount == null && leadDepositAmount == null;

  const saveEnabled =
    !!name &&
    !valueIsEmpty(type) &&
    !valueIsEmpty(bookingStatus) &&
    (bookingStatus !== requiresConsultationBookableSessionTypeStatus ||
      !!requiredConsultationSessionTypeId) &&
    (usingDefaultDepositSettings ||
      (!usingDefaultDepositSettings &&
        clientDepositAmount != '' &&
        leadDepositAmount != ''));

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <ModalContainer useSmallGap={true}>
        <MLText>Edit Service</MLText>
        <ModalInputsContainer>
          <ModalInputsContainerScrollWrapper>
            <Input
              id={'name'}
              value={name}
              label={'Name'}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              id={'description'}
              value={description}
              label={'Description'}
              onChange={(e) => setDescription(e.target.value)}
              multiline
            />
            {categoryOptionsLabelMap.length ? (
              <Input
                id={'category'}
                value={categoryId}
                label={'Category (optional)'}
                onChange={(e) => setCategoryId(e.target.value)}
                options={categoryOptionsLabelMap}
                type={dropdownInputType}
              />
            ) : (
              <></>
            )}
            <Input
              id={'price'}
              value={price}
              label={'Base price'}
              onChange={(e) => setPrice(e.target.value)}
              type={dollarInputType}
            />
            <Input
              id={'price-setting'}
              value={priceSetting}
              label={'Price type'}
              onChange={(e) => setPriceSetting(e.target.value)}
              type={dropdownInputType}
              options={priceSettingsLabelMap}
              useSmallGap
            />
            <Input
              id={'units'}
              value={units}
              label={'Units'}
              onChange={(e) => setUnits(e.target.value)}
              type={dropdownInputType}
              options={sessionTypeUnitsLabelMap}
            />
            <Input
              id={'display-duration'}
              value={displayDurationMinutes}
              label={'Minutes to schedule for the client'}
              onChange={(e) => setDisplayDurationMinutes(e.target.value)}
              type={integerInputType}
            />
            <Input
              id={'duration'}
              value={durationMinutes}
              label={'Minutes to schedule for the staff (includes prep time)'}
              onChange={(e) => setDurationMinutes(e.target.value)}
              type={integerInputType}
            />
            <Input
              id={'numpeople'}
              value={numberOfPeopleToBook}
              label={'Number of people per session'}
              onChange={(e) => setNumberOfPeopleToBook(e.target.value)}
              type={integerInputType}
            />
            <Input
              id={'type'}
              value={type}
              label={'Service type'}
              onChange={(e) => setType(e.target.value)}
              options={sessionTypeTypesLabelMap}
              type={dropdownInputType}
            />
            <ExpandedBookableStatusContainer>
              <Form autoHorizontalMargins>
                <DropdownLabel>Booking status</DropdownLabel>
                <Dropdown
                  value={bookingStatus}
                  label={'Booking status'}
                  options={sessionTypeBookableStatusLabelMap}
                  onChange={(e) => {
                    const updatedStatus = e.target.value;
                    setBookingStatus(updatedStatus);

                    if (
                      updatedStatus !==
                      requiresConsultationBookableSessionTypeStatus
                    ) {
                      setRequiredConsultationSessionTypeId();
                    }
                  }}
                >
                  {sessionTypeBookableStatusLabelMap.map(
                    (bookableStatusObj) => {
                      const { key, label } = bookableStatusObj;
                      return (
                        <MenuItem
                          value={key}
                          key={key}
                        >
                          {label}
                        </MenuItem>
                      );
                    },
                  )}
                </Dropdown>
              </Form>
            </ExpandedBookableStatusContainer>
            {bookingStatus ===
              requiresConsultationBookableSessionTypeStatus && (
              <Input
                id={'consult'}
                value={requiredConsultationSessionTypeId}
                label={'Required consultation'}
                onChange={(e) =>
                  setRequiredConsultationSessionTypeId(e.target.value)
                }
                options={consultationOptions}
                type={dropdownInputType}
              />
            )}
            {type !== addOnKey && (
              <>
                <InputToggleSection>
                  <InputToggleContainer>
                    <MediumDarkEssText>
                      Use default follow-up days
                    </MediumDarkEssText>
                    <SwitchToggle
                      value={followUpDays == null}
                      onChange={() => {
                        if (followUpDays == null) {
                          setFollowUpDays(1);
                        } else {
                          setFollowUpDays();
                        }
                      }}
                    />
                  </InputToggleContainer>
                </InputToggleSection>
                {followUpDays != null && (
                  <Input
                    id={'followupdays'}
                    value={followUpDays}
                    label={'Follow-up days'}
                    onChange={(e) => setFollowUpDays(e.target.value)}
                    type={integerInputType}
                    useExtraSmallGap
                  />
                )}
                <InputToggleSection>
                  <InputToggleContainer>
                    <MediumDarkEssText>
                      Use default deposit settings
                    </MediumDarkEssText>
                    <SwitchToggle
                      value={usingDefaultDepositSettings}
                      onChange={() => {
                        if (usingDefaultDepositSettings) {
                          setClientDepositAmount(50);
                          setLeadDepositAmount(50);
                        } else {
                          setClientDepositAmount();
                          setLeadDepositAmount();
                        }
                      }}
                    />
                  </InputToggleContainer>
                </InputToggleSection>
                {!usingDefaultDepositSettings && (
                  <>
                    <Input
                      id={'client-deposit'}
                      value={clientDepositAmount}
                      label={'Client deposit amount'}
                      onChange={(e) => setClientDepositAmount(e.target.value)}
                      type={integerInputType}
                      useSmallGap
                    />
                    <Input
                      id={'lead-deposit'}
                      value={leadDepositAmount}
                      label={'Lead deposit amount'}
                      onChange={(e) => setLeadDepositAmount(e.target.value)}
                      type={integerInputType}
                      useSmallGap
                    />
                  </>
                )}
              </>
            )}
          </ModalInputsContainerScrollWrapper>
        </ModalInputsContainer>
        <ModalActions addTopMargin={true}>
          <MediumPrimaryButton
            onClick={onClickSave}
            disabled={!saveEnabled}
          >
            Save
          </MediumPrimaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default EditServiceModal;
