import React, { useState } from 'react';
import {
  MenuDownArrow,
  MenuUpArrow,
} from '../../../styles/shared-styled-components';
import { getFormattedDateString } from '../../../utils/date';
import RangePopperMenuCalendar from '../../Schedule/Calendar/RangePopperMenuCalendar';
import { FilterContainer, FilterText } from '../shared-analytics-styles';

const DateFilterDropdown = ({
  start,
  end,
  onChangeStart,
  onChangeEnd,
  onReset,
  hardcoded,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState();

  const menuOpenBoolean = Boolean(menuAnchorEl);

  const startLabel = start ? getFormattedDateString(start, false) : 'Start';
  const endLabel = end ? getFormattedDateString(end, false) : 'Present';

  return (
    <>
      <FilterContainer
        clickable={true}
        onClick={(e) => {
          if (!hardcoded) {
            setMenuAnchorEl(e.currentTarget);
          }
        }}
      >
        <FilterText>
          {!hardcoded && (
            <>{menuOpenBoolean ? <MenuUpArrow /> : <MenuDownArrow />}</>
          )}
          {hardcoded
            ? 'Past Month'
            : !start && !end
            ? 'All-Time'
            : `${startLabel} - ${endLabel}`}
        </FilterText>
      </FilterContainer>
      <RangePopperMenuCalendar
        start={start}
        end={end}
        onChangeStart={onChangeStart}
        onChangeEnd={onChangeEnd}
        onReset={onReset}
        open={menuOpenBoolean}
        anchorElement={menuAnchorEl}
        onClose={() => setMenuAnchorEl()}
      />
    </>
  );
};

export default DateFilterDropdown;
