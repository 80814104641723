import { useApolloClient, useMutation } from '@apollo/client';
import { sortBy } from 'lodash';
import { matchIsValidTel } from 'mui-tel-input';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCalendarForDate } from '../../../api/hooks/calendar';
import { useChat } from '../../../api/hooks/chat';
import { useMyLocationUsers } from '../../../api/hooks/users';
import {
  CREATE_DEMO_CHAT,
  RESET_CHAT,
  SEND_MESSAGE,
} from '../../../api/mutations/chat';
import { getTypesenseClient } from '../../../api/typesense/typesense-client';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import Input from '../../../components/Form/Input';
import LoadingIndicator from '../../../components/LoadingIndicator';
import MessageQueryManager from '../../../components/Messages/MessageQueryManager';
import Header from '../../../components/NavBar/Header';
import PopperMenu from '../../../components/PopperMenu';
import PopperMenuCalendar from '../../../components/Schedule/Calendar/PopperMenuCalendar';
import StaffSchedule from '../../../components/Schedule/StaffSchedule/StaffSchedule';
import ClientSearch from '../../../components/Search/ClientSearch/ClientSearch';
import Snackbar from '../../../components/Snackbar';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  BeginChatButtonContainer,
  CalendarIcon,
  CardPageSection,
  CenteredDiv,
  CenteredDivWithExtraSmallGap,
  ChatActionButton,
  ChatActionsContainer,
  ChatBox,
  ColumnCenteredDiv,
  ContentContainer,
  CustomWidthDiv,
  EmptyGapColumnCenteredDiv,
  EssText,
  ExtraSmallPrimaryButton,
  FullSizeCenteredDiv,
  FullWidthCenteredDiv,
  InputPhoneNumberContainer,
  LightDarkEssText,
  LightDarkLargeTinyText,
  MediumDarkSmallText,
  NextPageButton,
  PageContainer,
  PageTitleText,
  PreviousPageButton,
  PrimaryTextSpan,
  SmallCopyIcon,
  SmallLightDarkTextSpan,
  Tab,
  Tabs,
  TextInput,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import { addChatToCache, removeChatFromCache } from '../../../utils/cache';
import {
  campaignForChatDemoCookieKey,
  chatDemoContextKey,
  chatDemoCookieKey,
  chatDemoTabCookieKey,
  chatLogsCookieKey,
  clientIdForChatDemoCookieKey,
  demoNameCookieKey,
  disengagedChatStatusKey,
  leadConnectorMessagingPlatformKey,
  phoneInputType,
  smsDemoChatMediumKey,
  textSeparatorChar,
  typesenseContactSchemaName,
  webDemoChatMediumKey,
} from '../../../utils/constants';
import {
  dateToTextFormat,
  getCookieExpiryObject,
  getDateFromUTCDateTime,
} from '../../../utils/date';
import {
  getFormattedFullNameFromUser,
  getWordWithCapitalizedFirstLetter,
} from '../../../utils/name';
import { copyTextToClipboard } from '../../../utils/string';
import {
  getUserLiveIQAgents,
  getUserLocation,
  getUserTypesenseApiKey,
} from '../../../utils/user';
import {
  CalendarHeaderContainer,
  CalendarSection,
  ChatDemoText,
  ClientSection,
  CreateChatButtonContainer,
  DateActionsContainer,
  DateText,
  RefreshCalendarIcon,
  RefreshContainer,
  ResetChatButtonContainer,
  ResetIcon,
} from './styled';

const cookieExpiryObject = getCookieExpiryObject();

const phoneErrorText = 'Please enter a valid phone number';

const clientSelectionTab = 0;
export const chatTab = 1;
const calendarTab = 2;

const ChatDemo = () => {
  const { cache } = useApolloClient();

  const {
    inDemoMode,
    user,
    drawerOpen,
    drawerExpanded,
    cookies,
    setCookie,
    removeCookie,
  } = useContext(BaseContext);

  const { users: locationUsers } = useMyLocationUsers({});
  const userMap = {};
  locationUsers?.map((u) => {
    userMap[u.id] = getFormattedFullNameFromUser(u);
  });

  const location = getUserLocation(user);
  const timezone = location?.timezone;

  const agent = getUserLiveIQAgents(user)[0];
  const role = user?.role;
  const locationId = location?.id;

  const typesenseApiKey = getUserTypesenseApiKey(user);
  const typesenseClient = getTypesenseClient(typesenseApiKey);

  const contentContainerRef = useRef();
  const inputRef = useRef(null);

  // General
  const tabCookie = cookies[chatDemoTabCookieKey];
  const [tab, setTab] = useState(tabCookie != null ? parseInt(tabCookie) : 0);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Chat
  const clientIdForChatCookie = cookies[clientIdForChatDemoCookieKey];
  const campaignForChatDemoCookie = cookies[campaignForChatDemoCookieKey];
  const demoNameCookie = cookies[demoNameCookieKey];
  const [clientIdForChatDemo, setClientIdForChatDemo] = useState(
    clientIdForChatCookie,
  );
  const [campaignForChatDemo, setCampaignForChatDemo] = useState(
    campaignForChatDemoCookie,
  );
  const [demoName, setDemoName] = useState(demoNameCookie);
  const [clientForChatDemo, setClientForChatDemo] = useState();
  const [hasFetchedClient, setHasFetchedClient] = useState(false);
  const [chatPhoneNumberMenuAnchorEl, setChatPhoneNumberMenuAnchorEl] =
    useState();
  const [chatPhoneNumber, setChatPhoneNumber] = useState();
  const [phoneErrorMessage, setPhoneErrorMessage] = useState();
  const [newMessage, setNewMessage] = useState('');

  const chatPhoneNumberMenuOpenBoolean = Boolean(chatPhoneNumberMenuAnchorEl);

  // Calendar
  const [dateToViewCalendar, setDateToViewCalendar] = useState(new Date());
  const [changeDateMenuAnchorEl, setChangeDateMenuAnchorEl] = useState();
  const [staffSchedule, setStaffSchedule] = useState([]);
  const [refetchingCalendar, setRefetchingCalendar] = useState(false);

  const changeDateMenuOpenBoolean = Boolean(changeDateMenuAnchorEl);

  // Chat
  const chatId = cookies[chatDemoCookieKey];
  const {
    chat,
    loading: chatLoading,
    refetch: refetchChat,
  } = useChat({ chatId });
  const [createDemoChatMutation, { loading: createDemoChatMutationLoading }] =
    useMutation(CREATE_DEMO_CHAT);
  const [sendMessageMutation, { loading: sendMessageMutationLoading }] =
    useMutation(SEND_MESSAGE);
  const [resetChatMutation, { loading: resetChatMutationLoading }] =
    useMutation(RESET_CHAT);

  // Calendar
  const {
    data: calendarData,
    loading: getCalendarForDateLoading,
    refetch: refetchCalendar,
  } = useCalendarForDate({
    date: getDateFromUTCDateTime(dateToViewCalendar, timezone),
    skipCondition: tab !== 2,
  });
  const dateFromReturnedCalendar = calendarData?.date;

  useEffect(() => {
    if (tabCookie != null) {
      setTab(parseInt(tabCookie));
    }
  }, [tabCookie]);

  useEffect(() => {
    if (clientIdForChatCookie) {
      setClientIdForChatDemo(clientIdForChatCookie);
    }
  }, [clientIdForChatCookie]);

  useEffect(() => {
    if (campaignForChatDemoCookie) {
      setCampaignForChatDemo(campaignForChatDemoCookie);
    }
  }, [campaignForChatDemoCookie]);

  useEffect(() => {
    if (demoNameCookie) {
      setDemoName(demoNameCookie);
    }
  }, [demoNameCookie]);

  useEffect(() => {
    async function fetchClient() {
      let filterString = `id:=[${clientIdForChatDemo}]`;

      let searchParameters = {
        q: '*',
        per_page: 1,
        page: 1,
        filter_by: filterString,
      };

      const data = await typesenseClient
        .collections(typesenseContactSchemaName)
        .documents()
        .search(searchParameters);
      const documents = data.hits.map((hit) => hit.document);

      if (documents?.length === 1) {
        const client = {
          ...documents[0],
          campaign: {
            id: campaignForChatDemo?.id,
            name: campaignForChatDemo?.name,
          },
        };
        setClientForChatDemo(client);
      }
      setHasFetchedClient(true);
    }

    if (clientIdForChatDemo) {
      fetchClient();
    }
  }, [clientIdForChatDemo]);

  useEffect(() => {
    if (
      dateToViewCalendar &&
      (!dateFromReturnedCalendar ||
        dateFromReturnedCalendar !== dateToViewCalendar.toISOString())
    ) {
      refetchCalendar();
    }
  }, [dateToViewCalendar, dateFromReturnedCalendar]);

  useEffect(() => {
    if (refetchingCalendar) {
      refetchCalendar();
    }
  }, [refetchingCalendar]);

  useEffect(() => {
    if (calendarData) {
      const staffData = calendarData.staff;
      const staff = sortBy(staffData, (s) => s.firstName);
      setStaffSchedule(staff);

      if (refetchingCalendar) {
        setRefetchingCalendar(false);
        setSnackbarMessage('Refetched calendar');
      }
    } else if (refetchingCalendar) {
      setSnackbarMessage('Refetched calendar');
    }
  }, [calendarData, refetchingCalendar]);

  // Chat
  const onCreateChat = ({ chatMedium }) => {
    createDemoChatMutation({
      variables: {
        contact: clientForChatDemo,
        agent,
        campaignId: clientForChatDemo?.campaign?.id,
        medium: chatMedium,
        phoneNumber: chatPhoneNumber,
        demoName,
      },
      onCompleted: async (data) => {
        const createdChat = data.createDemoChat;

        setCookie(chatDemoCookieKey, createdChat.id, cookieExpiryObject);

        if (createdChat) {
          await addChatToCache(createdChat, cache);
        }
      },
    });
  };

  const beginSmsChat = () => {
    const isValidPhone = matchIsValidTel(chatPhoneNumber || '');

    if (chatPhoneNumber && !isValidPhone) {
      setPhoneErrorMessage(phoneErrorText);
      return;
    }

    setChatPhoneNumberMenuAnchorEl();
    onCreateChat({ chatMedium: smsDemoChatMediumKey });
  };

  const onEnterMessage = () => {
    const messageToSend = newMessage;

    setNewMessage('');

    sendMessageMutation({
      variables: {
        chatId,
        messageId: uuidv4(),
        message: messageToSend,
        isHumanAgent: false,
      },
    });
  };

  const onResetChat = () => {
    setNewMessage('');
    if (chatId) {
      resetChatMutation({
        variables: {
          chatId,
        },
        onCompleted: async (data) => {
          const success = data.resetChat;
          if (success) {
            await removeChatFromCache(chatId, cache);

            removeCookie(chatDemoCookieKey);

            const chatLogsCookie = cookies[chatLogsCookieKey];
            if (chatLogsCookie === chatId) {
              removeCookie(chatLogsCookieKey);
            }

            refetchChat();
          }
        },
      });
    } else {
      window.location.reload();
    }
  };

  const onChangeTab = (tabValue) => {
    setCookie(chatDemoTabCookieKey, parseInt(tabValue), {});
  };

  const onSelectClientForChatDemo = (clientId, campaign) => {
    const campaignId = campaign?.id;
    const cookieCampaignId = campaignForChatDemoCookie?.id;

    if (clientIdForChatDemo !== clientId || cookieCampaignId !== campaignId) {
      setCookie(campaignForChatDemoCookieKey, campaign, cookieExpiryObject);
      setCookie(chatDemoTabCookieKey, 1, cookieExpiryObject);
      setCookie(chatDemoCookieKey, null, cookieExpiryObject);
      setCookie(clientIdForChatDemoCookieKey, clientId, cookieExpiryObject);

      if (demoName != null) {
        setCookie(demoNameCookieKey, demoName, cookieExpiryObject);
      }
    }

    setCookie(chatDemoTabCookieKey, 1, cookieExpiryObject);
  };

  const onResetClientForChatDemo = () => {
    removeCookie(clientIdForChatDemoCookieKey);
    setClientIdForChatDemo();
  };

  const isLoading =
    createDemoChatMutationLoading || chatLoading || resetChatMutationLoading;

  const selectedClientForChatDemo =
    clientIdForChatDemo && clientForChatDemo
      ? {
          [clientIdForChatDemo]: clientForChatDemo,
        }
      : null;

  const chatDisengaged = chat?.status === disengagedChatStatusKey;
  const chatInProgress = chat && chat?.id === chatId;
  const chatMedium = chat?.medium;

  return (
    <>
      <MetaSetter
        title={`Agent Chat Demo`}
        description={`Demo Your LiveIQ Agent`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          ref={contentContainerRef}
        >
          <ColumnCenteredDiv>
            <PageTitleText>Agent Chat Demo</PageTitleText>
          </ColumnCenteredDiv>
          <CardPageSection maxWidthUnset>
            <ColumnCenteredDiv>
              <Tabs
                value={tab}
                onChange={(_, key) => onChangeTab(key)}
              >
                <Tab
                  value={clientSelectionTab}
                  label='Contacts'
                />
                <Tab
                  value={chatTab}
                  label='Chat'
                />
                <Tab
                  value={calendarTab}
                  label='Calendar'
                />
              </Tabs>
            </ColumnCenteredDiv>
            {tab === clientSelectionTab ? (
              <>
                <ClientSection>
                  <EmptyGapColumnCenteredDiv>
                    <MediumDarkSmallText>
                      Select a contact to begin a demo chat
                    </MediumDarkSmallText>
                    <LightDarkLargeTinyText>
                      This will not contact them, it's just showing you how your
                      agent will interact with them
                    </LightDarkLargeTinyText>
                  </EmptyGapColumnCenteredDiv>
                  <FullWidthCenteredDiv>
                    <ClientSearch
                      locationId={locationId}
                      context={chatDemoContextKey}
                      demoName={demoName}
                      onUpdateDemoName={(updatedDemoName) => {
                        setDemoName(updatedDemoName);
                      }}
                      selectedClientForChatDemo={selectedClientForChatDemo}
                      onSelectClientForChatDemo={onSelectClientForChatDemo}
                      onResetClient={onResetClientForChatDemo}
                    />
                  </FullWidthCenteredDiv>
                </ClientSection>
              </>
            ) : tab === chatTab ? (
              <ChatBox
                remove={!chatInProgress && !isLoading}
                large
              >
                {isLoading ? (
                  <FullSizeCenteredDiv>
                    <LoadingIndicator fullScreen />
                  </FullSizeCenteredDiv>
                ) : !chatInProgress ? (
                  <CreateChatButtonContainer>
                    {!hasFetchedClient ? (
                      <ChatDemoText>
                        Select a contact to begin a demo chat
                      </ChatDemoText>
                    ) : !clientForChatDemo ? (
                      <div style={{ width: '80%' }}>
                        <ChatDemoText>
                          Received error when fetching contact for demo, please
                          unselect, refresh, and try again
                        </ChatDemoText>
                      </div>
                    ) : (
                      <CustomWidthDiv>
                        <ChatDemoText>
                          {agent?.name} is ready to begin a demo chat with{' '}
                          <PrimaryTextSpan>
                            {getFormattedFullNameFromUser(
                              clientForChatDemo,
                              inDemoMode,
                            )}
                          </PrimaryTextSpan>
                          , as part of the{' '}
                          <PrimaryTextSpan>
                            '{clientForChatDemo?.campaign?.name}'
                          </PrimaryTextSpan>{' '}
                          campaign{demoName && ` (for the '${demoName}' demo)`}
                          <br></br>
                          <br></br>
                          Here's the context we'll give {agent?.name}:<br></br>
                          <br></br>
                          <SmallLightDarkTextSpan>
                            {textSeparatorChar}{' '}
                            {clientForChatDemo.lastVisit
                              ? `${
                                  clientForChatDemo.firstName
                                    ? getWordWithCapitalizedFirstLetter(
                                        clientForChatDemo.firstName,
                                      )
                                    : 'The client'
                                }'s last visit was on ${dateToTextFormat(
                                  clientForChatDemo.lastVisit,
                                )} when they received the ${
                                  clientForChatDemo.lastVisitSessionTypeName
                                    ? `'${clientForChatDemo.lastVisitSessionTypeName}'`
                                    : '(service name unknown)'
                                } service${
                                  clientForChatDemo.lastVisitStaffName
                                    ? `, with ${
                                        inDemoMode
                                          ? 'Ashley'
                                          : clientForChatDemo.lastVisitStaffName
                                      }`
                                    : ' (staff member unknown)'
                                }`
                              : "They've never had an appointment with your business"}
                            <br></br>
                            <br></br>
                            <SmallLightDarkTextSpan>
                              {textSeparatorChar} {agent?.name}'s goal is to get
                              the client to book{' '}
                              {clientForChatDemo.lastVisit
                                ? 'another'
                                : 'their first'}{' '}
                              appointment with your business
                            </SmallLightDarkTextSpan>
                            {!clientForChatDemo.lastVisit && (
                              <>
                                <br></br>
                                <br></br>
                                <SmallLightDarkTextSpan>
                                  {textSeparatorChar} {agent?.name} should
                                  figure out what service they're interested in,
                                  then find a time in the schedule to book them
                                  in
                                </SmallLightDarkTextSpan>
                              </>
                            )}
                          </SmallLightDarkTextSpan>
                        </ChatDemoText>
                      </CustomWidthDiv>
                    )}
                    <ColumnCenteredDiv></ColumnCenteredDiv>
                  </CreateChatButtonContainer>
                ) : (
                  <MessageQueryManager
                    chat={chat}
                    context='demo'
                    userMap={userMap}
                  />
                )}
                {!isLoading && (
                  <ChatActionsContainer isDemo>
                    {chatInProgress || chatDisengaged ? (
                      <>
                        {chatMedium === webDemoChatMediumKey ? (
                          <>
                            <TextInput
                              ref={inputRef}
                              value={newMessage}
                              onChange={(e) => {
                                if (chatInProgress || chatDisengaged) {
                                  setNewMessage(e.target.value);
                                }
                              }}
                              onKeyPress={(event) => {
                                if (
                                  event.key === 'Enter' &&
                                  (chatInProgress || chatDisengaged)
                                ) {
                                  onEnterMessage();
                                }
                              }}
                              removeGap
                              useSmallWidth
                              style={{ width: '80%' }}
                              removeAutoMargins={true}
                            />{' '}
                            <CenteredDivWithExtraSmallGap>
                              <ChatActionButton
                                onClick={onEnterMessage}
                                disabled={!newMessage}
                              >
                                {' '}
                                Send
                              </ChatActionButton>
                              <Tooltip
                                title={
                                  <TooltipTitleText>
                                    Copy chat ID
                                  </TooltipTitleText>
                                }
                                onClick={() => copyTextToClipboard(chatId)}
                              >
                                <SmallCopyIcon />
                              </Tooltip>
                            </CenteredDivWithExtraSmallGap>
                          </>
                        ) : (
                          <LightDarkEssText>
                            To send messages as{' '}
                            {getWordWithCapitalizedFirstLetter(
                              clientForChatDemo?.firstName,
                            )}{' '}
                            for this demo, text from your phone
                          </LightDarkEssText>
                        )}
                      </>
                    ) : (
                      <>
                        <BeginChatButtonContainer>
                          <ChatActionButton
                            onClick={() =>
                              onCreateChat({
                                chatMedium: webDemoChatMediumKey,
                              })
                            }
                            disabled={!clientForChatDemo}
                          >
                            Begin Web Chat
                          </ChatActionButton>
                          {location?.messagingPlatform !==
                            leadConnectorMessagingPlatformKey && (
                            <ChatActionButton
                              onClick={(e) =>
                                setChatPhoneNumberMenuAnchorEl(e.currentTarget)
                              }
                              disabled={!clientForChatDemo}
                            >
                              Begin SMS Chat
                            </ChatActionButton>
                          )}
                        </BeginChatButtonContainer>
                        <PopperMenu
                          open={chatPhoneNumberMenuOpenBoolean}
                          anchorElement={chatPhoneNumberMenuAnchorEl}
                          onClose={() => {
                            setChatPhoneNumberMenuAnchorEl();
                            setChatPhoneNumber();
                          }}
                          variant='offset'
                          modifiers={{
                            name: 'offset',
                            options: {
                              offset: [-100, -230],
                            },
                          }}
                        >
                          <InputPhoneNumberContainer>
                            <EssText>
                              Input the phone number to use for the demo chat{' '}
                            </EssText>
                            <Input
                              ref={inputRef}
                              value={chatPhoneNumber}
                              onChange={(phoneNumber) => {
                                setChatPhoneNumber(phoneNumber);
                                if (phoneErrorMessage) {
                                  setPhoneErrorMessage();
                                }
                              }}
                              removeGap
                              useSmallWidth
                              removeAutoMargins={true}
                              style={{ width: '80%' }}
                              type={phoneInputType}
                              containsError={phoneErrorMessage}
                              errorMessage={phoneErrorMessage}
                            />
                            <ExtraSmallPrimaryButton
                              largePadding
                              disabled={!chatPhoneNumber}
                              onClick={beginSmsChat}
                            >
                              Begin SMS chat
                            </ExtraSmallPrimaryButton>
                          </InputPhoneNumberContainer>
                        </PopperMenu>
                      </>
                    )}
                  </ChatActionsContainer>
                )}
                {(chatInProgress || chatDisengaged || chatLoading) && (
                  <Tooltip
                    title={<TooltipTitleText>Reset chat</TooltipTitleText>}
                    placement='right'
                  >
                    <ResetChatButtonContainer onClick={onResetChat}>
                      <ResetIcon />
                    </ResetChatButtonContainer>
                  </Tooltip>
                )}
              </ChatBox>
            ) : tab === calendarTab ? (
              <CalendarSection>
                <CalendarHeaderContainer>
                  <DateText>
                    Schedule for{' '}
                    {getDateFromUTCDateTime(dateToViewCalendar, timezone)}{' '}
                    {getCalendarForDateLoading ? (
                      <DateActionsContainer>
                        <LoadingIndicator size={22} />
                      </DateActionsContainer>
                    ) : (
                      <DateActionsContainer>
                        <CalendarIcon
                          selected={changeDateMenuOpenBoolean}
                          onClick={(e) =>
                            setChangeDateMenuAnchorEl(e.currentTarget)
                          }
                        />
                        <CenteredDiv>
                          <PreviousPageButton
                            onClick={() => {
                              const currentDate = new Date(dateToViewCalendar);
                              currentDate.setDate(currentDate.getDate() - 1);
                              setDateToViewCalendar(currentDate);
                            }}
                          />
                          <NextPageButton
                            onClick={() => {
                              const currentDate = new Date(dateToViewCalendar);
                              currentDate.setDate(currentDate.getDate() + 1);
                              setDateToViewCalendar(currentDate);
                            }}
                          />
                        </CenteredDiv>
                      </DateActionsContainer>
                    )}
                  </DateText>
                  <RefreshContainer>
                    <Tooltip
                      title={
                        <TooltipTitleText>Refresh calendar</TooltipTitleText>
                      }
                      placement='bottom'
                    >
                      <RefreshCalendarIcon
                        onClick={() => {
                          setRefetchingCalendar(true);
                        }}
                      />
                    </Tooltip>
                  </RefreshContainer>
                </CalendarHeaderContainer>
                <PopperMenuCalendar
                  date={dateToViewCalendar}
                  open={changeDateMenuOpenBoolean}
                  anchorElement={changeDateMenuAnchorEl}
                  onClose={() => setChangeDateMenuAnchorEl()}
                  onChange={(d) => {
                    setDateToViewCalendar(d);
                    setChangeDateMenuAnchorEl();
                  }}
                />
                <StaffSchedule
                  date={dateToViewCalendar}
                  staffSchedule={staffSchedule}
                  includeLabel={false}
                  removeTopPadding={true}
                  fixedHeight={540}
                />
              </CalendarSection>
            ) : (
              <></>
            )}
          </CardPageSection>
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default ChatDemo;
