import {
  buyGoalKey,
  connectedStatusKey,
  ghlKey,
  growGoalKey,
  inProgressStatusKey,
  initializedStatusKey,
  mindBodyKey,
  multiLocationBusinessOwnerRole,
  sellGoalKey,
  singleLocationBusinessType,
  superAdminRole,
  unconnectedStatusKey,
  valueGoalKey,
  zenotiKey,
} from './constants';

const colorMap = {
  0: '#3498db',
  1: '#27ae60',
  2: '#e74c3c',
  3: '#f39c12',
  4: '#9b59b6',
  5: '#2ecc71',
  6: '#e67e22',
  7: '#3498db',
  8: '#1abc9c',
  9: '#d35400',
  a: '#3498db',
  b: '#27ae60',
  c: '#e74c3c',
  d: '#f39c12',
  e: '#9b59b6',
  f: '#2ecc71',
  g: '#e67e22',
  h: '#3498db',
  i: '#1abc9c',
  j: '#d35400',
  k: '#34495e',
  l: '#16a085',
  m: '#f39c12',
  n: '#8e44ad',
  o: '#2c3e50',
  p: '#e74c3c',
  q: '#d35400',
  r: '#3498db',
  s: '#27ae60',
  t: '#e67e22',
  u: '#2ecc71',
  v: '#1abc9c',
  w: '#3498db',
  x: '#9b59b6',
  y: '#f39c12',
  z: '#34495e',
};

export const checkIfUserIsASeller = (goals) => {
  goals?.includes(valueGoalKey) ||
    goals?.includes(sellGoalKey) ||
    goals?.includes(growGoalKey);
};

export const checkIfUserIsABuyer = (goals) => {
  return goals?.includes(buyGoalKey);
};

export const getUserColorBasedOnId = (userId) => {
  const char = userId.charAt(0).toLowerCase();
  return colorMap[char];
};

export const locationIdSelectedKey = 'location_id_selected';

export const getUserBusiness = (user) => user?.enterprise?.business;
export const getUserLocation = (user) => user?.enterprise?.location;
export const getUserLiveIQAgentId = (user) =>
  user?.enterprise?.location?.agents?.find((a) => a.productKey != null)?.id ||
  null;
export const getUserLiveIQAgentName = (user) =>
  user?.enterprise?.location?.agents?.find((a) => a.productKey != null)?.name ||
  null;
export const getUserLiveIQAgents = (user) =>
  user?.enterprise?.location?.agents?.filter((a) => !!a.productKey) || [];
export const getAllUserAgents = (user) =>
  user?.enterprise?.location?.agents?.filter((a) => !!a.userId) || [];
export const getUserBusinessId = (user) => user?.enterprise?.business?.id;
export const getUserBusinessName = (user) => user?.enterprise?.business?.name;
export const getUserBusinessType = (user) => user?.enterprise?.business?.type;
export const getUserLocationId = (user) => user?.enterprise?.location?.id;
export const getUserLocationName = (user) => user?.enterprise?.location?.name;
export const getUserLocationTimezone = (user) =>
  user?.enterprise?.location?.timezone;
export const getUserTypesenseApiKey = (user) =>
  user?.enterprise?.location?.typesenseApiKey;
export const checkIfUserIsMultiLocationBusinessOwner = (role, business) =>
  role === multiLocationBusinessOwnerRole ||
  (role === superAdminRole &&
    !!business &&
    business?.type !== singleLocationBusinessType);
export const checkIfUserIsSuperAdmin = (user) => user?.role === superAdminRole;

export const getUserConnections = (user) => {
  const location = getUserLocation(user);
  const connectedApps = location?.connectedApps;

  if (!connectedApps?.length) {
    return {
      [mindBodyKey]: unconnectedStatusKey,
      [zenotiKey]: unconnectedStatusKey,
      [ghlKey]: unconnectedStatusKey,
    };
  }

  let mindbodyStatus;
  let zenotiStatus;
  let ghlStatus;

  connectedApps.map((a) => {
    if (a.name === mindBodyKey) {
      mindbodyStatus =
        a.initialized === 'true'
          ? initializedStatusKey
          : a.initialized === inProgressStatusKey
          ? inProgressStatusKey
          : connectedStatusKey;
    } else if (a.name === zenotiKey) {
      zenotiStatus =
        a.initialized === 'true'
          ? initializedStatusKey
          : a.initialized === inProgressStatusKey
          ? inProgressStatusKey
          : connectedStatusKey;
    } else if (a.name === ghlKey) {
      ghlStatus =
        a.initialized === 'true'
          ? initializedStatusKey
          : a.initialized === inProgressStatusKey
          ? inProgressStatusKey
          : connectedStatusKey;
    }
  });

  return {
    [mindBodyKey]: mindbodyStatus,
    [zenotiKey]: zenotiStatus,
    [ghlKey]: ghlStatus,
  };
};

export default {
  checkIfUserIsABuyer,
  checkIfUserIsASeller,
  getUserConnections,
  getUserColorBasedOnId,
  getUserLocationId,
};
