import React, { useContext } from 'react';
import {
  AgentIcon,
  AppointmentBookedChip,
  AppointmentBookedIcon,
  AppointmentBookingMonitoringIcon,
  AppointmentWithNoteIcon,
  BaseChipText,
  DisengagedIcon,
  LightExtraTinyText,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import { getChatAppointmentStatus } from '../../../utils/chat';
import {
  appointmentBookedStatus,
  appointmentDepositRequiredStatus,
  appointmentMonitoringStatus,
  chatDisengagementReasonLabelMap,
  chatInterceptionReasonLabelMap,
  disengagedChatStatusKey,
  emptyArrayValue,
  emptyStringValue,
  fakeNames,
} from '../../../utils/constants';
import { getFormattedFullNameFromUser } from '../../../utils/name';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import {
  ChatCardContainer,
  ClientNameText,
  DemoIcon,
  DemoIconContainer,
  LeftSideContainer,
  RealIcon,
  RealIconContainer,
  RightSideContainer,
} from './styled';

const ChatCard = ({ chat, idx, position, onClick, selected }) => {
  const { inDemoMode } = useContext(BaseContext);

  const totalMessages = chat.numTotalMessages;
  const totalCalls = chat.callIds?.filter(
    (id) => id !== emptyArrayValue,
  ).length;
  const messageText = `${totalMessages} message${
    totalMessages !== 1 ? 's' : ''
  }`;
  const callText = `${totalCalls} call${totalCalls !== 1 ? 's' : ''}`;

  const countText =
    totalMessages && totalCalls
      ? `${messageText}, ${callText}`
      : totalMessages
      ? messageText
      : totalCalls
      ? callText
      : '';

  let clientFirstName = chat.userFirstName;
  let clientLastName = chat.userLastName;
  if (inDemoMode && idx < fakeNames.length) {
    clientFirstName = clientFirstName?.[0];
    clientLastName = clientLastName?.[0];
  }

  // Make the top chat a prod chat
  const isDemoChat = chat.medium.includes('demo');
  const chatStatus = chat.status;

  const { appointmentStatus, count } = getChatAppointmentStatus(
    chat?.appointmentStatuses,
  );
  const interceptionReason = chat?.interceptionReason;
  const disengagementReason = chat?.disengagementReason;

  return (
    <ChatCardContainer
      position={position}
      onClick={onClick}
      selected={selected}
    >
      <LeftSideContainer>
        <ClientNameText
          selected={selected}
          capitalize
        >
          {inDemoMode
            ? `${clientFirstName}.${clientLastName}.`
            : getFormattedFullNameFromUser({
                firstName: clientFirstName,
                lastName: clientLastName,
              })}
        </ClientNameText>
        <LightExtraTinyText>{countText}</LightExtraTinyText>
      </LeftSideContainer>
      <RightSideContainer>
        {appointmentStatus === appointmentBookedStatus ? (
          <Tooltip
            title={
              <TooltipTitleText>
                {count > 1
                  ? `${count} Appointments Booked`
                  : `Appointment Booked`}
              </TooltipTitleText>
            }
            placement='top'
          >
            <div style={{ position: 'relative', height: '100%' }}>
              <AppointmentBookedIcon />
              {count > 1 && (
                <AppointmentBookedChip>
                  <BaseChipText>{count}</BaseChipText>
                </AppointmentBookedChip>
              )}
            </div>
          </Tooltip>
        ) : appointmentStatus === appointmentDepositRequiredStatus ? (
          <Tooltip
            title={
              <TooltipTitleText>Appointment requires deposit</TooltipTitleText>
            }
            placement='top'
          >
            <AppointmentWithNoteIcon selected={selected} />
          </Tooltip>
        ) : appointmentStatus === appointmentMonitoringStatus ? (
          <Tooltip
            title={
              <TooltipTitleText>Monitoring potential booking</TooltipTitleText>
            }
            placement='top'
          >
            <span>
              <AppointmentBookingMonitoringIcon
                selected={selected}
                topMargin={2}
              />
            </span>
          </Tooltip>
        ) : interceptionReason !== emptyStringValue ? (
          <Tooltip
            title={
              <TooltipTitleText>
                Intercepted:{' '}
                {chatInterceptionReasonLabelMap[interceptionReason]}
              </TooltipTitleText>
            }
            placement='top'
          >
            <AgentIcon
              selected={selected}
              topMargin={-1}
            />
          </Tooltip>
        ) : disengagementReason !== emptyStringValue ||
          chatStatus === disengagedChatStatusKey ? (
          <Tooltip
            title={
              <TooltipTitleText>
                Disengaged
                {disengagementReason !== emptyStringValue
                  ? `: ${chatDisengagementReasonLabelMap[disengagementReason]}`
                  : ''}
              </TooltipTitleText>
            }
            placement='top'
          >
            <DisengagedIcon
              selected={selected}
              topMargin={-1}
            />
          </Tooltip>
        ) : (
          <></>
        )}
        {isDemoChat ? (
          <DemoIconContainer>
            <Tooltip
              title={<TooltipTitleText>Demo chat</TooltipTitleText>}
              placement='top'
            >
              <DemoIcon />
            </Tooltip>
          </DemoIconContainer>
        ) : (
          <RealIconContainer>
            <Tooltip
              title={<TooltipTitleText>Real chat</TooltipTitleText>}
              placement='top'
            >
              <RealIcon />
            </Tooltip>
          </RealIconContainer>
        )}
      </RightSideContainer>
    </ChatCardContainer>
  );
};

export default ChatCard;
