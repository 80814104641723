import React, { useContext, useState } from 'react';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import Paths from '../../../Paths';
import {
  DarkGoldText,
  GoldText,
} from '../../../styles/shared-styled-components';
import {
  AgentContainer,
  AgentInfo,
  AgentInfoSubtext,
  AgentsContainer,
  AnalyticsChatBubble,
  AvaMessageBubbleBookedIcon,
  AvaMessageBubbleDepositedIcon,
  BlueMessageBg,
  BookedChatBubble,
  BubbleLeftSide,
  BubbleRightSide,
  DemoButton,
  DepositReceivedChatBubble,
  Feature,
  FeaturesContainer,
  GradientSpan,
  GrayMessageBg,
  HeaderSubText,
  HeaderSubTextContainer,
  HeaderText,
  HeroContainer,
  IncomingMessageBubble,
  LightningIcon,
  OutgoingMessageBubble,
  SetupText,
  TextContainer,
} from '../styles/style-Homepage';

const features = [
  'EMR + Marketing integrations',
  'HIPAA compliant',
  'Books 500+ services',
  'Multi-location support',
  'Uses custom booking flows',
  'Speaks 20+ languages',
];

const Hero = ({ darkMode }) => {
  const { width } = useContext(BaseContext);
  const folds = width < 1025;

  const featuresFoldsToSeparateLines = width < 461;

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const TextComponent = (
    <TextContainer hideTopMargin={folds}>
      <HeaderText>
        <GradientSpan dark={darkMode}>
          The AI Receptionist custom-built to grow your MedSpa{' '}
        </GradientSpan>
        <LightningIcon />
        <br />
      </HeaderText>
      <HeaderSubTextContainer>
        <HeaderSubText dark={darkMode}>
          Handles all calls + texts. Schedules appointments. Increases revenue.
        </HeaderSubText>
      </HeaderSubTextContainer>
      <DemoButton href={Paths.calendlyLink}>See it in action →</DemoButton>
      <SetupText>
        <FeaturesContainer
          dark={darkMode}
          center={folds}
        >
          {features.map((feature) => (
            <Feature
              dark={darkMode}
              separateLine={featuresFoldsToSeparateLines}
            >
              {featuresFoldsToSeparateLines ? (
                <>
                  <span>{feature}</span>
                  <span>✔</span>
                </>
              ) : (
                <>✔&nbsp;&nbsp;{feature}</>
              )}
            </Feature>
          ))}
        </FeaturesContainer>
      </SetupText>
    </TextContainer>
  );

  const AgentComponent = (
    <AgentsContainer
      addTopMargin={folds}
      style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }}
    >
      <AgentContainer dark={darkMode}>
        <img
          src='https://i.ibb.co/Y2SD71N/ava-portrait-2.png'
          alt='Ava'
          onLoad={handleImageLoad}
        />
        <BookedChatBubble>
          <OutgoingMessageBubble>
            <BubbleLeftSide>
              <BlueMessageBg>Can I get Botox Tuesday 10am?</BlueMessageBg>
            </BubbleLeftSide>
            <BubbleRightSide>
              <img
                src='https://i.imgur.com/7hj18U7.png'
                alt='Lucas'
              />
            </BubbleRightSide>
          </OutgoingMessageBubble>
          <IncomingMessageBubble>
            <BubbleLeftSide>
              <img
                src='https://i.ibb.co/Y2SD71N/ava-portrait-2.png'
                alt='Ava'
              />
            </BubbleLeftSide>
            <BubbleRightSide>
              <IncomingMessageBubble>
                <GrayMessageBg>You're booked! See you then.</GrayMessageBg>
              </IncomingMessageBubble>
            </BubbleRightSide>
          </IncomingMessageBubble>
          {!folds && (
            <AvaMessageBubbleBookedIcon>
              Booked in EMR &nbsp;&nbsp;✔
            </AvaMessageBubbleBookedIcon>
          )}
        </BookedChatBubble>
        <DepositReceivedChatBubble>
          {!folds && (
            <AvaMessageBubbleDepositedIcon>
              Deposit Received &nbsp;&nbsp;✔
            </AvaMessageBubbleDepositedIcon>
          )}
          <IncomingMessageBubble>
            <BubbleLeftSide>
              <img
                src='https://i.ibb.co/Y2SD71N/ava-portrait-2.png'
                alt='Ava'
              />
            </BubbleLeftSide>
            <BubbleRightSide>
              <GrayMessageBg>
                Got the deposit, thanks!&nbsp;&nbsp;💳
              </GrayMessageBg>
            </BubbleRightSide>
          </IncomingMessageBubble>
        </DepositReceivedChatBubble>
        <AnalyticsChatBubble>
          <IncomingMessageBubble>
            <BubbleLeftSide>
              <img
                src='https://i.ibb.co/Y2SD71N/ava-portrait-2.png'
                alt='Ava'
              />
            </BubbleLeftSide>
            <BubbleRightSide>
              <GrayMessageBg>Revenue is up 14% this month! 📈</GrayMessageBg>
            </BubbleRightSide>
          </IncomingMessageBubble>
        </AnalyticsChatBubble>
        <AgentInfo dark={darkMode}>
          Meet&nbsp;<GradientSpan dark={darkMode}>Ava</GradientSpan>
        </AgentInfo>
        {!folds && (
          <AgentInfoSubtext dark={darkMode}>
            Your&nbsp;
            {darkMode ? (
              <GoldText>superhero</GoldText>
            ) : (
              <DarkGoldText>superhero</DarkGoldText>
            )}
            &nbsp;receptionist
          </AgentInfoSubtext>
        )}
      </AgentContainer>
    </AgentsContainer>
  );

  return (
    <HeroContainer>
      {folds ? (
        <>
          {AgentComponent}
          <br></br>
          {TextComponent}
        </>
      ) : (
        <>
          {TextComponent}
          {AgentComponent}
        </>
      )}
    </HeroContainer>
  );
};

export default Hero;
