import { useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { useMyFiles } from '../../../api/hooks/content';
import { DELETE_FILE, UPLOAD_FILES } from '../../../api/mutations/content';
import {
  BoxTitleContainer,
  BoxWithPadding,
  FileInput,
  FullSizeColumnCenteredDiv,
  FullWidthCenteredDiv,
  LightPrimaryEssText,
  MediumDarkSmallText,
  MediumPrimaryButton,
  NoDataIcon,
  ScrollWrapper,
  TinyGapColumnCenteredDiv,
} from '../../../styles/shared-styled-components';
import { getFileTypeFromUrl } from '../../../utils/string';
import LoadingIndicator from '../../LoadingIndicator';
import Snackbar from '../../Snackbar';
import {
  FileContainer,
  FilePlaceholderIcon,
  FileTitleText,
  FilesContainer,
  IconDiv,
  ImageFilePreview,
  RemoveFileIcon,
} from './styled';

const Files = () => {
  const { files, refetch, loading } = useMyFiles();
  const [uploadFilesMutation, { loading: uploadFilesLoading }] =
    useMutation(UPLOAD_FILES);
  const [deleteFileMutation, { loading: deleteFilesLoading }] =
    useMutation(DELETE_FILE);

  const uploadInputRef = useRef();

  const [snackbarMessage, setSnackbarMessage] = useState('');

  const numFiles = files.length;

  const onClickUpload = () => {
    uploadInputRef.current.click();
  };

  const onUpload = async (event) => {
    const file = event.target.files[0];

    uploadFilesMutation({
      variables: {
        files: [file],
      },
      onCompleted: async (data) => {
        const success = data.uploadFiles;

        if (success) {
          setSnackbarMessage('Uploaded successfully');
          await refetch();
        }
      },
    });
  };

  const onDeleteFile = async (id) => {
    deleteFileMutation({
      variables: {
        id,
      },
      onCompleted: async (data) => {
        const success = data.deleteFile;

        if (success) {
          setSnackbarMessage('Deleted file');
          await refetch();
        }
      },
    });
  };

  if (loading || uploadFilesLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <BoxWithPadding>
        <TinyGapColumnCenteredDiv>
          <BoxTitleContainer>
            <MediumDarkSmallText>
              Upload files for your agent to send
            </MediumDarkSmallText>
          </BoxTitleContainer>
        </TinyGapColumnCenteredDiv>
        {numFiles ? (
          <ScrollWrapper addTopMargin>
            <FilesContainer>
              {files.map((f) => {
                const fileType = getFileTypeFromUrl(f.url);
                return (
                  <FileContainer>
                    <IconDiv>
                      {['jpg', 'gif', 'png', 'svg', 'jpeg'].includes(
                        fileType,
                      ) ? (
                        <>
                          <ImageFilePreview src={f.url} />
                          <RemoveFileIcon
                            isImage
                            onClick={() => onDeleteFile(f.id)}
                          />
                        </>
                      ) : (
                        <>
                          <FilePlaceholderIcon />
                          <RemoveFileIcon onClick={() => onDeleteFile(f.id)} />
                        </>
                      )}
                    </IconDiv>
                    <FileTitleText>{f?.name}</FileTitleText>
                  </FileContainer>
                );
              })}
            </FilesContainer>
          </ScrollWrapper>
        ) : (
          <FullSizeColumnCenteredDiv>
            <NoDataIcon />
            <LightPrimaryEssText>No files uploaded</LightPrimaryEssText>
          </FullSizeColumnCenteredDiv>
        )}
      </BoxWithPadding>
      <FullWidthCenteredDiv>
        <MediumPrimaryButton onClick={() => onClickUpload()}>
          Upload
        </MediumPrimaryButton>
        <FileInput
          ref={uploadInputRef}
          onChange={(e) => onUpload(e)}
        />
      </FullWidthCenteredDiv>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        quick
      />
    </>
  );
};

export default Files;
