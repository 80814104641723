import { useApolloClient, useMutation } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import { useContactTags } from '../../../api/hooks/campaigns';
import { EDIT_CAMPAIGN } from '../../../api/mutations/campaign';
import {
  Checkbox,
  Chip,
  ChipCancelIcon,
  DarkMLText,
  Dropdown,
  DropdownLabel,
  FixedWidthCheckboxContainer,
  Form,
  FormControlLabel,
  FormGroup,
  InputContainerForMediumGap,
  InputContainerForSmallGap,
  InputWidthDiv,
  MediumDarkEssText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SMCheckbox,
  Stack,
} from '../../../styles/shared-styled-components';
import { updateMyCampaignInCache } from '../../../utils/cache';
import { campaignTypes, dropdownInputType } from '../../../utils/constants';
import { valueIsEmpty } from '../../../utils/data';
import { safeParseInt } from '../../../utils/numbers';
import {
  copyTextToClipboard,
  getCampaignTagToMatch,
} from '../../../utils/string';
import DateFilter from '../../Filters/DateFilter/DateFilter';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';
import Snackbar from '../../Snackbar';
import {
  DateRangeFieldContainer,
  DateRangeFieldTitleContainer,
} from '../CreateCampaignModal/styled';

const EditCampaignModal = ({
  isOpen,
  onClose,
  campaignToEdit,
  promos,
  messageTemplates,
  onEdit,
}) => {
  const { cache } = useApolloClient();

  const { tags, loading: tagsLoading } = useContactTags();
  const [editCampaignMutation, { loading: editCampaignLoading }] =
    useMutation(EDIT_CAMPAIGN);

  const [name, setName] = useState(campaignToEdit?.name || '');
  const [type, setType] = useState(campaignToEdit?.type || '');
  const [isTargettingClients, setIsTargettingClients] = useState(
    campaignToEdit?.targetClients || false,
  );
  const [isTargettingLeads, setIsTargettingLeads] = useState(
    campaignToEdit?.targetLeads || false,
  );
  const [messageNewLeads, setMessageNewLeads] = useState();
  const [lastContactDelayDays, setLastContactDelayDays] = useState(0);
  const [startDate, setStartDate] = useState(campaignToEdit?.startDate);
  const [endDate, setEndDate] = useState(campaignToEdit?.endDate);
  const [runsIndefinitely, setRunsIndefinitely] = useState(
    !startDate && !endDate,
  );
  const [tagsToExclude, setTagsToExclude] = useState(
    campaignToEdit?.tagsToExclude || [],
  );
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const tagToMatch = getCampaignTagToMatch(campaignToEdit?.id);

  useEffect(() => {
    if (campaignToEdit) {
      setName(campaignToEdit.name);
      setType(campaignToEdit.type);
      setIsTargettingClients(campaignToEdit?.targetClients);
      setIsTargettingLeads(campaignToEdit?.targetLeads);
      setMessageNewLeads(campaignToEdit.messageNewLeads);
      setStartDate(campaignToEdit?.startDate);
      setEndDate(campaignToEdit?.endDate);
      setRunsIndefinitely(
        !campaignToEdit?.endDate && !campaignToEdit?.startDate,
      );
      setTagsToExclude(campaignToEdit?.tagsToExclude || []);
    }
  }, [campaignToEdit]);

  const handleEdit = () => {
    const campaignId = campaignToEdit.id;

    editCampaignMutation({
      variables: {
        id: campaignId,
        name,
        type,
        startDate,
        endDate,
        targetClients: isTargettingClients,
        targetLeads: isTargettingLeads,
        messageNewLeads: messageNewLeads || false,
        status: campaignToEdit.status,
        lastContactDelayDays: safeParseInt(lastContactDelayDays) || 0,
        tagsToExclude,
      },
      onCompleted: async (data) => {
        const success = data.editCampaign.success;

        if (success) {
          await updateMyCampaignInCache(
            {
              id: campaignId,
              name,
            },
            cache,
          );
          onEdit();
          handleClose();
        }
      },
    });
  };

  const handleClose = () => {
    setName();
    onClose();
  };

  const onCopyTag = () => {
    copyTextToClipboard(tagToMatch);
    setSnackbarMessage('Tag copied');
  };

  if (editCampaignLoading) {
    return <LoadingIndicator fullScreen />;
  }

  const canSave =
    !valueIsEmpty(name) &&
    (runsIndefinitely || (!runsIndefinitely && startDate && endDate)) &&
    (messageNewLeads ||
      (!messageNewLeads && !isTargettingLeads) ||
      (!messageNewLeads &&
        isTargettingLeads &&
        !valueIsEmpty(lastContactDelayDays)));

  return (
    campaignToEdit &&
    isOpen && (
      <>
        <Modal
          open={isOpen}
          onClose={handleClose}
        >
          <ModalContainer>
            <DarkMLText>Edit Campaign</DarkMLText>
            <ModalInputsContainer>
              <ModalInputsContainerScrollWrapper>
                <Input
                  id='name'
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  label='Campaign name'
                  isRequired={false}
                  useSmallGap
                />
                <Input
                  id='type'
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  label='Type'
                  isRequired={false}
                  type={dropdownInputType}
                  options={campaignTypes}
                  useSmallGap
                />
                <InputContainerForMediumGap>
                  <InputWidthDiv>
                    <FixedWidthCheckboxContainer>
                      <MediumDarkEssText>Target clients</MediumDarkEssText>
                      <SMCheckbox
                        checked={isTargettingClients}
                        onClick={() => {
                          const updatedIsTargettingClients =
                            !isTargettingClients;
                          setIsTargettingClients(updatedIsTargettingClients);
                        }}
                      />
                    </FixedWidthCheckboxContainer>
                  </InputWidthDiv>
                  <InputWidthDiv>
                    <FixedWidthCheckboxContainer>
                      <MediumDarkEssText>Target leads</MediumDarkEssText>
                      <SMCheckbox
                        checked={isTargettingLeads}
                        onClick={() => {
                          const updatedIsTargettingLeads = !isTargettingLeads;
                          setMessageNewLeads(updatedIsTargettingLeads);
                          setLastContactDelayDays(0);
                          setIsTargettingLeads(updatedIsTargettingLeads);
                        }}
                      />
                    </FixedWidthCheckboxContainer>
                  </InputWidthDiv>
                  {isTargettingLeads && (
                    <InputContainerForSmallGap>
                      <InputWidthDiv>
                        <MediumDarkEssText>
                          Will message new leads shortly after the click event
                        </MediumDarkEssText>
                      </InputWidthDiv>
                    </InputContainerForSmallGap>
                  )}
                  <Input
                    id='tag'
                    value={tagToMatch}
                    label='Tag to match'
                    isRequired={false}
                    useSmallGap
                    disabled
                    addCopyButton
                    onCopy={onCopyTag}
                  />
                  <Input
                    id='staff'
                    value={tagsToExclude}
                    useSmallGap
                    CustomInputComponent={
                      <Form fitContentHeight>
                        <DropdownLabel>Tags to exclude</DropdownLabel>
                        <Dropdown
                          multiple
                          value={tagsToExclude}
                          label='Tags to exclude'
                          renderValue={(selected) => {
                            return (
                              <Stack>
                                {Object.values(selected)?.map((selectedTag) => {
                                  return (
                                    <Chip
                                      key={selectedTag}
                                      label={selectedTag}
                                      onDelete={() => {
                                        setTagsToExclude(
                                          tagsToExclude.filter(
                                            (t) => t !== selectedTag,
                                          ),
                                        );
                                      }}
                                      deleteIcon={
                                        <ChipCancelIcon
                                          onMouseDown={(event) =>
                                            event.stopPropagation()
                                          }
                                        />
                                      }
                                    />
                                  );
                                })}
                              </Stack>
                            );
                          }}
                        >
                          {sortBy(tags)?.map((tag) => {
                            const checked = tagsToExclude.includes(tag);
                            return (
                              <>
                                <FormGroup key={tag}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={checked}
                                        onClick={() => {
                                          if (!checked) {
                                            setTagsToExclude([
                                              ...tagsToExclude,
                                              tag,
                                            ]);
                                          } else {
                                            setTagsToExclude(
                                              tagsToExclude.filter(
                                                (t) => t !== tag,
                                              ),
                                            );
                                          }
                                        }}
                                      />
                                    }
                                    label={tag}
                                  />
                                </FormGroup>
                              </>
                            );
                          })}
                        </Dropdown>
                      </Form>
                    }
                  />
                </InputContainerForMediumGap>
                <InputContainerForMediumGap>
                  <DateRangeFieldContainer>
                    <DateRangeFieldTitleContainer>
                      <MediumDarkEssText>Runs indefinitely</MediumDarkEssText>
                      <SMCheckbox
                        checked={runsIndefinitely}
                        onClick={() => {
                          const updatedRunsIndefinitely = !runsIndefinitely;
                          setRunsIndefinitely(updatedRunsIndefinitely);
                          if (updatedRunsIndefinitely === true) {
                            setStartDate();
                            setEndDate();
                          }
                        }}
                      />
                    </DateRangeFieldTitleContainer>
                    {!runsIndefinitely && (
                      <DateFilter
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                      />
                    )}
                  </DateRangeFieldContainer>
                </InputContainerForMediumGap>
              </ModalInputsContainerScrollWrapper>
            </ModalInputsContainer>
            <ModalActions>
              <MediumPrimaryButton
                onClick={handleEdit}
                disabled={!canSave}
              >
                Save
              </MediumPrimaryButton>
              <MediumSecondaryButton onClick={handleClose}>
                Cancel
              </MediumSecondaryButton>
            </ModalActions>
          </ModalContainer>
        </Modal>
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
        />
      </>
    )
  );
};

export default EditCampaignModal;
