import styled, { css } from 'styled-components';
import {
  AddIcon,
  AutoHorizontalMargins,
  BaseBox,
  CardStyle,
  ColumnDiv,
  ColumnFlexStyle,
  ExtraLargeGapStyle,
  ExtraSmallText,
  LightDarkExtraSmallText,
  MLGapStyle,
  MediumGapStyle,
  PrimaryButton,
  SMGapStyle,
  SMMIconSize,
  ScrollStyle,
  SecondaryButton,
  SmallGapStyle,
  Tabs,
  TextInput,
} from '../../../styles/shared-styled-components';
import { PanelDivider } from '../../shared-page-styles';

export const LocationsContainer = styled(ColumnDiv)`
  ${ExtraLargeGapStyle}
  margin-bottom: 0px;
`;

const ButtonStyle = css`
  min-width: 210px;
  margin-left: 0px !important;
  margin-right: 0px;
  font-size: 19px;
  padding: 3px 20px;
`;

export const LocationsTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${MediumGapStyle}
`;

export const AddLocationButton = styled(SecondaryButton)`
  ${ButtonStyle}
  padding: 3px 10px;
  min-width: 50px;
  gap: 2px;
  font-size: 17px;
`;

export const AddLocationIcon = styled(AddIcon)`
  ${SMMIconSize}
  margin-top: -3px;
`;

export const TabsContainer = styled(Tabs)``;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -30px;
  align-items: flex-start;
`;

export const SearchInputContainer = styled(ColumnDiv)`
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2px;
`;

export const SearchInput = styled(TextInput)`
  width: 400px;
  .MuiOutlinedInput-root {
    height: 54px;
  }
  .MuiOutlinedInput-input {
    font-size: 16px;
  }
`;

export const LocationCardBox = styled(BaseBox)`
  padding: 20px 30px;
  width: 90%;
  margin: unset;
  margin-top: -32px;
`;

export const LocationCardContainer = styled(ColumnDiv)`
  ${MLGapStyle}
  height: 500px;
  width: calc(100% - 10px);
  ${ScrollStyle}
  padding: 30px 0px;
`;

export const EmptyLocationsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${ColumnFlexStyle}
  ${SMGapStyle}
`;

export const LocationCard = styled.div`
  ${CardStyle}
  width: 700px;
  ${AutoHorizontalMargins}
  padding: 30px 50px;
`;

export const LocationHeaderTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const LocationNameTextContainer = styled(ColumnDiv)``;

export const CardMetricsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${SmallGapStyle}
`;

export const CardMetricContainer = styled.div`
  ${ColumnFlexStyle}
  margin-top: 40px;
  justify-content: center;
  align-items: ${(props) =>
    props.position === 'start'
      ? 'flex-start'
      : props.position === 'end'
      ? 'flex-end'
      : 'center'};
`;

export const CardMetricTitleText = styled(LightDarkExtraSmallText)``;

export const CardMetricValueText = styled(ExtraSmallText)``;

export const CardButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 40px;
  ${SMGapStyle}
`;

export const CardButton = styled(PrimaryButton)`
  ${ButtonStyle}
`;

export const SecondaryCardButton = styled(SecondaryButton)`
  ${ButtonStyle}
`;

export const SelectedCardButton = styled(CardButton)`
  background-color: ${({ theme }) => theme.color.brightGreen};
  color: white;

  &:hover {
    background-color: ${({ theme }) => theme.color.brightGreen};
    color: white;
  }
`;

export const CardPanelDivider = styled(PanelDivider)`
  height: 30px;
  margin-top: 40px;
`;

export const MapImage = styled.img`
  height: 600px;
  width: auto;
  object-fit: contain;
`;

export const NoLocationsCreatedContainer = styled.div`
  ${ColumnFlexStyle}
  ${MediumGapStyle}
  margin-top: 30px;
`;
