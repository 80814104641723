import { useApolloClient, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMyEmbeddingsSyncStatus } from '../../../../api/hooks/enterprise';
import { RUN_REFRESH_EMBEDDINGS_JOB } from '../../../../api/mutations/agent';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import Files from '../../../../components/Content/Files';
import Header from '../../../../components/NavBar/Header';
import Snackbar from '../../../../components/Snackbar';
import MetaSetter from '../../../../components/Utils/MetaSetter';
import {
  CardPageSection,
  ColumnCenteredDiv,
  ContentContainer,
  PageContainer,
  PageTitleText,
} from '../../../../styles/shared-styled-components';
import {
  completeStatusKey,
  contentTabCookieKey,
  inProgressStatusKey,
  uninitializedStatusKey,
} from '../../../../utils/constants';
import { getCookieExpiryObject } from '../../../../utils/date';

const filesTab = 1;
const faqsTab = 2;

const Content = () => {
  const { cache } = useApolloClient();

  const {
    user,
    refetchUser,
    drawerOpen,
    drawerExpanded,
    cookies,
    setCookie,
    removeCookie,
  } = useContext(BaseContext);

  const {
    data: embeddingsSyncStatus,
    loading: embeddingsSyncStatusLoading,
    refetch: refetchEmbeddingsSyncStatus,
  } = useMyEmbeddingsSyncStatus();
  const [
    runRefreshEmbeddingsJobMutation,
    { loading: runRefreshEmbeddingsJobLoading },
  ] = useMutation(RUN_REFRESH_EMBEDDINGS_JOB);

  const contentContainerRef = useRef();

  const tabCookie = cookies[contentTabCookieKey];
  const [tab, setTab] = useState(
    tabCookie != null ? parseInt(tabCookie) : filesTab,
  );
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (tabCookie != null) {
      setTab(parseInt(tabCookie));
    }
  }, [tabCookie]);

  const onClickEmbeddingsSync = async () => {
    const syncStatus = embeddingsSyncStatus?.status;

    if (
      syncStatus === uninitializedStatusKey ||
      syncStatus === completeStatusKey
    ) {
      runRefreshEmbeddingsJobMutation({
        onCompleted: async (data) => {
          const success = data.runRefreshEmbeddingsJob;

          await refetchEmbeddingsSyncStatus();

          if (success) {
            setSnackbarMessage('Agent training in progress');
          } else {
            setSnackbarMessage(
              'Encountered error, unable to begin agent training',
            );
          }
        },
      });
    } else if (syncStatus === inProgressStatusKey) {
      await refetchEmbeddingsSyncStatus();
      setSnackbarMessage(
        'Agent training status refreshed: training in progress',
      );
    }
  };

  const onChangeTab = (tabValue) => {
    setCookie(contentTabCookieKey, parseInt(tabValue), getCookieExpiryObject());
  };

  return (
    <>
      <MetaSetter
        title={`Content`}
        description={`Content for Your LiveIQ Agent`}
      />
      <Header
        embeddingsSyncStatus={embeddingsSyncStatus}
        onClickEmbeddingsSync={onClickEmbeddingsSync}
      />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          ref={contentContainerRef}
        >
          <ColumnCenteredDiv>
            <PageTitleText>Content</PageTitleText>
          </ColumnCenteredDiv>
          <CardPageSection>
            <Files />
          </CardPageSection>
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        quick
      />
    </>
  );
};

export default Content;
