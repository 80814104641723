import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom/dist';
import Paths from '../../../Paths';
import { useMyAnalytics } from '../../../api/hooks/analytics';
import { useMyCampaigns } from '../../../api/hooks/campaigns';
import {
  EDIT_CHAT_APPOINTMENT,
  UNASSIGN_CHAT_APPOINTMENT,
} from '../../../api/mutations/calendar';
import DateFilterDropdown from '../../../components/Analytics/DateFilterDropdown/DateFilterDropdown';
import OptionsFilterDropdown from '../../../components/Analytics/OptionsFilterDropdown/OptionsFilterDropdown';
import {
  DataDiv,
  FieldTitleText,
  FieldValueText,
  FilterSectionContainer,
  InfoIcon,
  PanelDivider,
  TopSectionContainer,
  TopSectionPanel,
} from '../../../components/Analytics/shared-analytics-styles';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import AnalyticsAppointmentModal from '../../../components/Modals/AnalyticsAppointmentModal/AnalyticsAppointmentModal';
import Header from '../../../components/NavBar/Header';
import Snackbar from '../../../components/Snackbar';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  ColumnCenteredDiv,
  ContentContainer,
  FullWidthCenteredDiv,
  LightDarkMediumText,
  PageContainer,
  PageTitleText,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import {
  appointmentBookedStatus,
  appointmentStatusesField,
  chatLogsCookieKey,
  chatsSearchParamsCookieKey,
  clientContactType,
  equalsKey,
  leadContactType,
} from '../../../utils/constants';
import { getCookieExpiryObject } from '../../../utils/date';
import {
  formatNumber,
  getFormattedPercentageString,
  safeParseInt,
} from '../../../utils/numbers';
import { getUserLiveIQAgentName } from '../../../utils/user';
import {
  AnalyticsPanelTitleContainer,
  AnalyticsPanelTitleText,
  AppointmentsIcon,
  CampaignIcon,
  ChatsIcon,
  DataContainer,
  RevenueIcon,
} from './styled';

const pricePerCycle = 1000;
const daysPerCycle = 30;
const cycleTitle = 'Month';
const pricePerDay = pricePerCycle / daysPerCycle;

const cookieExpiryObject = getCookieExpiryObject();

const allAppointmentsKey = 'all';
const completedAppointmentsKey = 'completed';
const upcomingAppointmentsKey = 'upcoming';
const cancelledAppointmentsKey = 'cancelled';

const Analytics = () => {
  const navigate = useNavigate();
  const {
    user,
    drawerOpen,
    drawerExpanded,
    setCookie,
    removeCookie,
    inDemoMode,
  } = useContext(BaseContext);

  const hardcoded = inDemoMode;

  const agentName = getUserLiveIQAgentName(user);
  const contentContainerRef = useRef();

  const [startDateRange, setStartDateRange] = useState();
  const [endDateRange, setEndDateRange] = useState();

  const {
    campaigns,
    loading: campaignsLoading,
    refetch: refetchCampaigns,
  } = useMyCampaigns({ shallow: true });

  const [editChatAppointmentMutation, { loading: editChatAppointmentLoading }] =
    useMutation(EDIT_CHAT_APPOINTMENT);
  const [
    unassignChatAppointmentMutation,
    { loading: unassignChatAppointmentLoading },
  ] = useMutation(UNASSIGN_CHAT_APPOINTMENT);

  const allCampaignIds = [];
  const campaignOptions = (campaigns || []).map((c) => {
    allCampaignIds.push(c.id);
    return { key: c.id, label: c.name };
  });
  const [filteredCampaigns, setFilteredCampaigns] = useState(
    campaignOptions.map((c) => c.key),
  );
  const [hasLoadedCampaigns, setHasLoadedCampaigns] = useState(false);
  const [filteredContactTypes, setFilteredContactTypes] = useState([
    leadContactType,
    clientContactType,
  ]);
  const [appointmentsModalOpen, setAppointmentsModalOpen] = useState(false);
  const [appointmentsModalType, setAppointmentsModalType] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const filteredCampaignIds = filteredCampaigns?.length
    ? filteredCampaigns
    : allCampaignIds;
  const {
    analytics,
    loading: analyticsLoading,
    refetch,
  } = useMyAnalytics({
    filteredCampaignIds,
    start: startDateRange,
    end: endDateRange,
  });

  useEffect(() => {
    if (campaigns?.length && !hasLoadedCampaigns) {
      console.log('resetting filtered');
      setFilteredCampaigns(campaigns.map((c) => c.id));
      setHasLoadedCampaigns(true);
    }
  }, [campaigns]);

  const onViewChatsWithAppointmentStatus = async (statusesToView) => {
    const params = {
      filters: {
        [appointmentStatusesField]: [
          {
            key: appointmentStatusesField,
            operator: equalsKey,
            value: `[${statusesToView.join(', ')}]`,
          },
        ],
      },
      sortParam: 'lastMessageSentAt:desc',
    };

    await removeCookie(chatLogsCookieKey);
    await setCookie(chatsSearchParamsCookieKey, params, cookieExpiryObject);

    navigate(Paths.chats);
  };

  const onOpenModal = (type) => {
    setAppointmentsModalType(type);
    setAppointmentsModalOpen(true);
  };

  const onChangeFilteredCampaigns = (id, add) => {
    if (add) {
      setFilteredCampaigns([...filteredCampaigns, id]);
    } else {
      setFilteredCampaigns([...filteredCampaigns.filter((key) => key !== id)]);
    }
  };

  const onUpdateStart = (updatedValue) => {
    const updatedStartDate = new Date(updatedValue).toISOString();
    const endDate = endDateRange ? new Date(endDateRange).toISOString() : null;
    if (endDate && updatedStartDate > endDate) {
      const updatedEndDate = new Date(updatedStartDate);
      updatedEndDate.setDate(updatedEndDate.getDate() + 7);
      setEndDateRange(updatedEndDate.toISOString());
    }
    setStartDateRange(updatedValue);
  };

  const onUpdateEnd = (updatedValue) => {
    const updatedEndDate = new Date(updatedValue).toISOString();
    const startDate = startDateRange
      ? new Date(startDateRange).toISOString()
      : null;
    if (startDate && updatedEndDate < startDate) {
      const updatedStartDate = new Date(updatedEndDate);
      updatedStartDate.setDate(updatedStartDate.getDate() - 7);
      setStartDateRange(updatedStartDate.toISOString());
    }
    setEndDateRange(updatedValue);
  };

  const onEditAppointment = (appointment) => {
    setActionLoading(true);
    editChatAppointmentMutation({
      variables: {
        appointment,
      },
      onCompleted: async (data) => {
        const success = data.editChatAppointment;

        if (success) {
          await refetch();
          setSnackbarMessage(`Appointment edited`);
          setActionLoading(false);
        } else {
          setSnackbarMessage(`Failed to edit appointment`);
          setActionLoading(false);
        }
      },
    });
  };

  const onUnassignAppointment = (appointmentId) => {
    setActionLoading(true);
    unassignChatAppointmentMutation({
      variables: {
        appointmentId,
      },
      onCompleted: async (data) => {
        const success = data.unassignChatAppointment;

        if (success) {
          await refetch();
          setSnackbarMessage(`Appointment unassigned`);
          setActionLoading(false);
        } else {
          setSnackbarMessage(`Failed to unassign appointment`);
          setActionLoading(false);
        }
      },
    });
  };

  const revenueIsVariable = analytics?.revenue?.isVariable;
  const collectedRevenue = safeParseInt(analytics?.revenue?.collected);
  const bookedRevenue = safeParseInt(analytics?.revenue?.booked);

  const VariableRevenueComponent = revenueIsVariable ? (
    <Tooltip
      placement='bottom'
      title={
        <TooltipTitleText>
          Some appointments booked were for services with variable prices,
          meaning this metric is subject to variance
        </TooltipTitleText>
      }
    >
      <InfoIcon />
    </Tooltip>
  ) : (
    <></>
  );

  const emptyFilterPresent = !filteredCampaigns.length;

  return (
    <>
      <MetaSetter
        title={'Dashboard'}
        description={'Get insights on your business performance.'}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          ref={contentContainerRef}
        >
          <ColumnCenteredDiv>
            <PageTitleText>Dashboard</PageTitleText>
          </ColumnCenteredDiv>
          <DataDiv>
            <FilterSectionContainer>
              <OptionsFilterDropdown
                filtered={filteredCampaigns}
                options={campaignOptions}
                label='Campaigns'
                onChange={onChangeFilteredCampaigns}
              />
              <DateFilterDropdown
                start={startDateRange}
                end={endDateRange}
                onChangeStart={onUpdateStart}
                onChangeEnd={onUpdateEnd}
                onReset={() => {
                  setStartDateRange();
                  setEndDateRange();
                }}
                hardcoded={hardcoded}
              />
            </FilterSectionContainer>
            {analyticsLoading || !hasLoadedCampaigns ? (
              <TopSectionContainer>
                <FullWidthCenteredDiv>
                  <LoadingIndicator />
                </FullWidthCenteredDiv>
              </TopSectionContainer>
            ) : !analyticsLoading &&
              hasLoadedCampaigns &&
              (emptyFilterPresent || !analytics) ? (
              <TopSectionContainer>
                <FullWidthCenteredDiv>
                  <LightDarkMediumText>No data displayed</LightDarkMediumText>
                </FullWidthCenteredDiv>
              </TopSectionContainer>
            ) : (
              <DataContainer>
                <TopSectionContainer>
                  <AnalyticsPanelTitleContainer>
                    <AnalyticsPanelTitleText>
                      Appointments <AppointmentsIcon />
                    </AnalyticsPanelTitleText>
                  </AnalyticsPanelTitleContainer>
                  <TopSectionPanel position='start'>
                    <FieldTitleText
                      position='start'
                      clickable
                      onClick={() => onOpenModal(allAppointmentsKey)}
                    >
                      Booked with LiveIQ
                    </FieldTitleText>
                    <FieldValueText
                      clickable
                      onClick={() => onOpenModal(allAppointmentsKey)}
                    >
                      {hardcoded ? 151 : analytics?.appointments?.total}
                    </FieldValueText>
                  </TopSectionPanel>
                  <PanelDivider />
                  <TopSectionPanel>
                    <FieldTitleText>Completed</FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? 107
                        : analytics?.appointments?.completed?.length || 0}
                    </FieldValueText>
                  </TopSectionPanel>
                  <PanelDivider />
                  <TopSectionPanel>
                    <FieldTitleText>Upcoming</FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? 29
                        : analytics?.appointments?.upcoming?.length || 0}
                    </FieldValueText>
                  </TopSectionPanel>
                  <PanelDivider />
                  <TopSectionPanel position='end'>
                    <FieldTitleText position='end'>
                      Cancel / No-Show
                    </FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? 15
                        : analytics?.appointments?.cancelled?.length || 0}
                    </FieldValueText>
                  </TopSectionPanel>
                </TopSectionContainer>
                <TopSectionContainer>
                  <AnalyticsPanelTitleContainer>
                    <AnalyticsPanelTitleText>
                      Revenue <RevenueIcon />
                    </AnalyticsPanelTitleText>
                  </AnalyticsPanelTitleContainer>
                  <TopSectionPanel position='start'>
                    <FieldTitleText position='start'>Received</FieldTitleText>
                    <FieldValueText>
                      ${hardcoded ? `26,884` : formatNumber(collectedRevenue)}
                      {VariableRevenueComponent}
                    </FieldValueText>
                  </TopSectionPanel>
                  <PanelDivider />
                  <TopSectionPanel>
                    <FieldTitleText>To Be Paid</FieldTitleText>
                    <FieldValueText>
                      ${hardcoded ? `8,961` : formatNumber(bookedRevenue)}
                      {VariableRevenueComponent}
                    </FieldValueText>
                  </TopSectionPanel>
                  <PanelDivider />
                  <TopSectionPanel>
                    <FieldTitleText>Revenue / Message</FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? `$1.52`
                        : collectedRevenue || bookedRevenue
                        ? `$${formatNumber(
                            (
                              (collectedRevenue + bookedRevenue) /
                              analytics?.chats?.messages
                            ).toFixed(2),
                          )}`
                        : revenueIsVariable
                        ? 'Unknown'
                        : 'N/A'}
                      {VariableRevenueComponent}
                    </FieldValueText>
                  </TopSectionPanel>
                  <PanelDivider />
                  <TopSectionPanel position='end'>
                    <FieldTitleText position='end'>
                      ROI (${hardcoded ? `2,000` : formatNumber(pricePerCycle)}/
                      {cycleTitle})
                    </FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? 13.42
                        : collectedRevenue || bookedRevenue
                        ? (
                            (collectedRevenue + bookedRevenue) /
                            (pricePerDay * parseInt(analytics?.daysSinceLaunch))
                          ).toFixed(2)
                        : revenueIsVariable
                        ? 'Unknown'
                        : 'N/A'}
                      {VariableRevenueComponent}
                    </FieldValueText>
                  </TopSectionPanel>
                </TopSectionContainer>
                <TopSectionContainer>
                  <AnalyticsPanelTitleContainer>
                    <AnalyticsPanelTitleText>
                      Messages <ChatsIcon />
                    </AnalyticsPanelTitleText>
                  </AnalyticsPanelTitleContainer>
                  <TopSectionPanel position='start'>
                    <FieldTitleText position='start'>Total</FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? `23,525`
                        : formatNumber(analytics?.chats?.messages)}
                    </FieldValueText>
                  </TopSectionPanel>
                  <PanelDivider />
                  <TopSectionPanel>
                    <FieldTitleText>Reply Rate</FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? `7.9%`
                        : analytics?.chats?.total
                        ? getFormattedPercentageString(
                            analytics?.chats?.validResponse /
                              analytics?.chats?.total,
                          )
                        : 'N/A'}
                    </FieldValueText>
                  </TopSectionPanel>
                  <PanelDivider />
                  <TopSectionPanel>
                    <FieldTitleText>Disengagement Rate</FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? `2.2%`
                        : analytics?.chats?.total
                        ? getFormattedPercentageString(
                            analytics?.chats?.disengaged /
                              analytics?.chats?.total,
                          )
                        : 'N/A'}
                    </FieldValueText>
                  </TopSectionPanel>
                  <PanelDivider />
                  <TopSectionPanel position='end'>
                    <FieldTitleText position='end'>
                      Interception Rate
                    </FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? `2.7%`
                        : analytics?.chats?.total
                        ? getFormattedPercentageString(
                            analytics?.chats?.intercepted /
                              analytics?.chats?.total,
                          )
                        : 'N/A'}
                    </FieldValueText>
                  </TopSectionPanel>
                </TopSectionContainer>
                <TopSectionContainer>
                  <AnalyticsPanelTitleContainer>
                    <AnalyticsPanelTitleText>
                      Campaigns <CampaignIcon />
                    </AnalyticsPanelTitleText>
                  </AnalyticsPanelTitleContainer>
                  <TopSectionPanel position='start'>
                    <FieldTitleText position='start'>
                      Clients Messaged
                    </FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? `7,225`
                        : formatNumber(analytics?.members?.clients)}
                    </FieldValueText>
                  </TopSectionPanel>
                  <PanelDivider />
                  <TopSectionPanel>
                    <FieldTitleText>Leads Messaged</FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? `689`
                        : formatNumber(analytics?.members?.leads)}
                    </FieldValueText>
                  </TopSectionPanel>
                  <PanelDivider />
                  <TopSectionPanel>
                    <FieldTitleText>New Chat Sequences</FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? `104`
                        : formatNumber(
                            analytics?.campaigns?.sequences?.newChats,
                          )}
                    </FieldValueText>
                  </TopSectionPanel>
                  <PanelDivider />
                  <TopSectionPanel position='end'>
                    <FieldTitleText position='end'>
                      Follow-Up Sequences
                    </FieldTitleText>
                    <FieldValueText>
                      {hardcoded
                        ? `45`
                        : formatNumber(
                            analytics?.campaigns?.sequences?.chatFollowUps,
                          )}
                    </FieldValueText>
                  </TopSectionPanel>
                </TopSectionContainer>
              </DataContainer>
            )}
          </DataDiv>
        </ContentContainer>
      </PageContainer>
      <AnalyticsAppointmentModal
        isOpen={appointmentsModalOpen}
        onClose={() => setAppointmentsModalOpen(false)}
        appointments={
          !appointmentsModalType
            ? []
            : appointmentsModalType === allAppointmentsKey
            ? analytics?.appointments?.all
            : appointmentsModalType === upcomingAppointmentsKey
            ? analytics?.appointments?.upcoming
            : appointmentsModalType === completedAppointmentsKey
            ? analytics?.appointments?.completed
            : appointmentsModalType === cancelledAppointmentsKey
            ? analytics?.appointments?.cancelled
            : []
        }
        title={
          !appointmentsModalType
            ? ''
            : appointmentsModalType === allAppointmentsKey
            ? 'All Appointments'
            : appointmentsModalType === upcomingAppointmentsKey
            ? 'Upcoming Appointments'
            : appointmentsModalType === completedAppointmentsKey
            ? 'Completed Appointments'
            : analytics?.appointments?.cancelled
            ? 'Cancelled Appointments'
            : ''
        }
        onViewChatsWithAppointmentStatus={() =>
          onViewChatsWithAppointmentStatus([appointmentBookedStatus])
        }
        onEdit={onEditAppointment}
        onUnassign={onUnassignAppointment}
        actionLoading={actionLoading}
      />
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        quick
      />
    </>
  );
};

export default Analytics;
