import React from 'react';
import { ClearInputIcon } from '../../../styles/shared-styled-components';
import { ClientSearchBoxComponent } from './styled';

const ClientSearchBox = ({ value, onChange, onReset }) => {
  return (
    <ClientSearchBoxComponent
      placeholder='Search by contact name'
      onChange={onChange}
      value={value}
      InputProps={{
        endAdornment: <ClearInputIcon onClick={onReset} />,
      }}
    />
  );
};

export default ClientSearchBox;
