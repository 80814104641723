import styled from 'styled-components';
import {
  homepageMobilePixelDistance,
  homepageMobileSideMargin,
  homepagePercentageWidth,
  homepagePixelDistance,
} from '../../../utils/constants';

export const TestimonialContainer = styled.div`
  width: ${homepagePercentageWidth}%;
  margin: 0 auto;
  margin-top: ${homepagePixelDistance}px;
  height: fit-content;
  background-color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.charcoal
      : ({ theme }) => theme.color.lightGray};
  border: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 50px;

  background: rgba(245, 245, 245, 0.6);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.02);
  border-radius: 10px;

  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 viewBox%3D%220%200%20100%20100%22 version%3D%221.1%22%3E%3Cdefs%3E%3ClinearGradient id%3D%22sw-gradient%22 x1%3D%220%22 x2%3D%221%22 y1%3D%221%22 y2%3D%220%22%3E%3Cstop id%3D%22stop1%22 stop-color%3D%22rgba(57%2C%2049%2C%2080%2C%20.02)%22 offset%3D%220%25%22/%3E%3Cstop id%3D%22stop2%22 stop-color%3D%22rgba(113%2C%2097%2C%20160%2C%201)%22 offset%3D%22100%25%22/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill%3D%22url(%23sw-gradient)%22 d%3D%22M11.6%2C-14.8C15.8%2C-12.8%2C20.6%2C-10.6%2C25.8%2C-5.8C31%2C-1%2C36.5%2C6.3%2C37%2C14.2C37.5%2C22.1%2C32.9%2C30.6%2C25.9%2C31.8C18.9%2C33%2C9.5%2C26.9%2C2.4%2C23.6C-4.7%2C20.4%2C-9.5%2C20%2C-14%2C18C-18.5%2C16%2C-22.9%2C12.4%2C-27%2C7C-31.2%2C1.6%2C-35.2%2C-5.5%2C-32.4%2C-9.4C-29.6%2C-13.2%2C-19.9%2C-13.7%2C-13.4%2C-15C-6.9%2C-16.2%2C-3.4%2C-18.2%2C0.1%2C-18.4C3.7%2C-18.5%2C7.3%2C-16.8%2C11.6%2C-14.8Z%22 width%3D%22100%25%22 height%3D%22100%25%22 transform%3D%22translate(50%2050)%22 style%3D%22transition%3A%200.3s%3B%22 stroke-width%3D%220%22/%3E%3C/svg%3E');
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: right 150px bottom -250px;

  @media (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${homepageMobilePixelDistance}px;
    padding-left: ${homepageMobileSideMargin}px;
    padding-right: ${homepageMobileSideMargin}px;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 80%;
  }

  transition: all 0.3s;
  :hover {
    background-size: 38%;
  }
  z-index: 20;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
      transform: translateX(-200px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

export const TestimonialTextContainer = styled.div`
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};

  max-width: 40%;
  margin-right: auto;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 1024px) {
    max-width: 100%;
    margin-left: auto;
    align-items: top;
  }
`;

export const TestimonialHeaderText = styled.div`
  font-size: 300%;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
  font-weight: 600;
  letter-spacing: -3px;
  line-height: 1.1;
  @media (max-width: 1024px) {
    font-size: 40px;
    letter-spacing: -2px;
  }

  @media (max-width: 576px) {
    font-size: 25px;
    letter-spacing: -1px;
  }
`;

export const TestimonialHeaderSubText = styled.div`
  letter-spacing: -1px;
  font-size: 28px;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};

  @media (max-width: 1024px) {
    font-size: 20px;
  }

  @media (max-width: 576px) {
    font-size: 15px;
    letter-spacing: -0.5px;
  }
`;

export const TestimonialMediaContainer = styled.div`
  height: fit-content;
  width: 60%;
  margin-left: auto;
  max-width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 5;
  transition: transform 0.3s;

  :hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  @media (max-width: 1024px) {
    min-width: 90%;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const TestimonialMedia = styled.div`
  width: 80%;
  height: 350px;
  position: relative; /* Ensure positioning for overlay */

  iframe {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 480px) {
    height: 200px;
    max-height: 200px;
  }

  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10; /* Ensures the thumbnail is above the video */
    border-radius: 15px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  }
`;
