import styled, { css } from 'styled-components';
import {
  AutoHorizontalMargins,
  AutoScrollWrapper,
  CenteredDiv,
  CenterFlexStyle,
  ColumnFlexStyle,
  DarkExtraSmallText,
  EmptyGapColumnCenteredDiv as EGCCD,
  EssGapStyle,
  ExtraSmallGapStyle,
  SmallGapStyle,
  SMGapStyle,
  StartAlignedMediumDarkLargeTinyText,
  ToggleHoveredTextDisplayStyle,
  UnderlineStyle,
  WhiteExtraSmallText,
} from '../../../styles/shared-styled-components';
import {
  appointmentBookedStatus,
  appointmentDepositRequiredStatus,
  appointmentErrorStatus,
  appointmentMonitoringStatus,
  callEndedChatEvent,
  chatResumedMessageContent,
  disengagedChatStatusKey,
  endOfExchangeChatMessageContent,
  interceptedChatStatusKey,
  lastFollowUpMessageContent,
  newCallChatEvent,
  staleMessageContent,
} from '../../../utils/constants';

export const ModifiedScrollWrapper = styled(AutoScrollWrapper)`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  padding-bottom: 5px;
`;

export const MessagesBox = styled.div`
  ${ColumnFlexStyle}
  flex: 1;
  ${SmallGapStyle}
  padding: 30px 10px;
  padding-bottom: 10px;
  border-radius: ${({ theme }) => theme.spacing.mlBorderRadius}px
    ${({ theme }) => theme.spacing.mlBorderRadius}px 0px 0px;
  width: 90%;
  ${AutoHorizontalMargins}
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const BubbleWidthStyle = css`
  max-width: 360px;
  min-width: 20px;
`;

const Bubble = styled.div`
  --r: 25px; /* the radius */
  --t: 20px; /* the size of the tail */
  ${BubbleWidthStyle}
  padding: calc(2 * var(--r) / 3);
  -webkit-mask: radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%)
      var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,
    conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r) / -2)
      calc(var(--r) / -2) padding-box,
    radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r) space
      padding-box;
  // background: linear-gradient(70deg, #fe6d00, #1384c5) border-box;
  color: white;
  font-family: ${({ theme }) => theme.typography.baseFont};
  font-size: 17px;
  position: relative;
`;

export const LeftBubbleContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: flex-start;
  ${ExtraSmallGapStyle}
`;

export const LeftBubble = styled(Bubble)`
  --_d: 0%;
  border-left: var(--t) solid #0000;
  margin-right: var(--t);
  align-self: start;
  background: unset;
  background-color: white;
  color: ${({ theme }) => theme.color.mediumDarkText};
  ${ColumnFlexStyle}
`;

export const RightBubbleContainer = styled(LeftBubbleContainer)`
  align-items: flex-end;
`;

export const RightBubble = styled(Bubble)`
  --_d: 100%;
  border-right: var(--t) solid #0000;
  margin-left: var(--t);
  align-self: end;
  // background: linear-gradient(70deg, #fe6d00, #1384c5) border-box;
  background-color: ${({ theme }) => theme.color.deepBlue};
`;

export const LeftBubbleAuthorText = styled(DarkExtraSmallText)`
  padding-left: 10px;
  font-size: 13px;
`;

export const LeftBubbleMetadataContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: white;
  border: 1px dotted ${({ theme }) => theme.color.muiInput};
  margin-top: -6px;
  margin-left: 14px;
  border-radius: 0px 0px 10px 10px;
  cursor: pointer;
  padding: 5px 15px;
`;

export const RightBubbleMetadataContainer = styled(LeftBubbleMetadataContainer)`
  margin-right: 14px;
  background-color: ${({ theme }) => theme.color.turquoise};
  color: white;
`;

export const RightBubbleAuthorText = styled(LeftBubbleAuthorText)`
  padding-right: 10px;
`;

export const BubbleInfoTextContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.start ? 'flex-start' : 'flex-end')};
  align-items: center;
`;

export const StatusChangeDetailsContainer = styled(CenteredDiv)`
  background-color: white;
  margin-bottom: -10px;
  margin-top: ${(props) => (props.isNextToBanner ? '-10px' : '30px')};
  width: fit-content;
  ${AutoHorizontalMargins}
  padding: 7px 60px;
  border-radius: ${({ theme }) => theme.spacing.mlBorderRadius}px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.color.lightPrimary};
  z-index: 2;
  ${EssGapStyle}
`;

export const StatusChangeDetailsIconContainer = styled.div`
  height: 25px;
  width: 25px;
  padding: 2px;
  border-radius: 50%;
  ${CenterFlexStyle}
  background-color: white;
  color: ${({ theme }) => theme.color.lightPrimary};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.color.lightPrimary};
    color: white;
  }
  border: ${(props) =>
    props.selected && `1px solid ${props.theme.color.lightPrimary}`};
`;

export const ExpandedStatusChangeDetailsContainer = styled(EGCCD)`
  background-color: white;
  width: 100%;
  margin-top: -18px;
  border: 1px solid ${({ theme }) => theme.color.lightPrimary};
  border-radius: ${({ theme }) => theme.spacing.mlBorderRadius}px;
  padding: 20px 0px;
  ${SMGapStyle}
`;

export const ExpandedStatusChangeDetailsContentSection = styled.div`
  ${ColumnFlexStyle}
  align-items: flex-start;
  width: calc(100% - 40px);
  ${EssGapStyle}
`;

export const ExpandedStatusChangeDetailsHeaderSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${SmallGapStyle}
`;

export const ExpandedStatusChangeDetailsHeaderText = styled(
  DarkExtraSmallText,
)``;

export const ExpandedStatusChangeDetailsSubtextSection = styled(
  ExpandedStatusChangeDetailsHeaderSection,
)`
  flex-direction: column;
  ${EssGapStyle}
`;

export const ExpandedStatusChangeDetailsSubtext = styled(
  StartAlignedMediumDarkLargeTinyText,
)`
  line-height: 1.3;
  width: 100%;
  display: inline-block;
`;

export const TranscriptMessageContainer = styled.div`
  margin: 7px 0px;
`;

export const StatusChangeBannerContainer = styled.div`
  margin: 40px 15px;
  margin-bottom: ${(props) => (props.isBottom ? '-2px' : '30px')};
  margin-top: ${(props) => (props.isNextToBanner ? '-30px' : '30px')};
`;

export const StatusChangeLine = styled.div`
  position: relative;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 4px dotted
      ${(props) =>
        props.status === interceptedChatStatusKey ||
        props.status === chatResumedMessageContent ||
        props.status === lastFollowUpMessageContent ||
        props.status === newCallChatEvent
          ? props.theme.color.darkBlue
          : props.status === endOfExchangeChatMessageContent ||
            props.status === staleMessageContent ||
            props.status === callEndedChatEvent
          ? props.theme.color.defaultGray
          : props.status === disengagedChatStatusKey
          ? props.theme.color.lightError
          : props.status === appointmentBookedStatus ||
            props.status === appointmentDepositRequiredStatus
          ? props.theme.color.brightGreen
          : props.status === appointmentMonitoringStatus ||
            props.status === appointmentErrorStatus
          ? props.theme.color.lightOrange
          : null};
  }
`;

export const StatusChangeTextContainer = styled.div`
  padding: 7px 30px;
  background-color: ${(props) =>
    props.status === interceptedChatStatusKey ||
    props.status === chatResumedMessageContent ||
    props.status === lastFollowUpMessageContent ||
    props.status === newCallChatEvent
      ? props.theme.color.darkBlue
      : props.status === endOfExchangeChatMessageContent ||
        props.status === staleMessageContent ||
        props.status === callEndedChatEvent
      ? props.theme.color.defaultGray
      : props.status === disengagedChatStatusKey
      ? props.theme.color.lightError
      : props.status === appointmentBookedStatus ||
        props.status === appointmentDepositRequiredStatus
      ? props.theme.color.brightGreen
      : props.status === appointmentMonitoringStatus ||
        props.status === appointmentErrorStatus
      ? props.theme.color.lightOrange
      : null};
  border-radius: 20px;
  z-index: 100;
  position: relative;
  width: fit-content;
  min-width: 165px;
  ${AutoHorizontalMargins}
  ${CenterFlexStyle}
  ${SmallGapStyle}
`;

export const StatusChangeText = styled(WhiteExtraSmallText)`
  font-size: 16px;
  ${(props) =>
    props.status === appointmentBookedStatus
      ? css`
          &:hover {
            color: ${({ theme }) => theme.color.gold};
            ${UnderlineStyle}
            text-underline-offset: 4px;
            cursor: pointer;
          }
        `
      : props.status === appointmentMonitoringStatus ||
        props.status === appointmentErrorStatus
      ? css`
          color: ${({ theme }) => theme.color.offwhite};
        `
      : null}
  ${ToggleHoveredTextDisplayStyle}
`;
