import { useMutation } from '@apollo/client';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import { useMyLocations } from '../../../api/hooks/enterprise';
import { EDIT_BUSINESS_USER_LOCATION_VIEW } from '../../../api/mutations/users';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import AddLocationModal from '../../../components/Modals/AddLocationModal';
import Header from '../../../components/NavBar/Header';
import Snackbar from '../../../components/Snackbar';
import {
  AddIcon,
  ColumnCenteredDiv,
  CompleteButtonCheckmarkIcon,
  ContentContainer,
  LightDarkSmallText,
  MediumDarkMLText,
  MediumDarkMediumText,
  MediumPrimaryButton,
  NoDataIcon,
  PageContainer,
  PageTitleText,
  SmallSecondaryButton,
  StartAlignedLightDarkExtraSmallText,
  StartAlignedMediumDarkSMText,
} from '../../../styles/shared-styled-components';
import {
  justCompletedOnboardingCookieKey,
  justCompletedOnboardingSnackbarMessage,
} from '../../../utils/auth';
import {
  ascendingKey,
  dateInputType,
  descendingDateLaunchedSortKey,
  descendingEbitdaSortKey,
  descendingKey,
  descendingRevenueSortKey,
  descendingValuationSortKey,
  floatInputType,
  selectActionKey,
  stagingFilterConversionDelay,
  unselectActionKey,
} from '../../../utils/constants';
import { getDefaultSortValueForEmptyField } from '../../../utils/data';
import { getFormattedFullNameFromUser } from '../../../utils/name';
import { formatNumber } from '../../../utils/numbers';
import { getUserBusiness, locationIdSelectedKey } from '../../../utils/user';
import { DataDiv } from '../../shared-page-styles';
import {
  CardButtonContainer,
  EmptyLocationsContainer,
  LocationCard,
  LocationCardBox,
  LocationCardContainer,
  LocationHeaderTextContainer,
  LocationNameTextContainer,
  LocationsContainer,
  LocationsTitleContainer,
  MapImage,
  NoLocationsCreatedContainer,
} from './styled';

const getSortedLocations = (locations, sortField) => {
  let updatedSortedLocations;

  if (sortField.includes('ebitda')) {
    if (sortField === descendingEbitdaSortKey) {
      updatedSortedLocations = sortBy(locations, (l) =>
        l.ebitda
          ? parseFloat(l.ebitda)
          : getDefaultSortValueForEmptyField(descendingKey, floatInputType),
      ).reverse();
    } else {
      updatedSortedLocations = sortBy(locations, (l) =>
        l.ebitda
          ? parseFloat(l.ebitda)
          : getDefaultSortValueForEmptyField(ascendingKey, floatInputType),
      );
    }
  } else if (sortField.includes('revenue')) {
    if (sortField === descendingRevenueSortKey) {
      updatedSortedLocations = sortBy(locations, (l) =>
        l.revenue
          ? parseFloat(l.revenue)
          : getDefaultSortValueForEmptyField(descendingKey, floatInputType),
      ).reverse();
    } else {
      updatedSortedLocations = sortBy(locations, (l) =>
        l.revenue
          ? parseFloat(l.revenue)
          : getDefaultSortValueForEmptyField(ascendingKey, floatInputType),
      );
    }
  } else if (sortField.includes('valuation')) {
    if (sortField === descendingValuationSortKey) {
      updatedSortedLocations = sortBy(locations, (l) =>
        l.valuation
          ? parseFloat(l.valuation)
          : getDefaultSortValueForEmptyField(descendingKey, floatInputType),
      ).reverse();
    } else {
      updatedSortedLocations = sortBy(locations, (l) =>
        l.valuation
          ? parseFloat(l.valuation)
          : getDefaultSortValueForEmptyField(ascendingKey, floatInputType),
      );
    }
  } else if (sortField.includes('date')) {
    if (sortField === descendingDateLaunchedSortKey) {
      updatedSortedLocations = sortBy(locations, (l) => {
        l.launchedAt
          ? new Date(l.launchedAt)
          : getDefaultSortValueForEmptyField(descendingKey, dateInputType);
      }).reverse();
    } else {
      updatedSortedLocations = sortBy(locations, (l) => {
        l.launchedAt
          ? new Date(l.launchedAt)
          : getDefaultSortValueForEmptyField(ascendingKey, dateInputType);
      });
    }
  }

  return updatedSortedLocations;
};

const HeadOffice = () => {
  const navigate = useNavigate();
  const {
    user,
    refetchUser,
    locationIdSelected,
    drawerOpen,
    drawerExpanded,
    cookies,
    removeCookie,
  } = useContext(BaseContext);

  const username = getFormattedFullNameFromUser(user);
  const business = getUserBusiness(user);
  const businessName = business?.name;
  const businessHqAddress = business?.hqAddress;

  const {
    locations,
    refetch: refetchLocations,
    loading: locationsLoading,
  } = useMyLocations();

  const [tab, setTab] = useState(0);
  const [stagingSearchFilter, setStagingSearchFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState();
  const [sortField, setSortField] = useState(descendingEbitdaSortKey);
  const [sortedLocations, setSortedLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentLocationIdSelected, setCurrentLocationIdSelected] =
    useState(locationIdSelected);
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);

  const totalNumBusinessLocations = business?.numLocations;

  const [editBusinessUserLocationViewMutation] = useMutation(
    EDIT_BUSINESS_USER_LOCATION_VIEW,
  );

  const updateSearchFilter = (stagingSearchFilterValue) => {
    setSearchFilter(stagingSearchFilterValue);
  };

  const justOnboardedCookie = cookies[justCompletedOnboardingCookieKey];
  const [showConfetti, setShowConfetti] = useState(false);

  const stopConfetti = () => {
    setShowConfetti(false);
    removeCookie(justCompletedOnboardingCookieKey);
  };

  useEffect(() => {
    async function stopConfettiOnTimer() {
      setTimeout(stopConfetti, 3000);
    }
    if (justOnboardedCookie) {
      setShowConfetti(true);
      setSnackbarMessage(justCompletedOnboardingSnackbarMessage);
      stopConfettiOnTimer();
    }
  }, [justOnboardedCookie]);

  useEffect(() => {
    setCurrentLocationIdSelected(locationIdSelected);
  }, [locationIdSelected]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateSearchFilter(stagingSearchFilter);
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingSearchFilter]);

  useEffect(() => {
    if (searchFilter) {
      const formattedSearchFilter = searchFilter.toLowerCase();

      const matchingLocations = locations.filter((l) => {
        return (
          l.name?.toLowerCase().includes(formattedSearchFilter) ||
          l.owner?.toLowerCase().includes(formattedSearchFilter) ||
          l.state?.toLowerCase().includes(formattedSearchFilter)
        );
      });

      const sortedMatchingLocations = getSortedLocations(
        matchingLocations,
        sortField,
      );
      setSortedLocations(sortedMatchingLocations);
    } else {
      const updatedSortedLocations = getSortedLocations(locations, sortField);
      setSortedLocations(updatedSortedLocations);
    }
  }, [searchFilter, locations]);

  useEffect(() => {
    if (locations.length && sortField) {
      const locationsToUse = stagingSearchFilter ? sortedLocations : locations;
      const updatedSortedLocations = getSortedLocations(
        locationsToUse,
        sortField,
      );
      setSortedLocations(updatedSortedLocations);
    }
  }, [sortField, locations]);

  const onSelectLocation = (locationId, action) => {
    setLoading(true);
    editBusinessUserLocationViewMutation({
      variables: {
        locationId,
        action,
      },
      onCompleted: async (data) => {
        const success = data.editBusinessUserLocationView;

        if (success === true) {
          if (action === selectActionKey) {
            await refetchUser();
            localStorage.setItem(locationIdSelectedKey, locationId);
            navigate(Paths.overview);
          } else {
            await refetchUser();
            localStorage.setItem(locationIdSelectedKey, '');
            setCurrentLocationIdSelected();
          }
        } else {
          setSnackbarMessage('Error modifying location, refresh and try again');
        }

        setLoading(false);
      },
    });
  };

  if (loading || locationsLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    locations && (
      <>
        <ReactConfetti
          size={8}
          shape='circle'
          numberOfPieces={showConfetti ? 300 : 0}
        />
        <Header onRemoveLocation={() => setCurrentLocationIdSelected()} />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
          >
            <ColumnCenteredDiv>
              <PageTitleText>{businessName} - Head Office</PageTitleText>
            </ColumnCenteredDiv>
            <DataDiv>
              <LocationsContainer>
                {locationsLoading ? (
                  <LoadingIndicator />
                ) : locations?.length ? (
                  <>
                    <LocationsTitleContainer>
                      <StartAlignedMediumDarkSMText>
                        Locations ({locations?.length}/
                        {formatNumber(totalNumBusinessLocations)} onboarded)
                      </StartAlignedMediumDarkSMText>
                    </LocationsTitleContainer>
                    {tab === 0 ? (
                      <LocationCardBox>
                        <LocationCardContainer>
                          {sortedLocations.length === 0 ? (
                            <EmptyLocationsContainer>
                              <NoDataIcon />
                              <MediumDarkMLText>No results</MediumDarkMLText>
                            </EmptyLocationsContainer>
                          ) : (
                            <>
                              {sortedLocations.map((l) => {
                                return (
                                  <LocationCard>
                                    <LocationHeaderTextContainer>
                                      <LocationNameTextContainer>
                                        <StartAlignedMediumDarkSMText>
                                          {l.nickname || l.name}
                                        </StartAlignedMediumDarkSMText>
                                        <StartAlignedLightDarkExtraSmallText>
                                          {l.address}
                                        </StartAlignedLightDarkExtraSmallText>
                                      </LocationNameTextContainer>
                                      <LightDarkSmallText>
                                        {l.state}
                                      </LightDarkSmallText>
                                    </LocationHeaderTextContainer>
                                    <CardButtonContainer>
                                      {currentLocationIdSelected === l.id ? (
                                        <SmallSecondaryButton
                                          onClick={() =>
                                            onSelectLocation(
                                              l.id,
                                              unselectActionKey,
                                            )
                                          }
                                          removeMargins
                                          largePadding
                                          addGap
                                        >
                                          Currently viewing
                                          <CompleteButtonCheckmarkIcon small />
                                        </SmallSecondaryButton>
                                      ) : (
                                        <SmallSecondaryButton
                                          onClick={() =>
                                            onSelectLocation(
                                              l.id,
                                              selectActionKey,
                                            )
                                          }
                                          removeMargins
                                          largePadding
                                        >
                                          Select location
                                        </SmallSecondaryButton>
                                      )}
                                    </CardButtonContainer>
                                  </LocationCard>
                                );
                              })}
                            </>
                          )}
                        </LocationCardContainer>
                      </LocationCardBox>
                    ) : (
                      <MapImage src='https://medacquirestorage.nyc3.cdn.digitaloceanspaces.com/assets/images/genericmap.jpeg' />
                    )}
                  </>
                ) : (
                  <NoLocationsCreatedContainer>
                    <MediumDarkMediumText>
                      Add a location to begin tracking metrics
                    </MediumDarkMediumText>
                    <MediumPrimaryButton
                      onClick={() => setAddLocationModalOpen(true)}
                    >
                      <AddIcon /> Add location
                    </MediumPrimaryButton>
                  </NoLocationsCreatedContainer>
                )}
              </LocationsContainer>
            </DataDiv>
          </ContentContainer>
        </PageContainer>
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
        />
        <AddLocationModal
          isOpen={addLocationModalOpen}
          onClose={() => setAddLocationModalOpen(false)}
          onAdd={(sentInviteToOwner) => {
            refetchLocations();
            const snackbarText = `Added location${
              sentInviteToOwner ? ' and sent invite to location admin' : ''
            }`;
            setSnackbarMessage(snackbarText);
          }}
        />
      </>
    )
  );
};

export default HeadOffice;
