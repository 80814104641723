import React from 'react';
import Paths from '../../../Paths';
import { NoUnderlineLink } from '../../../styles/shared-styled-components';
import { DemoButton } from '../styles/style-Homepage';
import {
  PricingHeaderText,
  PricingPageContainer,
  TierPerkDescription,
  TierPricingCard,
  TierPricingCardsContainer,
  TierPricingName,
} from '../styles/style-Pricing';

const Pricing = () => {
  const pricingData = [
    {
      tier: 'Starter',
      description: 'For smaller businesses just starting out with Ava',
      messages: '2,500 messages',
      perks: [
        'Complain To Jeff',
        'Detailed Statistics',
        'Monthly Strategy Call',
        'Steve, the AI assistant',
      ],
      price: 250,
    },
    {
      tier: 'Enterprise',
      description: 'For multi-location, franchises, and scaling medspas',
      messages: '10,000 messages',
      perks: [
        'Complain To Jeff',
        'Detailed Statistics',
        'Monthly Strategy Call',
        'Steve, the AI assistant',
      ],
      price: 1000,
    },
    {
      tier: 'Whitelabel',
      description:
        'For marketing agencies, business consultants, and educators',
      messages: '10,000 messages',
      perks: [
        'Complain To Jeff',
        'Detailed Statistics',
        'Monthly Strategy Call',
        'Steve, the AI assistant',
      ],
      price: 1000,
    },
  ];

  return (
    <div id='pricing'>
      <PricingPageContainer>
        <PricingHeaderText>PRICING</PricingHeaderText>
        <p />

        <TierPricingCardsContainer>
          {pricingData.map((data, index) => (
            <NoUnderlineLink href={Paths.calendlyLink}>
              <TierPricingCard key={index}>
                <TierPricingName>{data.tier}</TierPricingName>
                <TierPerkDescription>{data.description}</TierPerkDescription>
                <DemoButton href={Paths.calendlyLink}>Get a quote →</DemoButton>
              </TierPricingCard>
            </NoUnderlineLink>
          ))}
        </TierPricingCardsContainer>
      </PricingPageContainer>
    </div>
  );
};

export default Pricing;
