import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import styled from 'styled-components';
import {
  AutoHorizontalMargins,
  CenterFlexStyle,
  ChatIcon,
  ColumnCenteredDiv,
  MarketingIcon,
  SMMIconSize,
  SMMMIconSize,
  SmallGapStyle,
  SmallText,
  ViewCalendarIcon,
} from '../../../styles/shared-styled-components';

export const DataContainer = styled(ColumnCenteredDiv)`
  width: 100%;
  ${AutoHorizontalMargins}
`;

export const AnalyticsPanelTitleContainer = styled.div`
  position: absolute;
  top: -16px;
  left: 23px;
  background-color: transparent;
  z-index: 1000;
`;

export const AnalyticsPanelTitleText = styled(SmallText)`
  ${CenterFlexStyle}
  ${SmallGapStyle}
  border-radius: 4px;
  background-color: white;
  padding: 0px 10px;
`;

export const AppointmentsIcon = styled(ViewCalendarIcon)`
  ${SMMIconSize}
  margin-right: 1px;
`;

export const RevenueIcon = styled(MonetizationOnRoundedIcon)`
  ${SMMMIconSize}
  color: ${({ theme }) => theme.color.primary};
  margin-left: -4px;
  margin-right: -2px;
`;

export const ChatsIcon = styled(ChatIcon)`
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.color.primary};
  margin-left: 0px;
  margin-right: 2px;
`;

export const CampaignIcon = styled(MarketingIcon)`
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.color.primary};
  margin-right: 3px;
  margin-left: 2px;
`;

export const DateFilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  ${AutoHorizontalMargins}
`;
