import { useApolloClient } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../../Paths';
import { useMyCampaigns } from '../../../../api/hooks/campaigns';
import { getTypesenseClient } from '../../../../api/typesense/typesense-client';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Header from '../../../../components/NavBar/Header';
import Snackbar from '../../../../components/Snackbar';
import {
  ColumnCenteredDiv,
  ContentContainer,
  DarkLargeText,
  ExtraSmallLightPrimaryCopyIcon,
  PageContainer,
  PrimaryButton,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import { dropdownInputType } from '../../../../utils/constants';
import { baseClientSearchParams } from '../../../../utils/filters';
import { copyTextToClipboard } from '../../../../utils/string';
import {
  getUserLocationId,
  getUserTypesenseApiKey,
} from '../../../../utils/user';
import {
  DropDownMenu,
  EmptyGoalsContainer,
  GoalsIconContainer,
  GoalsSubtitleText,
  GoalsTitleText,
  LargeGoalsIcon,
  PipelineClient,
  PipelineClientContacted,
  PipelineClientContainer,
  PipelineClientName,
  PipelineClientTooltips,
  PipelineColumn,
  PipelineColumnHeader,
  PipelineColumnHeaderSubtext,
  PipelineColumnHeaderTitle,
  PipelineContainer,
  PipelineDropDownContainer,
  PipelineFilterResults,
  PipelineFiltersContainer,
  PipelineSearchBox,
  PiplineCampaignFilter,
  TopSectionContainer,
} from './styled';

const testClients = [
  {
    name: 'Priyanka lal',
    spending: 1000,
    lastContacted: '',
    status: 'client',
  },
  {
    name: 'Eric James',
    spending: 1000,
    lastContacted: '2021-10-10',
    status: 'client',
  },
  {
    name: 'James White',
    spending: 1000,
    lastContacted: '',
    status: 'client',
  },
  {
    name: 'Harry',
    spending: 1000,
    lastContacted: '2021-10-10',
    status: 'lead',
  },
  {
    name: 'L. James',
    spending: 1000,
    lastContacted: '',
    status: 'lead',
  },
  {
    name: 'Jeff Luk',
    spending: 1000,
    lastContacted: '2021-10-10',
    status: 'client',
  },
  {
    name: 'Barrack O.',
    spending: 1000,
    lastContacted: '',
    status: 'lead',
  },
  {
    name: 'Yusuf Khaled',
    spending: 1000,
    lastContacted: '',
    status: 'member',
  },
  {
    name: 'Donald T.',
    spending: 1000,
    lastContacted: '',
    status: 'member',
  },
  {
    name: 'Dwayne J.',
    spending: 1000,
    lastContacted: '',
    status: 'lead',
  },
  {
    name: 'Eric Green',
    spending: 1000,
    lastContacted: '2021-10-10',
    lastVisit: '2023-10-08',
    status: 'member',
  },
  {
    name: 'Chris Johnson',
    spending: 1000,
    lastContacted: '2021-10-10',
    lastVisit: '2022-10-08',
    status: 'member',
  },
];

const Pipeline = () => {
  const navigate = useNavigate();
  const { cache } = useApolloClient();
  const { user, drawerOpen, drawerExpanded, fullyConnected, width } =
    useContext(BaseContext);
  const locationId = getUserLocationId(user);

  const fixedInputWidth = width > 1600 ? 180 : width > 1000 ? 150 : 130;

  // CAMPAIGNS
  const {
    campaigns,
    loading: campaignsLoading,
    refetch: refetchCampaigns,
  } = useMyCampaigns({});

  useEffect(() => {
    refetchCampaigns();
  }, [refetchCampaigns]);

  const campaignNames = campaigns
    ? campaigns.map((campaign) => campaign.name)
    : [];
  const dropDownOptions = campaignNames.map((campaignName) => {
    return { key: campaignName, label: campaignName };
  });

  // CLIENTS
  // const [clients, setClients] = useState([]);
  const [numResults, setNumResults] = useState();
  const [hasFetched, setHasFetched] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState({});

  const typesenseApiKey = getUserTypesenseApiKey(user);
  const typesenseClient = getTypesenseClient(typesenseApiKey);

  const baseClientFilter = baseClientSearchParams?.filters || {};

  const members = testClients.filter((client) => client.status === 'member');
  const clients = testClients.filter(
    (client) => client.status === 'client' || client.status === 'member',
  );
  const leads = testClients.filter((client) => client.status === 'lead');
  const uncontacted = testClients.filter(
    (client) => client.lastContacted === '',
  );
  const hasVisited = testClients.filter((client) => !!client.lastVisit);

  const labels = ['Uncontacted', 'Clients', 'Leads', 'Members', 'Has Visited'];
  const labelMapping = {
    Clients: clients,
    Members: members,
    Leads: leads,
    Uncontacted: uncontacted,
    'Has Visited': hasVisited,
  };

  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const OnCreateCampaign = () => {};

  const onClickNavigateToGoal = (goalId) => {
    navigate(Paths.editGoal.replace(':goalId', goalId));
  };

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          {loading ? (
            <LoadingIndicator fullScreen />
          ) : (
            <ColumnCenteredDiv>
              <DarkLargeText>Pipeline</DarkLargeText>
              {campaigns?.length > 0 || true ? (
                <>
                  <PipelineFiltersContainer>
                    <PiplineCampaignFilter>
                      <PipelineDropDownContainer>
                        <DropDownMenu
                          type={dropdownInputType}
                          options={dropDownOptions}
                          value={dropDownOptions[0]}
                          label={'Campaign'}
                          fixedWidth={fixedInputWidth}
                          onChange={(e) => console.log(e)}
                        />
                        <PipelineFilterResults>
                          1,370 Results
                        </PipelineFilterResults>
                      </PipelineDropDownContainer>
                    </PiplineCampaignFilter>
                    <PiplineCampaignFilter>
                      &nbsp;&nbsp;&nbsp;
                      <PipelineSearchBox value={''}></PipelineSearchBox>
                    </PiplineCampaignFilter>
                  </PipelineFiltersContainer>
                  <PipelineContainer>
                    {labels.map((label) => (
                      <PipelineColumn>
                        <PipelineColumnHeader>
                          <PipelineColumnHeaderTitle>
                            {label}
                          </PipelineColumnHeaderTitle>
                          <PipelineColumnHeaderSubtext>
                            {labelMapping[label].length} Users
                          </PipelineColumnHeaderSubtext>
                        </PipelineColumnHeader>
                        <PipelineClientContainer>
                          {labelMapping[label].map((client) => (
                            <PipelineClient>
                              <PipelineClientName>
                                {client.name}
                              </PipelineClientName>
                              <PipelineClientContacted>
                                Last Contacted:{' '}
                                {client.lastContacted === ''
                                  ? 'Never'
                                  : client.lastContacted}
                              </PipelineClientContacted>
                              <PipelineClientTooltips>
                                <Tooltip
                                  title={
                                    <TooltipTitleText>
                                      Copy ID ({''})
                                    </TooltipTitleText>
                                  }
                                  placement='bottom'
                                >
                                  <ExtraSmallLightPrimaryCopyIcon
                                    onClick={() => {
                                      copyTextToClipboard('');
                                      setSnackbarMessage('Copied');
                                    }}
                                  />
                                </Tooltip>
                              </PipelineClientTooltips>
                            </PipelineClient>
                          ))}
                        </PipelineClientContainer>
                      </PipelineColumn>
                    ))}
                  </PipelineContainer>
                </>
              ) : (
                <TopSectionContainer useDynamicHeight>
                  <EmptyGoalsContainer>
                    <GoalsIconContainer>
                      <LargeGoalsIcon />
                    </GoalsIconContainer>
                    <ColumnCenteredDiv>
                      <GoalsTitleText>
                        A client pipeline view to kickstart your marketing goals
                      </GoalsTitleText>
                      <GoalsSubtitleText>
                        Create your first campaign to get started
                      </GoalsSubtitleText>
                    </ColumnCenteredDiv>
                    <PrimaryButton onClick={OnCreateCampaign}>
                      Create campaign
                    </PrimaryButton>
                  </EmptyGoalsContainer>
                </TopSectionContainer>
              )}
            </ColumnCenteredDiv>
          )}
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Pipeline;
