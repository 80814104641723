import gql from 'graphql-tag';

export const GET_MY_USER = gql`
  query getMyUser($email: String) {
    getMyUser(email: $email) {
      id
      email
      firstName
      lastName
      phone
      enterprise {
        business {
          id
          name
          type
          numLocations
          quantityOfLocationsUsing
          website
          hqAddress
        }
        location {
          id
          name
          timezone
          connectedApps {
            name
            initialized
          }
          address
          addressInstructions
          isVirtual
          lat
          lng
          postalCode
          contactPhoneNumber
          businessPhoneNumberForClient
          messagingPlatform
          calendarPlatform
          typesenseApiKey
          isPaused
          agents {
            id
            name
            productKey
            userId
            status
            createdAt
          }
        }
      }
      hasOnboarded
      role
      createdAt
    }
  }
`;

export const GET_MY_LOCATION_USERS = gql`
  query getMyLocationUsers {
    getMyLocationUsers {
      id
      firstName
      lastName
      email
      role
      enterprise {
        business {
          id
        }
      }
    }
  }
`;

export const GET_MULTI_LOCATION_USERS = gql`
  query getMultiLocationUsers($locationId: String) {
    getMultiLocationUsers(locationId: $locationId) {
      user {
        id
        firstName
        lastName
        email
        role
      }
      assignedToLocation
    }
  }
`;
