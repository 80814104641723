import { useMutation } from '@apollo/client';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../../Paths';
import {
  useContactTags,
  useMyCampaigns,
} from '../../../../api/hooks/campaigns';
import {
  DELETE_SMART_LIST,
  DELETE_UNCONTACTED_CAMPAIGN_MEMBERS,
} from '../../../../api/mutations/campaign';
import { CREATE_SCRIPT } from '../../../../api/mutations/content';
import {
  ATTACH_EXISTING_PROMO_TO_CAMPAIGN,
  CREATE_PROMO,
  DELETE_PROMO,
  REPLACE_EXISTING_TEMPLATE_OR_SCRIPT_FOR_CAMPAIGN,
} from '../../../../api/mutations/enterprise';
import OptionsFilterDropdown from '../../../../components/Analytics/OptionsFilterDropdown/OptionsFilterDropdown';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import EmptyCard from '../../../../components/Micro/EmptyCard';
import CreateCampaignModal from '../../../../components/Modals/CreateCampaignModal';
import DefaultConfirmationModal from '../../../../components/Modals/DefaultConfirmationModal';
import DeleteCampaignModal from '../../../../components/Modals/DeleteCampaignModal';
import EditCampaignModal from '../../../../components/Modals/EditCampaignModal/EditCampaignModal';
import EditCampaignStatusModal from '../../../../components/Modals/EditCampaignStatusModal/EditCampaignStatusModal';
import EditTemplateTypeModal from '../../../../components/Modals/EditTemplateTypeModal';
import FilterClientsModal from '../../../../components/Modals/FilterClientsModal';
import SingleNodeTemplateModal from '../../../../components/Modals/SingleNodeTemplateModal';
import Header from '../../../../components/NavBar/Header';
import PopperMenu from '../../../../components/PopperMenu';
import Snackbar from '../../../../components/Snackbar';
import MetaSetter from '../../../../components/Utils/MetaSetter';
import {
  AbsoluteLoadingIndicatorContainer,
  AgentIconButtonContainer,
  AgentIconInButton,
  BaseCard,
  CardPageSection,
  CardPageSectionActionsContainer,
  CenteredDiv,
  ColumnCenteredDiv,
  ContentContainer,
  DeleteIcon,
  EditIcon,
  EndActionsContainer,
  ExtraSmallSecondaryButton,
  FlexDiv,
  MediumDarkTextSpan,
  MediumGapColumnCenteredDiv,
  MenuItem,
  PageContainer,
  PageTitleText,
  PrimaryTextSpan,
  SecondaryRemoveIcon,
  SmallAddIcon,
  SmallLightDarkTextSpan,
  SmallMenuItemText,
  SmallMenuItemTitleContainer,
  SmallMenuItemTitleText,
  SmallPrimaryCopyIcon,
  SmallText,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  audienceSearchParamsCookieKey,
  campaignSortFieldCookieKey,
  campaignStatusLabelMap,
  campaignTypeLabelMap,
  defaultSortOptions,
  liveKey,
  newAppointmentsCampaignTypeKey,
  scheduleFillUpCampaignTypeKey,
  scriptTemplateType,
  serviceFollowUpCampaignTypeKey,
  singleNodeTemplateType,
  superAdminRole,
  textSeparatorChar,
  updatedAtFieldKey,
} from '../../../../utils/constants';
import { getCookieExpiryObject } from '../../../../utils/date';
import {
  audienceClientSearchParams,
  getCampaignFilterString,
  getClientFilterMapFromString,
  updateSearchFiltersCookie,
} from '../../../../utils/filters';
import { getEditPromoUrl, getEditScriptUrl } from '../../../../utils/routing';
import { copyTextToClipboard } from '../../../../utils/string';
import { getUserLocationId } from '../../../../utils/user';
import {
  ActionIconContainer,
  ActionIncompleteIcon,
  ActionsContainer,
  AgentTextContainer,
  CampaignActions,
  CampaignClientsContainer,
  CampaignHeader,
  CampaignParagraphText,
  CampaignTitleContainer,
  CampaignTitleText,
  ContactsIcon,
  LaunchCampaignIcon,
  PauseCampaignIcon,
  PromoIcon,
  ScriptIcon,
  SmartListActionIcon,
  StatusChip,
  TemplatesIcon,
} from './styled';

const campaignTypeDescriptionMap = {
  [newAppointmentsCampaignTypeKey]:
    'will engage contacts to book new appointments',
  [serviceFollowUpCampaignTypeKey]:
    'will follow-up with clients after they come in',
};

const getCampaignDescription = (
  type,
  targetClients,
  targetLeads,
  newLeadMessageDelay,
) => {
  let prefixString;
  let contactString;
  let suffixString;
  const clientString = 'existing clients';
  let leadString;

  if (targetLeads) {
    leadString = `new leads${
      newLeadMessageDelay
        ? ` (after ${newLeadMessageDelay} day${
            newLeadMessageDelay !== 1 ? 's' : ''
          } of inactivity) `
        : ''
    }`;
  }

  if (targetClients && targetLeads) {
    contactString = `${clientString} and ${leadString}`;
  } else if (targetClients) {
    contactString = clientString;
  } else {
    contactString = leadString;
  }

  if (type === newAppointmentsCampaignTypeKey) {
    prefixString = 'will engage';
    suffixString = 'to book new appointments';
  } else if (type === serviceFollowUpCampaignTypeKey) {
    prefixString = 'will follow-up with';
    suffixString = 'after they come in';
  } else if (type === scheduleFillUpCampaignTypeKey) {
    prefixString = 'will engage';
    suffixString = 'to fill up your schedule';
  }

  return `${prefixString} ${contactString} ${suffixString}`;
};

const cookieExpiryObject = getCookieExpiryObject();

const Campaigns = () => {
  const navigate = useNavigate();

  const [createPromoMutation] = useMutation(CREATE_PROMO);
  const [deletePromoMutation] = useMutation(DELETE_PROMO);
  const [attachExistingPromoMutation, { loading: attachExistingPromoLoading }] =
    useMutation(ATTACH_EXISTING_PROMO_TO_CAMPAIGN);
  const [
    replaceExistingTemplateOrScriptForCampaignMutation,
    { loading: replaceExistingTemplateOrScriptForCampaignLoading },
  ] = useMutation(REPLACE_EXISTING_TEMPLATE_OR_SCRIPT_FOR_CAMPAIGN);
  const [createScriptMutation] = useMutation(CREATE_SCRIPT);
  const [
    deleteUncontactedCampaignMembersMutation,
    { loading: deleteUncontactedLoading },
  ] = useMutation(DELETE_UNCONTACTED_CAMPAIGN_MEMBERS);
  const [deleteSmartListMutation, { loading: deleteSmartListLoading }] =
    useMutation(DELETE_SMART_LIST);

  const { user, drawerOpen, drawerExpanded, cookies, setCookie } =
    useContext(BaseContext);
  const locationId = getUserLocationId(user);
  const role = user?.role;

  const contentContainerRef = useRef();

  const storedClientSearchParams = cookies[audienceSearchParamsCookieKey];
  const clientSearchParamsFromCookie = storedClientSearchParams
    ? storedClientSearchParams
    : audienceClientSearchParams;

  const campaignSortFieldValue =
    cookies[campaignSortFieldCookieKey] || updatedAtFieldKey;

  const {
    campaigns,
    loading: campaignsLoading,
    refetch: refetchCampaigns,
  } = useMyCampaigns({});
  const campaignNameMap = {};
  const campaignsParsedFiltersMap = {};
  campaigns?.map((c) => {
    campaignNameMap[c.id] = c.name;
    campaignsParsedFiltersMap[c.id] = c.smartList.filter
      ? getClientFilterMapFromString(c.smartList.filter)
      : null;
  });

  const { tags, loading: tagsLoading } = useContactTags();

  const [singleNodeTemplateModalOpen, setSingleNodeTemplateModalOpen] =
    useState(false);
  const [templateToEditId, setTemplateToEditId] = useState();
  const [smartListModalOpen, setSmartListModalOpen] = useState(false);
  const [selectedSmartListCampaignFilter, setSelectedSmartListCampaignFilter] =
    useState('');
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editCampaignStatusModalOpen, setEditCampaignStatusModalOpen] =
    useState(false);
  const [editTemplateTypeModalOpen, setEditTemplateTypeModalOpen] =
    useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [campaignToEdit, setCampaignToEdit] = useState();
  const [createPromoLoading, setCreatePromoLoading] = useState(false);
  const [createScriptLoading, setCreateScriptLoading] = useState(false);
  const [campaignToChangeStatusFor, setCampaignToChangeStatusFor] =
    useState(null);
  const [
    deleteUncontactedMembersModalOpen,
    setDeleteUncontactedMembersModalOpen,
  ] = useState(false);
  const [deleteSmartListModalOpen, setDeleteSmartListModalOpen] =
    useState(false);
  const [sortField, setSortField] = useState(campaignSortFieldValue);
  const [promoMenuAnchorEl, setPromoMenuAnchorEl] = useState();
  const [attachExistingPromoMenuAnchorEl, setAttachExistingPromoMenuAnchorEl] =
    useState();
  const [
    attachExistingTemplateMenuAnchorEl,
    setAttachExistingTemplateMenuAnchorEl,
  ] = useState();
  const [
    attachExistingScriptMenuAnchorEl,
    setAttachExistingScriptMenuAnchorEl,
  ] = useState();
  const [templateMenuAnchorEl, setTemplateMenuAnchorEl] = useState();
  const [smartListMenuAnchorEl, setSmartListMenuAnchorEl] = useState();

  const promoMenuOpenBoolean = Boolean(promoMenuAnchorEl);
  const attachExistingPromoMenuOpenBoolean = Boolean(
    attachExistingPromoMenuAnchorEl,
  );
  const attachExistingTemplateMenuOpenBoolean = Boolean(
    attachExistingTemplateMenuAnchorEl,
  );
  const attachExistingScriptMenuOpenBoolean = Boolean(
    attachExistingScriptMenuAnchorEl,
  );
  const templateMenuOpenBoolean = Boolean(templateMenuAnchorEl);
  const smartListMenuOpenBoolean = Boolean(smartListMenuAnchorEl);

  const confirmationModalOpen =
    deleteUncontactedMembersModalOpen || deleteSmartListModalOpen;

  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (campaignSortFieldValue != null) {
      setSortField(campaignSortFieldValue);
    }
  }, [campaignSortFieldValue]);

  const onApplyFilters = (updatedFilters) => {
    setSelectedSmartListCampaignFilter(updatedFilters);
    setSnackbarMessage('Filter change staged');
  };

  const onChangeSortField = (updatedSortField) => {
    setCookie(campaignSortFieldCookieKey, updatedSortField, cookieExpiryObject);
  };

  const onCreateCampaign = () => {
    setSnackbarMessage('Campaign created');
    refetchCampaigns();
  };

  const onCreatePromo = (campaignId) => {
    setCreatePromoLoading(true);
    setPromoMenuAnchorEl();
    createPromoMutation({
      variables: {
        campaignId,
      },
      onCompleted: async (data) => {
        const createdPromo = data.createPromo;
        const id = createdPromo.id;
        await refetchCampaigns();
        setCreatePromoLoading(false);
        navigate(getEditPromoUrl(id));
      },
    });
  };

  const onDeletePromo = (id) => {
    setPromoMenuAnchorEl();
    deletePromoMutation({
      variables: {
        id,
      },
      onCompleted: async (data) => {
        const success = data.deletePromo;
        setSnackbarMessage(`Promo deleted`);
        await refetchCampaigns();
      },
    });
  };

  const onAttachExistingPromoToCampaign = (campaignId, promoId) => {
    setAttachExistingPromoMenuAnchorEl();

    attachExistingPromoMutation({
      variables: {
        campaignId,
        promoId,
      },
      onCompleted: async (data) => {
        const success = data.attachExistingPromoToCampaign;
        setSnackbarMessage(`Promo attached`);
        await refetchCampaigns();
      },
    });
  };

  const onAttachExistingTemplateOrScriptToCampaign = (
    templateId,
    scriptId,
    campaignId,
  ) => {
    setAttachExistingTemplateMenuAnchorEl();
    setAttachExistingScriptMenuAnchorEl();

    replaceExistingTemplateOrScriptForCampaignMutation({
      variables: {
        templateId,
        scriptId,
        campaignId,
      },
      onCompleted: async () => {
        setSnackbarMessage(`${templateId ? `Template` : `Script`} attached`);
        await refetchCampaigns();
      },
    });
  };

  const navigateToEditScript = (id) => {
    navigate(getEditScriptUrl(id));
  };

  const onCreateScript = () => {
    setCreateScriptLoading(true);
    setTemplateMenuAnchorEl();
    createScriptMutation({
      variables: {
        campaignId: campaignToEditId,
      },
      onCompleted: async (data) => {
        const createdScriptId = data.createScript;
        await refetchCampaigns();

        if (!createdScriptId) {
          setSnackbarMessage(
            'Unable to create script, please refresh and try again',
          );
        } else {
          setCreateScriptLoading(false);
          navigateToEditScript(createdScriptId);
        }
      },
    });
  };

  const onClickViewCampaignClients = (campaignId) => {
    const newCampaignString = getCampaignFilterString(campaignId, locationId);
    const newFilterMap = getClientFilterMapFromString(newCampaignString);

    updateSearchFiltersCookie(
      { ...clientSearchParamsFromCookie },
      newFilterMap,
      audienceSearchParamsCookieKey,
      setCookie,
    );

    navigate(Paths.audience);
  };

  const onCopyId = (id) => {
    copyTextToClipboard(id);
    setSnackbarMessage(`ID copied`);
  };

  const onDeleteUncontactedCampaignMembers = (campaignId) => {
    deleteUncontactedCampaignMembersMutation({
      variables: {
        id: campaignId,
      },
      onCompleted: async (data) => {
        const success = data.deleteUncontactedCampaignMembers;
        setDeleteUncontactedMembersModalOpen();
        if (success) {
          await refetchCampaigns();
          setSnackbarMessage(`Uncontacted members removed`);
        }
      },
    });
  };

  const onDeleteSmartList = (campaignId) => {
    deleteSmartListMutation({
      variables: {
        campaignId,
      },
      onCompleted: async (data) => {
        const success = data.deleteSmartList;
        setDeleteSmartListModalOpen();
        if (success) {
          await refetchCampaigns();
          setSnackbarMessage(`Smart list removed`);
        }
      },
    });
  };

  if (campaignsLoading || createPromoLoading || createScriptLoading) {
    return <LoadingIndicator fullScreen />;
  } else if (deleteUncontactedLoading) {
    return (
      <AbsoluteLoadingIndicatorContainer>
        <SmallText>Deleting contacts from campaign...</SmallText>
        <LoadingIndicator />
      </AbsoluteLoadingIndicatorContainer>
    );
  }

  const sortedCampaigns = sortBy(campaigns, (c) => c[sortField]).reverse();
  const campaignToEditId = campaignToEdit?.id;

  return (
    <>
      <MetaSetter
        title={`Campaigns`}
        description={`Your LiveIQ Campaigns`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          ref={contentContainerRef}
          largeBottomPadding
        >
          <ColumnCenteredDiv>
            <PageTitleText>Campaigns</PageTitleText>
          </ColumnCenteredDiv>
          <CardPageSection>
            <CardPageSectionActionsContainer spaced>
              <ExtraSmallSecondaryButton
                onClick={() => setCreateModalOpen(true)}
              >
                <SmallAddIcon /> Create campaign
              </ExtraSmallSecondaryButton>
              <OptionsFilterDropdown
                filtered={[sortField]}
                options={defaultSortOptions}
                label={`Sort: ${
                  defaultSortOptions.find((o) => o.key === sortField)?.label
                }`}
                leftIconDisplay={false}
                isMultiSelect={false}
                onChange={(key) => {
                  if (key !== sortField) {
                    onChangeSortField(key);
                  }
                }}
              />
            </CardPageSectionActionsContainer>
            {!campaigns || campaigns?.length === 0 ? (
              <EmptyCard text='No campaigns created' />
            ) : (
              <MediumGapColumnCenteredDiv>
                {sortedCampaigns?.map((campaign) => {
                  const campaignId = campaign.id;
                  const name = campaign.name;
                  const campaignType = campaign.type;
                  const agent = campaign.agent;
                  const promoId = campaign.promoId;

                  const template = campaign.template;
                  const templateId = template?.id;
                  const templateType = template?.type;

                  const numMembers = campaign.numMembers;
                  const numContacted = campaign.numContacted;
                  const status = campaign.status;

                  const smartList = campaign.smartList;
                  const smartListEnabled = smartList.enabled;
                  return (
                    <BaseCard>
                      <CampaignHeader>
                        <CampaignTitleContainer>
                          <CampaignTitleText>
                            {name} {textSeparatorChar}{' '}
                            <SmallLightDarkTextSpan>
                              {campaignTypeLabelMap[campaign.type]} Campaign{' '}
                            </SmallLightDarkTextSpan>
                          </CampaignTitleText>
                        </CampaignTitleContainer>
                        <StatusChip
                          status={status}
                          fixedMinWidth={170}
                        >
                          {campaignStatusLabelMap[status]}
                        </StatusChip>
                      </CampaignHeader>
                      <CampaignClientsContainer>
                        <CampaignParagraphText>
                          <AgentTextContainer>
                            <Tooltip
                              title={
                                <TooltipTitleText>
                                  Manage {agent.name}
                                </TooltipTitleText>
                              }
                              placement='bottom'
                            >
                              <AgentIconButtonContainer
                                onClick={() => navigate(Paths.agents)}
                              >
                                <AgentIconInButton />
                              </AgentIconButtonContainer>
                            </Tooltip>
                            <MediumDarkTextSpan>
                              {agent.name}{' '}
                              {getCampaignDescription(
                                campaign.type,
                                campaign.targetClients,
                                campaign.targetLeads,
                                campaign.lastContactDelayDays,
                              )}
                            </MediumDarkTextSpan>
                          </AgentTextContainer>
                          {campaignType === newAppointmentsCampaignTypeKey && (
                            <FlexDiv>
                              <span>Enrolled:</span>{' '}
                              <PrimaryTextSpan>
                                {numMembers?.toLocaleString()}
                              </PrimaryTextSpan>
                              {numMembers && numMembers > numContacted ? (
                                <CenteredDiv>
                                  <Tooltip
                                    title={
                                      <TooltipTitleText>
                                        Remove uncontacted members
                                      </TooltipTitleText>
                                    }
                                    placement='bottom'
                                  >
                                    <SecondaryRemoveIcon
                                      onClick={() => {
                                        setDeleteUncontactedMembersModalOpen(
                                          true,
                                        );
                                        setCampaignToEdit(campaign);
                                      }}
                                    />
                                  </Tooltip>
                                </CenteredDiv>
                              ) : (
                                <></>
                              )}
                            </FlexDiv>
                          )}
                          <span>Contacted: </span>
                          <PrimaryTextSpan>
                            {numContacted?.toLocaleString()}
                          </PrimaryTextSpan>
                        </CampaignParagraphText>
                      </CampaignClientsContainer>
                      <CampaignActions>
                        <ActionsContainer>
                          {status === liveKey ? (
                            <Tooltip
                              title={
                                <TooltipTitleText>
                                  Pause campaign
                                </TooltipTitleText>
                              }
                              placement='bottom'
                              onClick={() => {
                                setCampaignToChangeStatusFor(campaign);
                                setEditCampaignStatusModalOpen(true);
                              }}
                            >
                              <ActionIconContainer variant='stop'>
                                <PauseCampaignIcon />
                              </ActionIconContainer>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={
                                <TooltipTitleText>
                                  Launch campaign
                                </TooltipTitleText>
                              }
                              placement='bottom'
                              onClick={() => {
                                setCampaignToChangeStatusFor(campaign);
                                setEditCampaignStatusModalOpen(true);
                              }}
                            >
                              <ActionIconContainer variant='start'>
                                <LaunchCampaignIcon />
                              </ActionIconContainer>
                            </Tooltip>
                          )}
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                {!promoId ? `No promo created` : `Promo`}
                              </TooltipTitleText>
                            }
                            placement='bottom'
                            onClick={(e) => {
                              setPromoMenuAnchorEl(e.currentTarget);
                              setCampaignToEdit(campaign);
                            }}
                          >
                            <ActionIconContainer>
                              {!promoId && <ActionIncompleteIcon />}
                              <PromoIcon />
                            </ActionIconContainer>
                          </Tooltip>
                          <PopperMenu
                            open={
                              promoMenuOpenBoolean &&
                              campaignToEditId === campaignId
                            }
                            anchorElement={promoMenuAnchorEl}
                            onClose={() => {
                              setPromoMenuAnchorEl();
                            }}
                            variant='offset'
                          >
                            {!promoId ? (
                              <>
                                <MenuItem
                                  onClick={() => onCreatePromo(campaignId)}
                                >
                                  <SmallMenuItemText>
                                    Create promo
                                  </SmallMenuItemText>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    const existingPromoMenuAnchor =
                                      promoMenuAnchorEl;
                                    setAttachExistingPromoMenuAnchorEl(
                                      existingPromoMenuAnchor,
                                    );
                                    setPromoMenuAnchorEl();
                                  }}
                                >
                                  <SmallMenuItemText>
                                    Attach existing
                                  </SmallMenuItemText>
                                </MenuItem>
                              </>
                            ) : (
                              <>
                                <MenuItem
                                  onClick={() =>
                                    navigate(getEditPromoUrl(promoId))
                                  }
                                >
                                  <SmallMenuItemText>
                                    Edit promo
                                  </SmallMenuItemText>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => onDeletePromo(promoId)}
                                >
                                  <SmallMenuItemText>
                                    Delete promo
                                  </SmallMenuItemText>
                                </MenuItem>
                              </>
                            )}
                          </PopperMenu>
                          <PopperMenu
                            open={
                              attachExistingPromoMenuOpenBoolean &&
                              campaignToEditId === campaignId
                            }
                            anchorElement={attachExistingPromoMenuAnchorEl}
                            onClose={() => {
                              setAttachExistingPromoMenuAnchorEl();
                            }}
                            variant='offset'
                          >
                            <SmallMenuItemTitleContainer disableRipple>
                              <SmallMenuItemTitleText>
                                Select existing promo to attach
                              </SmallMenuItemTitleText>
                            </SmallMenuItemTitleContainer>
                            {campaigns.map((c) => {
                              const { name, promoId } = c;
                              return (
                                promoId && (
                                  <MenuItem
                                    onClick={() => {
                                      onAttachExistingPromoToCampaign(
                                        campaignId,
                                        promoId,
                                      );
                                    }}
                                  >
                                    <SmallMenuItemText>
                                      Promo for '{name}' campaign
                                    </SmallMenuItemText>
                                  </MenuItem>
                                )
                              );
                            })}
                          </PopperMenu>
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                {!templateId
                                  ? `No template added`
                                  : templateType === singleNodeTemplateType
                                  ? `Template`
                                  : templateType === scriptTemplateType
                                  ? `Script`
                                  : ''}
                              </TooltipTitleText>
                            }
                            placement='bottom'
                            onClick={(e) => {
                              if (!templateId) {
                                setTemplateMenuAnchorEl(e.currentTarget);
                                setCampaignToEdit(campaign);
                              } else {
                                setTemplateMenuAnchorEl(e.currentTarget);
                                setCampaignToEdit(campaign);
                              }
                            }}
                          >
                            <ActionIconContainer>
                              {!templateId && <ActionIncompleteIcon />}
                              {templateType === scriptTemplateType ? (
                                <ScriptIcon />
                              ) : (
                                <TemplatesIcon />
                              )}
                            </ActionIconContainer>
                          </Tooltip>
                          <PopperMenu
                            open={
                              templateMenuOpenBoolean &&
                              campaignToEditId === campaignId
                            }
                            anchorElement={templateMenuAnchorEl}
                            onClose={() => {
                              setTemplateMenuAnchorEl();
                            }}
                            variant='offset'
                          >
                            {!templateId ? (
                              <>
                                <MenuItem
                                  onClick={() => {
                                    setTemplateMenuAnchorEl();
                                    setSingleNodeTemplateModalOpen(true);
                                  }}
                                >
                                  <SmallMenuItemText>
                                    Create static template
                                  </SmallMenuItemText>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    onCreateScript();
                                  }}
                                >
                                  <SmallMenuItemText>
                                    Create script
                                  </SmallMenuItemText>
                                </MenuItem>
                              </>
                            ) : (
                              <>
                                {templateType === scriptTemplateType ? (
                                  <>
                                    <MenuItem
                                      onClick={() => {
                                        navigateToEditScript(templateId);
                                      }}
                                    >
                                      <SmallMenuItemText>
                                        Edit script
                                      </SmallMenuItemText>
                                    </MenuItem>
                                  </>
                                ) : (
                                  <>
                                    <MenuItem
                                      onClick={() => {
                                        setTemplateMenuAnchorEl();
                                        setTemplateToEditId(templateId);
                                        setSingleNodeTemplateModalOpen(true);
                                      }}
                                    >
                                      <SmallMenuItemText>
                                        Edit template
                                      </SmallMenuItemText>
                                    </MenuItem>
                                  </>
                                )}
                                <MenuItem
                                  onClick={() => {
                                    setTemplateToEditId(templateId);
                                    setAttachExistingTemplateMenuAnchorEl(
                                      templateMenuAnchorEl,
                                    );
                                    setTemplateMenuAnchorEl();
                                  }}
                                >
                                  <SmallMenuItemText>
                                    Replace with existing template
                                  </SmallMenuItemText>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setTemplateToEditId(templateId);
                                    setAttachExistingScriptMenuAnchorEl(
                                      templateMenuAnchorEl,
                                    );
                                    setTemplateMenuAnchorEl();
                                  }}
                                >
                                  <SmallMenuItemText>
                                    Replace with existing script
                                  </SmallMenuItemText>
                                </MenuItem>
                              </>
                            )}
                          </PopperMenu>
                          <PopperMenu
                            open={
                              attachExistingTemplateMenuOpenBoolean &&
                              campaignToEditId === campaignId
                            }
                            anchorElement={attachExistingTemplateMenuAnchorEl}
                            onClose={() => {
                              setAttachExistingTemplateMenuAnchorEl();
                            }}
                            variant='offset'
                          >
                            <SmallMenuItemTitleContainer disableRipple>
                              <SmallMenuItemTitleText>
                                Select existing template to attach
                              </SmallMenuItemTitleText>
                            </SmallMenuItemTitleContainer>
                            {campaigns.map((c) => {
                              const { id, name, template } = c;
                              const contentId = template?.id;
                              const templateType = template?.type;
                              const isTemplate =
                                templateType === singleNodeTemplateType;
                              const templateId = isTemplate ? contentId : null;
                              const scriptId = !isTemplate ? contentId : null;
                              return (
                                isTemplate &&
                                id !== campaignId && (
                                  <MenuItem
                                    onClick={() => {
                                      onAttachExistingTemplateOrScriptToCampaign(
                                        templateId,
                                        scriptId,
                                        campaignId,
                                      );
                                    }}
                                  >
                                    <SmallMenuItemText>
                                      Template for '{name}' campaign
                                    </SmallMenuItemText>
                                  </MenuItem>
                                )
                              );
                            })}
                          </PopperMenu>
                          <PopperMenu
                            open={
                              attachExistingScriptMenuOpenBoolean &&
                              campaignToEditId === campaignId
                            }
                            anchorElement={attachExistingScriptMenuAnchorEl}
                            onClose={() => {
                              setAttachExistingScriptMenuAnchorEl();
                            }}
                            variant='offset'
                          >
                            <SmallMenuItemTitleContainer disableRipple>
                              <SmallMenuItemTitleText>
                                Select existing script to attach
                              </SmallMenuItemTitleText>
                            </SmallMenuItemTitleContainer>
                            {campaigns.map((c) => {
                              const { id, name, template } = c;
                              const contentId = template?.id;
                              const templateType = template?.type;
                              const isTemplate =
                                templateType === singleNodeTemplateType;
                              const templateId = isTemplate ? contentId : null;
                              const scriptId = !isTemplate ? contentId : null;
                              return (
                                !isTemplate &&
                                id !== campaignId && (
                                  <MenuItem
                                    onClick={() => {
                                      onAttachExistingTemplateOrScriptToCampaign(
                                        templateId,
                                        scriptId,
                                        campaignId,
                                      );
                                    }}
                                  >
                                    <SmallMenuItemText>
                                      Script for '{name}' campaign
                                    </SmallMenuItemText>
                                  </MenuItem>
                                )
                              );
                            })}
                          </PopperMenu>
                          <Tooltip
                            title={
                              <TooltipTitleText>View audience</TooltipTitleText>
                            }
                            placement='bottom'
                            onClick={() =>
                              onClickViewCampaignClients(campaignId)
                            }
                          >
                            <ActionIconContainer>
                              <ContactsIcon />
                            </ActionIconContainer>
                          </Tooltip>
                          {campaignType === newAppointmentsCampaignTypeKey && (
                            <Tooltip
                              title={
                                <TooltipTitleText>
                                  {!smartListEnabled
                                    ? `No smart list enabled`
                                    : `Smart list`}
                                </TooltipTitleText>
                              }
                              placement='bottom'
                              onClick={(e) => {
                                setSmartListMenuAnchorEl(e.currentTarget);
                                setCampaignToEdit(campaign);
                              }}
                            >
                              <ActionIconContainer>
                                {!smartListEnabled && <ActionIncompleteIcon />}
                                <SmartListActionIcon />
                              </ActionIconContainer>
                            </Tooltip>
                          )}
                          <PopperMenu
                            open={
                              smartListMenuOpenBoolean &&
                              campaignToEditId === campaignId
                            }
                            anchorElement={smartListMenuAnchorEl}
                            onClose={() => {
                              setSmartListMenuAnchorEl();
                            }}
                            variant='offset'
                          >
                            {smartListEnabled ? (
                              <>
                                {' '}
                                <MenuItem
                                  onClick={() => {
                                    setSmartListMenuAnchorEl();
                                    setSmartListModalOpen(true);
                                    setCampaignToEdit(campaign);
                                    setSelectedSmartListCampaignFilter(
                                      campaignsParsedFiltersMap[campaignId],
                                    );
                                  }}
                                >
                                  <SmallMenuItemText>
                                    Edit smart list
                                  </SmallMenuItemText>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setSmartListMenuAnchorEl();
                                    setCampaignToEdit(campaign);
                                    setDeleteSmartListModalOpen(true);
                                  }}
                                >
                                  <SmallMenuItemText>
                                    Delete smart list
                                  </SmallMenuItemText>
                                </MenuItem>
                              </>
                            ) : (
                              <MenuItem
                                onClick={() => {
                                  navigate(Paths.audience);
                                }}
                              >
                                <SmallMenuItemText>
                                  Create smart list
                                </SmallMenuItemText>
                              </MenuItem>
                            )}
                          </PopperMenu>
                        </ActionsContainer>
                        <EndActionsContainer>
                          {role === superAdminRole && (
                            <Tooltip
                              title={
                                <TooltipTitleText>
                                  Copy ID {campaignId.slice(0, 4)}
                                </TooltipTitleText>
                              }
                              placement='bottom'
                            >
                              <SmallPrimaryCopyIcon
                                onClick={() => {
                                  onCopyId(campaignId);
                                }}
                              />
                            </Tooltip>
                          )}
                          <Tooltip
                            title={
                              <TooltipTitleText>Edit campaign</TooltipTitleText>
                            }
                            placement='bottom'
                          >
                            <EditIcon
                              onClick={() => {
                                setCampaignToEdit(campaign);
                                setEditModalOpen(true);
                              }}
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                Delete campaign
                              </TooltipTitleText>
                            }
                            placement='bottom'
                          >
                            <DeleteIcon
                              onClick={() => {
                                setCampaignToDelete(campaign);
                                setDeleteModalOpen(true);
                              }}
                            />
                          </Tooltip>
                        </EndActionsContainer>
                      </CampaignActions>
                    </BaseCard>
                  );
                })}
              </MediumGapColumnCenteredDiv>
            )}
          </CardPageSection>
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
      <SingleNodeTemplateModal
        id={templateToEditId}
        campaignId={campaignToEditId}
        isOpen={singleNodeTemplateModalOpen}
        onClose={() => {
          setSingleNodeTemplateModalOpen(false);
          setTemplateToEditId();
        }}
        onSave={() => {
          refetchCampaigns();
          setSnackbarMessage(`Template created`);
        }}
      />
      <FilterClientsModal
        isOpen={smartListModalOpen}
        onClose={() => {
          setSmartListModalOpen(false);
          setSelectedSmartListCampaignFilter('');
          setCampaignToEdit();
        }}
        filters={selectedSmartListCampaignFilter}
        locationId={locationId}
        onApply={onApplyFilters}
        refetchCampaigns={refetchCampaigns}
        campaignNameMap={campaignNameMap}
        campaignToEdit={campaignToEdit}
        isEditing={true}
        tags={tags}
      />
      <CreateCampaignModal
        isOpen={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onCreate={onCreateCampaign}
      />
      <DeleteCampaignModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setCampaignToDelete();
        }}
        campaignToDelete={campaignToDelete}
        onDelete={() => {
          refetchCampaigns();
          setSnackbarMessage('Campaign deleted');
          setDeleteModalOpen(false);
          setCampaignToDelete();
        }}
      />
      <EditCampaignModal
        isOpen={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setCampaignToEdit();
        }}
        campaignToEdit={campaignToEdit}
        onEdit={() => {
          refetchCampaigns();
          setSnackbarMessage('Campaign updated');
          setEditModalOpen(false);
          setCampaignToEdit();
        }}
      />
      <EditCampaignStatusModal
        isOpen={editCampaignStatusModalOpen}
        onClose={() => setEditCampaignStatusModalOpen(false)}
        campaign={campaignToChangeStatusFor}
        onEdit={() => {
          setSnackbarMessage('Campaign updated');
          setEditCampaignStatusModalOpen(false);
          refetchCampaigns();
        }}
      />
      <EditTemplateTypeModal
        isOpen={editTemplateTypeModalOpen}
        onClose={() => setEditTemplateTypeModalOpen(false)}
        campaign={campaignToEdit}
      />
      <DefaultConfirmationModal
        isOpen={confirmationModalOpen}
        onClose={() => {
          if (deleteSmartListModalOpen) {
            setDeleteSmartListModalOpen();
          } else if (deleteUncontactedMembersModalOpen) {
            setDeleteUncontactedMembersModalOpen();
          }
        }}
        paragraphText={
          deleteSmartListModalOpen
            ? `delete this smart list?`
            : deleteUncontactedMembersModalOpen
            ? `delete all uncontacted members for this campaign?`
            : ''
        }
        onClick={() => {
          if (deleteSmartListModalOpen) {
            onDeleteSmartList(campaignToEditId);
          } else if (deleteUncontactedMembersModalOpen) {
            onDeleteUncontactedCampaignMembers(campaignToEditId);
          }
        }}
        loading={deleteUncontactedLoading || deleteSmartListLoading}
      />
    </>
  );
};

export default Campaigns;
