import { useApolloClient, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { CREATE_LOCATION } from '../../../api/mutations/enterprise';
import {
  AddLocationIcon,
  ColumnDivWithSmallGap,
  Dropdown,
  Form,
  FormHeaderText,
  MLText,
  MediumPrimaryButton,
  MediumSecondaryButton,
  MenuItem,
  Modal,
  ModalActions,
  ModalContainer,
  SMGapColumnCenteredDiv,
  TextInput,
} from '../../../styles/shared-styled-components';
import { addToMyLocationsInCache } from '../../../utils/cache';
import { valueIsEmpty } from '../../../utils/data';
import { checkIfIsValidEmail } from '../../../utils/input';
import { stripAllSpacesFromString } from '../../../utils/string';
import { scrollElementIntoView } from '../../../utils/view';

const selfInitializerKey = 'myself';
const otherInitializerKey = 'other';

const emailId = 'owner-email';

const AddLocationModal = ({ isOpen, onClose, onAdd }) => {
  const { cache } = useApolloClient();

  const [createLocationMutation, { loading: createLocationMutationLoading }] =
    useMutation(CREATE_LOCATION);

  const [locationName, setLocationName] = useState('');
  const [locationAddress, setLocationAddress] = useState('');
  const [dataInitializer, setDataInitializer] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState();

  const onCloseModal = () => {
    setLocationName();
    setOwnerName();
    onClose();
  };

  const onClickAdd = () => {
    let formattedEmail = ownerEmail;

    if (dataInitializer === otherInitializerKey) {
      const formattedEmail = stripAllSpacesFromString(ownerEmail);
      const isValidEmail = checkIfIsValidEmail(formattedEmail);

      if (!isValidEmail) {
        scrollElementIntoView(document, emailId);
        setEmailErrorMessage('Please enter a valid email address');

        return;
      }
    }

    const sentInviteToOwner = dataInitializer !== selfInitializerKey;

    createLocationMutation({
      variables: {
        name: locationName,
        address: locationAddress,
        ownerName: dataInitializer === selfInitializerKey ? null : ownerName,
        ownerEmail:
          dataInitializer === selfInitializerKey ? null : formattedEmail,
      },
      onCompleted: async (data) => {
        const createdLocation = data.createLocation;

        await addToMyLocationsInCache(createdLocation, cache);
        onAdd(sentInviteToOwner);
        onCloseModal();
      },
    });
  };

  const createEnabled =
    !valueIsEmpty(locationName) &&
    !valueIsEmpty(locationAddress) &&
    !valueIsEmpty(dataInitializer) &&
    (dataInitializer === selfInitializerKey ||
      (!valueIsEmpty(ownerName) && !valueIsEmpty(ownerEmail)));

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      maxWidth={830}
    >
      <ModalContainer>
        <SMGapColumnCenteredDiv>
          <AddLocationIcon />
          <MLText>Add Location</MLText>
        </SMGapColumnCenteredDiv>
        <TextInput
          id='location-name'
          value={locationName}
          onChange={(e) => {
            setLocationName(e.target.value);
          }}
          label='Location name *'
        />
        <TextInput
          id='location-address'
          value={locationAddress}
          onChange={(e) => {
            setLocationAddress(e.target.value);
          }}
          label='Location address *'
        />
        <ColumnDivWithSmallGap>
          <FormHeaderText>
            Are you going to connect the location data yourself, or do you want
            to delegate that to the location admin?
          </FormHeaderText>
          <Form>
            <Dropdown
              value={dataInitializer}
              onChange={(e) => setDataInitializer(e.target.value)}
            >
              <MenuItem
                value={selfInitializerKey}
                key={selfInitializerKey}
              >
                I'll connect location data myself
              </MenuItem>
              <MenuItem
                value={otherInitializerKey}
                key={otherInitializerKey}
              >
                I'll assign a location admin to connect the data
              </MenuItem>
            </Dropdown>
          </Form>
        </ColumnDivWithSmallGap>
        {dataInitializer === otherInitializerKey && (
          <>
            <TextInput
              id='owner-name'
              value={ownerName}
              onChange={(e) => {
                setOwnerName(e.target.value);
              }}
              label='Location admin name *'
            />
            <TextInput
              id={emailId}
              value={ownerEmail}
              onChange={(e) => {
                setOwnerEmail(e.target.value);
                if (emailErrorMessage) {
                  setEmailErrorMessage();
                }
              }}
              label='Location admin email *'
              containsError={emailErrorMessage}
              helperText={emailErrorMessage ? emailErrorMessage : ''}
            />
          </>
        )}
        <ModalActions>
          <MediumPrimaryButton
            onClick={onClickAdd}
            disabled={!createEnabled}
          >
            Add
          </MediumPrimaryButton>
          <MediumSecondaryButton onClick={onCloseModal}>
            Cancel
          </MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default AddLocationModal;
