import {
  ApolloClient,
  ApolloProvider as BuiltInApolloProvider,
  InMemoryCache,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/link-context';
import { useAuth0 } from '@auth0/auth0-react';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import React, { useEffect } from 'react';
import Paths from './Paths';

let restartRequestedBeforeConnected = false;
let gracefullyRestart = () => {
  restartRequestedBeforeConnected = true;
};

const ApolloProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const reactEnv = process.env.REACT_APP_ENV;
  const isLocal = reactEnv === 'local';
  const serverLink = createUploadLink({
    uri: isLocal ? Paths.localHttpBackend : Paths.prodHttpsBackend,
  });

  const origin = window.location.origin;

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently({ useRefreshTokens: true });
      return token;
    } catch (error) {
      if (origin.includes('http://localhost')) {
        console.log('Error when getting access token: ', error);
      }
    }
  };

  // Not sure why, but for some reason we need to force the fetching of an access token here
  useEffect(() => {
    async function callGetToken() {
      await getToken();
    }
    callGetToken();
  }, []);

  const authLink = setContext(async (_, { headers, ...rest }) => {
    let token;

    try {
      token = await getAccessTokenSilently();
    } catch (error) {
      if (origin.includes('http://localhost')) {
        console.log('Error when fetching access token: ', error);
      }
    }

    if (!token) {
      return {
        headers,
        ...rest,
      };
    }

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const httpLink = authLink.concat(serverLink);
  // https://stackoverflow.com/a/74904132/9571288
  const baseWebSocketLink = new GraphQLWsLink(
    createClient({
      url: isLocal
        ? `ws://${Paths.localWsBackend}`
        : `wss://${Paths.prodWsBackend}`,
      retryAttempts: Infinity,
      shouldRetry: () => true,
      keepAlive: 30000,
      connectionParams: async () => {
        const authToken = await getToken();
        return {
          authToken: `Bearer ${authToken}`,
        };
      },
      retryWait: async function waitForServerHealthyBeforeRetry() {
        await new Promise((resolve) =>
          setTimeout(resolve, 1000 + Math.random() * 3000),
        );
      },
      on: {
        connected: (socket) => {
          gracefullyRestart = () => {
            if (socket.readyState === WebSocket.OPEN) {
              socket.close(4205, 'Client Restart');
            }
          };

          // just in case you were eager to restart
          if (restartRequestedBeforeConnected) {
            restartRequestedBeforeConnected = false;
            gracefullyRestart();
          }
        },
      },
    }),
  );
  const webSocketLink = authLink.concat(baseWebSocketLink);

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    webSocketLink,
    httpLink,
  );

  const client = React.useRef();

  if (!client.current) {
    client.current = new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache({ addTypename: false }),
      connectToDevTools: true,
    });
  }

  return (
    <BuiltInApolloProvider client={client.current}>
      {children}
    </BuiltInApolloProvider>
  );
};

export default ApolloProvider;
