import React from 'react';
import {
  Feature1SentenceHeader,
  Feature1SentenceSubtext,
  Features1Container,
  Features1Header,
  Features1LeftContainer,
  Features1Media,
  Features1RightContainer,
  Features1Sentence,
} from '../styles/style-Features1';
import { GradientSpan } from '../styles/style-Homepage';

const featuresData = [
  {
    header: 'Intelligent Scripting',
    subText:
      'Optimize lead nurturing and qualification for services like weight loss and fertility with our unmatched AI.',
  },
  {
    header: 'Last Minute Cancellations',
    subText:
      'Automatically fill calendar gaps with targeted promotions to counteract daily cancellations',
  },
  {
    header: 'Membership Upsell & Packages',
    subText:
      'Boost your product and service credibility by adding testimonials from your clients. Recommendations are invaluable.',
  },
  {
    header: 'Full Detailed Customization',
    subText:
      'Design campaigns and promotions for services that you wish to target. Customize the audience selection, message, timing, and more.',
  },
  {
    header: 'Voice AI',
    subText:
      'Seamlessly enhance your outbound conversion and inbound customer service with our advanced voice AI technology.',
  },
];

const Features1 = ({ darkMode }) => {
  return (
    <div id='features'>
      <Features1Container>
        <Features1LeftContainer>
          <Features1Header dark={darkMode}>
            <GradientSpan>
              UNPARALLELED
              <br />
              AI FEATURES
            </GradientSpan>
          </Features1Header>
          <Features1Media></Features1Media>
        </Features1LeftContainer>

        <Features1RightContainer>
          {featuresData.map((feature, index) => (
            <Features1Sentence key={index}>
              <Feature1SentenceHeader dark={darkMode}>
                {feature.header}
              </Feature1SentenceHeader>
              <Feature1SentenceSubtext dark={darkMode}>
                {feature.subText}
              </Feature1SentenceSubtext>
            </Features1Sentence>
          ))}
        </Features1RightContainer>
      </Features1Container>
    </div>
  );
};

export default Features1;
