import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import LoginButton from '../../../components/Auth/LoginButton/LoginButton';
import SignupButton from '../../../components/Auth/SignupButton/SignupButton';
import OldWebsiteHeader from '../../../components/NavBar/WebsiteHeader/OldWebsiteHeader';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  ColumnCenteredDiv,
  ContentContainer,
  DarkBackgroundSecondaryButton,
  MediumGapColumnCenteredDiv,
  PrimaryButton,
  WhiteMLText,
  WhiteMediumText,
} from '../../../styles/shared-styled-components';
import { AuthSection, StyledBackgroundContainer } from './styled';

const GetStarted = () => {
  const navigate = useNavigate();
  const { user: auth0User, isLoading } = useAuth0();

  const { pathname, search } = useLocation();
  const pathToReturnTo = `${pathname}${search}`;

  const onClickLogout = () => {
    navigate(Paths.logout);
  };

  return (
    !isLoading && (
      <>
        <MetaSetter
          title={'Get Started'}
          description={'Get started with LiveIQ, your appointment concierge'}
        />
        <OldWebsiteHeader />
        <StyledBackgroundContainer>
          <ContentContainer>
            <AuthSection>
              {!auth0User ? (
                <>
                  <WhiteMLText>Sign up or login to continue</WhiteMLText>
                  <ColumnCenteredDiv>
                    <SignupButton size={'medium'}>Sign up</SignupButton>
                    <LoginButton
                      variant={'dark-secondary'}
                      pathToReturnTo={pathToReturnTo}
                    >
                      Log in
                    </LoginButton>
                  </ColumnCenteredDiv>
                </>
              ) : (
                <MediumGapColumnCenteredDiv>
                  <WhiteMediumText>Already signed in</WhiteMediumText>
                  <ColumnCenteredDiv>
                    <PrimaryButton
                      variant={'dark-secondary'}
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Continue
                    </PrimaryButton>
                    <DarkBackgroundSecondaryButton onClick={onClickLogout}>
                      Logout
                    </DarkBackgroundSecondaryButton>
                  </ColumnCenteredDiv>
                </MediumGapColumnCenteredDiv>
              )}
            </AuthSection>
          </ContentContainer>
        </StyledBackgroundContainer>
      </>
    )
  );
};

export default GetStarted;
