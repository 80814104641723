import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import {
  MediumDarkBackgroundSecondaryButton,
  MediumExternalLinkPrimaryButton,
  NewLogo,
} from '../../../styles/shared-styled-components';
import { screenWidthIsMobileSizeOrSmaller } from '../../../utils/view';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import {
  AppBar,
  LogoContainer,
  LogoText,
  ToolBar,
  ToolBarContainerLeft,
  ToolBarContainerRight,
} from './oldWebsiteHeaderStyled';

const appReady = true;

const OldWebsiteHeader = ({ onNavigate = () => {} }) => {
  const { pathname } = useLocation();
  const { width } = useContext(BaseContext);

  const { user: auth0User } = useAuth0();
  const navigate = useNavigate();

  const navigateToPath = (path) => {
    onNavigate();
    navigate(path);
  };

  const displayDrawer = screenWidthIsMobileSizeOrSmaller(width);

  const onHomeScreen = pathname === Paths.homepage;

  return (
    <>
      <AppBar>
        <ToolBar>
          <ToolBarContainerLeft>
            <LogoContainer
              onClick={() => !onHomeScreen && navigateToPath(Paths.homepage)}
            >
              <NewLogo />
              <LogoText>LiveIQ</LogoText>
            </LogoContainer>
          </ToolBarContainerLeft>
          <ToolBarContainerRight>
            {displayDrawer ? (
              <></>
            ) : (
              <>
                <MediumExternalLinkPrimaryButton href={Paths.calendlyLink}>
                  Book a demo
                </MediumExternalLinkPrimaryButton>
                <MediumDarkBackgroundSecondaryButton
                  onClick={() =>
                    navigateToPath(
                      !auth0User ? Paths.getStarted : Paths.callback,
                    )
                  }
                >
                  {!auth0User ? 'Get started' : 'Go to app'}
                </MediumDarkBackgroundSecondaryButton>
              </>
            )}
          </ToolBarContainerRight>
        </ToolBar>
      </AppBar>
    </>
  );
};

export default OldWebsiteHeader;
