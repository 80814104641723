import { useApolloClient, useMutation } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useMySessionTypes,
  useMyStaff,
  usePromoById,
} from '../../../../api/hooks/enterprise';
import { EDIT_PROMO } from '../../../../api/mutations/enterprise';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import Input from '../../../../components/Form/Input';
import SplitInput from '../../../../components/Form/SplitInput/SplitInput';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Header from '../../../../components/NavBar/Header';
import PopperMenuCalendar from '../../../../components/Schedule/Calendar/PopperMenuCalendar';
import MetaSetter from '../../../../components/Utils/MetaSetter';
import Paths from '../../../../Paths';
import {
  CalendarIcon,
  CenteredDivWithLargerGap,
  Checkbox,
  Chip,
  ChipCancelIcon,
  ContentContainer,
  Dropdown,
  Form,
  FormControlLabel,
  FormGroup,
  LightDarkEssText,
  MediumDarkEssText,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  PageContainer,
  PageHeaderContainer,
  PageSubtitleText,
  PageTitleBottomBorderContainer,
  ScrollWrapper,
  SecondaryBackIcon,
  Stack,
  StartAlignedMediumDarkSmallText,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import { updatePromoByIdInCache } from '../../../../utils/cache';
import {
  discountOptions,
  dropdownInputType,
  flatRateType,
  percentInputType,
  unclassifiedKey,
} from '../../../../utils/constants';
import { removeItemObject } from '../../../../utils/data';
import { dateToTextFormat } from '../../../../utils/date';
import { getInitials } from '../../../../utils/name';
import { getUserLocationId } from '../../../../utils/user';
import {
  DateContainer,
  DatesContainer,
  DateTextContainer,
  SelectContainer,
  SelectTitleTextContainer,
} from './styled';

const promoToEditParamKey = 'promoId';
const allStaffKey = 'all_staff';
const allStaffLabel = 'All Staff';
const allServicesKey = 'all_services';
const allServicesLabel = 'All Services';

const EditPromo = ({}) => {
  const { cache } = useApolloClient();
  const navigate = useNavigate();

  const { user, drawerOpen, drawerExpanded, inDemoMode, cookies, setCookie } =
    useContext(BaseContext);
  const locationId = getUserLocationId(user);
  const role = user?.role;

  const contentContainerRef = useRef();

  const { promoId: promoIdToEdit } = useParams();

  const {
    sessionTypes: sessionTypeCatalog,
    loading: sessionTypesLoading,
    refetch: refetchSessionTypes,
  } = useMySessionTypes({ onlyBookable: true });

  const {
    staff: staffData,
    loading: staffLoading,
    refetch: refetchStaff,
  } = useMyStaff({
    locationId,
  });
  const {
    promo: promoToEditData,
    loading: promoToEditLoading,
    refetch: refetchPromoToEdit,
  } = usePromoById({ id: promoIdToEdit, editing: true });

  const [editPromoMutation, { loading: editPromoLoading }] =
    useMutation(EDIT_PROMO);

  const [promoToEditDescription, setPromoToEditDescription] = useState('');
  const [availableSessionTypes, setAvailableSessionTypes] = useState();
  const [promoToEditSessionTypes, setPromoToEditSessionTypes] = useState({});
  const [availableStaff, setAvailableStaff] = useState();
  const [promoToEditStaff, setPromoToEditStaff] = useState([]);
  const [promoToEditDiscountType, setPromoToEditDiscountType] =
    useState(percentInputType);
  const [promoToEditDiscountAmount, setPromoToEditDiscountAmount] = useState();
  const [promoToEditStartDate, setPromoToEditStartDate] = useState();
  const [
    promoToEditStartDateMenuAnchorEl,
    setPromoToEditStartDateMenuAnchorEl,
  ] = useState();
  const [promoToEditEndDate, setPromoToEditEndDate] = useState();
  const [promoToEditEndDateMenuAnchorEl, setPromoToEditEndDateMenuAnchorEl] =
    useState();
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const promoToEditStartDateMenuOpenBoolean = Boolean(
    promoToEditStartDateMenuAnchorEl,
  );
  const promoToEditEndDateMenuOpenBoolean = Boolean(
    promoToEditEndDateMenuAnchorEl,
  );

  useEffect(() => {
    if (sessionTypeCatalog) {
      const values = Object.values(sessionTypeCatalog?.categories);
      setAvailableSessionTypes(
        values.map((s) => {
          return {
            categoryId: s.id,
            categoryName: s.name,
            services: [...(s?.dropIns || []), ...(s?.consultations || [])],
          };
        }),
      );
    }
  }, [sessionTypeCatalog]);

  useEffect(() => {
    if (promoToEditData) {
      const promoToEdit = promoToEditData.promo;
      setPromoToEditDescription(promoToEdit.description);
      setPromoToEditDiscountType(promoToEdit.discountType);
      setPromoToEditDiscountAmount(promoToEdit.discountAmount);
      setPromoToEditStartDate(promoToEdit.startDate);
      setPromoToEditEndDate(promoToEdit.endDate);

      setPromoToEditStaff(promoToEdit.staff);

      const promoToEditSessionTypeRes = promoToEdit.sessionTypes;
      setPromoToEditSessionTypes(promoToEditSessionTypeRes);

      const availableStaffData = promoToEditData.availableStaff || [];
      if (availableStaffData.length === 0) {
        const allStaffObject = {
          id: allStaffKey,
          name: allStaffKey,
          isAllKey: true,
        };
        setAvailableStaff([allStaffObject]);
      } else {
        setAvailableStaff(availableStaffData);
      }
    }
  }, [promoToEditData]);

  const onUpdatePromoSessionTypes = (
    checked,
    id,
    name,
    isCategory,
    isAllKey = false,
    categoryId = null,
  ) => {
    let newServices;

    if (checked) {
      const object = {
        id,
        categoryId,
        isCategory,
        isAllKey,
        name,
      };
      if (isAllKey) {
        newServices = { [id]: object };
      } else if (isCategory) {
        newServices = {
          ...promoToEditSessionTypes,
          [id]: object,
        };
        newServices = Object.fromEntries(
          Object.entries({ ...newServices }).filter(
            ([entryId, value]) =>
              value.categoryId !== id &&
              !(value.categoryId == null && value.isCategory == null),
          ),
        );
      } else {
        newServices = { ...promoToEditSessionTypes };
        newServices[id] = object;
      }
    } else {
      newServices = removeItemObject(promoToEditSessionTypes, id);
    }

    setPromoToEditSessionTypes(newServices);
  };

  const onUpdatePromoStaff = (checked, id, name, isAllKey = false) => {
    let newStaff;

    if (checked) {
      const object = {
        id,
        name,
        isAllKey,
      };
      if (isAllKey) {
        newStaff = { [id]: object };
      } else {
        newStaff = { ...promoToEditStaff };
        newStaff[id] = object;
      }
    } else {
      newStaff = removeItemObject(promoToEditStaff, id);
    }

    setPromoToEditStaff(newStaff);
  };

  const onSavePromo = () => {
    editPromoMutation({
      variables: {
        id: promoIdToEdit,
        description: promoToEditDescription,
        staff: promoToEditStaffArray,
        sessionTypes: promoToEditSessionTypesArray,
        discountType: promoToEditDiscountType,
        discountAmount: parseFloat(promoToEditDiscountAmount),
        startDate: promoToEditStartDate,
        endDate: promoToEditEndDate,
      },
      onCompleted: async (data) => {
        const updatedPromo = data.editPromo;

        if (updatedPromo) {
          await updatePromoByIdInCache(promoIdToEdit, updatedPromo, cache);
          setSnackbarMessage('Promo saved');
          onClickBack();
        }
      },
    });
  };

  const resetPromoToEditData = () => {
    setPromoToEditDescription();
    setPromoToEditSessionTypes([]);
    setPromoToEditStaff([]);
    setPromoToEditStartDate();
    setPromoToEditEndDate();
  };

  const onClickBack = () => {
    resetPromoToEditData();
    navigate(Paths.campaigns);
  };

  const promoToEditStaffArray = Object.values(promoToEditStaff) || [];
  const promoToEditSessionTypesArray =
    Object.values(promoToEditSessionTypes) || [];

  const allStaffSelected = allStaffKey in promoToEditStaff;
  const allServicesSelected = allServicesKey in promoToEditSessionTypes;

  const savePromoEnabled =
    promoToEditDiscountType &&
    promoToEditDiscountAmount != null &&
    promoToEditDescription &&
    promoToEditSessionTypesArray.length &&
    promoToEditStaffArray.length &&
    promoToEditStartDate &&
    promoToEditEndDate;

  if (promoToEditLoading || staffLoading || sessionTypesLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <MetaSetter
        title={`Edit Promo`}
        description={`Edit LiveIQ Promo`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
        removeAllTopMargin
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          ref={contentContainerRef}
          fullWidth
          hideOverflow
          removeGap
          topPadding={0}
          center
        >
          <PageHeaderContainer
            ref={contentContainerRef}
            fixed
          >
            <CenteredDivWithLargerGap>
              <Tooltip
                title={<TooltipTitleText>Back to campaigns</TooltipTitleText>}
                placement='left'
              >
                <SecondaryBackIcon onClick={() => onClickBack()} />
              </Tooltip>
              <PageSubtitleText>Editing Campaign Promo</PageSubtitleText>
            </CenteredDivWithLargerGap>
            <PageTitleBottomBorderContainer />
          </PageHeaderContainer>
          <ScrollWrapper topPadding={80}>
            <MediumGapColumnCenteredDiv>
              <Input
                id='description'
                value={promoToEditDescription}
                onChange={(e) => {
                  setPromoToEditDescription(e.target.value);
                }}
                label='Description'
                useSmallGap={true}
                isRequired={false}
                multiline
                numRows={3}
              />
              <SelectContainer>
                <SelectTitleTextContainer>
                  <StartAlignedMediumDarkSmallText>
                    Select which services to apply the promo for
                  </StartAlignedMediumDarkSmallText>
                </SelectTitleTextContainer>
                <Input
                  id='services'
                  value={promoToEditSessionTypesArray}
                  removeGap={true}
                  CustomInputComponent={
                    <Form fitContentHeight>
                      <Dropdown
                        multiple
                        value={promoToEditSessionTypesArray}
                        renderValue={(selected) => (
                          <Stack>
                            {Object.values(selected)?.map(
                              (selectedServiceObject) => {
                                const { id, name, isCategory, isAllKey } =
                                  selectedServiceObject;

                                const categoryLabel = isCategory
                                  ? `All ${name} Services`
                                  : null;

                                const formattedLabel = isAllKey
                                  ? allServicesLabel
                                  : isCategory
                                  ? categoryLabel
                                  : name?.length > 26
                                  ? `${name.slice(0, 23)}...`
                                  : name;

                                const isHeader = isCategory || isAllKey;

                                return (
                                  <Chip
                                    key={id}
                                    secondary={isHeader}
                                    ternary={!isHeader}
                                    label={formattedLabel}
                                    onDelete={() => {
                                      const updatedPromoToEditSessionTypes =
                                        removeItemObject(
                                          promoToEditSessionTypes,
                                          id,
                                        );
                                      setPromoToEditSessionTypes(
                                        updatedPromoToEditSessionTypes,
                                      );
                                    }}
                                    deleteIcon={
                                      <ChipCancelIcon
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                  />
                                );
                              },
                            )}
                          </Stack>
                        )}
                      >
                        <FormGroup
                          key={allServicesKey}
                          smallBottomMargin={true}
                        >
                          <FormControlLabel
                            isLabel
                            isTitle
                            control={
                              <Checkbox
                                checked={allServicesSelected}
                                onClick={(e) =>
                                  onUpdatePromoSessionTypes(
                                    e.target.checked,
                                    allServicesKey,
                                    allServicesLabel,
                                    false,
                                    true,
                                  )
                                }
                              />
                            }
                            label={`${allServicesLabel} Eligible for Selected Staff`}
                          />
                        </FormGroup>
                        {!allServicesSelected &&
                          sortBy(availableSessionTypes, (s) =>
                            s.categoryName === unclassifiedKey
                              ? 'zz'
                              : s.categoryName,
                          )?.map((sessionTypeCategory) => {
                            const { categoryId, categoryName, services } =
                              sessionTypeCategory;
                            const categoryLabel = `${
                              categoryName || 'Unknown Category'
                            } Services`;
                            const wholeCategorySelected =
                              categoryId in promoToEditSessionTypes;
                            return (
                              <>
                                <FormGroup
                                  key={categoryId}
                                  smallBottomMargin={wholeCategorySelected}
                                >
                                  <FormControlLabel
                                    isLabel
                                    isTitle
                                    control={
                                      <Checkbox
                                        checked={wholeCategorySelected}
                                        onClick={(e) =>
                                          onUpdatePromoSessionTypes(
                                            e.target.checked,
                                            categoryId,
                                            categoryName,
                                            true,
                                          )
                                        }
                                      />
                                    }
                                    label={categoryLabel}
                                  />
                                </FormGroup>
                                {!wholeCategorySelected &&
                                  sortBy(services, (s) => s.displayName).map(
                                    (s, idx) => (
                                      <FormGroup
                                        key={s.id}
                                        largeBottomMargin={
                                          idx === services?.length - 1
                                        }
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={
                                                wholeCategorySelected ||
                                                s.id in promoToEditSessionTypes
                                              }
                                              onClick={(e) =>
                                                onUpdatePromoSessionTypes(
                                                  e.target.checked,
                                                  s.id,
                                                  s.displayName,
                                                  false,
                                                  false,
                                                  categoryId,
                                                )
                                              }
                                            />
                                          }
                                          label={s.displayName}
                                        />
                                      </FormGroup>
                                    ),
                                  )}
                              </>
                            );
                          })}
                      </Dropdown>
                    </Form>
                  }
                />
              </SelectContainer>
              <SelectContainer>
                <SelectTitleTextContainer>
                  <StartAlignedMediumDarkSmallText>
                    Select which staff to apply the promo for
                  </StartAlignedMediumDarkSmallText>
                </SelectTitleTextContainer>
                <Input
                  id='staff'
                  value={promoToEditStaffArray}
                  removeGap={true}
                  CustomInputComponent={
                    <Form fitContentHeight>
                      <Dropdown
                        multiple
                        value={promoToEditStaffArray}
                        renderValue={(selected) => {
                          return (
                            <Stack>
                              {Object.values(selected)?.map(
                                (selectedStaffObject) => {
                                  const { id, name, isAllKey } =
                                    selectedStaffObject;
                                  const formattedName = isAllKey
                                    ? allStaffLabel
                                    : inDemoMode
                                    ? `${getInitials(
                                        name.split(' ')?.[0],
                                        name.split(' ')?.[1],
                                      )}`
                                    : name?.length > 20
                                    ? `${name.slice(0, 17)}...`
                                    : name;

                                  return (
                                    <Chip
                                      key={id}
                                      secondary={isAllKey}
                                      label={formattedName}
                                      onDelete={() => {
                                        const updatedStaff = removeItemObject(
                                          promoToEditStaff,
                                          id,
                                        );
                                        setPromoToEditStaff(updatedStaff);
                                      }}
                                      deleteIcon={
                                        <ChipCancelIcon
                                          onMouseDown={(event) =>
                                            event.stopPropagation()
                                          }
                                        />
                                      }
                                    />
                                  );
                                },
                              )}
                            </Stack>
                          );
                        }}
                      >
                        <FormGroup key={allStaffKey}>
                          <FormControlLabel
                            isLabel
                            isTitle
                            control={
                              <Checkbox
                                checked={allStaffSelected}
                                onClick={(e) =>
                                  onUpdatePromoStaff(
                                    e.target.checked,
                                    allStaffKey,
                                    allStaffLabel,
                                    true,
                                  )
                                }
                              />
                            }
                            label={'All Eligible Staff'}
                          />
                        </FormGroup>
                        {!allStaffSelected &&
                          sortBy(availableStaff, (s) => s.firstName)?.map(
                            (staffMember) => {
                              const { id, firstName, lastName } = staffMember;
                              const name = inDemoMode
                                ? getInitials(firstName, lastName)
                                : `${firstName} ${lastName}`;

                              return (
                                <>
                                  <FormGroup key={id}>
                                    <FormControlLabel
                                      isLabel
                                      control={
                                        <Checkbox
                                          checked={id in promoToEditStaff}
                                          onClick={(e) =>
                                            onUpdatePromoStaff(
                                              e.target.checked,
                                              id,
                                              name,
                                            )
                                          }
                                        />
                                      }
                                      label={name}
                                    />
                                  </FormGroup>
                                </>
                              );
                            },
                          )}
                      </Dropdown>
                    </Form>
                  }
                />
              </SelectContainer>
              <SelectContainer>
                <SelectTitleTextContainer>
                  <StartAlignedMediumDarkSmallText>
                    Apply discount
                  </StartAlignedMediumDarkSmallText>
                </SelectTitleTextContainer>
                <SplitInput
                  id={'discount'}
                  splitInputs={[
                    {
                      id: 'discount-type',
                      value: promoToEditDiscountType,
                      onChange: (e) =>
                        setPromoToEditDiscountType(e.target.value),
                      label: 'Type',
                      type: dropdownInputType,
                      options: discountOptions,
                    },
                    {
                      id: 'amount',
                      value: promoToEditDiscountAmount,
                      onChange: (e) =>
                        setPromoToEditDiscountAmount(e.target.value),
                      label:
                        promoToEditDiscountType !== flatRateType
                          ? 'Amount discounted'
                          : 'Flat rate price ($) ',
                      type: promoToEditDiscountType,
                    },
                  ]}
                  useExtraSmallGap={true}
                />
              </SelectContainer>
              <SelectContainer>
                <SelectTitleTextContainer>
                  <StartAlignedMediumDarkSmallText>
                    Select when this promo should run
                  </StartAlignedMediumDarkSmallText>
                </SelectTitleTextContainer>
                <DatesContainer>
                  <DateContainer>
                    <DateTextContainer>
                      <MediumDarkEssText>Start Date</MediumDarkEssText>
                      <CalendarIcon
                        selected={promoToEditStartDateMenuOpenBoolean}
                        onClick={(e) =>
                          setPromoToEditStartDateMenuAnchorEl(e.currentTarget)
                        }
                      />
                      <PopperMenuCalendar
                        date={promoToEditStartDate}
                        open={promoToEditStartDateMenuOpenBoolean}
                        anchorElement={promoToEditStartDateMenuAnchorEl}
                        onClose={() => setPromoToEditStartDateMenuAnchorEl()}
                        onChange={(d) => {
                          setPromoToEditStartDate(d);
                          setPromoToEditStartDateMenuAnchorEl();
                        }}
                        placement='bottom-start'
                      />
                    </DateTextContainer>
                    {promoToEditStartDate ? (
                      <LightDarkEssText>
                        {dateToTextFormat(promoToEditStartDate)}
                      </LightDarkEssText>
                    ) : (
                      <LightDarkEssText>
                        <br></br>
                      </LightDarkEssText>
                    )}
                  </DateContainer>
                  <DateContainer end>
                    <DateTextContainer end>
                      <MediumDarkEssText>End Date</MediumDarkEssText>
                      <CalendarIcon
                        selected={promoToEditEndDateMenuOpenBoolean}
                        onClick={(e) => {
                          setPromoToEditEndDateMenuAnchorEl(e.currentTarget);
                        }}
                      />
                      <PopperMenuCalendar
                        date={promoToEditEndDate}
                        open={promoToEditEndDateMenuOpenBoolean}
                        anchorElement={promoToEditEndDateMenuAnchorEl}
                        onClose={() => setPromoToEditEndDateMenuAnchorEl()}
                        onChange={(d) => {
                          setPromoToEditEndDate(d);
                          setPromoToEditEndDateMenuAnchorEl();
                        }}
                        placement='bottom-start'
                      />
                    </DateTextContainer>
                    {promoToEditEndDate ? (
                      <LightDarkEssText>
                        {dateToTextFormat(promoToEditEndDate)}
                      </LightDarkEssText>
                    ) : (
                      <LightDarkEssText>
                        <br></br>
                      </LightDarkEssText>
                    )}
                  </DateContainer>
                </DatesContainer>
              </SelectContainer>
              <CenteredDivWithLargerGap topMargin={20}>
                <MediumPrimaryButton
                  disabled={!savePromoEnabled}
                  onClick={() => onSavePromo()}
                >
                  Save
                </MediumPrimaryButton>
                <MediumSecondaryButton onClick={() => onClickBack()}>
                  Cancel
                </MediumSecondaryButton>
              </CenteredDivWithLargerGap>
            </MediumGapColumnCenteredDiv>
          </ScrollWrapper>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default EditPromo;
