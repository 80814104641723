import styled, { css } from 'styled-components';
import {
  CenterFlexStyle,
  ColumnFlexStyle,
  ContainerPadding,
  LargeOffsetUnderlineStyle,
  LightDarkExtraSmallText,
  SMText,
  ScrollStyle,
  SmallGapStyle,
} from '../../../styles/shared-styled-components';
import {
  availableKey,
  bookedKey,
  demoStatusKey,
  inactiveKey,
  unavailableKey,
} from '../../../utils/constants';
import { CalendarFixedMaxWidth } from '../Calendar/styled';

export const ScheduleContainer = styled.div`
  ${ColumnFlexStyle}
  width: ${CalendarFixedMaxWidth}px;
  height: ${(props) =>
    props.fixedHeight ? `${props.fixedHeight}px` : '900px'};
  background-color: white;
  margin-bottom: 100px;
  ${(props) =>
    !props.removeTopPadding
      ? css`
          margin-top: 30px;
          ${ContainerPadding}
        `
      : css`
          padding: 0px;
        `}
`;

export const DateTitleText = styled(SMText)`
  color: ${({ theme }) => theme.color.mediumDarkText};
  ${LargeOffsetUnderlineStyle}
`;

export const CalendarBox = styled.div`
  height: 80%;
  padding-right: 100px;
  display: flex;
`;

export const CalendarContainer = styled.div`
  height: 100%;
  ${CenterFlexStyle}
  flex: 1;
  overflow-x: scroll;
  border: 1px solid black;
  border-radius: ${({ theme }) => theme.spacing.mlBorderRadius}px
    ${({ theme }) => theme.spacing.mlBorderRadius}px 0px 0px;
  position: relative;
  background-color: ${({ theme }) => theme.color.muiInput};
`;

export const ScrollContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  ${ScrollStyle}

  ::-webkit-scrollbar {
    height: 5px;
  }
`;

export const TimeSlotTitleColumn = styled.div`
  ${ColumnFlexStyle}
  width: 121px;
`;

export const BlankSquare = styled.div`
  background-color: ${({ theme }) => theme.color.superLightPrimary};
  border-bottom: 3px solid ${({ theme }) => theme.color.disabled};
  border-right: 3px solid ${({ theme }) => theme.color.disabled};
  min-height: 50px;
  display: inline-block;
`;

export const TimeSlotTextContainer = styled.div`
  color: ${({ theme }) => theme.color.darkText};
  border-right: 1px solid ${({ theme }) => theme.color.muiInputDarkGray};
  ${CenterFlexStyle}
  width: 120px;
  margin-right: 2px;
  min-height: 20px;
  background-color: white;
  ${(props) =>
    props.placeholder
      ? css`
          border: 0px solid white;
        `
      : props.top
      ? css``
      : props.bottom
      ? css`
          border-top: 1px solid ${({ theme }) => theme.color.muiInputDarkGray};
          border-bottom: 1px solid
            ${({ theme }) => theme.color.muiInputDarkGray};
        `
      : css`
          border-top: 1px solid ${({ theme }) => theme.color.muiInputDarkGray};
        `}
`;

export const TimeSlotText = styled(LightDarkExtraSmallText)`
  font-size: 12px;
`;

export const StaffColumnContainer = styled.div`
  display: flex;
  ${SmallGapStyle}
`;

export const StaffColumn = styled.div`
  ${ColumnFlexStyle}
  min-width: 80px;
  background-color: ${({ theme }) => theme.color.muiInputDarkGray};
`;

export const StaffNameCell = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.muiInputDarkGray};
  border-right: 1px solid ${({ theme }) => theme.color.muiInputDarkGray};
  ${CenterFlexStyle}
  width: calc(100% - 2px);
  min-height: 52px;
  background-color: white;
`;

export const StaffNameText = styled(LightDarkExtraSmallText)`
  overflow-wrap: anywhere;
  line-height: 1.2;
  cursor: default;
  font-size: 13px;
`;

export const TimeSlotColumn = styled(TimeSlotTitleColumn)`
  width: unset;
`;

export const TimeSlotContainer = styled(TimeSlotTextContainer)`
  border-color: transparent;
  background-color: ${(props) =>
    props.status === unavailableKey
      ? props.theme.color.error
      : props.status === bookedKey
      ? props.theme.color.brightGreen
      : props.status === availableKey
      ? props.theme.color.offwhite
      : props.status === demoStatusKey
      ? props.theme.color.lightPrimary
      : props.theme.color.muiInput};
  ${(props) =>
    props.status !== inactiveKey &&
    css`
      border-right: 1px solid ${({ theme }) => theme.color.charcoal};
      border-left: 1px solid ${({ theme }) => theme.color.charcoal};
    `}
  ${(props) =>
    props.isStart &&
    css`
      border-top: 1px solid ${({ theme }) => theme.color.charcoal};
      margin-top: 1px;
    `}
    ${(props) =>
    props.isEnd &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.color.charcoal};
      margin-bottom: 1px;
    `}
  width: unset;
  margin: 0px;
  padding-bottom: 0px;
  /* border-bottom: ${(props) =>
    props.bottom && `1px solid ${props.theme.color.muiInputDarkGray}`}; */
`;
