import styled from 'styled-components';
import {
  AutoHorizontalMargins,
  ColumnCenteredDiv,
} from '../../../styles/shared-styled-components';

export const CopyContainer = styled(ColumnCenteredDiv)`
  margin-top: 90px;
  margin-bottom: 10px;
  width: 80%;
  ${AutoHorizontalMargins}
`;
