import React, { useContext, useState } from 'react';
import { useAgentVersion } from '../../../../api/hooks/agent';
import {
  DarkGoldText,
  LightDarkEssText,
  MediumDarkSmallText,
  MediumText,
  SmallEditIcon,
  SmallEditIconContainer,
  SmallSaveIcon,
  SmallSaveIconContainer,
} from '../../../../styles/shared-styled-components';
import { dateToTextFormat } from '../../../../utils/date';
import { getUserLocation } from '../../../../utils/user';
import { BaseContext } from '../../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  PromptContainer,
  PromptInput,
  PromptsSection,
} from '../../shared-training-components';
import {
  AgentAvatar,
  AgentAvatarContainer,
  AgentNameContainer,
} from './styled';

const Info = ({ agent, onChange, onBlur, loading }) => {
  const { user } = useContext(BaseContext);

  const id = agent?.id;
  const { version, loading: versionLoading } = useAgentVersion({
    id,
    skipCondition: !id,
  });
  console.log('version', version);
  const versionTitle = `v${version?.version}`;
  const versionName = `${version?.name}`;
  const versionDescription = version ? `${versionName}: ${versionName}` : '';

  const location = getUserLocation(user);

  const name = agent?.name;
  const createdAt = agent?.createdAt;
  console.log('agent', agent);

  const [editing, setEditing] = useState(false);

  const handleBlur = () => {
    onBlur();
    setEditing(false);
  };

  if (loading || versionLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer>
        <AgentAvatarContainer>
          <AgentAvatar />
        </AgentAvatarContainer>
        <AgentNameContainer>
          {!editing ? (
            <>
              <MediumText>{name || `LiveIQ Agent`}</MediumText>
              <SmallEditIconContainer onClick={() => setEditing(true)}>
                <SmallEditIcon />
              </SmallEditIconContainer>
            </>
          ) : (
            <>
              <PromptInput
                value={name}
                onChange={(e) => onChange(e.target.value)}
                onBlur={() => handleBlur()}
                useExtraSmallWidth
              />
              <SmallSaveIconContainer onClick={() => handleBlur()}>
                <SmallSaveIcon />
              </SmallSaveIconContainer>
            </>
          )}
        </AgentNameContainer>
      </PromptContainer>
      <PromptContainer>
        <MediumDarkSmallText>
          A valued front desk team member at {location.name} since{' '}
          {dateToTextFormat(createdAt)}
        </MediumDarkSmallText>
        {version && (
          <LightDarkEssText>
            Running <DarkGoldText>LIQ {versionTitle}:</DarkGoldText>{' '}
            {versionName}
          </LightDarkEssText>
        )}
      </PromptContainer>
    </PromptsSection>
  );
};

export default Info;
