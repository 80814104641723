import styled, { css } from 'styled-components';
import { BaseStatusChip } from '../../../styles/shared-styled-components';
import { assistantKey } from '../../../utils/constants';

export const AgentTypeChip = styled(BaseStatusChip)`
  ${(props) =>
    props.type === assistantKey
      ? css`
          color: white;
          background-color: ${({ theme }) => theme.color.turquoise};
        `
      : css`
          color: ${({ theme }) => theme.color.muiInputDarkGray};
          background-color: ${({ theme }) => theme.color.underReview};
        `}
`;
